import {Component, OnInit, Input, HostBinding, ViewChild} from '@angular/core';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyPipe, PercentPipe} from '@angular/common';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {PeerGroupInfo, PeerGroupDetails} from 'src/app/core/models/create-view.model';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import {Subscription, forkJoin} from 'rxjs';
import FirmBasicsModel from '../../../models/firm-basics.model';
import {v4 as uuidv4} from 'uuid';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';

@Component({
  selector: 'app-expand-table-offices-expenses',
  templateUrl: './expand-table-offices-expenses.component.html',
})
export class ExpandTableOfficesExpensesComponent implements OnInit {
  @Input() statistic: any;
  @Input() metricId: number;
  @Input() metricBasicData: any;
  @Input() highLowPercentilesCols: any;
  @Input() peerGroupItems: any;
  @Input() pivotIndex: any;
  @Input() selectedFirmId: any;
  @Input() expandType: string;
  @Input() selectedMericData: any;
  @Input() userSettingsModel: any;
  subscription: Subscription[];
  @Input() frozenColumnCount = 0;
  @Input() isFromExpandTableView: boolean = false;
  @Input() isTAdmin: boolean = false;

  columnGroups: any;
  pg_mean_variance: any;
  pg_median_variance: any;
  standardDeviationLess: any;
  standardDeviationGreater: any;
  isExpandDataLoading = false;
  alerts: BentoAlertItemOptions[] = [];
  errorMessage = '';

  office = [];
  officeChildren = [];
  officeFinalData = [];
  listChildrenNames = [];
  listParentNames = [];
  peerGroupDetail: PeerGroupInfo;
  showExpand = false;

  itemFormatter = this.itemFormatterBounded.bind(this);
  itemFormatterExpenses = this.itemFormatterBoundedExpenses.bind(this);
  flag = false;
  gridHeaders = [];
  selectedOffice: any;

  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }
  modalTitle: String = '';
  mainData = [];
  showBasicTable = false;
  requestBody: any;
  isServiceCalled = false;
  officeDataParent: any;
  officeDataChildren: any;
  allOffices: any;

  firmBasicData = new FirmBasicsModel();
  category = [];
  categoryGroup = [];
  categoryFinalData = [];
  level2 = [];
  level3 = [];
  finalData = [];
  firmRules = [];

  categoryParentData: any;
  categoryChildrenData: any;
  dataForTotal: any;
  metricData: any;
  peerGroupSelectedItems: any;
  yoyHeader = '';
  lastPressedKey: any;
  isGetBasicAPICalled = false;
  isValueInitialized = false;
  isRowDataEmpty = true;
  isCANfirm: boolean = false;
  _lastFocusCell: any;
  @ViewChild('listOfFirmsModal', {static: true}) listOfFirmsModalContent: any;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjGrid.FlexGrid;

  constructor(
    private modalService: NgbModal,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    public _service: PeerCheckServiceService,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService
  ) {
    store.subscribe((state) => {
      const {pivotRequestBody} = state;
      const {statistic} = state;
      if (!this.requestBody && pivotRequestBody) {
        this.requestBody = pivotRequestBody;
        if (this.requestBody && this.isServiceCalled == false && this.expandType == 'expandExpenses') {
          this.getDataForExpenseCategories();
        } else if (this.requestBody && this.isServiceCalled == false && this.expandType == 'expandOffices') {
          this.getDataForOffice();
        }
      }
      if (statistic != null && this.expandType == 'expandOffices') {
        this.statistic = statistic;
        this.columnGroups = this.getColumnGroups();
      }
      // this.columnGroups = this.getColumnGroupsExpenses();
    });
  }

  ngOnInit(): void {
    //this.expandType = this.type1;
    if (this.expandType == 'expandExpenses') this.getBasicDetails();
    this.dataForTotal = this.selectedMericData;
    this.subscription = [];
    eventDispatcher.next({type: ActionTypes.GetPivotRequestBody});
  }

  async getBasicDetails() {
    this.isExpandDataLoading = true;
    //Service will return getBasic details and firm level rules
    this.firmBasicData = await this._service.getFirmBasicsData(this.getfirmId);
    this.initializeValues();
    this.isGetBasicAPICalled = true;
  }

  initializeValues() {
    this.metricData = this.firmBasicData.metrics.filter((item) => item.metricId == this.metricId)[0];
    this.firmRules = this.firmBasicData.rules;
    this.applyFirmRules();
    this.peerGroupSelectedItems = this.peerGroupItems;
    this.isValueInitialized = true;
    if (this.firmBasicData.defaultAccess == 'CAN') this.isCANfirm = true;
  }
  applyFirmRules() {
    let rules = [];
    this.firmRules.forEach((element) => {
      rules.push(element.rule_name);
    });
  }

  onChangeStatistic(event) {
    eventDispatcher.next({type: ActionTypes.SetStatistic, payload: event.target.value});
  }

  itemFormatterBounded(panel, r, c, cell) {
    if (this.expandType == 'expandOffices') {
      if (panel.cellType === wjGrid.CellType.Cell) {
        const flex = panel.grid;
        const col = flex.columns[c];
        if (col.binding === 'officeName') {
          const cellData = flex.getCellData(r, c);
          if (this.listChildrenNames.includes(cellData)) cell.style['padding-left'] = '2.75rem';
          if (this.listParentNames.includes(cellData)) cell.style['fontWeight'] = 'bold';
        } else if (col.binding === 'trend') {
          const cellData = flex.getCellData(r, c);
          if (cellData >= 0) {
            cell.innerText = '';
            cell.classList.add('bento-icon-caret-up-filled');
            cell.style.color = '#1ca91c';
          } else if (cellData < 0) {
            cell.innerText = '';
            cell.classList.add('bento-icon-caret-down-filled');
            cell.style.color = '#e23704';
          }
        }
      }
    } else if (this.expandType == 'expandExpenses') {
      if (panel.cellType === wjGrid.CellType.Cell) {
        const flex = panel.grid;
        const col = flex.columns[c];
        const row = flex.rows[r];
        if (row.dataItem.category === 'TOTAL' && c > 0) {
          cell.align = 'right';
          if (this.isCANfirm) row.visible = false;
        } else {
          if (col.binding === 'category') {
            const cellData = flex.getCellData(r, c);
            if (this.listChildrenNames.includes(cellData)) cell.style['padding-left'] = '2.75rem';
            if (this.listParentNames.includes(cellData)) cell.style['fontWeight'] = 'bold';
          }
          if (col.header === 'Quartile') {
            cell.align = 'center';
          }
        }
      }
    }
  }

  private get getfirmId(): number {
    if (this.selectedFirmId) return this.selectedFirmId;
    else {
      const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
      return backendTokenClaims.userDDO && !this.route.snapshot.params.id
        ? backendTokenClaims.userDDO.firmID
        : parseInt(this.route.snapshot.paramMap.get('id'));
    }
  }
  getDataForExpandTable() {
    if (this.expandType == 'expandOfffices') this.getDataForOffice();
    else this.getDataForExpenseCategories();
  }

  getDataForOffice() {
    this.isExpandDataLoading = true;
    this.isServiceCalled = true;
    this.requestBody[this.pivotIndex].requestBodyExpandOfficeParent.metrics = [this.metricId];
    this.requestBody[this.pivotIndex].requestBodyExpandOfficeParent.groupBy = ['office'];
    this.requestBody[this.pivotIndex].requestBodyExpandOfficeParent.quid = uuidv4();

    this.requestBody[this.pivotIndex].requestBodyExpandOfficeChildren.metrics = [this.metricId];
    this.requestBody[this.pivotIndex].requestBodyExpandOfficeChildren.officeGroups =
      this.requestBody[this.pivotIndex].officeGroups;
    this.requestBody[this.pivotIndex].requestBodyExpandOfficeChildren.quid = uuidv4();

    this.requestBody[this.pivotIndex].requestBodyExpandAllOffices.metrics = [this.metricId];
    this.requestBody[this.pivotIndex].requestBodyExpandAllOffices.quid = uuidv4();

    this.selectedOffice = this.requestBody[this.pivotIndex].requestBodyExpandOfficeParent.offices;

    let officeParent = this.service.post(
      environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId,
      this.requestBody[this.pivotIndex].requestBodyExpandOfficeParent
    );
    let officeChildren = this.service.post(
      environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId,
      this.requestBody[this.pivotIndex].requestBodyExpandOfficeChildren
    );
    let allOffices = this.service.post(
      environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId,
      this.requestBody[this.pivotIndex].requestBodyExpandAllOffices
    );

    forkJoin([officeParent, officeChildren, allOffices]).subscribe(
      (results) => {
        if (results[0]) this.officeDataParent = results[0][0];
        this.officeDataChildren = results[1];
        this.allOffices = results[2][0];
        this.processOfficeData();
        this.getStdRatesData();
        this.columnGroups = this.getColumnGroups();
        this.showExpand = true;
        this.isExpandDataLoading = false;
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });

        this.isExpandDataLoading = false;
      }
    );
  }

  processOfficeData() {
    let k = false;
    this.officeDataChildren.forEach((element) => {
      if (element.officeGroupChildren) {
        let officeChildren = element.officeGroupChildren.children;
        element.values.forEach((group) => {
          if (this.officeDataParent.values) {
            this.officeDataParent.values.forEach((item) => {
              if (officeChildren.includes(Number(item.office))) {
                item['olvl2'] = item.office;
                item.office = group.office;
                k = true;
              }
            });
            if (k == true) {
              this.officeDataParent.values.push(group);
              k = false;
            }
          }
        });
      }
    });
  }

  getStdDataFinal() {
    this.mainData = [];
    this.office = [];
    this.officeChildren = [];
    this.officeFinalData = [];
    this.listChildrenNames = [];
    let indent = false;

    if (this.allOffices.values) {
      this.allOffices.values.forEach((obj, indx) => {
        obj.officeName = 'All Offices';
        this.officeFinalData.push(obj);
      });
    }

    // handle null values for office names
    if (this.officeDataParent.values) {
      this.officeDataParent.values.forEach((off) => {
        if (off.officeName == null) off.officeName = '';
      });

      let testData = this.officeDataParent;

      testData.values.forEach((obj, indx) => {
        if (obj.olvl2) this.officeChildren.push(obj);
        else this.office.push(obj);
      });

      this.office = this.office.sort((a, b) => a.officeName.localeCompare(b.officeName));
      this.officeChildren = this.officeChildren.sort((a, b) => a.officeName.localeCompare(b.officeName));
      this.office.forEach((obj, indx) => {
        if (
          (this.selectedOffice.length > 0 && this.selectedOffice.includes(Number(obj.office))) ||
          this.selectedOffice.length == 0
        )
          this.officeFinalData.push(obj);
        indent = false;
        let a = this.officeChildren.filter((a) => a.office == obj.office);
        if (a.length > 0) {
          this.listParentNames.push(obj.officeName);
          if (this.officeFinalData.length > 0)
            if (a[0].office == this.officeFinalData[this.officeFinalData.length - 1].office) indent = true;
        }
        a.forEach((item) => {
          this.officeFinalData.push(item);
          if (indent) this.listChildrenNames.push(item.officeName);
        });
      });
    }
    return this.officeFinalData;
  }

  getStdRatesData() {
    let mainData = [];
    let testData = this.getStdDataFinal();

    if (testData) {
      testData.forEach((obj, indx) => {
        let officeName = '';
        officeName = obj.officeName;

        obj.metrics.forEach((item, index) => {
          if (item.metricId == this.metricId) {
            let practiceGroupData = {};
            let myFirmValue = this.metricBasicData.isDeltaOnly ? item.firmDelta : item.firmValue;
            let myFirmFormat = this.metricBasicData.isDeltaOnly ? item.firmDeltaFormat : item.firmValueFormat;
            practiceGroupData['officeName'] = officeName;
            if (myFirmValue == null) practiceGroupData['firmValue'] = '*';
            else {
              if (myFirmFormat.startsWith('percent'))
                practiceGroupData['firmValue'] = this.percentPipe.transform(
                  myFirmValue,
                  '1.1-1' + myFirmFormat.slice(-1)
                );
              else if (myFirmFormat.startsWith('number') || myFirmFormat.startsWith('actual'))
                practiceGroupData['firmValue'] = myFirmValue.toFixed(myFirmFormat.slice(-1));
              else
                practiceGroupData['firmValue'] = this.currencyPipe.transform(
                  myFirmValue,
                  this.officeDataParent.currency,
                  'symbol',
                  '1.0-' + myFirmFormat.slice(-1)
                );
            }

            let myFirmDeltaValue = item.firmDelta;
            let myFirmDeltaFormat = item.firmDeltaFormat;
            if (item.firmDelta == null) practiceGroupData['firmDelta'] = '--';
            else {
              if (myFirmDeltaFormat.startsWith('percent'))
                practiceGroupData['firmDelta'] = this.percentPipe.transform(
                  myFirmDeltaValue,
                  '1.1-1' + myFirmDeltaFormat.slice(-1)
                );
              else if (myFirmDeltaFormat.startsWith('number') || myFirmDeltaFormat.startsWith('actual'))
                practiceGroupData['firmDelta'] = myFirmDeltaFormat.toFixed(myFirmDeltaFormat.slice(-1));
              else
                practiceGroupData['firmDelta'] = this.currencyPipe.transform(
                  myFirmDeltaValue,
                  this.officeDataParent.currency,
                  'symbol',
                  '1.0-' + myFirmDeltaFormat.slice(-1)
                );
            }

            if (item.firmDelta == null) practiceGroupData['trend'] = 'N/A';
            else practiceGroupData['trend'] = item.firmDelta;

            item.peerGroups.forEach((element, index) => {
              let peerGroupData = {};
              peerGroupData['pg_peerGrpName'] = element.peerGroupName;
              let meanValue = this.metricBasicData.isDeltaOnly ? element.meanDelta : element.mean;
              let meanFormat = this.metricBasicData.isDeltaOnly ? element.meanDeltaFormat : element.meanFormat;
              if (meanValue == null) peerGroupData['pg_mean'] = '*';
              else {
                if (meanFormat.startsWith('percent'))
                  peerGroupData['pg_mean'] = this.percentPipe.transform(meanValue, '1.1-1' + meanFormat.slice(-1));
                else if (meanFormat.startsWith('number') || meanFormat.startsWith('actual'))
                  peerGroupData['pg_mean'] = meanValue.toFixed(meanFormat.slice(-1));
                else
                  peerGroupData['pg_mean'] = this.currencyPipe.transform(
                    meanValue,
                    this.officeDataParent.currency,
                    'symbol',
                    '1.0-' + meanFormat.slice(-1)
                  );
              }

              let yoyMeanValue = element.meanDelta;
              let yoyMeanFormat = element.meanDeltaFormat;
              if (yoyMeanValue == null) peerGroupData['pg_mean_delta'] = '*';
              else {
                if (yoyMeanFormat.startsWith('percent'))
                  peerGroupData['pg_mean_delta'] = this.percentPipe.transform(
                    yoyMeanValue,
                    '1.1-1' + yoyMeanFormat.slice(-1)
                  );
                else if (yoyMeanFormat.startsWith('number') || yoyMeanFormat.startsWith('actual'))
                  peerGroupData['pg_mean_delta'] = yoyMeanValue.toFixed(yoyMeanFormat.slice(-1));
                else
                  peerGroupData['pg_mean_delta'] = this.currencyPipe.transform(
                    yoyMeanValue,
                    this.officeDataParent.currency,
                    'symbol',
                    '1.0-' + yoyMeanFormat.slice(-1)
                  );
              }

              let yoyMedianValue = element.medianDelta;
              let yoyMedianFormat = element.medianDeltaFormat;
              if (yoyMedianValue == null) peerGroupData['pg_median_delta'] = '*';
              else {
                if (yoyMedianFormat.startsWith('percent'))
                  peerGroupData['pg_median_delta'] = this.percentPipe.transform(
                    yoyMedianValue,
                    '1.1-1' + yoyMedianFormat.slice(-1)
                  );
                else if (yoyMedianFormat.startsWith('number') || yoyMedianFormat.startsWith('actual'))
                  peerGroupData['pg_median_delta'] = yoyMedianValue.toFixed(yoyMedianFormat.slice(-1));
                else
                  peerGroupData['pg_median_delta'] = this.currencyPipe.transform(
                    yoyMedianValue,
                    this.officeDataParent.currency,
                    'symbol',
                    '1.0-' + yoyMedianFormat.slice(-1)
                  );
              }

              let medianValue = this.metricBasicData.isDeltaOnly ? element.medianDelta : element.median;
              let medianFormat = this.metricBasicData.isDeltaOnly ? element.medianDeltaFormat : element.medianFormat;

              if (medianValue == null) peerGroupData['pg_median'] = '*';
              else {
                if (medianFormat.startsWith('percent'))
                  peerGroupData['pg_median'] = this.percentPipe.transform(
                    medianValue,
                    '1.1-1' + medianFormat.slice(-1)
                  );
                else if (medianFormat.startsWith('number') || medianFormat.startsWith('actual'))
                  peerGroupData['pg_median'] = medianValue.toFixed(medianFormat.slice(-1));
                else
                  peerGroupData['pg_median'] = this.currencyPipe.transform(
                    medianValue,
                    this.officeDataParent.currency,
                    'symbol',
                    '1.0-' + medianFormat.slice(-1)
                  );
              }

              //calculate stdDeviation

              let peerVal = this.metricBasicData.isDeltaOnly ? element.meanDelta : element.mean;

              this.standardDeviationLess =
                peerVal == null && element.standardDeviation == null ? null : peerVal - element.standardDeviation;

              this.standardDeviationGreater =
                peerVal == null && element.standardDeviation == null ? null : peerVal + element.standardDeviation;

              if (this.standardDeviationLess == null) peerGroupData['pg_one_std_deviation_less'] = 'N/A';
              else {
                if (element.standardDeviationFormat.startsWith('percent'))
                  peerGroupData['pg_one_std_deviation_less'] = this.percentPipe.transform(
                    this.standardDeviationLess,
                    '1.1-1' + element.standardDeviationFormat.slice(-1)
                  );
                else if (
                  element.standardDeviationFormat.startsWith('number') ||
                  element.standardDeviationFormat.startsWith('actual')
                )
                  peerGroupData['pg_one_std_deviation_less'] = this.standardDeviationLess.toFixed(
                    element.standardDeviationFormat.slice(-1)
                  );
                else
                  peerGroupData['pg_one_std_deviation_less'] = this.currencyPipe.transform(
                    this.standardDeviationLess,
                    this.officeDataParent.currency,
                    'symbol',
                    '1.0-' + element.standardDeviationFormat.slice(-1)
                  );
              }

              if (this.standardDeviationGreater == null) peerGroupData['pg_one_std_deviation_greater'] = 'N/A';
              else {
                if (element.standardDeviationFormat.startsWith('percent'))
                  peerGroupData['pg_one_std_deviation_greater'] = this.percentPipe.transform(
                    this.standardDeviationGreater,
                    '1.1-1' + element.standardDeviationFormat.slice(-1)
                  );
                else if (
                  element.standardDeviationFormat.startsWith('number') ||
                  element.standardDeviationFormat.startsWith('actual')
                )
                  peerGroupData['pg_one_std_deviation_greater'] = this.standardDeviationGreater.toFixed(
                    element.standardDeviationFormat.slice(-1)
                  );
                else
                  peerGroupData['pg_one_std_deviation_greater'] = this.currencyPipe.transform(
                    this.standardDeviationGreater,
                    this.officeDataParent.currency,
                    'symbol',
                    '1.0-' + element.standardDeviationFormat.slice(-1)
                  );
              }

              //calculate High-Low
              let low = this.metricBasicData.highBad ? element.highPercentile : element.lowPercentile;
              let high = this.metricBasicData.highBad ? element.lowPercentile : element.highPercentile;

              if (low == null) peerGroupData['pg_low'] = 'N/A';
              else {
                if (element.highPercentileFormat.startsWith('percent'))
                  peerGroupData['pg_low'] = this.percentPipe.transform(
                    low,
                    '1.1-1' + element.highPercentileFormat.slice(-1)
                  );
                else if (
                  element.highPercentileFormat.startsWith('number') ||
                  element.highPercentileFormat.startsWith('actual')
                )
                  peerGroupData['pg_low'] = low.toFixed(element.highPercentileFormat.slice(-1));
                else
                  peerGroupData['pg_low'] = this.currencyPipe.transform(
                    low,
                    this.officeDataParent.currency,
                    'symbol',
                    '1.0-' + element.highPercentileFormat.slice(-1)
                  );
              }

              if (high == null) peerGroupData['pg_high'] = 'N/A';
              else {
                if (element.highPercentileFormat.startsWith('percent'))
                  peerGroupData['pg_high'] = this.percentPipe.transform(
                    high,
                    '1.1-1' + element.highPercentileFormat.slice(-1)
                  );
                else if (
                  element.highPercentileFormat.startsWith('number') ||
                  element.highPercentileFormat.startsWith('actual')
                )
                  peerGroupData['pg_high'] = high.toFixed(element.highPercentileFormat.slice(-1));
                else
                  peerGroupData['pg_high'] = this.currencyPipe.transform(
                    high,
                    this.officeDataParent.currency,
                    'symbol',
                    '1.0-' + element.highPercentileFormat.slice(-1)
                  );
              }

              //calculate mean variance
              var format = this.metricBasicData.isDeltaOnly ? item.firmDeltaFormat : item.firmValueFormat;
              if (element.meanVariance == null) peerGroupData['pg_mean_variance'] = '*';
              else {
                this.pg_mean_variance = this.percentPipe.transform(element.meanVariance, '1.1-1' + format.slice(-1));

                if (element.meanVariance < 0)
                  peerGroupData['pg_mean_variance'] = this.pg_mean_variance.replace('-', '(') + ')';
                else peerGroupData['pg_mean_variance'] = this.pg_mean_variance;
              }

              //calculate median variance

              if (element.medianVariance == null) peerGroupData['pg_median_variance'] = '*';
              else {
                this.pg_median_variance = this.percentPipe.transform(
                  element.medianVariance,
                  '1.1-1' + format.slice(-1)
                );

                if (element.medianVariance < 0)
                  peerGroupData['pg_median_variance'] = this.pg_median_variance.replace('-', '(') + ')';
                else peerGroupData['pg_median_variance'] = this.pg_median_variance;
              }

              if (practiceGroupData[element.peerGroupId] != undefined)
                practiceGroupData[element.peerGroupId].push(peerGroupData);
              else practiceGroupData[element.peerGroupId] = peerGroupData;

              if (indx == 0) {
                let option = {
                  id: element.peerGroupId,
                  name: element.peerGroupName,
                };
                if (this.gridHeaders.findIndex((pg) => pg.id == element.peerGroupId) < 0) this.gridHeaders.push(option);
                this.flag = true;
              }
            });
            mainData.push(practiceGroupData);
          }
        });
      });
      setTimeout(() => {
        this.mainData = mainData;
      }, 0);
    }
  }

  onInitialized(grid: wjGrid.FlexGrid) {
    const self = this;
    grid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });
    // TODO: To be removed commented code after MVP release
    // grid.formatItem.addHandler((s: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) => {
    //   if (e.range.columnSpan > 1) {
    //     e.cell.innerHTML =
    //       '<a href="" class="F' +
    //       e.cell.id +
    //       '" role="link" aria-label="' +
    //       e.cell.innerText +
    //       '">' +
    //       e.cell.innerText +
    //       '</a>';
    //     let el = e.cell.querySelector('a');
    //     el.onclick = (et: any) => {
    //       et.preventDefault();
    //       this.modalTitle = et.target.innerText;
    //       this.populateSelectedFirmsForPeerGroup(et.target.innerText);
    //     };
    //   }
    // });
    grid.hostElement.setAttribute('role', 'grid');

    // center-align merged header cells
    let count = 1;
    let colSpan = 1;
    if (this.expandType == 'expandExpenses') colSpan = 2;
    grid.formatItem.addHandler((s: wjGrid.FlexGrid, e: any) => {
      if (e.panel == s.columnHeaders) {
        if (e.cell.innerText != 'My firm' && e.cell.innerText != '') {
          let headerText = e.panel.getCellData(e.row, e.col, true);
          let peerGroup = this.peerGroupItems.find((obj) => obj.name == headerText);
          if (!headerText) {
            return;
          } else if (headerText && peerGroup) {
            e.cell.tabIndex = 0;
            e.cell.innerHTML = `
              <h2 class="h5 DataGrid-heading">
                <a
                  href=""
                  aria-haspopup="dialog"
                  id="${peerGroup.id}"
                  role="button"
                  aria-label="${peerGroup.name}"
                  data-pgid="${peerGroup.id}"
                  data-pgName="${peerGroup.name}"
                  tabindex="-1"
                >
                  ${peerGroup.name}
                </a>
              </h2>
            `;

            count++;
            e.cell.setAttribute('role', 'rowheader');
            let el = e.cell.querySelector('a');
            el.onclick = (et: any) => {
              et.preventDefault();
              let dataSet = et.target.dataset;
              this.modalTitle = et.target.ariaLabel;
              this.populateSelectedFirmsForPeerGroup(dataSet, et.target.ariaLabel);
            };
          } else if (headerText && !peerGroup && headerText != '') {
            if (e.row == 1) {
              let header1 = e.panel.getCellData(0, e.col);
              let header2 = e.panel.getCellData(e.row, e.col);
              let headerAriaLabel = header1 ? header1 + ' ' + header2 : header2;
              e.cell.setAttribute('aria-label', `${headerAriaLabel}`);
              let col = e.getColumn();
              e.cell.setAttribute('aria-colindex', col.visibleIndex + 1);
            }
          }
        }
        if (e.row == 0) {
          e.cell.removeAttribute('role');
        }
      } else if (e.cell.innerText && e.cell.classList.value.indexOf('wj-header') != -1) {
        e.cell.innerHTML = '<h2 class="h5 DataGrid-heading">' + e.cell.innerText + '</h2>';
        // e.cell.setAttribute('role', 'rowheader');
      } else {
        e.cell.setAttribute('role', 'gridcell');
      }
      if (e.panel == s.cells) {
        let col = e.getColumn();
        e.cell.setAttribute('aria-colindex', col.visibleIndex + 1);
      }
    });
    this.flexGrid = grid;
    grid.select(new wjGrid.CellRange(-0, 1), true, grid.columnHeaders);
    this.flexGrid = grid;
  }

  populateSelectedFirmsForPeerGroup(dataset: any, peerGroupName: string) {
    this.isExpandDataLoading = true;
    let peerGroup = null;
    if (dataset) {
      peerGroup = this.peerGroupItems.find((obj) => obj.id == dataset.pgid || obj.name == dataset.pgname);
    } else {
      peerGroup = this.peerGroupItems.find((obj) => obj.name == peerGroupName);
    }
    if (peerGroup) {
      if (peerGroup.isPpg) {
        this._service.getCriteriaForPerformancePg(peerGroup, this.isCANfirm, this.isTAdmin);
      } else {
        this._service.getSelectedFirmsForPg(peerGroup);
      }
      let peerGroupDetail = this._service.peerGroupSelectedFirms.subscribe((peerGroupDetails: PeerGroupDetails) => {
        if (peerGroupDetails.hasError) {
          if (peerGroupDetails.alerts && peerGroupDetails.alerts.length) {
            this.alerts = peerGroupDetails.alerts;
          }
        } else {
          this.peerGroupDetail = peerGroupDetails.successResponse;
          if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
          }
          // Addded flexgrid refresh to detect changes in UI
          // TODO: find Root cause of changeDetection after MVP
          this.flexGrid.refresh();
          this.modalService.open(this.listOfFirmsModalContent, {ariaLabelledBy: 'modalPeerGroup'});
        }
      });
      this.subscription.push(peerGroupDetail);
    }
    this.isExpandDataLoading = false;
  }

  getDataForExpenseCategories() {
    this.isExpandDataLoading = true;
    this.isServiceCalled = true;
    let requestBodyForCategory = this.requestBody[this.pivotIndex].requestBodyExpandCategory;
    requestBodyForCategory['groupBy'] = ['level2'];
    requestBodyForCategory['metrics'] = [this.metricId];
    requestBodyForCategory['quid'] = uuidv4();

    let requestBodyForCategoryGroup = this.requestBody[this.pivotIndex].requestBodyExpandCategoryChildren;
    requestBodyForCategoryGroup['groupBy'] = ['level2', 'level3'];
    requestBodyForCategoryGroup['metrics'] = [this.metricId];
    requestBodyForCategoryGroup['quid'] = uuidv4();

    let categoryParent = this.service.post(
      environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId,
      requestBodyForCategory
    );
    let categoryChildren = this.service.post(
      environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.getfirmId,
      requestBodyForCategoryGroup
    );

    forkJoin([categoryParent, categoryChildren]).subscribe(
      (results) => {
        if (results[0]) this.categoryParentData = results[0][0];
        if (results[1]) this.categoryChildrenData = results[1][0];
        if (this.categoryParentData.values) {
          if (this.categoryChildrenData.values) {
            this.processCategoryData();
            this.getStdRatesDataExpenses();
            this.columnGroups = this.getColumnGroupsExpenses();
            this.showExpand = true;
          }
        }
        this.isExpandDataLoading = false;
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
        this.isExpandDataLoading = false;
      }
    );
  }

  processCategoryData() {
    let k = [];
    this.categoryParentData.values.forEach((category) => {
      k = this.categoryChildrenData.values.filter((item) => item.level2 == category.level2);
      this.finalData.push(category);
      if (k.length > 0)
        k.forEach((element) => {
          if (element.level3 != null) this.finalData.push(element);
        });
    });
  }

  itemFormatterBoundedExpenses(panel, r, c, cell) {
    if (panel.cellType === wjGrid.CellType.Cell) {
      const flex = panel.grid;
      const col = flex.columns[c];
      const row = flex.rows[r];
      if (row.dataItem.category === 'TOTAL' && c > 0) {
        cell.align = 'right';
        if (this.isCANfirm) row.visible = false;
      } else {
        if (col.binding === 'category') {
          const cellData = flex.getCellData(r, c);
          if (this.listChildrenNames.includes(cellData)) cell.style['padding-left'] = '2.75rem';
          if (this.listParentNames.includes(cellData)) cell.style['fontWeight'] = 'bold';
        }
        if (col.header === 'Quartile') {
          cell.align = 'center';
        }
      }
    }
  }

  getExpandDataFinal() {
    let total = this.dataForTotal;
    this.level2.push(this.categoryParentData);
    this.level3.push(this.categoryChildrenData);

    this.level2[0].values.forEach((obj, indx) => {
      this.category.push(obj);
    });
    this.level3[0].values.forEach((obj, indx) => {
      if (obj.level2 != obj.level3) this.categoryGroup.push(obj);
    });

    this.category.forEach((obj, indx) => {
      this.categoryFinalData.push(obj);
      let a = this.categoryGroup.filter((a) => a.level2 == obj.level2);
      if (a.length > 0) this.listParentNames.push(obj.level2);
      a.forEach((item) => this.categoryFinalData.push(item));
    });

    if (total) this.categoryFinalData.push(total);
    this.categoryGroup.forEach((obj) => {
      this.listChildrenNames.push(obj.level3);
    });

    return this.categoryFinalData;
  }

  getStdRatesDataExpenses() {
    let mainData = [];
    let testData = this.getExpandDataFinal();
    let option = {
      id: 1,
      name: 'My firm',
    };
    this.gridHeaders.push(option);

    testData.forEach((obj, indx) => {
      let category = '';
      if (!obj.level3 && !obj.level2) category = 'TOTAL';
      else if (!obj.level3) {
        category = obj.level2;
      } else category = obj.level3;
      obj.metrics.forEach((item, index) => {
        let practiceGroupData = {};
        practiceGroupData['category'] = category;
        this.isRowDataEmpty = true;
        if (item.firmValue == null) practiceGroupData['firmValue'] = '*';
        else {
          this.isRowDataEmpty = false;
          if (item.firmValueFormat.startsWith('percent'))
            practiceGroupData['firmValue'] = this.percentPipe.transform(
              item.firmValue,
              '1.1-1' + item.firmValueFormat.slice(-1)
            );
          else if (item.firmValueFormat.startsWith('number') || item.firmValueFormat.startsWith('actual'))
            practiceGroupData['firmValue'] = item.firmValue.toFixed(item.firmValueFormat.slice(-1));
          else
            practiceGroupData['firmValue'] = this.currencyPipe.transform(
              item.firmValue,
              this.categoryParentData.currency,
              'symbol',
              '1.0-' + item.firmValueFormat.slice(-1)
            );
        }
        if (item.firmDelta == null) practiceGroupData['firmDelta'] = '*';
        else {
          this.isRowDataEmpty = false;
          practiceGroupData['firmDelta'] = this.percentPipe.transform(
            item.firmDelta,
            '1.1-1' + item.firmDeltaFormat.slice(-1)
          );
        }

        item.peerGroups.forEach((element, index) => {
          let peerGroupData = {};
          peerGroupData['pg_peerGrpName'] = element.peerGroupName;
          if (element.mean == null) peerGroupData['pg_mean'] = '*';
          else {
            this.isRowDataEmpty = false;
            if (element.meanFormat.startsWith('percent'))
              peerGroupData['pg_mean'] = this.percentPipe.transform(
                element.mean,
                '1.1-1' + element.meanFormat.slice(-1)
              );
            else if (element.meanFormat.startsWith('number') || element.meanFormat.startsWith('actual'))
              peerGroupData['pg_mean'] = element.mean.toFixed(element.meanFormat.slice(-1));
            else
              peerGroupData['pg_mean'] = this.currencyPipe.transform(
                element.mean,
                this.categoryParentData.currency,
                'symbol',
                '1.0-' + element.meanFormat.slice(-1)
              );
          }
          if (element.median == null) peerGroupData['pg_median'] = '*';
          else {
            this.isRowDataEmpty = false;
            if (element.medianFormat.startsWith('percent'))
              peerGroupData['pg_median'] = this.percentPipe.transform(
                element.median,
                '1.1-1' + element.medianFormat.slice(-1)
              );
            else if (element.medianFormat.startsWith('number') || element.medianFormat.startsWith('actual'))
              peerGroupData['pg_median'] = element.median.toFixed(element.medianFormat.slice(-1));
            else
              peerGroupData['pg_median'] = this.currencyPipe.transform(
                element.median,
                this.categoryParentData.currency,
                'symbol',
                '1.0-' + element.medianFormat.slice(-1)
              );
          }

          if (element.meanDelta == null) peerGroupData['pg_meanDelta'] = '*';
          else {
            this.isRowDataEmpty = false;
            peerGroupData['pg_meanDelta'] = this.percentPipe.transform(
              element.meanDelta,
              '1.1-1' + element.meanDeltaFormat.slice(-1)
            );
          }
          if (element.medianDelta == null) peerGroupData['pg_medianDelta'] = '*';
          else {
            this.isRowDataEmpty = false;
            peerGroupData['pg_medianDelta'] = this.percentPipe.transform(
              element.medianDelta,
              '1.1-1' + element.medianDeltaFormat.slice(-1)
            );
          }

          if (element.meanQuartile == null) peerGroupData['pg_meanQuartile'] = '--';
          else {
            this.isRowDataEmpty = false;
            peerGroupData['pg_meanQuartile'] = element.meanQuartile;
          }
          if (element.medianQuartile == null) peerGroupData['pg_medianQuartile'] = '--';
          else {
            this.isRowDataEmpty = false;
            peerGroupData['pg_medianQuartile'] = element.medianQuartile;
          }
          var format = this.metricBasicData.isDeltaOnly ? item.firmDeltaFormat : item.firmValueFormat;
          if (element.meanVariance == null) peerGroupData['pg_mean_variance'] = '*';
          else {
            this.pg_mean_variance = this.percentPipe.transform(element.meanVariance, '1.1-1' + format.slice(-1));

            if (element.meanVariance < 0)
              peerGroupData['pg_mean_variance'] = this.pg_mean_variance.replace('-', '(') + ')';
            else peerGroupData['pg_mean_variance'] = this.pg_mean_variance;
          }

          //calculate median variance

          if (element.medianVariance == null) peerGroupData['pg_median_variance'] = '*';
          else {
            this.pg_median_variance = this.percentPipe.transform(element.medianVariance, '1.1-1' + format.slice(-1));

            if (element.medianVariance < 0)
              peerGroupData['pg_median_variance'] = this.pg_median_variance.replace('-', '(') + ')';
            else peerGroupData['pg_median_variance'] = this.pg_median_variance;
          }

          if (practiceGroupData[element.peerGroupId] != undefined)
            practiceGroupData[element.peerGroupId].push(peerGroupData);
          else practiceGroupData[element.peerGroupId] = peerGroupData;

          if (indx == 0) {
            let option = {
              id: element.peerGroupId,
              name: element.peerGroupName,
            };
            if (this.gridHeaders.findIndex((pg) => pg.id == element.peerGroupId) < 0) this.gridHeaders.push(option);
          }
        });
        if (!this.isRowDataEmpty) mainData.push(practiceGroupData);
      });
    });
    setTimeout(() => {
      this.mainData = mainData;
    }, 0);
  }

  getColumnGroups(): any[] {
    let diffOfYear = this.peercheckMessageHandlerService.diffOfYears(
      this.requestBody[this.pivotIndex].requestBodyExpandAllOffices['endPeriod'],
      this.requestBody[this.pivotIndex].requestBodyExpandAllOffices['startPeriod']
    );
    this.yoyHeader = diffOfYear && diffOfYear > 1 ? 'Rolling growth' : 'YOY growth';
    let hiloCol1 = '25th';
    let hiloCol2 = '75th';
    if (this.highLowPercentilesCols.length > 0) {
      hiloCol1 = this.highLowPercentilesCols[0] + 'th';
      hiloCol2 = this.highLowPercentilesCols[1] + 'th';
    }
    let colGroups = [];
    let col = {
      header: '',
      align: 'left',
      columns: [{binding: 'officeName', header: 'Office', width: 250, wordWrap: true, align: 'left'}],
    };
    colGroups.push(col);
    col = {
      header: 'My firm',
      align: 'left',
      columns: [
        {binding: 'firmValue', header: 'Value', width: 120, wordWrap: true, align: 'left'},
        {binding: 'firmDelta', header: this.yoyHeader, width: 130, wordWrap: true, align: 'left'},
        {binding: 'trend', header: 'Trend', width: 120, wordWrap: true, align: 'left'},
      ],
    };
    colGroups.push(col);
    this.gridHeaders.forEach((item) => {
      let col = {
        header: item.name,
        align: 'left',
        columns: [
          {
            binding: item.id + '.pg_mean',
            header: 'Mean',
            width: 130,
            align: 'left',
            cssClass: 'main-column',
            visible: this.statistic == 'Mean',
          },
          // eslint-disable-next-line max-len
          {
            binding: item.id + '.pg_median',
            header: 'Median',
            width: 130,
            align: 'left',
            cssClass: 'main-column',
            visible: this.statistic == 'Median',
          },
          {
            binding: item.id + '.pg_mean_delta',
            header: this.yoyHeader,
            width: 145,
            align: 'left',
            wordWrap: true,
            visible: this.userSettingsModel.comparisonType == 'year over year' && this.statistic == 'Mean',
          },
          {
            binding: item.id + '.pg_median_delta',
            header: this.yoyHeader,
            width: 145,
            align: 'left',
            wordWrap: true,
            visible: this.userSettingsModel.comparisonType == 'year over year' && this.statistic == 'Median',
          },
          {
            binding: item.id + '.pg_mean_variance',
            header: 'Variance',
            align: 'left',
            width: 120,
            visible: this.userSettingsModel.comparisonType == 'variance' && this.statistic == 'Mean',
          },
          {
            binding: item.id + '.pg_median_variance',
            header: 'Variance',
            align: 'left',
            width: 120,
            visible: this.userSettingsModel.comparisonType == 'variance' && this.statistic == 'Median',
          },
          {
            binding: item.id + '.pg_one_std_deviation_less',
            header: '(-1) σ',
            align: 'left',
            width: 120,
            visible: this.statistic == 'Mean' && this.metricBasicData.stdDev,
          },
          {
            binding: item.id + '.pg_low',
            header: hiloCol1,
            align: 'left',
            width: 120,
            visible: this.statistic == 'Median' && this.metricBasicData.hilo,
          },
          {
            binding: item.id + '.pg_one_std_deviation_greater',
            header: '(+1) σ',
            align: 'left',
            width: 120,
            visible: this.statistic == 'Mean' && this.metricBasicData.stdDev,
          },
          {
            binding: item.id + '.pg_high',
            header: hiloCol2,
            align: 'left',
            width: 120,
            visible: this.statistic == 'Median' && this.metricBasicData.hilo,
          },
        ],
      };
      colGroups.push(col);
    });
    return colGroups;
  }

  getColumnGroupsExpenses(): any[] {
    let diffOfYear = this.peercheckMessageHandlerService.diffOfYears(
      this.requestBody[this.pivotIndex].requestBodyExpandCategory['endPeriod'],
      this.requestBody[this.pivotIndex].requestBodyExpandCategory['startPeriod']
    );
    this.yoyHeader = diffOfYear && diffOfYear > 1 ? 'Rolling growth' : 'YOY growth';
    let colGroups = [];
    let col = {
      header: '',
      align: 'left',
      columns: [{binding: 'category', header: 'Category', width: 340, wordWrap: true}],
    };
    colGroups.push(col);
    this.gridHeaders.forEach((item) => {
      if (item.name == 'My firm') {
        let col = {
          header: item.name,
          align: 'left',
          columns: [
            {
              binding: 'firmValue',
              header: 'Value',
              width: 135,
              align: 'right',
              cssClass: 'main-column',
            },
            // eslint-disable-next-line max-len
            {
              binding: 'firmDelta',
              header: this.yoyHeader,
              width: 145,
              align: 'right',
              wordWrap: true,
              cssClass: 'main-column',
            },
          ],
        };
        colGroups.push(col);
      } else {
        let col = {
          header: item.name,
          align: 'left',
          columns: [
            {
              binding: item.id + '.pg_mean',
              header: 'Mean',
              width: 140,
              align: 'right',
              cssClass: 'main-column',
              visible: this.metricBasicData.mean && this.statistic == 'Mean',
            },
            {
              binding: item.id + '.pg_median',
              header: 'Median',
              width: 140,
              align: 'right',
              cssClass: 'main-column',
              visible: this.metricBasicData.median && this.statistic == 'Median',
            },
            // eslint-disable-next-line max-len
            {
              binding: item.id + '.pg_meanDelta',
              header: this.yoyHeader,
              width: 145,
              align: 'right',
              wordWrap: true,
              cssClass: 'main-column',
              visible: this.userSettingsModel.comparisonType == 'year over year' && this.statistic == 'Mean',
            },
            {
              binding: item.id + '.pg_medianDelta',
              header: this.yoyHeader,
              width: 145,
              align: 'right',
              wordWrap: true,
              cssClass: 'main-column',
              visible: this.userSettingsModel.comparisonType == 'year over year' && this.statistic == 'Median',
            },
            {
              binding: item.id + '.pg_mean_variance',
              header: 'Variance',
              width: 140,
              align: 'left',
              visible: this.userSettingsModel.comparisonType == 'variance' && this.statistic == 'Mean',
            },
            {
              binding: item.id + '.pg_median_variance',
              header: 'Variance',
              width: 140,
              align: 'left',
              visible: this.userSettingsModel.comparisonType == 'variance' && this.statistic == 'Median',
            },
            {
              binding: item.id + '.pg_meanQuartile',
              header: 'Quartile',
              align: 'left',
              width: 140,
              visible: this.metricBasicData.quartile && this.statistic == 'Mean',
            },
            {
              binding: item.id + '.pg_medianQuartile',
              header: 'Quartile',
              align: 'left',
              width: 140,
              visible: this.metricBasicData.quartile && this.statistic == 'Median',
            },
          ],
        };
        colGroups.push(col);
      }
    });
    return colGroups;
  }

  disp() {}

  handleMetricSelection(selectedOption) {
    this.showBasicTable = true;
  }

  isExpandTableOffices() {
    if (this.expandType == 'expandOffices') return true;
    else return false;
  }
  handleKeyDown(flexGrid, event) {
    if (wjcCore.closestClass(event.target, 'wj-colheaders') && event.code == 'Space') {
      this._lastFocusCell = flexGrid.hitTest(event.target);
      let el = this._lastFocusCell.querySelector('a');
      el.click();
    }
  }
  focusOnGrid(s, args) {
    if (this._lastFocusCell) s.select(new wjGrid.CellRange(-0, this._lastFocusCell.col), true, s.columnHeaders);
    else s.select(new wjGrid.CellRange(-0, 1), true, s.columnHeaders);
    this._lastFocusCell = null;
  }
  closePGPopup() {
    this.modalService.dismissAll();
    this.flexGrid.select(new wjGrid.CellRange(-0, this._lastFocusCell.col), true, this.flexGrid.columnHeaders);
  }
  handleSelectionChanged(sender: wjGrid.FlexGrid, args: wjGrid.CellRangeEventArgs) {
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }
}
