<bento-alert [items]="alerts"></bento-alert>
<section class="Section" [bentoBusyLoader]="isInformationLoading" *ngIf="!isFAdmin">
  <header class="Section-header">
    <h2 class="Section-heading">General</h2>
  </header>
  <span class="label-required-summary">Required field</span>
  <form name="firmDetail" #firmDetail="ngForm" class="Section-form Form" bfmForm *ngIf="isGetDataLoading">
    <div class="Section-body">
      <fieldset class="Form-set">
        <legend class="sr-only">Firm details</legend>
        <div class="form-group Form-input Form-input--medium">
          <label for="expirationDate">Expiration date</label>
          <input
            [(ngModel)]="expirationDate"
            type="text"
            name="expirationDate"
            id="expirationDate"
            class="form-control"
            bfmField
            bfmLabel="expirationDate"
            placeholder="MM-DD-YYYY"
            pattern="^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-\d{4}$"
            [validations]="validations.validateDatePattern"
          />
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="firmId">Firm ID</label>
          <input
            type="text"
            name="firmId"
            id="firmId"
            class="form-control"
            bfmField
            bfmLabel="firmId"
            disabled
            [(ngModel)]="firmDetailModel.id"
          />
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="firmName" class="bento-label-required">Firm name</label>
          <input
            type="text"
            pattern=".*\S+.*"
            [validations]="validations.validatePattern"
            name="firmName"
            id="firmName"
            class="form-control"
            bfmField
            bfmLabel="Firm name"
            required
            [(ngModel)]="firmDetailModel.name"
          />
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="status" id="statusLabel">Status</label>
          <select
            name="status"
            id="status"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="status"
            aria-labelledby="statusLabel"
            [(ngModel)]="firmDetailModel.status"
          >
            <option *ngFor="let type of statusValues" [ngValue]="type.value">{{ type.label }}</option>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="overallRanking" class="bento-label-required">Overall ranking</label>
          <input
            type="text"
            name="overallRanking"
            id="overallRanking"
            class="form-control"
            inputmode="numeric"
            pattern="[0-9]*"
            bfmField
            bfmLabel="Overall ranking"
            required
            [(ngModel)]="firmDetailModel.overallRanking"
          />
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="numberOfAttorneys" class="bento-label-required">Number of attorneys</label>
          <input
            type="text"
            name="numberOfAttorneys"
            id="numberOfAttorneys"
            class="form-control"
            inputmode="numeric"
            pattern="[0-9]*"
            bfmField
            bfmLabel="Number Of Attorneys"
            required
            [(ngModel)]="firmDetailModel.numberOfAttorneys"
          />
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="mainOfficeCountry" id="mainOfficeCountryLabel" class="bento-label-required">Main office country</label>
          <select
            name="mainOfficeCountry"
            id="mainOfficeCountry"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Main office country"
            required
            aria-labelledby="mainOfficeCountryLabel"
            [(ngModel)]="firmDetailModel.mainOfficeCountry"
          >
            <ng-container *ngIf="!newzealandCurrency">
              <option *ngFor="let type of mainOfficeCountryValues" [ngValue]="type.value">
                {{ type.label }}
              </option>
            </ng-container>
            <ng-container *ngIf="newzealandCurrency">
              <option *ngFor="let type of mainOfficeCountryValues" [ngValue]="type.countryCode">
                {{ type.countryDescription }}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="mainOfficeState">Main office state</label>
          <input
            type="text"
            name="mainOfficeState"
            id="mainOfficeState"
            class="form-control"
            bfmField
            bfmLabel="mainOfficeState"
            [(ngModel)]="firmDetailModel.mainOfficeState"
            [pattern]="validatePattern"
            [validations]="validations.validatePattern"
          />
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="mainOfficeCity">Main office city</label>
          <input
            type="text"
            name="mainOfficeCity"
            id="mainOfficeCity"
            class="form-control"
            bfmField
            bfmLabel="mainOfficeCity"
            [(ngModel)]="firmDetailModel.mainOfficeCity"
            [pattern]="validatePattern"
            [validations]="validations.validatePattern"
          />
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="currency" id="currencyLabel" class="bento-label-required">Currency</label>
          <select
            name="currency"
            id="currency"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Currency"
            required
            aria-labelledby="currencyLabel"
            [(ngModel)]="firmDetailModel.currency"
          >
            <ng-container *ngIf="!newzealandCurrency">
              <option *ngFor="let type of currencyValues" [ngValue]="type.value">
                {{ type.label }}
              </option>
            </ng-container>
            <ng-container *ngIf="newzealandCurrency">
              <option *ngFor="let type of currencyValues" [ngValue]="type.currencyCode">
                {{ type.currencyDescription }}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="defaultAccess" id="defaultAccessLabel"class="bento-label-required">Set default access</label>
          <select
            name="defaultAccess"
            id="defaultAccess"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Set default access"
            required
            aria-labelledby="defaultAccessLabel"
            [(ngModel)]="firmDetailModel.defaultAccess"
          >
            <option *ngFor="let type of defaultAccess" [ngValue]="type.localeId">{{ type.description }}</option>
          </select>
        </div>
        <div class="Form-group Form-group--indent">
          <div class="form-group Form-input Form-input--medium">
            <label for="practiceDisplayFormat" id="practiceDisplayFormatLabel" class="bento-label-required">Practice display format</label>
            <select
              name="practiceDisplayFormat"
              id="practiceDisplayFormat"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Practice display format"
              [(ngModel)]="firmDetailModel.practiceDisplayFormat"
              required
              aria-labelledby="practiceDisplayFormatLabel"
            >
              <option *ngFor="let type of practice" [ngValue]="type">{{ type }}</option>
            </select>
          </div>
          <div class="form-group Form-input Form-input--medium">
            <label for="titleDisplayFormat" id="titleDisplayFormatLabel"class="bento-label-required">Title display format</label>
            <select
              name="titleDisplayFormat"
              id="titleDisplayFormat"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Title display format"
              required
              aria-labelledby="titleDisplayFormatLabel"
              [(ngModel)]="firmDetailModel.titleDisplayFormat"
            >
              <option *ngFor="let type of title" [ngValue]="type">{{ type }}</option>
            </select>
          </div>
          <div class="form-group Form-input Form-input--medium">
            <label for="metricDisplayFormat" id="metricDisplayFormatLabel" class="bento-label-required">Metric display format</label>
            <select
              name="metricDisplayFormat"
              id="metricDisplayFormat"
              class="form-control"
              bentoSelect
              bfmField
              bfmLabel="Metric display format"
              required
              aria-labelledby="metricDisplayFormatLabel"
              [(ngModel)]="firmDetailModel.metricDisplayFormat"
            >
              <option *ngFor="let type of metric" [ngValue]="type">{{ type }}</option>
            </select>
          </div>
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="dataLag" id="dataLagLabel">Data lag</label>
          <select
            name="dataLag"
            id="dataLag"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="dataLag"
            [(ngModel)]="firmDetailModel.dataLag"
            aria-labelledby="dataLagLabel"
          >
            <option *ngFor="let type of dataLags" [ngValue]="type.dataLagId">{{ type.dataLagName }}</option>
          </select>
        </div>
      </fieldset>

      <fieldset class="Form-set">
        <legend class="h4 Form-legend">Peer group options</legend>
        <div class="form-group Form-input Form-input--medium">
          <label for="peerGroupList" id="peerGroupListLabel" class="bento-label-required">Peer group list</label>
          <select
            name="peerGroupList"
            id="peerGroupList"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Peer group list"
            required
            aria-labelledby="peerGroupListLabel"
            [(ngModel)]="firmDetailModel.peerGroupList"
          >
            <option *ngFor="let type of peerGroupValues" [ngValue]="type.value">{{ type.label }}</option>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="performancePeerGroupList"id="performancePeerGroupListLabel"  class="bento-label-required">Performance peer group list</label>
          <select
            name="performancePeerGroupList"
            id="performancePeerGroupList"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Performance peer group list"
            required
            aria-labelledby="performancePeerGroupListLabel"
            [(ngModel)]="firmDetailModel.performancePeerGroupList"
          >
            <option *ngFor="let type of performancePeerGroupValues" [ngValue]="type.value">{{ type.label }}</option>
          </select>
        </div>
        <div class="form-group Form-input Form-input--medium">
          <label for="peerGroupsAllowed" id="peerGroupsAllowedLabel" class="bento-label-required">Peer groups allowed</label>
          <select
            name="peerGroupsAllowed"
            id="peerGroupsAllowed"
            class="form-control"
            bentoSelect
            bfmField
            bfmLabel="Peer groups allowed"
            required
            aria-labelledby="peerGroupsAllowedLabel"
            [(ngModel)]="firmDetailModel.peerGroupsAllowed"
          >
            <option *ngFor="let type of peerGroupsAllowesValues" [ngValue]="type.value">{{ type.label }}</option>
          </select>
        </div>
      </fieldset>
    </div>
    <footer class="Section-footer">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="resetInformation()">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item a11y">
          <button
            type="submit"
            class="btn btn-primary"
            (click)="firmDetail.invalid ? $event.stopPropagation() : saveInformation() "
            [attr.aria-disabled]="firmDetail.invalid ? 'true' : 'false' "
            [attr.aria-labelledby]="firmDetail.invalid ? 'aria-required-save' : null "
          >
            <span class="btn-text">Save</span>
          </button>
          <span class="sr-only" id="aria-required-save">Fill required fields, Save</span>
        </div>
      </div>
    </footer>
  </form>
</section>
<section class="Section" *ngIf="isFAdmin">
  <header class="Section-header">
    <h2 class="Section-heading">General</h2>
  </header>
  <div class="Section-body">
    <dl class="bento-desc-list bento-desc-list--sideBySide FirmMeta" aria-label="Firm details">
      <dt>Firm Id</dt>
      <dd>{{ firmId }}</dd>
      <dt>Subscription expiration date</dt>
      <dd>{{ expirationDate }}</dd>
      <dt>Firm name</dt>
      <dd>{{ firmDetailModel.name }}</dd>
      <dt>Status</dt>
      <dd>{{ firmDetail.status }}</dd>
      <dt>Overall ranking</dt>
      <dd>{{ firmDetailModel.overallRanking }}</dd>
      <dt>Number of attorneys</dt>
      <dd>{{ firmDetailModel.numberOfAttorneys }}</dd>
      <dt>Main office country</dt>
      <dd>{{ firmDetailModel.mainOfficeCountry }}</dd>
      <dt>Main office state</dt>
      <dd>{{ firmDetailModel.mainOfficeState }}</dd>
      <dt>Main office city</dt>
      <dd>{{ firmDetailModel.mainOfficeCity }}</dd>
      <dt>Currency</dt>
      <dd>{{ firmDetail.currency }}</dd>
      <dt>Set default access</dt>
      <dd>{{ firmDetail.defaultAccess }}</dd>
      <dt class="FirmMeta-indent">Practice display format</dt>
      <dd>{{ firmDetailModel.practiceDisplayFormat }}</dd>
      <dt class="FirmMeta-indent">Title display format</dt>
      <dd>{{ firmDetailModel.titleDisplayFormat }}</dd>
      <dt class="FirmMeta-indent">Metric display format</dt>
      <dd>{{ firmDetailModel.metricDisplayFormat }}</dd>
      <dt>Data lag</dt>
      <dd>{{ firmDetail.dataLag }}</dd>
      <dt>Peer group options</dt>
      <dt class="FirmMeta-indent">Peer group list</dt>
      <dd>{{ firmDetail.peerGroupList }}</dd>
      <dt class="FirmMeta-indent">Performance peer group list</dt>
      <dd>{{ firmDetail.performancePeerGroupList }}</dd>
      <dt class="FirmMeta-indent">Peer groups allowed</dt>
      <dd>{{ firmDetail.peerGroupsAllowed }}</dd>
    </dl>
  </div>
</section>
