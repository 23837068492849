import {Component, OnInit, Input, HostBinding, ViewChild, OnDestroy} from '@angular/core';
import * as wjGrid from '@grapecity/wijmo.grid';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyPipe, PercentPipe} from '@angular/common';
import {store, eventDispatcher} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {ActivatedRoute} from '@angular/router';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {PeerGroupInfo, PeerGroupDetails} from 'src/app/core/models/create-view.model';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import {Subscription} from 'rxjs';
import * as wjcCore from '@grapecity/wijmo';
import $ from 'jquery';
import {AxeToolCommonMethodsService} from 'src/app/core/services/axe-tool-common-methods/axe-tool-common-methods.service';

@Component({
  selector: 'app-pivot-table-grid-view',
  templateUrl: './pivot-table-grid-view.component.html',
})
export class PivotTableGridViewComponent implements OnInit {
  @Input() statistic;
  @Input() metricId: number;
  @Input() metricBasicData: any;
  @Input() highLowPercentilesCols: any;
  @Input() peerGroupItems: any;
  @Input() pivotIndex: any;
  @Input() practiceData: any;
  @Input() officeData: any;
  @Input() officeGroupData: any;
  @Input() pivotTab: string;
  @Input() activeTab: string;
  @Input() titleData: any;
  @Input() titleGroupData: any;
  @Input() allLawyersData: any;
  @Input() allPartnersData: any;
  @Input() allTimekeepersData: any;
  @Input() selectedOffice: any;
  @Input() currency: any;
  @Input() frozenColumnCount = 0;
  @Input() userSettingsModel: any;
  @Input() yoyHeader: any;
  @Input() isTAdmin: boolean = false;
  @Input() isCANFirm: boolean = false;

  columnGroups: any;
  pg_mean_variance: any;
  pg_median_variance: any;
  standardDeviationLess: any;
  standardDeviationGreater: any;
  alerts: BentoAlertItemOptions[] = [];
  peerGroupDetail: PeerGroupInfo;
  subscription: Subscription[];

  isDataLoading = false;

  requestBody: any;

  itemFormatter = this.itemFormatterBounded.bind(this);
  flag = false;
  gridHeaders = [];
  errorMessage = '';

  office = [];
  officeChildren = [];
  officeFinalData = [];
  listChildrenNames = [];
  listParentNames = [];
  title = [];
  titleChildren = [];
  titleFinalData = [];
  finalData = [];
  lastPressedKey: any;
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }
  modalTitle: String = '';
  mainData = [];
  showBasicTable = false;
  isServiceCalled = false;
  showPivot = false;
  _lastFocusCell: any;
  @ViewChild('listOfFirmsModal', {static: true}) listOfFirmsModalContent: any;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjGrid.FlexGrid;

  constructor(
    private modalService: NgbModal,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    public _service: PeerCheckServiceService,
    private axeToolService: AxeToolCommonMethodsService
  ) {
    store.subscribe((state) => {
      const {statistic} = state;

      if (statistic != null && this.statistic != statistic) {
        this.statistic = statistic;
        this.columnGroups = this.getColumnGroups();
      }
    });
  }

  ngOnInit(): void {
    eventDispatcher.next({type: ActionTypes.GetStatistic});
    this.subscription = [];
    let dataLoader = this._service.isDataLoadingMessage.subscribe((isDataProcessing: boolean) => {
      this.isDataLoading = isDataProcessing;
    });
    this.subscription.push(dataLoader);
    this.isDataLoading = true;
    if (this.pivotTab == 'practices' && this.practiceData) {
      this.isDataLoading = true;
      if (this.practiceData.values) this.getStdRatesData(this.practiceData.values);
    } else if (this.pivotTab == 'offices') {
      this.isDataLoading = true;
      this.processOfficeData();
      let officeDataProcessed = this.getStdDataFinalOffices();
      this.getStdRatesData(officeDataProcessed);
    } else if (this.pivotTab == 'titles') {
      this.isDataLoading = true;
      this.processTitleData();
      let titleDataProcessed = this.getStdDataFinalTitles();
      this.getStdRatesData(titleDataProcessed);
    }
    this.columnGroups = this.getColumnGroups();
    this.showPivot = true;
    this.isDataLoading = false;
  }

  processOfficeData() {
    let k = false;
    if (!this.officeGroupData) return;
    this.officeGroupData.forEach((element) => {
      if (element.officeGroupChildren) {
        let officeChildren = element.officeGroupChildren.children;
        element.values.forEach((group) => {
          if (this.officeData.values) {
            this.officeData.values.forEach((item) => {
              if (officeChildren.includes(Number(item.office))) {
                item['olvl2'] = item.office;
                item.office = group.office;
                k = true;
              }
            });
            if (k == true) {
              this.officeData.values.push(group);
              k = false;
            }
          }
        });
      }
    });
  }

  processTitleData() {
    let k = [];
    if (this.titleData && this.titleData.values)
      this.titleData.values.forEach((office) => {
        if (this.titleGroupData && this.titleGroupData.values) {
          k = this.titleGroupData.values.filter((item) => item.titleName == office.titleName);
          this.finalData.push(office);
          if (k.length > 0) {
            k.forEach((element) => {
              if (element.experienceYears != null) {
                this.listParentNames.push(office.titleName);
                this.finalData.push(element);
              }
            });
          }
        }
      });
  }

  getStdDataFinalOffices() {
    this.mainData = [];
    this.office = [];
    this.officeChildren = [];
    this.officeFinalData = [];
    this.listChildrenNames = [];
    let indent = false;

    // handle null values for office names
    if (this.officeData.values) {
      this.officeData.values.forEach((off) => {
        if (off.officeName == null) off.officeName = '';
      });

      let testData = this.officeData;

      testData.values.forEach((obj, indx) => {
        if (obj.olvl2) this.officeChildren.push(obj);
        else this.office.push(obj);
      });

      this.office = this.office.sort((a, b) => a.officeName.localeCompare(b.officeName));
      this.officeChildren = this.officeChildren.sort((a, b) => a.officeName.localeCompare(b.officeName));
      this.office.forEach((obj, indx) => {
        if (
          (this.selectedOffice.length > 0 && this.selectedOffice.includes(Number(obj.office))) ||
          this.selectedOffice.length == 0
        )
          this.officeFinalData.push(obj);
        indent = false;
        let a = this.officeChildren.filter((a) => a.office == obj.office);
        if (a.length > 0) {
          this.listParentNames.push(obj.officeName);
          if (this.officeFinalData.length > 0)
            if (a[0].office == this.officeFinalData[this.officeFinalData.length - 1].office) indent = true;
        }
        a.forEach((item) => {
          this.officeFinalData.push(item);
          if (indent) this.listChildrenNames.push(item.officeName);
        });
      });
    }

    return this.officeFinalData;
  }

  getStdDataFinalTitles() {
    this.mainData = [];

    this.title = [];
    this.titleChildren = [];
    this.titleFinalData = [];
    this.listChildrenNames = [];

    if (this.allTimekeepersData && this.allTimekeepersData.values)
      this.allTimekeepersData.values.forEach((obj, indx) => {
        if (this.activeTab == 'Timekeepers') obj.titleName = 'All Timekeepers';
        else if (this.activeTab == 'Fee Earners') obj.titleName = 'All Fee Earners';
        this.titleFinalData.push(obj);
      });
    if (this.allLawyersData && this.allLawyersData.values)
      this.allLawyersData.values.forEach((obj, indx) => {
        obj.titleName = 'Total All Lawyers';
        this.titleFinalData.push(obj);
      });
    if (this.allPartnersData && this.allPartnersData.values)
      this.allPartnersData.values.forEach((obj, indx) => {
        obj.titleName = 'Total All Partners';
        this.titleFinalData.push(obj);
      });

    this.finalData.forEach((obj, indx) => {
      if (obj['experienceYears'] == null) this.title.push(obj);
      else this.titleChildren.push(obj);
    });

    //remove this code when titleName is available
    this.title.forEach((item) => {
      if (item.titleName == null) item.titleName = 'tempName';
    });

    this.finalData.forEach((item) => this.titleFinalData.push(item));

    this.titleChildren.forEach((obj) => {
      this.listChildrenNames.push(obj['experienceYears']);
    });
    return this.titleFinalData;
  }

  itemFormatterBounded(panel, r, c, cell) {
    if (panel.cellType === wjGrid.CellType.Cell) {
      const flex = panel.grid;
      const col = flex.columns[c];

      if (col.binding === 'officeName' || col.binding === 'titleName') {
        const cellData = flex.getCellData(r, c);
        if (this.listChildrenNames.includes(cellData)) cell.style['padding-left'] = '2.75rem';
        if (this.listParentNames.includes(cellData)) cell.style['fontWeight'] = 'bold';
      } else if (col.binding === 'trend') {
        const cellData = flex.getCellData(r, c);
        if (cellData >= 0) {
          cell.innerText = '';
          cell.classList.add('bento-icon-caret-up-filled');
          cell.style.color = '#1ca91c';
        } else if (cellData < 0) {
          cell.innerText = '';
          cell.classList.add('bento-icon-caret-down-filled');
          cell.style.color = '#e23704';
        }
      }
    }
    if (r == this.mainData.length - 1) this.isDataLoading = false;
  }

  getStdRatesData(testData) {
    let mainData = [];
    if (testData) {
      testData.forEach((obj, indx) => {
        let pivotTableData = {};
        if (this.pivotTab == 'practices') pivotTableData['practiceGroupName'] = obj.practiceName;
        else if (this.pivotTab == 'offices') pivotTableData['officeName'] = obj.officeName;
        else if (this.pivotTab == 'titles') {
          let titleName = '';
          if (!obj['experienceYears']) titleName = obj.titleName;
          else if (obj['experienceYears'] == null) {
            titleName = obj.titleName;
          } else titleName = obj['experienceYears'];
          pivotTableData['titleName'] = titleName;
        }
        obj.metrics.forEach((item, index) => {
          if (item.metricId == this.metricId) {
            let myFirmValue = this.metricBasicData.isDeltaOnly ? item.firmDelta : item.firmValue;
            let myFirmFormat = this.metricBasicData.isDeltaOnly ? item.firmDeltaFormat : item.firmValueFormat;
            if (myFirmValue == null) pivotTableData['firmValue'] = '*';
            else {
              if (myFirmFormat.startsWith('percent'))
                pivotTableData['firmValue'] = this.percentPipe.transform(myFirmValue, '1.1-1' + myFirmFormat.slice(-1));
              else if (myFirmFormat.startsWith('number') || myFirmFormat.startsWith('actual'))
                pivotTableData['firmValue'] = myFirmValue.toFixed(myFirmFormat.slice(-1));
              else
                pivotTableData['firmValue'] = this.currencyPipe.transform(
                  myFirmValue,
                  this.currency,
                  'symbol',
                  '1.0-' + myFirmFormat.slice(-1)
                );
            }
            let myFirmDeltaValue = item.firmDelta;
            let myFirmDeltaFormat = item.firmDeltaFormat;
            if (item.firmDelta == null) pivotTableData['firmDelta'] = '--';
            else {
              if (myFirmDeltaFormat.startsWith('percent'))
                pivotTableData['firmDelta'] = this.percentPipe.transform(
                  myFirmDeltaValue,
                  '1.1-1' + myFirmDeltaFormat.slice(-1)
                );
              else if (myFirmDeltaFormat.startsWith('number') || myFirmDeltaFormat.startsWith('actual'))
                pivotTableData['firmDelta'] = myFirmDeltaFormat.toFixed(myFirmDeltaFormat.slice(-1));
              else
                pivotTableData['firmDelta'] = this.currencyPipe.transform(
                  myFirmDeltaValue,
                  this.currency,
                  'symbol',
                  '1.0-' + myFirmDeltaFormat.slice(-1)
                );
            }

            if (item.firmDelta == null) pivotTableData['trend'] = 'N/A';
            else pivotTableData['trend'] = item.firmDelta;

            item.peerGroups.forEach((element, index) => {
              let peerGroupData = {};
              peerGroupData['pg_peerGrpName'] = element.peerGroupName;
              let meanValue = this.metricBasicData.isDeltaOnly ? element.meanDelta : element.mean;
              let meanFormat = this.metricBasicData.isDeltaOnly ? element.meanDeltaFormat : element.meanFormat;
              if (meanValue == null) peerGroupData['pg_mean'] = '*';
              else {
                if (meanFormat.startsWith('percent'))
                  peerGroupData['pg_mean'] = this.percentPipe.transform(meanValue, '1.1-1' + meanFormat.slice(-1));
                else if (meanFormat.startsWith('number') || meanFormat.startsWith('actual'))
                  peerGroupData['pg_mean'] = meanValue.toFixed(meanFormat.slice(-1));
                else
                  peerGroupData['pg_mean'] = this.currencyPipe.transform(
                    meanValue,
                    this.currency,
                    'symbol',
                    '1.0-' + meanFormat.slice(-1)
                  );
              }

              let yoyMeanValue = element.meanDelta;
              let yoyMeanFormat = element.meanDeltaFormat;
              if (yoyMeanValue == null) peerGroupData['pg_mean_delta'] = '*';
              else {
                if (yoyMeanFormat.startsWith('percent'))
                  peerGroupData['pg_mean_delta'] = this.percentPipe.transform(
                    yoyMeanValue,
                    '1.1-1' + yoyMeanFormat.slice(-1)
                  );
                else if (yoyMeanFormat.startsWith('number') || yoyMeanFormat.startsWith('actual'))
                  peerGroupData['pg_mean_delta'] = yoyMeanValue.toFixed(yoyMeanFormat.slice(-1));
                else
                  peerGroupData['pg_mean_delta'] = this.currencyPipe.transform(
                    yoyMeanValue,
                    this.currency,
                    'symbol',
                    '1.0-' + yoyMeanFormat.slice(-1)
                  );
              }

              let yoyMedianValue = element.medianDelta;
              let yoyMedianFormat = element.medianDeltaFormat;
              if (yoyMedianValue == null) peerGroupData['pg_median_delta'] = '*';
              else {
                if (yoyMedianFormat.startsWith('percent'))
                  peerGroupData['pg_median_delta'] = this.percentPipe.transform(
                    yoyMedianValue,
                    '1.1-1' + yoyMedianFormat.slice(-1)
                  );
                else if (yoyMedianFormat.startsWith('number') || yoyMedianFormat.startsWith('actual'))
                  peerGroupData['pg_median_delta'] = yoyMedianValue.toFixed(yoyMedianFormat.slice(-1));
                else
                  peerGroupData['pg_median_delta'] = this.currencyPipe.transform(
                    yoyMedianValue,
                    this.currency,
                    'symbol',
                    '1.0-' + yoyMedianFormat.slice(-1)
                  );
              }

              let medianValue = this.metricBasicData.isDeltaOnly ? element.medianDelta : element.median;
              let medianFormat = this.metricBasicData.isDeltaOnly ? element.medianDeltaFormat : element.medianFormat;
              if (medianValue == null) peerGroupData['pg_median'] = '*';
              else {
                if (medianFormat.startsWith('percent'))
                  peerGroupData['pg_median'] = this.percentPipe.transform(
                    medianValue,
                    '1.1-1' + medianFormat.slice(-1)
                  );
                else if (medianFormat.startsWith('number') || medianFormat.startsWith('actual'))
                  peerGroupData['pg_median'] = medianValue.toFixed(medianFormat.slice(-1));
                else
                  peerGroupData['pg_median'] = this.currencyPipe.transform(
                    medianValue,
                    this.currency,
                    'symbol',
                    '1.0-' + medianFormat.slice(-1)
                  );
              }

              //calculate stdDeviation
              let peerVal = this.metricBasicData.isDeltaOnly ? element.meanDelta : element.mean;
              //  let stdDevFormat = this.metricBasicData.isDeltaOnly ? element.medianDeltaFormat : element.medianFormat;

              this.standardDeviationLess =
                peerVal == null && element.standardDeviation == null ? null : peerVal - element.standardDeviation;

              this.standardDeviationGreater =
                peerVal == null && element.standardDeviation == null ? null : peerVal + element.standardDeviation;

              if (this.standardDeviationLess == null) peerGroupData['pg_one_std_deviation_less'] = 'N/A';
              else {
                if (element.standardDeviationFormat.startsWith('percent'))
                  peerGroupData['pg_one_std_deviation_less'] = this.percentPipe.transform(
                    this.standardDeviationLess,
                    '1.1-1' + element.standardDeviationFormat.slice(-1)
                  );
                else if (
                  element.standardDeviationFormat.startsWith('number') ||
                  element.standardDeviationFormat.startsWith('actual')
                )
                  peerGroupData['pg_one_std_deviation_less'] = this.standardDeviationLess.toFixed(
                    element.standardDeviationFormat.slice(-1)
                  );
                else
                  peerGroupData['pg_one_std_deviation_less'] = this.currencyPipe.transform(
                    this.standardDeviationLess,
                    this.currency,
                    'symbol',
                    '1.0-' + element.standardDeviationFormat.slice(-1)
                  );
              }

              if (this.standardDeviationGreater == null) peerGroupData['pg_one_std_deviation_greater'] = 'N/A';
              else {
                if (element.standardDeviationFormat.startsWith('percent'))
                  peerGroupData['pg_one_std_deviation_greater'] = this.percentPipe.transform(
                    this.standardDeviationGreater,
                    '1.1-1' + element.standardDeviationFormat.slice(-1)
                  );
                else if (
                  element.standardDeviationFormat.startsWith('number') ||
                  element.standardDeviationFormat.startsWith('actual')
                )
                  peerGroupData['pg_one_std_deviation_greater'] = this.standardDeviationGreater.toFixed(
                    element.standardDeviationFormat.slice(-1)
                  );
                else
                  peerGroupData['pg_one_std_deviation_greater'] = this.currencyPipe.transform(
                    this.standardDeviationGreater,
                    this.currency,
                    'symbol',
                    '1.0-' + element.standardDeviationFormat.slice(-1)
                  );
              }

              //calculate High-Low
              let low = this.metricBasicData.highBad ? element.highPercentile : element.lowPercentile;
              let high = this.metricBasicData.highBad ? element.lowPercentile : element.highPercentile;

              if (low == null) peerGroupData['pg_low'] = 'N/A';
              else {
                if (element.highPercentileFormat.startsWith('percent'))
                  peerGroupData['pg_low'] = this.percentPipe.transform(
                    low,
                    '1.1-1' + element.highPercentileFormat.slice(-1)
                  );
                else if (
                  element.highPercentileFormat.startsWith('number') ||
                  element.highPercentileFormat.startsWith('actual')
                )
                  peerGroupData['pg_low'] = low.toFixed(element.highPercentileFormat.slice(-1));
                else
                  peerGroupData['pg_low'] = this.currencyPipe.transform(
                    low,
                    this.currency,
                    'symbol',
                    '1.0-' + element.highPercentileFormat.slice(-1)
                  );
              }

              if (high == null) peerGroupData['pg_high'] = 'N/A';
              else {
                if (element.highPercentileFormat.startsWith('percent'))
                  peerGroupData['pg_high'] = this.percentPipe.transform(
                    high,
                    '1.1-1' + element.highPercentileFormat.slice(-1)
                  );
                else if (
                  element.highPercentileFormat.startsWith('number') ||
                  element.highPercentileFormat.startsWith('actual')
                )
                  peerGroupData['pg_high'] = high.toFixed(element.highPercentileFormat.slice(-1));
                else
                  peerGroupData['pg_high'] = this.currencyPipe.transform(
                    high,
                    this.currency,
                    'symbol',
                    '1.0-' + element.highPercentileFormat.slice(-1)
                  );
              }

              //calculate mean variance
              var format = this.metricBasicData.isDeltaOnly ? item.firmDeltaFormat : item.firmValueFormat;
              if (element.meanVariance == null) peerGroupData['pg_mean_variance'] = '*';
              else {
                this.pg_mean_variance = this.percentPipe.transform(element.meanVariance, '1.1-1' + format.slice(-1));

                if (element.meanVariance < 0)
                  peerGroupData['pg_mean_variance'] = this.pg_mean_variance.replace('-', '(') + ')';
                else peerGroupData['pg_mean_variance'] = this.pg_mean_variance;
              }

              //calculate median variance

              if (element.medianVariance == null) peerGroupData['pg_median_variance'] = '*';
              else {
                this.pg_median_variance = this.percentPipe.transform(
                  element.medianVariance,
                  '1.1-1' + format.slice(-1)
                );

                if (element.medianVariance < 0)
                  peerGroupData['pg_median_variance'] = this.pg_median_variance.replace('-', '(') + ')';
                else peerGroupData['pg_median_variance'] = this.pg_median_variance;
              }

              if (pivotTableData[element.peerGroupId] != undefined)
                pivotTableData[element.peerGroupId].push(peerGroupData);
              else pivotTableData[element.peerGroupId] = peerGroupData;

              if (indx == 0) {
                let option = {
                  id: element.peerGroupId,
                  name: element.peerGroupName,
                };
                if (this.gridHeaders.findIndex((pg) => pg.id == element.peerGroupId) < 0) this.gridHeaders.push(option);
                this.flag = true;
              }
            });
            mainData.push(pivotTableData);
          }
        });
      });
    }
    setTimeout(() => {
      if (this.pivotTab == 'practices')
        this.mainData = mainData.sort((a, b) => a.practiceGroupName.localeCompare(b.practiceGroupName));
      else {
        this.mainData = mainData;
        this.showPivot = true;
      }
    }, 0);
  }

  onInitialized(grid: wjGrid.FlexGrid) {
    if (!grid) return;

    grid._useFrozenDiv = function () {
      return false;
    };

    grid.rows.defaultSize = 56;
    grid.columnHeaders.rows.defaultSize = 40;
    //grid.columnHeaders.rows[0].height =0;

    // TODO: To be removed commented code after MVP release
    // grid.formatItem.addHandler((s: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) => {
    //   if (e.range.columnSpan > 1) {
    //     e.cell.innerHTML =
    //       '<a href="" class="F' +
    //       e.cell.id +
    //       '" role="link" aria-label="' +
    //       e.cell.innerText +
    //       '">' +
    //       e.cell.innerText +
    //       '</a>';
    //     let el = e.cell.querySelector('a');
    //     el.onclick = (et: any) => {
    //       et.preventDefault();
    //       this.modalTitle = et.target.innerText;
    //       this.populateSelectedFirmsForPeerGroup(et.target.innerText);
    //     };
    //   }
    // });

    grid.hostElement.setAttribute('role', 'presentation');

    // center-align merged header cells
    let count = 1;
    grid.formatItem.addHandler((s: wjGrid.FlexGrid, e: any) => {
      if (e.panel == s.columnHeaders) {
        if (e.cell.innerText != 'My firm' && e.cell.innerText != '') {
          let headerText = e.panel.getCellData(e.row, e.col, true);
          let peerGroup = this.peerGroupItems.find((obj) => obj.name == headerText);

          if (!headerText) {
            if (this.mainData.length == 0) {
              e.cell.style.display = 'none';
              return;
            }
          } else if (headerText && peerGroup) {
            // e.cell.tabIndex = 0;
            e.cell.innerHTML = `
              <h3 class="h6 mb-0 DataGrid-heading">
                <a
                  href=""
                  aria-haspopup="dialog"
                  id="${peerGroup.id}"
                  role="button"
                  aria-label="${peerGroup.name}"
                  data-pgid="${peerGroup.id}"
                  data-pgName="${peerGroup.name}"
                  tabindex="-1"
                >
                  ${peerGroup.name}
                </a>
              </h3>
            `;
            count++;
            let el = e.cell.querySelector('a');
            el.onclick = (et: any) => {
              et.preventDefault();
              let dataSet = et.target.dataset;
              this.modalTitle = et.target.ariaLabel;

              this.populateSelectedFirmsForPeerGroup(dataSet, et.target.ariaLabel);
            };
          } else if (headerText && !peerGroup && headerText != '') {
            if (e.row == 1) {
              let header1 = e.panel.getCellData(0, e.col);
              let header2 = e.panel.getCellData(e.row, e.col);
              let headerAriaLabel = header1 ? header1 + ' ' + header2 : header2;
              e.cell.setAttribute('aria-label', `${headerAriaLabel}`);
              let col = e.getColumn();
              e.cell.setAttribute('aria-colindex', col.visibleIndex + 1);
            }
          }
        }
        if (e.row == 0) {
          e.cell.removeAttribute('role');
        }
      } else if (e.cell.innerText && e.cell.classList.value.indexOf('wj-header') != -1) {
        e.cell.innerHTML = '<h3 class="h6 mb-0 DataGrid-heading">' + e.cell.innerText + '</h3>';
      } else {
        e.cell.setAttribute('role', 'gridcell');
      }
      if (e.panel == s.cells) {
        let col = e.getColumn();
        e.cell.setAttribute('aria-colindex', col.visibleIndex + 1);
      }
    });
    this.flexGrid = grid;
    this.flexGrid.refresh();
    this.flexGrid.select(new wjGrid.CellRange(-0, 1), true, this.flexGrid.columnHeaders);
    grid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });
  }

  populateSelectedFirmsForPeerGroup(dataset: any, peerGroupName: string) {
    this.isDataLoading = true;
    let peerGroup = null;
    if (dataset) {
      peerGroup = this.peerGroupItems.find((obj) => obj.id == dataset.pgid || obj.name == dataset.pgname);
    } else {
      peerGroup = this.peerGroupItems.find((obj) => obj.name == peerGroupName);
    }
    if (peerGroup) {
      if (peerGroup.isPpg) {
        this._service.getCriteriaForPerformancePg(peerGroup, this.isCANFirm, this.isTAdmin);
      } else {
        this._service.getSelectedFirmsForPg(peerGroup);
      }
      let peerGroupDetail = this._service.peerGroupSelectedFirms.subscribe((peerGroupDetails: PeerGroupDetails) => {
        if (peerGroupDetails.hasError) {
          if (peerGroupDetails.alerts && peerGroupDetails.alerts.length) {
            this.alerts = peerGroupDetails.alerts;
          }
        } else {
          this.peerGroupDetail = peerGroupDetails.successResponse;
          if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
          }
          // Addded flexgrid refresh to detect changes in UI
          // TODO: find Root cause of changeDetection after MVP
          this.flexGrid.refresh();
          setTimeout(function () {
            $('.close').focus();
          }, 100);
          this.modalService.open(this.listOfFirmsModalContent, {ariaLabelledBy: 'modalPeerGroup'});
        }
      });
      this.subscription.push(peerGroupDetail);
    }
    this.isDataLoading = false;
  }

  getColumnGroups(): any[] {
    // this.isDataLoading = true;
    let hiloCol1 = '25th';
    let hiloCol2 = '75th';
    let binding = '';
    let header = '';
    if (this.highLowPercentilesCols.length > 0) {
      hiloCol1 = this.highLowPercentilesCols[0] + 'th';
      hiloCol2 = this.highLowPercentilesCols[1] + 'th';
    }
    if (this.pivotTab == 'practices') {
      binding = 'practiceGroupName';
      header = 'Practice group';
    } else if (this.pivotTab == 'offices') {
      binding = 'officeName';
      header = 'Office';
    } else {
      binding = 'titleName';
      header = this.activeTab.slice(0, -1);
    }
    let colGroups = [];
    let col = {
      header: '',
      align: 'left',
      columns: [
        {
          binding: binding,
          header: header,
          width: 300,
          align: 'left',
          wordWrap: true,
          visible: true,
        },
      ],
    };
    colGroups.push(col);
    col = {
      header: 'My firm',
      align: 'left',
      columns: [
        {
          binding: 'firmValue',
          header: 'Value',
          width: 100,
          align: 'left',
          wordWrap: true,
          visible: true,
        },
        {
          binding: 'firmDelta',
          header: this.yoyHeader == 'YOY growth' ? 'YOY growth' : 'Rolling growth',
          width: 100,
          align: 'left',
          wordWrap: true,
          visible: true,
        },
        {
          binding: 'trend',
          header: 'Trend',
          width: 100,
          align: 'left',
          wordWrap: true,
          visible: true,
        },
      ],
    };
    colGroups.push(col);
    this.gridHeaders.forEach((item) => {
      let col = {
        header: item.name,
        align: 'left',
        columns: [
          {
            binding: item.id + '.pg_mean',
            header: 'Mean',
            width: 130,
            align: 'left',
            visible: this.statistic == 'Mean',
          },
          // eslint-disable-next-line max-len
          {
            binding: item.id + '.pg_median',
            header: 'Median',
            width: 130,
            align: 'left',
            cssClass: 'main-column',
            visible: this.statistic == 'Median',
          },
          {
            binding: item.id + '.pg_mean_delta',
            header: this.yoyHeader == 'YOY growth' ? 'YOY growth' : 'Rolling growth',
            width: 130,
            align: 'left',
            wordWrap: true,
            visible: this.userSettingsModel.comparisonType == 'year over year' && this.statistic == 'Mean',
          },
          {
            binding: item.id + '.pg_median_delta',
            header: this.yoyHeader == 'YOY growth' ? 'YOY growth' : 'Rolling growth',
            width: 130,
            align: 'left',
            wordWrap: true,
            visible: this.userSettingsModel.comparisonType == 'year over year' && this.statistic == 'Median',
          },
          {
            binding: item.id + '.pg_mean_variance',
            header: 'Variance',
            align: 'left',
            width: 130,
            visible: this.userSettingsModel.comparisonType == 'variance' && this.statistic == 'Mean',
          },
          {
            binding: item.id + '.pg_median_variance',
            header: 'Variance',
            align: 'left',
            width: 130,
            visible: this.userSettingsModel.comparisonType == 'variance' && this.statistic == 'Median',
          },
          {
            binding: item.id + '.pg_one_std_deviation_less',
            header: '(-1) σ',
            align: 'left',
            width: 100,
            visible: this.statistic == 'Mean' && this.metricBasicData.stdDev,
          },
          {
            binding: item.id + '.pg_low',
            header: hiloCol1,
            align: 'left',
            width: 100,
            visible: this.statistic == 'Median' && this.metricBasicData.hilo,
          },
          {
            binding: item.id + '.pg_one_std_deviation_greater',
            header: '(+1) σ',
            align: 'left',
            width: 100,
            visible: this.statistic == 'Mean' && this.metricBasicData.stdDev,
          },
          {
            binding: item.id + '.pg_high',
            header: hiloCol2,
            align: 'left',
            width: 100,
            visible: this.statistic == 'Median' && this.metricBasicData.hilo,
          },
        ],
      };
      colGroups.push(col);
    });
    return colGroups;
  }

  disp() {}

  handleMetricSelection(selectedOption) {
    this.showBasicTable = true;
  }
  closePGPopup() {
    this.modalService.dismissAll();
    this.flexGrid.select(new wjGrid.CellRange(-0, this._lastFocusCell.col), true, this.flexGrid.columnHeaders);
  }

  handleKeyDown(flexGrid, event) {
    if (wjcCore.closestClass(event.target, 'wj-colheaders') && event.code == 'Space') {
      this._lastFocusCell = flexGrid.hitTest(event.target);
      let el = this._lastFocusCell.querySelector('a');
      el.click();
    }
    if (event.keyCode === wjcCore.Key.Tab) {
      this.axeToolService.enableFocusFromCloumnAndRowHeaders(
        event,
        'button, input, textarea, select,a[role="tab"], [tabindex]:not([tabindex="-1"])'
      );
    }
  }

  focusOnGrid(s, args) {
    if (this._lastFocusCell) s.select(new wjGrid.CellRange(-0, this._lastFocusCell.col), true, s.columnHeaders);
    else s.select(new wjGrid.CellRange(-0, 1), true, s.columnHeaders);
    this._lastFocusCell = null;
  }
  handleSelectionChanged(sender: wjGrid.FlexGrid, args: wjGrid.CellRangeEventArgs) {
    let selectedCell = args.panel.getCellElement(args.row, args.col);
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
    if (this.lastPressedKey == 'ArrowLeft' || this.lastPressedKey == 'ArrowRight') {
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }
}
