import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BentoModule} from '@bento/bento-ng';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BentoD3Module} from '@bento/bento-ng-d3';
import {BentoD3DataMapModule} from '@bento/bento-ng-datamap';
import {BentoTransferboxModule} from '@bento/bento-ng-transferbox';
import {BentoFlexGridControlModule} from '@bento/bento-ng-flexgrid-control';
import {WjGridModule} from '@grapecity/wijmo.angular2.grid';
import {WjGridTransposedModule} from '@grapecity/wijmo.angular2.grid.transposed';
import {WjChartModule} from '@grapecity/wijmo.angular2.chart';
import {WjChartAnimationModule} from '@grapecity/wijmo.angular2.chart.animation';
import {WjInputModule} from '@grapecity/wijmo.angular2.input';
import {WjGridFilterModule} from '@grapecity/wijmo.angular2.grid.filter';
import {WjGridGrouppanelModule} from '@grapecity/wijmo.angular2.grid.grouppanel';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BfmModule} from '@bento/bfm-ng';
import {WjGridDetailModule} from '@grapecity/wijmo.angular2.grid.detail';

// Core
import {ActionDropdownComponent} from './core/components/shared/action-dropdown/action-dropdown.component';
import { ActionsMenuComponent } from './core/components/shared/actions-menu/actions-menu.component'; 
import {PmGlobalHeaderComponent} from './core/components/global-header/global-header.component';
import {PmGlobalSidebarComponent} from './core/components/global-sidebar/global-sidebar.component';
import {AuthenticationInterceptor} from './core/interceptors/authentication.interceptor';
import {BaseService} from './core/services/base/base.service';
import {AuthService} from './core/services/auth/auth.service';
import {LocalStorageService} from './core/services/local-storage/local-storage.service';
import {AuthenticationGuard} from './core/guards/authentication.guard';
import {AdministratorComponent} from './core/components/administrator/administrator/administrator.component';
import {FirmSettingComponent} from './core/components/administrator/firm-setting/firm-setting.component';
import {FirmDetailsComponent} from './core/components/administrator/firm-details/firm-details.component';
import {DetailsComponent} from './core/components/administrator/details/details.component';
import {FirmParameterComponent} from './core/components/administrator/firm-parameter/firm-parameter.component';
import {GlobalBreadcrumbsComponent} from './core/components/global-breadcrumbs/global-breadcrumbs.component';
import {FirmUsersComponent} from './core/components/administrator/firm-users/firm-users.component';
import {UserInformationComponent} from './core/components/administrator/user-information/user-information.component';
import {FirmUserDetailsComponent} from './core/components/administrator/firm-user-details/firm-user-details.component';
import {FirmPeerGroupsComponent} from './core/components/administrator/firm-peer-groups/firm-peer-groups.component';
import {FirmCustomPeerGroupsComponent} from './core/components/administrator/firm-custom-peer-groups/firm-custom-peer-groups.component';
import {CurrentViewsComponent} from './core/components/peer-check/current-views/current-views.component';
import {CreateViewComponent} from './core/components/peer-check/create-view/create-view.component';
import {PeerCheckComponent} from './core/components/peer-check/peer-check/peer-check.component';
import {TitlePermissionComponent} from './core/components/administrator/title-permission/title-permission.component';
import {CurrentViewDetailsComponent} from './core/components/peer-check/current-view-details/current-view-details.component';
import {GlobalPeerGroupsComponent} from './core/components/administrator/global-peer-groups/global-peer-groups.component';
import {GlobalPeerGroupsFirmsComponent} from './core/components/administrator/global-peer-groups-firms/global-peer-groups-firms.component';
import {PerformancePeerGroupsComponent} from './core/components/peer-check/performance-peer-groups/performance-peer-groups.component';
// eslint-disable-next-line max-len
import {CreatePerformancePeerGroupComponent} from './core/components/peer-check/create-performance-peer-group/create-performance-peer-group.component';
// eslint-disable-next-line max-len
import {CurrentPerformancePeerGroupsComponent} from './core/components/peer-check/current-performance-peer-groups/current-performance-peer-groups.component';

import {ViewsSettingsComponent} from './core/components/peer-check/create-view/views-settings/views-settings.component';
import {SaveViewComponent} from './core/components/peer-check/create-view/save-view/save-view.component';
import {SvgLogoComponent} from './shared/svg/svg-logo/svg-logo.component';
import {LandingComponent} from './core/components/peer-check/landing/landing.component';
import {ContactUsComponent} from './core/components/contact-us/contact-us.component';
import {CurrencyPipe, PercentPipe} from '@angular/common';
import {PivotTableViewComponent} from './core/components/peer-check/pivot-table-view/pivot-table-view.component';
// eslint-disable-next-line max-len
import {ExpandTableOfficesExpensesComponent} from './core/components/peer-check/expand-table-offices-expenses/expand-table-offices-expenses.component';
import {PivotTableGridViewComponent} from './core/components/peer-check/pivot-table-grid-view/pivot-table-grid-view.component';
import {ExpandTableViewComponent} from './core/components/peer-check/expand-table-view/expand-table-view.component';
import {ResourcesComponent} from './core/components/peer-check/resources/resources.component';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {environment} from 'src/environments/environment';
// eslint-disable-next-line max-len
import {BentoCustomMultiselectOverlayComponent} from './core/components/peer-check/bento-custom-multiselect-overlay/bento-custom-multiselect-overlay.component';
// eslint-disable-next-line max-len
import {BentoMultiselectListComponent} from './core/components/peer-check/bento-custom-multiselect-overlay/multiselect-list.component';
import {BentoListSubscribable} from './core/components/peer-check/bento-custom-multiselect-overlay/list/list-data-subscribable';
import {BentoListComponent} from './core/components/peer-check/bento-custom-multiselect-overlay/list/list.component';
import {UnauthorizedComponent} from './core/components/errors/unauthorized/unauthorized.component';
import {UserNotFoundComponent} from './core/components/errors/user-not-found/user-not-found.component';
import {PageNotFoundComponent} from './core/components/errors/page-not-found/page-not-found.component';
import {OfflineComponent} from './core/components/errors/offline/offline.component';
import {SiteDownComponent} from './core/components/errors/site-down/site-down.component';
import {AccessDeniedComponent} from './core/components/errors/access-denied/access-denied.component';
import {SvgClockComponent} from './shared/svg/svg-clock/svg-clock.component';
import {SvgExclamationComponent} from './shared/svg/svg-exclamation/svg-exclamation.component';
import {SvgQuestionComponent} from './shared/svg/svg-question/svg-question.component';
import {ExportModalComponent} from './core/components/peer-check/export-modal/export-modal.component';
import {ShareViewModelComponent} from './core/components/peer-check/current-views/share-view-model/share-view-model.component';
import {ViewDataGridComponent} from './core/components/peer-check/view-grid/view-data-grid/view-data-grid.component';
import {ServerErrorComponent} from './core/components/errors/server-error/server-error.component';
import {FirmPermissionComponent} from './core/components/administrator/firm-permission/firm-permission/firm-permission.component';
import {GridRecordDetailsComponent} from './core/components/peer-check/shared/grid-record-details/grid-record-details.component';
import {GenericErrorComponent} from './core/components/errors/generic-error/generic-error.component';
import {UserPermissionsComponent} from './core/components/administrator/user-permissions/user-permissions/user-permissions.component';
import {FirmPeerGroupDetailComponent} from './core/components/administrator/firm-peer-group-detail/firm-peer-group-detail.component';
import {ViewsComponent} from './core/components/peer-check/views/views.component';
import {CurrentChartsComponent} from './core/components/peer-check/current-charts/current-charts.component';
import {CurrentChartsListComponent} from './core/components/peer-check/current-charts-list/current-charts-list.component';
import {SortingDropdownComponent} from './core/components/shared/sorting-dropdown/sorting-dropdown.component';
import {ChartDetailsComponent} from './core/components/peer-check/charts/chart-details/chart-details.component';
import {CreateChartComponent} from './core/components/peer-check/charts/create-chart/create-chart.component';
import {DateRangeComponent} from './core/components/peer-check/charts/shared/date-range/date-range.component';
import {DataManagerComponent} from './core/components/data-manager/data-manager/data-manager.component';
import {TwoDigitDecimalNumberDirective} from './core/components/shared/directives/two-digit-decimal-number.directive';
import {InlineStylesCSPModule} from './inline-styles-csp/inline-styles-csp.module';
import {RatesReportsComponent} from './core/components/rates-reports/rates-reports/rates-reports.component';
import {NewRatesReportComponent} from './core/components/rates-reports/new-rates-report/new-rates-report.component';
import {ReportsTabComponent} from './core/components/rates-reports/reports-tab/reports-tab.component';
import {ReportsProgressComponent} from './core/components/rates-reports/reports-progress/reports-progress.component';
import {StaffingRatioComponent} from './core/components/staffing-ratio/staffing-ratio.component';
import {StaffingRatioPeerGroupsComponent} from './core/components/administrator/staffing-ratio/staffing-ratio-peer-groups/staffing-ratio-peer-groups.component';
import {StaffingRatioAddPeerGroupsComponent} from './core/components/administrator/staffing-ratio/staffing-ratio-add-peer-groups/staffing-ratio-add-peer-groups.component';
import {FirmStaffingratioSubscriptionComponent} from './core/components/administrator/firm-staffingratio-subscription/firm-staffingratio-subscription.component';
import {MyInputSheetComponent} from './core/components/staffing-ratio/my-input-sheet/my-input-sheet.component';
import {MyPeerGroupsComponent} from './core/components/staffing-ratio/my-peer-groups/my-peer-groups.component';
import {MyReportsComponent} from './core/components/staffing-ratio/my-reports/my-reports.component';
import {StaffingRatioCutomiseSegmentComponent} from './core/components/administrator/staffing-ratio/staffing-ratio-cutomise-segment/staffing-ratio-cutomise-segment.component';
import {RenameCustomPeergroupComponent} from './core/components/staffing-ratio/my-peer-groups/rename-custom-peergroup/rename-custom-peergroup.component';
import {AdminConfigurationsComponent} from './core/components/administrator/staffing-ratio/admin-configurations/admin-configurations.component';
import {StaffingRatioPeergroupDetailsComponent} from './core/components/administrator/staffing-ratio/staffing-ratio-peergroup-details/staffing-ratio-peergroup-details.component';
import {CustomPeergroupGridComponent} from './core/components/staffing-ratio/my-peer-groups/custom-peergroup-grid/custom-peergroup-grid.component';
import {PmmLinksComponent} from './core/components/pmm-links/pmm-links/pmm-links.component';
import {OneOffsComponent} from './core/components/pmm-links/one-offs/one-offs.component';
import {PmmFaqComponent} from './core/components/pmm-links/pmm-faq/pmm-faq/pmm-faq.component';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup


@NgModule({
  declarations: [
    AppComponent,
    PmGlobalHeaderComponent,
    PmGlobalSidebarComponent,
    FirmSettingComponent,
    AdministratorComponent,
    FirmDetailsComponent,
    DetailsComponent,
    FirmParameterComponent,
    GlobalBreadcrumbsComponent,
    ActionDropdownComponent,
    FirmUsersComponent,
    UserInformationComponent,
    FirmUserDetailsComponent,
    FirmPeerGroupsComponent,
    FirmCustomPeerGroupsComponent,
    CurrentViewsComponent,
    CreateViewComponent,
    PeerCheckComponent,
    TitlePermissionComponent,
    CurrentViewDetailsComponent,
    GlobalPeerGroupsComponent,
    GlobalPeerGroupsFirmsComponent,
    PerformancePeerGroupsComponent,
    CreatePerformancePeerGroupComponent,
    CurrentPerformancePeerGroupsComponent,
    ViewsSettingsComponent,
    SaveViewComponent,
    SvgLogoComponent,
    SvgClockComponent,
    SvgExclamationComponent,
    SvgQuestionComponent,
    LandingComponent,
    ContactUsComponent,
    PivotTableViewComponent,
    ExpandTableOfficesExpensesComponent,
    PivotTableGridViewComponent,
    ExpandTableViewComponent,
    ResourcesComponent,
    BentoCustomMultiselectOverlayComponent,
    BentoListSubscribable,
    BentoMultiselectListComponent,
    BentoListComponent,
    UnauthorizedComponent,
    UserNotFoundComponent,
    PageNotFoundComponent,
    OfflineComponent,
    SiteDownComponent,
    AccessDeniedComponent,
    ExportModalComponent,
    ShareViewModelComponent,
    ViewDataGridComponent,
    ServerErrorComponent,
    FirmPermissionComponent,
    GridRecordDetailsComponent,
    GenericErrorComponent,
    UserPermissionsComponent,
    FirmPeerGroupDetailComponent,
    ViewsComponent,
    CurrentChartsComponent,
    CurrentChartsListComponent,
    SortingDropdownComponent,
    CreateChartComponent,
    ChartDetailsComponent,
    DateRangeComponent,
    DataManagerComponent,
    TwoDigitDecimalNumberDirective,
    RatesReportsComponent,
    NewRatesReportComponent,
    ReportsTabComponent,
    ReportsProgressComponent,
    StaffingRatioComponent,
    StaffingRatioPeerGroupsComponent,
    StaffingRatioAddPeerGroupsComponent,
    FirmStaffingratioSubscriptionComponent,
    MyInputSheetComponent,
    MyPeerGroupsComponent,
    MyReportsComponent,
    StaffingRatioCutomiseSegmentComponent,
    RenameCustomPeergroupComponent,
    AdminConfigurationsComponent,
    StaffingRatioPeergroupDetailsComponent,
    CustomPeergroupGridComponent,
    PmmLinksComponent,
    OneOffsComponent,
    PmmFaqComponent,
    ActionsMenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BentoModule,
    NgbModule,
    BentoD3Module,
    BentoD3DataMapModule,
    BentoTransferboxModule,
    BentoFlexGridControlModule,
    WjGridModule,
    WjGridTransposedModule,
    WjChartModule,
    WjChartAnimationModule,
    WjInputModule,
    WjGridFilterModule,
    WjGridGrouppanelModule,
    BrowserAnimationsModule,
    BfmModule,
    WjGridDetailModule,
    InlineStylesCSPModule,
    NgIdleKeepaliveModule.forRoot(),
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        ...environment.httpInterceptor,
      },
    }),
  ],
  providers: [
    Title,
    BaseService,
    AuthService,
    AuthenticationGuard,
    CurrencyPipe,
    PercentPipe,
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
