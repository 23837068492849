<div  class="modal-content" [bentoBusyLoader]="isDataLoading" >
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalExport" class="modal-header h2">Export</h3>
  <div class="modal-body" >
    <bento-alert [inline]="true" [items]="warningAlerts"></bento-alert>    
    <div class="message" aria-live="polite">
    <p *ngIf="!isDataLoading">
      Select export criteria below. <span class="sr-only">&nbsp;</span>
      <span class="note-info tempinfo">
        Note - Maximum of <strong>{{ maxNumberOfExportCheckbox }} checkboxes </strong> are allowed for export at a
        time.</span
      >
    </p>
  </div>
  <table *ngIf="!isDataLoading" class="table table-striped">
    <thead>
      <tr>
        <td id="blank"></td>
        <th class="table-header" id="co1" scope="col" headers="blank">
          <!-- TEMPORARY SOLUTION UNCOMMENT HEADER CHECKBOXES ONCE EXPORT ISSUE IS FIXED-->
          <!-- <bento-checkbox
            ngDefaultControl
            class="checbox-display"
            (change)="onChangeHeader('detailed')"
            [(ngModel)]="detailed"
            id="detailed"
            name="detailed"
          >
          </bento-checkbox> -->
          <!-- TEMPORARY SOLUTION UNCOMMENT HEADER CHECKBOXES ONCE EXPORT ISSUE IS FIXED-->
          <label class="label-display" for="detailed">Detailed</label>
        </th>
        <th class="table-header" id="co1" scope="col" headers="blank">
          <!-- TEMPORARY SOLUTION UNCOMMENT HEADER CHECKBOXES ONCE EXPORT ISSUE IS FIXED-->
          <!-- <bento-checkbox
            (change)="onChangeHeader('monthly')"
            ngDefaultControl
            class="checbox-display"
            [(ngModel)]="monthly"
            id="monthly"
            name="monthly"
          >
          </bento-checkbox> -->
          <!-- TEMPORARY SOLUTION UNCOMMENT HEADER CHECKBOXES ONCE EXPORT ISSUE IS FIXED-->
          <label class="label-display" for="monthly">Monthly</label>
        </th>
        <th class="table-header" id="co1" scope="col" headers="blank">
          <!-- TEMPORARY SOLUTION UNCOMMENT HEADER CHECKBOXES ONCE EXPORT ISSUE IS FIXED-->
          <!-- <bento-checkbox
            (change)="onChangeHeader('quarter')"
            class="checbox-display"
            ngDefaultControl
            [(ngModel)]="quarter"
            id="quarter"
            name="quarter"
          >
          </bento-checkbox> -->
          <!-- TEMPORARY SOLUTION UNCOMMENT HEADER CHECKBOXES ONCE EXPORT ISSUE IS FIXED-->
          <label class="label-display" for="quarter">Quarter</label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let metric of metrics">
        <td id="c1" headers="blank">{{ metric.name }}</td>
        <td class="text-center">
          <bento-checkbox
            [(ngModel)]="metric.detailed"
            id="detailed {{ metric.name }}"
            [disabled]="totalMetricSelected >= maxNumberOfExportCheckbox && !metric.detailed"
            (change)="onCheckChange()"
            [attr.aria-label]="'detailed ' + metric.name"
          ></bento-checkbox>
        </td>
        <td class="text-center">
          <bento-checkbox
            [(ngModel)]="metric.monthly"
            id="'monthly' + {{ metric.name }}"
            [disabled]="totalMetricSelected >= maxNumberOfExportCheckbox && !metric.monthly"
            (change)="onCheckChange()"
            [attr.aria-label]="'monthly ' + metric.name"
          ></bento-checkbox>
        </td>
        <td class="text-center">
          <bento-checkbox
            [(ngModel)]="metric.quarter"
            id="'quarter' + {{ metric.name }}"
            [disabled]="totalMetricSelected >= maxNumberOfExportCheckbox && !metric.quarter"
            (change)="onCheckChange()"
            [attr.aria-label]="'quarter ' + metric.name"
          ></bento-checkbox>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  <div class="modal-footer a11y">
    <button
      type="button"
      class="btn btn-primary"
      (click)="totalMetricSelected == 0 ? $event.stopPropagation() : onExportClick()"
      [attr.aria-disabled]="totalMetricSelected == 0 ? 'true' : 'false'"
      [attr.aria-labelledby]="totalMetricSelected == 0 ? 'aria-error' : null"
    >
      Export
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('OK')">Cancel</button>
    <span class="sr-only" id="aria-error">Select export criteria, Export</span>
  </div>
</div>
