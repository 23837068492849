<div [bentoBusyLoader]="isInformationLoading">
  <header class="Section-header">
    <h2 class="Section-heading">Title</h2>
  </header>
  <div class="Section-body" *ngIf="isGetDataloading">
    <div class="row">
      <div class="col">
        <h3>Firm visible metrics</h3>
      </div>
      <div class="col">
        <h3>Restricted metrics</h3>
      </div>
    </div>
    <bento-transferbox-basic
      #transferbox
      [boxOneItemSource]="dataOne"
      [boxTwoItemSource]="dataTwo"
      [boxOneTitle]="'Available items'"
      [boxTwoTitle]="'Selected items'"
      [columnDefinitions]="gridOptions"
      [columnKey]="'id'"
      [height]="gridHeight"
      [hidePagination]="hidePagination"
      (transfer)="onTransfer($event)"
      (keydown)="onKeyDown($event)"
    ></bento-transferbox-basic>
  </div>
  <footer class="Section-footer">
    <div class="Actions">
      <div class="Actions-item">
        <button type="button" class="btn btn-outline-secondary" (click)="closeMetricInfo()">
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item">
        <button type="submit" class="btn btn-primary" (click)="saveMetricInfo()">
          <span class="btn-text">Save</span>
        </button>
      </div>
    </div>
  </footer>
</div>
