import {Component, OnInit, ViewChild} from '@angular/core';
import {BentoAlertItemOptions, BentoComboboxOptions} from '@bento/bento-ng';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcCore from '@grapecity/wijmo';
import {BehaviorSubject, from} from 'rxjs';
import {CollectionView} from '@grapecity/wijmo';
import * as wijmo from '@grapecity/wijmo';
import {IValidator, RequiredValidator, MinDateValidator, MaxDateValidator} from './app.validation';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {DataManagerDetails, Month} from 'src/app/core/models/data-manager-details.model';
import {GlobalService} from 'src/app/core/services/global/global.service';
import { CellType } from '@grapecity/wijmo.grid';

@Component({
  selector: 'app-data-manager',
  templateUrl: './data-manager.component.html',
  styleUrls:['./data-manager.component.scss']
})
export class DataManagerComponent implements OnInit {
  selectedYear: any;
  listOfYearStream: BehaviorSubject<any>;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  // reference to FlexGrid Data Collection View
  data: wjcCore.CollectionView;
  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    autoSelect: false,
  };
  dataBackup = [];
  errorRows: any[];
  isDataModified = false;
  isDataLoading: boolean;
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  proccessedDataManager: {processedData: Month[]; processedColumn: any[]};
  rowCount:any =0;
  colCount:any =0;
  lastPressedKey: any;

  constructor(private baseService: BaseService, private _peercheckMsgHandlerService: PeercheckMessageHandlerService, private globalservice: GlobalService) {
    this.errorRows = [];
  }

  ngOnInit(): void {
    this.getListOfMonthYear();
  }

  getListOfMonthYear() {
    this.isDataLoading = true;
    this.baseService.get(environment.FIClientBaseEndpoint + 'v1/dataschedule/years', '').subscribe(
      (result) => {
        this.isDataLoading = false;
        this.listOfYearStream = new BehaviorSubject(result.loadYears);
        this.selectedYear = result.currentYear;
        this.getBigbangReleaseDates();
        //added for skip 2 focus betno combobox for accessibility fix
        this.globalservice.skipFocusTwiceCombobox();
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: this._peercheckMsgHandlerService.getServiceErrorMessageString(this.errorMessage),
          closeable: true,
        });
      }
    );
  }

  getBigbangReleaseDates() {
    this.isDataLoading = true;
    this.baseService.get(environment.FIClientBaseEndpoint + 'v1/dataschedule/year/', this.selectedYear).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.processDataManagerResponse(result);
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: this._peercheckMsgHandlerService.getServiceErrorMessageString(this.errorMessage),
          closeable: true,
        });
      }
    );
  }

  /**
   * Handler for FlexGrid's Initialized method
   */
  gridInitialized(flexGrid: wjcGrid.FlexGrid) {
    flexGrid.cellEditEnded.addHandler(this.editHandler.bind(this));

    if (flexGrid) {
      flexGrid.cells.hostElement.setAttribute('aria-labelledby', 'DataManagerName');
      this.rowCount = wijmo.Globalize.format(flexGrid.rows.length, 'n0');
      this.colCount = wijmo.Globalize.format(flexGrid.columns.length, 'n0');
    }
    //Add custom 'aria-label' to custom input editors
    flexGrid.formatItem.addHandler((s, e) => {
      const col = s.columns[e.col],
          row = s.rows[e.row];
      if (e.panel.cellType === CellType.Cell && col.editor) {
          if((col.editor as any).inputElement !== null )
          {
              const inputEle = (col.editor as any).inputElement;
              inputEle.setAttribute('aria-label', 'Date editor');
          }
      }
    })
    this.flexGrid=flexGrid;
    flexGrid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });
    // flexGrid.select(-1,-1);
  }
  focusOnGrid(flexGrid,event){
    flexGrid.select(0,0);
  }

  editHandler(s, e: wjcGrid.FormatItemEventArgs) {
    if (JSON.stringify(this.dataBackup) !== JSON.stringify(this.data.items)) this.isDataModified = true;
    else this.isDataModified = false;
    const data = s.rows[e.row].dataItem;
    var errors = this.validate(data, s.columns[e.col].binding, this.selectedYear);
    if (errors && this.errorRows.indexOf(e.row) == -1) {
      this.errorRows.push(e.row);
    } else if (!errors) {
      if (this.errorRows.indexOf(e.row) != -1) {
        var idx = this.errorRows.indexOf(e.row);
        this.errorRows.splice(idx, 1);
      }
      let countryBinding = s.columns[e.col].binding.split('.');
      const selectedObj = this.getPropByString(data, countryBinding[0]);
      const requestBody = {
        country: selectedObj.country,
        yyyymm: data.yyyymm,
        bigBangDate: this.formatDate(selectedObj.bigBangDate),
      };
      this.updateDataSchedule(requestBody);
    }
  }

  updateDataSchedule(requestBody: any) {
    if (!requestBody) {
      return;
    }
    this.isDataLoading = true;
    this.baseService.put(environment.FIClientBaseEndpoint + 'v1/dataschedule', requestBody).subscribe(
      (result) => {
        this.isDataLoading = false;
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: this._peercheckMsgHandlerService.getServiceErrorMessageString(this.errorMessage),
          closeable: true,
        });
      }
    );
  }
  //use to disable all dates other than current month
  dropDownChanged(s, e: wjcCore.EventArgs) {
    // let dt = this.getMinMaxDate(e[0], this.selectedYear);
    // s.calendar.displayMonth = dt.min;
    // s.min = dt.min;
    // s.max = dt.max;
  }

  processDataManagerResponse(response: DataManagerDetails) {
    let columns = [];
    const dataToProcess = response ? response : null;
    if (!dataToProcess) return;
    dataToProcess.months.forEach((month, index) => {
      month.countries.forEach((country) => {
        if (index == 0) {
          let column = {
            header: this.getInitCapString(country.country),
            binding: country.country.replace(' ', '_') + '.bigBangDate',
            format: 'MM/dd/yyyy',
          };
          columns.push(column);
        }
        country.bigBangDate = new Date(country.bigBangDate.toString().replace(/-/g, '/'));
        country.modifiedDate = new Date(country.modifiedDate.toString().replace(/-/g, '/'));
        month[country.country.replace(' ', '_')] = country;
        delete month.countries;
      });
    });

    this.proccessedDataManager = {
      processedData: dataToProcess.months,
      processedColumn: columns,
    };

    this.data = new CollectionView(dataToProcess.months, {
      getError: (item: any, prop: any) => {
        const isValid = this.validate(item, prop, this.selectedYear);
        return isValid;
      },
    });
  }

  getInitCapString(value: string, firstOnly: boolean = false): string {
    const arr = value.split(' ');

    for (var i = 0; i < arr.length; i++) {
      if (firstOnly && i > 0) break;
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    return arr.join(' ');
  }
  yearSelectionChange() {
    this.getBigbangReleaseDates();
  }

  saveData() {}

  resetData() {}

  loadRows(s, e) {
    var self = this;
    s.rows.forEach((r) => {
      var data = r.dataItem;
      let errorFlag = this.checkForError(s.columns, data);
      if (errorFlag) {
        self.errorRows.push(r.index);
      }
    });
  }

  rowEditEnded(s, e) {
    var rowIdx = e.row;
    var data = s.rows[rowIdx].dataItem;
    var errors = this.checkForError(s.columns, data);
    if (errors && this.errorRows.indexOf(rowIdx) == -1) {
      this.errorRows.push(rowIdx);
    } else if (!errors && this.errorRows.indexOf(rowIdx) != -1) {
      var idx = this.errorRows.indexOf(rowIdx);
      this.errorRows.splice(idx, 1);
    }
  }

  checkForError(columns, data) {
    var errorFlag = false;
    columns.forEach((c) => {
      let error = this.data.getError(data, c.binding);
      if (error != undefined && error != '') {
        errorFlag = true;
        return;
      }
    });
    return errorFlag;
  }

  // use to get mindate and max date allowed to be selected
  getMinMaxDate(item, year) {
    let mon = this.getMonthFromString(item.month, year);
    let rowDate = new Date(year, mon, 1);
    let firstDay = new Date(rowDate.getFullYear(), rowDate.getMonth(), 1);
    let lastDay = new Date(rowDate.getFullYear(), rowDate.getMonth() + 1, 0);
    const obj = {
      min: firstDay,
      max: lastDay,
    };
    return obj;
  }
  validationConfig(item, year) {
    let mon = this.getMonthFromString(item.month, year);
    let rowDate = new Date(year, mon, 1);
    let firstDay = new Date(rowDate.getFullYear(), rowDate.getMonth(), 1);
    let lastDay = new Date(rowDate.getFullYear(), rowDate.getMonth() + 1, 0);
    const obj = [new RequiredValidator(), new MinDateValidator(firstDay), new MaxDateValidator(lastDay)];
    return obj;
  }
  getMonthFromString(mon, year) {
    var d = Date.parse(mon + '1,' + year);
    if (!isNaN(d)) {
      return new Date(d).getMonth();
    }
    return -1;
  }
  validate(item: any, prop: string, year: any): string {
    const validators: IValidator[] = this.validationConfig(item, year);
    if (wjcCore.isUndefined(validators)) {
      return '';
    }

    // Use of dynamic property accessor.
    const value = this.getPropByString(item, prop);
    if (isNaN(value)) return '';
    for (let i = 0; i < validators.length; i++) {
      const validationError = validators[i].validate('Date', value);
      if (!wjcCore.isNullOrWhiteSpace(validationError)) {
        return validationError;
      }
    }
  }

  getPropByString(obj, propString) {
    if (!propString) return obj;

    var prop,
      props = propString.split('.');

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];

      var candidate = obj[prop];
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[i]];
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  handleComboboxKeydown(event){
    if (event.key === 'Tab') {
      this.flexGrid.select(0,0);
    }
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
      if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
        let selectedCell = args.panel.getCellElement(args.row, args.col);
        if (selectedCell) {
          selectedCell.scrollIntoView({
            block: 'center',
            behavior: 'auto',
            inline: 'nearest',
          });
        }
      }
    }
}
