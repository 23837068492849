<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" [bentoBusyLoader]="isGetDataLoading">
  <header class="Main-header" role=" Main-header">
    <div class="row">
      <div class="col">
        <h1 class="Main-heading">General Ledger (GL) account codes</h1>
        <app-mapping-firms-details></app-mapping-firms-details>
      </div>
      <div class="col">
        <app-action-dropdown
          #actionButton
          [actionDropdownItems]="actionDropdownItems"
          (dropDownSelectChange)="dropDownOnSelectChange($event)"
        >
        </app-action-dropdown>
        <input
          type="file"
          id="fileImport"
          class="d-none"
          (change)="handleFileChange($event.target.files)"
          #importFile
        />
        <ng-template #importExcelModal let-c="close" let-d="dismiss">
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <i class="bento-icon-close-x" aria-hidden="true"></i>
          </button>
          <h3 id="modalImport" class="modal-header h2">Import</h3>
          <div class="modal-body">
            <p>{{ fileUploadMessage }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="c('Close click')">OK</button>
          </div>
        </ng-template>
      </div>
    </div>
  </header>
  <div class="Main-body">
    <wj-flex-grid
      (click)="onMappedClicked()"
      #flexGrid
      class="DataGrid DataGridFlexScroll"
      [class.list-grid]="isListGrid"
      [itemsSource]="data"
      [stickyHeaders]="true"
      [showMarquee]="true"
      [showSort]="true"
      [headersVisibility]="'Column'"
      [autoRowHeights]="true"
      [virtualizationThreshold]="data.totalItemCount"
      (initialized)="gridInitialized(flexGrid)"
      (loadedRows)="initLoadedRow(flexGrid)"
    >
      <wj-flex-grid-filter
        #filter
        (initialized)="initFilter()"
        (filterChanged)="onFilterChange()"
      ></wj-flex-grid-filter>
      <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'" let-col="col">
        <ng-container *ngIf="col.index === 0">
          <bento-checkbox
            id="selectAll"
            [ngModel]="isAllSelected()"
            (change)="onHeaderCheckBoxChange()"
            [indeterminate]="isIndeterminate()"
          ></bento-checkbox>
          <label for="selectAll" class="sr-only">Select all</label>
        </ng-container>
      </ng-template>
      <ng-template wjFlexGridCellTemplate [cellType]="'RowHeader'" let-col="col" let-row="row" let-item="item">
        <ng-container *ngIf="col.index === 0">
          <bento-checkbox
            id="selectRow-{{ row.index }}"
            [ngModel]="item.selected"
            (change)="item.disabled || (item.selected = !item.selected); onCheckBoxChange()"
          ></bento-checkbox>
          <label for="selectRow-{{ row.index }}" class="sr-only">{{ item.name }}</label>
        </ng-container>
      </ng-template>
      <wj-flex-grid-column
        [header]="'Code'"
        [binding]="'firmCode'"
        [visible]="columnDefinitionsByBinding['firmCode'].visible"
        [width]="columnDefinitionsByBinding['firmCode'].width"
        [isReadOnly]="columnDefinitionsByBinding['firmCode'].isReadOnly"
        [wordWrap]="true"
      ></wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Name'"
        [binding]="'firmDescription'"
        [minWidth]="columnDefinitionsByBinding['firmDescription'].minWidth"
        [width]="columnDefinitionsByBinding['firmDescription'].width"
        [visible]="columnDefinitionsByBinding['firmDescription'].visible"
        [isReadOnly]="columnDefinitionsByBinding['firmDescription'].isReadOnly"
        [wordWrap]="true"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
          <span class="DataGrid-overrideIndicator" *ngIf="item.hasOverride">
            <span class="sr-only">Override applied:</span>
          </span>
          <a [routerLink]="['.']" (click)="selectedOverride(item)">{{ item.firmDescription }}</a>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Account category'"
        [binding]="'accountCategory'"
        [minWidth]="columnDefinitionsByBinding['accountCategory'].minWidth"
        [width]="columnDefinitionsByBinding['accountCategory'].width"
        [visible]="columnDefinitionsByBinding['accountCategory'].visible"
        [isReadOnly]="columnDefinitionsByBinding['accountCategory'].isReadOnly"
        [wordWrap]="true"
      ></wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Mapped account name'"
        [binding]="'pmCode'"
        [visible]="columnDefinitionsByBinding['pmCode'].visible"
        [minWidth]="columnDefinitionsByBinding['pmCode'].minWidth"
        [width]="columnDefinitionsByBinding['pmCode'].width"
        [wordWrap]="true"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'CellEdit'" let-cell="cell">
          <label for="mappedGLAccountCodeName" class="sr-only">Mapped General Ledger (GL) account code name</label>
          <bento-combobox
            id="mappedGLAccountCodeName"
            (ngModelChange)="onChangeDropdownValue($event, cell)"
            [(ngModel)]="cell.value"
            [itemsObservable]="dataEmitter"
            [options]="comboboxOptions"
            [placeholder]="'Select here...'"
            [appendTo]="'main'"
            (ready)="onComboboxReady()"
            bentoFlexGridControl
          ></bento-combobox>
        </ng-template>
      </wj-flex-grid-column>
    </wj-flex-grid>
    <!-- TODO: Comment out pagination per Product -->
    <!-- BENTO PAGINATION -->
    <!-- <bento-pagination
      [page]="data.pageIndex + 1"
      [directionLinks]="true"
      [boundaryLinks]="true"
      [totalItems]="data.totalItemCount"
      [(itemsPerPage)]="data.pageSize"
      (itemsPerPageChange)="onPageSizeChanged($event)"
      (pageChange)="onPageChanged($event)"
    ></bento-pagination> -->
  </div>
  <footer class="Main-footer">
    <div class="Actions justify-content-end">
      <div class="Actions-item a11y">
        <button type="button" class="btn btn-outline-secondary grey-outline"
                [attr.aria-disabled]="isDisabled ? 'true' : 'false' " 
                (click)="isDisabled ? $event.stopPropagation() : resetGlAccountCodes()"
        >
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item a11y">
        <button type="submit" class="btn btn-primary" 
                [attr.aria-disabled]="isDisabled ? 'true' : 'false' " 
                (click)="isDisabled ? $event.stopPropagation() : saveGlAccountCodes()"
                [attr.aria-labelledby]="isDisabled ? 'aria-required-save' : null "
        >
          <span class="btn-text">Save</span>
        </button>
      </div>
      <span class="sr-only" id="aria-required-save">Save</span>
    </div>
  </footer>
</main>
