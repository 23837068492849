<bento-alert [items]="alerts" (close)="closeAlert($event)"></bento-alert>
<div class="GlobalLayout" [bentoBusyLoader]="isDataLoading" *ngIf="currentStatus != 'Pending'">
  <main id="MainContent" role="main" class="Main" tabindex="-1">
    <header class="Main-header">
      <app-global-breadcrumbs
        [isFirmPeerGroup]="true"
        (updateService)="updateServiceHandler()"
        [firmName]="isTAdmin ? 'Peer groups' : firmDetails.name"
        [peerLabel]="selectedOption && selectedOption.peerlabel ? selectedOption.peerlabel : ''"
      >
      </app-global-breadcrumbs>
      <div class="row">
        <div class="col">
          <h1 class="Main-heading" id="peergroupHeader">
            {{ selectedOption && selectedOption.peerlabel ? selectedOption.peerlabel : '' }}
          </h1>
          <dl class="bento-desc-list bento-desc-list--inline bento-desc-list--bullets FirmDetails">
            <div class="bento-desc-list-item" *ngIf="currentStatus == 'Approved'">
              <dt>Peer Group ID:</dt>
              <dd>{{ selectedOption && selectedOption.peerid ? selectedOption.peerid : '' }}</dd>
            </div>
            <div class="bento-desc-list-item" *ngIf="isTAdmin && currentStatus == 'Approved'">
              <dt>Firm:</dt>
              <dd>{{ firmDetails.name }}</dd>
            </div>
            <div class="bento-desc-list-item" *ngIf="isTAdmin && currentStatus == 'Global'">
              <dt>Visibility:</dt>
              <dd>{{ visibility }}</dd>
            </div>
          </dl>
        </div>
        <div class="col">
          <div class="Actions justify-content-end">
            <div class="Actions-item">
              <button
                type="button"
                class="btn btn-primary"
                (click)="handleEditButtonAction()"
                *ngIf="currentStatus == 'Approved'"
              >
                <span class="btn-text">Edit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="Main-body">
      <section class="Section">
        <header class="row align-items-end Section-header">
          <div class="col">
            <app-grid-record-details
              [currentRecords]="currentRecords"
              [totalRecords]="totalRecords"
            ></app-grid-record-details>
          </div>
          <div class="col">
            <div class="Actions justify-content-end">
              <div class="Actions-item">
                <span class="bento-search">
                  <label for="firmPeerGroup" class="sr-only">Search firm</label>
                  <input
                    type="text"
                    class="form-control"
                    name="firmPeerGroup"
                    id="firmPeerGroup"
                    [(ngModel)]="searchText"
                  />
                  <button type="button" class="btn btn-primary" (click)="searchGlobalPeerGroups()">
                    <span class="btn-text">Search</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </header>
        <div class="Section-body">
          <wj-flex-grid
            #flexGrid
            class="DataGrid"
            [itemsSource]="gridData"
            [autoClipboard]="false"
            [isReadOnly]="true"
            [allowDragging]="false"
            [autoRowHeights]="true"
            [showMarquee]="true"
            (initialized)="gridInitialized(flexGrid, 'peergroupHeader')"
            (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
          >
            <wj-flex-grid-column
              [header]="'Firm name'"
              [binding]="'name'"
              [align]="'left'"
              [width]="'*'"
              [align]="'left'"
              [wordWrap]="true"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Headquarter Office'"
              [binding]="'headQuarterOffice'"
              [allowResizing]="false"
              [width]="200"
              [align]="'left'"
              [wordWrap]="true"
              *ngIf="currentStatus != 'Global'"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Rank (AmLaw)'"
              [binding]="'rank'"
              [allowResizing]="false"
              [width]="140"
              [align]="'left'"
              [wordWrap]="true"
              *ngIf="currentStatus != 'Global'"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Lawyers'"
              [binding]="'lawyers'"
              [allowResizing]="false"
              [width]="120"
              [align]="'left'"
              [wordWrap]="true"
              *ngIf="currentStatus != 'Global'"
            ></wj-flex-grid-column>
          </wj-flex-grid>
        </div>
        <footer class="Section-footer">
          <bento-pagination
            [page]="gridData.pageIndex + 1"
            [directionLinks]="true"
            [boundaryLinks]="true"
            [totalItems]="gridData.totalItemCount"
            [(itemsPerPage)]="gridData.pageSize"
            (itemsPerPageChange)="onItemsPerPageChanged($event)"
            (pageChange)="onPageChanged($event)"
          ></bento-pagination>
        </footer>
      </section>
    </div>
  </main>
</div>

<div class="GlobalLayout" [bentoBusyLoader]="isDataLoading" *ngIf="currentStatus == 'Pending'">
  <main id="MainContent" class="Main" tabindex="-1">
    <header class="Main-header">
      <app-global-breadcrumbs
        [isFirmPeerGroup]="true"
        (updateService)="updateServiceHandler()"
        [firmName]="isTAdmin ? 'Peer groups' : firmDetails.name"
        [peerLabel]="selectedOption && selectedOption.peerlabel ? selectedOption.peerlabel : ''"
      ></app-global-breadcrumbs>
      <h1 class="Main-heading" id="peergroupHeader">
        {{ selectedOption && selectedOption.peerlabel ? selectedOption.peerlabel : '' }}
      </h1>
      <dl class="bento-desc-list bento-desc-list--inline bento-desc-list--bullets FirmDetails" *ngIf="isTAdmin">
        <div class="bento-desc-list-item">
          <dt>Peer Group ID:</dt>
          <dd>{{ selectedOption && selectedOption.peerid ? selectedOption.peerid : '' }}</dd>
        </div>
        <div class="bento-desc-list-item">
          <dt>Firm:</dt>
          <dd>{{ firmDetails.name }}</dd>
        </div>
      </dl>
    </header>
    <div class="Main-body">
      <section class="Section">
        <header class="row justify-content-end Section-header" *ngIf="isFAdmin">
          <div class="col-6">
            <div class="Actions justify-content-end">
              <div class="Actions-item">
                <span class="bento-search">
                  <label for="firmPeerGroup" class="sr-only">Search firm</label>
                  <input
                    type="text"
                    class="form-control"
                    name="firmPeerGroup"
                    id="firmPeerGroup"
                    [(ngModel)]="searchText"
                  />
                  <button type="button" class="btn btn-primary" (click)="searchGlobalPeerGroups()">
                    <span class="btn-text">Search</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </header>
        <div class="Section-body">
          <wj-flex-grid
            #flexGrid
            class="DataGrid"
            [itemsSource]="gridData"
            [showMarquee]="true"
            [autoClipboard]="false"
            [isReadOnly]="true"
            [allowDragging]="false"
            [autoRowHeights]="true"
            (initialized)="gridInitialized(flexGrid, 'peergroupHeader')"
            (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
          >
            <wj-flex-grid-column
              [header]="'Firm ID'"
              [binding]="'firmId'"
              [align]="'left'"
              [width]="150"
              [wordWrap]="true"
              *ngIf="isTAdmin"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Firm name'"
              [binding]="'name'"
              [align]="'left'"
              [width]="'*'"
              [wordWrap]="true"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Headquarter Office'"
              [binding]="'headQuarterOffice'"
              [allowResizing]="false"
              [width]="200"
              [align]="'left'"
              [wordWrap]="true"
              *ngIf="isFAdmin"
            >
            </wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Rank (AmLaw)'"
              [binding]="'rank'"
              [allowResizing]="false"
              [width]="140"
              [align]="'left'"
              [wordWrap]="true"
              *ngIf="isFAdmin"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Lawyers'"
              [binding]="'lawyers'"
              [allowResizing]="false"
              [width]="120"
              [align]="'left'"
              [wordWrap]="true"
              *ngIf="isFAdmin"
            ></wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Change date'"
              [binding]="'changeDate'"
              [format]="'MM-dd-yyyy'"
              [allowResizing]="false"
              [width]="220"
              [align]="'left'"
              [wordWrap]="true"
              *ngIf="isTAdmin"
            >
            </wj-flex-grid-column>
            <wj-flex-grid-column
              [header]="'Status'"
              [binding]="'status'"
              [allowResizing]="false"
              [width]="150"
              [align]="'center'"
              [wordWrap]="true"
              *ngIf="isTAdmin"
            ></wj-flex-grid-column>
          </wj-flex-grid>
        </div>
      </section>
    </div>
    <footer class="Main-footer">
      <div class="Actions justify-content-end" *ngIf="isTAdmin">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="handleButtonAction('Disapprove')">
            <span class="btn-text">Disapprove</span>
          </button>
        </div>
        <div class="Actions-item">
          <button type="button" class="btn btn-primary" (click)="handleButtonAction('Approve')">
            <span class="btn-text">Approve</span>
          </button>
        </div>
      </div>
      <div class="Actions justify-content-end" *ngIf="isFAdmin">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="handleButtonAction('Cancel')">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-danger" (click)="handleButtonAction('Delete')">
            <span class="btn-text">Delete</span>
          </button>
        </div>
        <div class="Actions-item">
          <button type="button" class="btn btn-primary" (click)="handleButtonAction('Edit')">
            <span class="btn-text">Edit</span>
          </button>
        </div>
      </div>
    </footer>
  </main>
</div>
