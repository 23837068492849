import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticationGuard} from './core/guards/authentication.guard';
import {AdministratorComponent} from './core/components/administrator/administrator/administrator.component';
import {Role} from './shared/enums';
import {FirmParameterComponent} from './core/components/administrator/firm-parameter/firm-parameter.component';
import {FirmSettingComponent} from './core/components/administrator/firm-setting/firm-setting.component';
import {CurrentViewsComponent} from './core/components/peer-check/current-views/current-views.component';
import {CreateViewComponent} from './core/components/peer-check/create-view/create-view.component';
import {PeerCheckComponent} from './core/components/peer-check/peer-check/peer-check.component';
import {GlobalPeerGroupsComponent} from './core/components/administrator/global-peer-groups/global-peer-groups.component';
import {PerformancePeerGroupsComponent} from './core/components/peer-check/performance-peer-groups/performance-peer-groups.component';
import {LandingComponent} from './core/components/peer-check/landing/landing.component';
import {ContactUsComponent} from './core/components/contact-us/contact-us.component';
import {ResourcesComponent} from './core/components/peer-check/resources/resources.component';
import {UnauthorizedComponent} from './core/components/errors/unauthorized/unauthorized.component';
import {UserNotFoundComponent} from './core/components/errors/user-not-found/user-not-found.component';
import {PageNotFoundComponent} from './core/components/errors/page-not-found/page-not-found.component';
import {OfflineComponent} from './core/components/errors/offline/offline.component';
import {AccessDeniedComponent} from './core/components/errors/access-denied/access-denied.component';
import {ServerErrorComponent} from './core/components/errors/server-error/server-error.component';
import {GenericErrorComponent} from './core/components/errors/generic-error/generic-error.component';
import {CurrentChartsComponent} from './core/components/peer-check/current-charts/current-charts.component';
import {CreateChartComponent} from './core/components/peer-check/charts/create-chart/create-chart.component';
import {FeatureToggleGuard} from './core/guards/featureToggle.guards';
import {DataManagerComponent} from './core/components/data-manager/data-manager/data-manager.component';
import {ReportsTabComponent} from './core/components/rates-reports/reports-tab/reports-tab.component';
import {StaffingRatioComponent} from './core/components/staffing-ratio/staffing-ratio.component';
import {StaffingRatioPeerGroupsComponent} from './core/components/administrator/staffing-ratio/staffing-ratio-peer-groups/staffing-ratio-peer-groups.component';
import {StaffingRatioCutomiseSegmentComponent} from './core/components/administrator/staffing-ratio/staffing-ratio-cutomise-segment/staffing-ratio-cutomise-segment.component'
import { AdminConfigurationsComponent } from './core/components/administrator/staffing-ratio/admin-configurations/admin-configurations.component';
import { PmmLinksComponent } from './core/components/pmm-links/pmm-links/pmm-links.component';
const routes: Routes = [
  {
    path: '',
    component: PeerCheckComponent,
    canActivate: [AuthenticationGuard],
    data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser], title: 'Resources'},
    children: [
      {
        path: '',
        component: LandingComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser], title: 'Home'},
      },
      {
        path: 'firm/:id',
        component: LandingComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Home'},
      },
      {
        path: 'current-views',
        component: CurrentViewsComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser], title: 'Current views'},
      },
      {
        path: 'firm/:id/current-views',
        component: CurrentViewsComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Current views'},
      },
      {
        path: 'create-view',
        component: CreateViewComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser], title: 'Create view'},
      },
      {
        path: 'firm/:id/create-view',
        component: CreateViewComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Create view'},
      },
      {
        path: 'create-view/performance-peer-groups',
        component: PerformancePeerGroupsComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser], title: 'Create view'},
      },
      {
        path: 'firm/:id/create-view/performance-peer-groups',
        component: PerformancePeerGroupsComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Create view'},
      },
      {
        path: 'resources',
        component: ResourcesComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser], title: 'Resources'},
      },
      {
        path: 'visualizations',
        component: CurrentChartsComponent,
        canActivate: [AuthenticationGuard, FeatureToggleGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser], title: 'Visualizations'},
      },
      {
        path: 'firm/:id/visualizations',
        component: CurrentChartsComponent,
        canActivate: [AuthenticationGuard, FeatureToggleGuard],
        data: {roles: [Role.TAdmin], title: 'Visualizations'},
      },
      {
        path: 'new-visualization',
        component: CreateChartComponent,
        canActivate: [AuthenticationGuard, FeatureToggleGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser], title: 'New Visualization'},
      },
      {
        path: 'firm/:id/new-visualization',
        component: CreateChartComponent,
        canActivate: [AuthenticationGuard, FeatureToggleGuard],
        data: {roles: [Role.TAdmin], title: 'New Visualization'},
      },
      {
        path: 'data-manager',
        component: DataManagerComponent,
        canActivate: [AuthenticationGuard, FeatureToggleGuard],
        data: {roles: [Role.TAdmin], title: 'Data Manager'},
      },
      {
        path: 'rates-reports',
        component: ReportsTabComponent,
        canActivate: [AuthenticationGuard, FeatureToggleGuard],
        data: {roles: [Role.TAdmin], title: 'Rates Reports'},
      },
      {
        path: 'staffing-ratio',
        component: StaffingRatioComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser, Role.NOAccess], title: 'Staffing Ratio'},
      },
      {
        path: 'pmm',
        component: PmmLinksComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser, Role.NOAccess], title: 'Pmm Links'},
      },
    ],
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,

    canActivate: [AuthenticationGuard],
    data: {roles: [Role.TAdmin, Role.FAdmin, Role.GUser, Role.NOAccess], title: 'Contact us'},
  },
  {
    path: 'administrator',
    component: AdministratorComponent,
    canActivate: [AuthenticationGuard],
    data: {roles: [Role.TAdmin, Role.FAdmin], title: 'Administrator'},
    children: [
      {
        path: '',
        component: FirmParameterComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin, Role.FAdmin], title: 'Administrator'},
      },
      {
        path: 'firms',
        component: FirmSettingComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Firms'},
      },
      {
        path: 'firms/:id',
        component: FirmParameterComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Firms'},
      },
      {
        path: 'addfirm',
        component: FirmParameterComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Add firm'},
      },
      {
        path: 'peer-groups',
        component: GlobalPeerGroupsComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Global peer groups'},
      },
      {
        path: 'staffing-peer-groups',
        component: StaffingRatioPeerGroupsComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Staffing ratio peer groups'},
      },
      {
        path: 'staffingratio-configurations',
        component: AdminConfigurationsComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Staffing ratio admin configurations'},
      },
      {
        path: 'staffing-custom-segment',
        component: StaffingRatioCutomiseSegmentComponent,
        canActivate: [AuthenticationGuard],
        data: {roles: [Role.TAdmin], title: 'Staffing ratio custom segment'},
      },
    ],
  },
  
  {path: 'unauthorized', component: UnauthorizedComponent, data: {title: 'Unauthorized'}},
  {path: 'usernotfound', component: UserNotFoundComponent, data: {title: 'User not found'}},
  {path: 'offline', component: OfflineComponent, data: {title: 'Offline'}},
  {path: 'accessdenied', component: AccessDeniedComponent, data: {title: 'Access denied'}},
  {path: 'servererror', component: ServerErrorComponent, data: {title: 'Server error'}},
  {path: 'error/:errorstatus', component: GenericErrorComponent, data: {title: 'Error'}},
  // path not found then redirect to pagenotfound
  {path: '**', component: PageNotFoundComponent, data: {title: 'Page not found'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
