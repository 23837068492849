<div #topPanel class="bento-multiselect-list-item bento-multiselect-search">
  <div class="pills">
    <ul class="nav nav-pills" role="tablist" (keydown)="_onTabsKeydown($event)" cdkMonitorElementFocus>
      <li [attr.id]="'show-all' + _uid" [ngClass]="_selection == 0 ? 'active ' : ''" role="presentation">
        <button
          role="tab"
          (click)="show(0)"
          [attr.aria-selected]="_selection == 0 ? true : false"
          type="button"
          #showAll
        >
          <span>
            {{ 'BENTO_UI_MULTISELECT_OVERLAY_SHOW_ALL' | bentoTranslate : showAllText }}
          </span>
        </button>
      </li>
      <li [attr.id]="'show-selected' + _uid" [ngClass]="_selection == 1 ? 'active ' : ''" role="presentation">
        <button role="tab" (click)="show(1)" [attr.aria-selected]="_selection == 1 ? true : false" type="button">
          <span>
            {{ 'BENTO_UI_MULTISELECT_OVERLAY_SHOW_SELECTED' | bentoTranslate : showSelectedText }}
          </span>
        </button>
      </li>
    </ul>
  </div>
  <label class="bento-search-label" [attr.for]="'multiselect-filter-items-' + _uid">
    {{ 'BENTO_UI_MULTISELECT_OVERLAY_SEARCH_LABEL' | bentoTranslate : searchLabelText }}
    <span class="form-text" *ngIf="searchLabelHelpText != ''">
      {{ searchLabelHelpText }}
    </span>
  </label>
  <div class="bento-search">
    <input
      [attr.id]="'multiselect-filter-items-' + _uid"
      #searchField
      type="text"
      [ngModel]="_searchTerm"
      (ngModelChange)="onSearch($event)"
      class="form-control"
      aria-invalid="false"
    />
    <button
      class="bento-reset-close-button-custom"
      tabindex="0"
      aria-label="Reset  Search all items"
      *ngIf="_searchTerm != ''"
      (click)="clearSearchItem()"
    >
      <i class="bento-icon-close-x" aria-hidden="true"></i>
    </button>
  </div>
  <div class="bento-items-label" [attr.id]="'bento-items-label-' + _uid">
    <span>
      {{
        _selection === 0
          ? ('BENTO_UI_MULTISELECT_OVERLAY_SHOW_ALL' | bentoTranslate : showAllText)
          : ('BENTO_UI_MULTISELECT_OVERLAY_SHOW_SELECTED' | bentoTranslate : showSelectedText)
      }}
    </span>
    <span class="bento-multi-line-span" [hidden]="maxSelectedItems === 0">
      {{
        maxSelectedItems === 1
          ? ('BENTO_UI_MULTISELECT_OVERLAY_MAX_SELECTED_INFO' | bentoTranslate)
          : ('BENTO_UI_MULTISELECT_OVERLAY_MAX_SELECTED_INFO_PLURAL'
            | bentoTranslate
            | bentoStringReplace : '_NUMBER_' : maxSelectedItems)
      }}
    </span>
  </div>
</div>
<div class="sr-only" role="status" aria-live="polite">{{ this._searchResultInfo }}</div>
<bento-list
  #list
  cdkMonitorElementFocus
  [dataObservable]="_dataChange"
  [options]="_listOptions"
  [height]="_listHeight"
  [width]="_listWidth"
  [attr.id]="_selection == 0 ? 'show-all-panel' + _uid : 'show-selected-panel' + _uid"
  [attr.aria-labelledby]="'bento-items-label-' + _uid"
  (focus)="_onListFocus($event)"
  (keydown)="_onListKeydown($event)"
  aria-hidden="false"
  role="listbox"
  [attr.aria-activedescendant]="_activeIndex == -1 ? ( _selection == 0 ? 'row' + _uid + 0  : null ) : 'row' + _uid + _activeIndex"
  aria-multiselectable="true"
  tabindex="0"
>
  <ng-template #rowTemplate let-$index="index" let-$row="row" let-$o="options">
    <ng-container *ngIf="listItemTemplate && !$row.isSelectAll; else defaultListItemTemplate">
      <div
        [attr.id]="'row' + _uid + $index"
        class="bento-list-row bento-multiselect-list-item"
        [ngClass]="{
          'highlight-parent': $row.groupId == null && minSelectedItems == 0 && !isRatesReport,
          focused: $index === _activeIndex
        }"
        (click)="_onRowClick($event, $row, $index)"
        role="option"
        [attr.aria-selected]="!!$o.checkStatusRef.get($row)"
        [attr.aria-setsize]="_dataSize"
        [attr.aria-posinset]="$index + 1"
        [class.disabled]="$row.disabled"
      >
        <bento-checkbox
          class="items"
          [ngModel]="minSelectedItems > 0 ? $o.checkStatusRef.get($row.key) : $o.checkStatusRef.get($row)"
          [attr.tabindex]="-1"
          [attr.aria-hidden]="true"
          [disabled]="$row.disabled"
          [attr.id]="'checkbox_label_' + _uid + $index"
        ></bento-checkbox>
        <div class="bento-multiselect-list-item-label label-value">
          <ng-container
            *ngTemplateOutlet="listItemTemplate; context: {item: $row, index: $index, options: $o}"
          ></ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #defaultListItemTemplate>
      <div
        [attr.id]="'row' + _uid + $index"
        class="bento-list-row bento-multiselect-list-item"
        [ngClass]="{
          'bento-item-disabled': $row.disabled || $row.disabled === 1,
          focused: $index === _activeIndex
        }"
        (click)="_onRowClick($event, $row, $index)"
        role="option"
        [attr.aria-selected]="!!$o.checkStatusRef.get($row)"
        [attr.aria-setsize]="_dataSize"
        [attr.aria-posinset]="$index + 1"
        [attr.title]="$row.isSelectAll ? '' : $row.name"
        [class.disabled]="$row.disabled"
      >
        <bento-checkbox
          class="selectall"
          [ngModel]="minSelectedItems == 0 ? $o.checkStatusRef.get($row) : $o.checkStatusRef.get($row.key)"
          [disabled]="$row.disabled"
          [attr.tabindex]="-1"
          [attr.aria-hidden]="true"
          [attr.id]="'checkbox_label_' + _uid + $index"
        ></bento-checkbox>
        <div class="bento-multiselect-list-item-label label-value">
          {{ $row.isSelectAll ? ($row.name | bentoTranslate : selectAllText) : $row.name }}
        </div>
      </div>
    </ng-template>
  </ng-template>
</bento-list>
