<div [bentoBusyLoader]="isDataLoading" class="Main"> 
  <!--Bento loder overlay issue : Temporary Fix for Bento loader overlay in _layout.scss:288-->
  <bento-alert [items]="alerts"></bento-alert>
  <div class="modal-header model-header-sec">
    <div class="model-header--title-sec"><h3 class="">Custom Peer Group Selections</h3></div>
    <div class="sr-cpr-action-btns Actions-item">
      <button
        class="btn btn-primary"
        [disabled]="isDataLoading || isSaving || selectedFirmSRSubscriptionInfo.peerSubmitted"
        (click)="openResetAllConfirmation(resetAllModalContent)"
      >
        Reset All
      </button>
      <button class="btn btn-primary" (click)="printDoc()" [disabled]="isDataLoading">Print</button>
      <button
        class="btn btn-primary Actions-button max-width-100"
        [disabled]="
          isDataLoading || isSaving || isSubmitCustomPeerGroupDisabled || selectedFirmSRSubscriptionInfo.peerSubmitted
        "
        [bentoBusyLoader]="isSaving"
        (click)="submitCustomPeerGroup()"
      >
        <span class="btn-text"> Save </span>
      </button>
      <button class="btn btn-outline-primary" [disabled]="isSaving" (click)="cancelOperation(confirmationModalContent)">
        Cancel
      </button>
    </div>
  </div>
  <div class="modal-body">
    <p class="cpg-info-para">
      This page allows you to define and name your custom peer groups by selecting at least 5 firms in each group. Click
      each firm's button to add a firm to your peer group, (click again to remove any selection). If you would like to
      name a peer group, enter the appropriate name in the pop up window that opens when you click on the peer group
      column header. Click <b>Save</b> to send your selection and return to the previous screen.
    </p>
    <div class="Actions">
      <div class="results" style="display: inline-block; padding-bottom: 5px">
        <button
          type="button"
          class="btn btn-outline-secondary btn-icon btn-sm"
          [attr.aria-label]="
            isColumnFreezed
              ? 'Unfreeze Firms, Amlaw segment, Lawyer segment and Office segment columns'
              : 'Freeze Firms, Amlaw segment, Lawyer segment and Office segment columns'
          "
          [ngbTooltip]="
            isColumnFreezed
              ? 'Unfreeze Firms, Amlaw segment, Lawyer segment and Office segment columns'
              : 'Freeze Firms, Amlaw segment, Lawyer segment and Office segment columns'
          "
          placement="right"
          (click)="freezeColumnToggle()"
        >
          <i [ngClass]="isColumnFreezed ? 'bento-icon-lock' : 'bento-icon-unlocked'" aria-hidden="true"></i>
        </button>
        <span style="padding-left: 10px" class="sr-total-firms">Showing {{ currentRecords }} of {{ totalRecords }}</span>
      </div>
      <div role="alert" aria-live="polite" class="notify Actions-item">       
        <p class="note-info">Note - Press <span class="shortcut">Alt + Shift + Down Arrow </span> to see filter options for selected column.</p>
      </div>
    </div>
    <div id="sectionToPrint" class="mt-20 custom-peer-groups-list custom-popup justify-content-center">
      <wj-flex-grid
        #flexGrid
        class="DataGrid DataGridFlexScroll widthClass btn-addreport"
        [itemsSource]="customPeerGroupsData"
        [showMarquee]="true"
        [showSort]="true"
        [autoRowHeights]="true"
        [headersVisibility]="'Column'"     
        (initialized)="gridInitialized(flexGrid)"        
        [frozenColumns]="frozenColumnCount"
        [virtualizationThreshold]="totalRecords"
        (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
      >
        <wj-flex-grid-filter #filter (initialized)="initFilter()" (filterApplied)="onFilterApply()"></wj-flex-grid-filter>
        <wj-flex-grid-column
          [header]="'Firms'"
          [binding]="'firmName'"
          
          [minWidth]="360"
          [width]="'*'"
          [visible]="true"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Amlaw segment'"
          [width]="145"
          [binding]="'amLawSegment'"
          
          [visible]="true"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Lawyer segment'"
          
          [width]="160"
          [binding]="'lawyerSegment'"
          [visible]="true"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>

        <wj-flex-grid-column
          [header]="'Office segment'"
          
          [width]="150"
          [binding]="'officeSegment'"
          [visible]="true"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>

        <wj-flex-grid-column
          *ngFor="let col of colArr"
          
          [binding]="col.binding"
          header="{{ col.header }}"
          [allowSorting]="false"
          [autoSizeRows]="false"
          [isReadOnly]="true"
          [wordWrap]="true"
        >
          <ng-template
            wjFlexGridCellTemplate
            [cellType]="'Cell'"
            let-cell="cell"
            let-row="row"
            let-item="item"
            [autoSizeRows]="false"
          >
            <bento-checkbox
              type="checkbox"
              id="{{ row.index }}_{{ col.binding }}"
              [disabled]="isSaving || isSubmitCustomPeerGroupDisabled || selectedFirmSRSubscriptionInfo.peerSubmitted"
              (change)="onPeerGroupCheckBoxChange()"
              [(ngModel)]="item[col.binding]"
            />
          </ng-template>
        </wj-flex-grid-column>
      </wj-flex-grid>
    </div>
  </div>
  <ng-template #confirmationModalContent let-c="close" let-d="dismiss">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <i class="bento-icon-close-x" aria-hidden="true"></i>
    </button>
    <h2 id="modalViewRemove" class="modal-header h2">Please confirm</h2> 
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <p>Are you sure you want to cancel and lose the changes that were made?</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-common-action-btns-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="c('confirm')">Yes</button>
      <button type="button" class="btn btn-primary" (click)="c('cancel')">No</button>
    </div>
  </ng-template>
  <ng-template #resetAllModalContent let-c="close" let-d="dismiss">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <i class="bento-icon-close-x" aria-hidden="true"></i>
    </button>
    <h2 id="modalViewRemove" class="modal-header h2">Please confirm</h2>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <p>Are you sure you want to reset all the changes that were made?</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-common-action-btns-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="c('confirm')">Yes</button>
      <button type="button" class="btn btn-primary" (click)="c('cancel')">No</button>
    </div>
  </ng-template>
</div>

