<style nonce="cssRand0m">
  @media screen and (max-width: 600px) {
    .export {
      margin-top: 20px;
    }
    .checkbox-font {
      font-size: 8px;
    }
  }
  .popover-header {
    font-size: 20px !important;
  }
</style>
<bento-alert [items]="alerts"></bento-alert>
<div class="ViewPanel">
  <section class="ViewPanel-section ViewPanel-section--primary">
    <header class="ViewPanel-header" *ngIf="showDescription && userSettingsModel.descriptionList.length > 0">
      <div class="bento-desc-list bento-desc-list--inline PeerMeta">
        <div class="bento-desc-list-item" *ngFor="let description of userSettingsModel.descriptionList">
          <span class="dtCss">{{ description.header }}</span>
          <span class="ddCss">{{ description.value }}</span>
        </div>
      </div>
    </header>
    <header class="Main-header Main-header--summary" *ngIf="showCustomDescription && !showDescription">
      <div class="bento-desc-list bento-desc-list--inline PeerMeta" *ngIf="summary">
        <div class="bento-desc-list-item">
          <span class="dtCss">Date range:</span>
          <span class="ddCss">{{ summary.timeRange }}</span>
        </div>
        <div class="bento-desc-list-item" *ngIf="viewDetails && viewDetails.peerGroups">
          <span class="dtCss">Peer Group(s):</span>
          <span class="ddCss">{{ summary.peerGroups }}</span>
        </div>
        <div class="bento-desc-list-item" *ngIf="viewDetails && viewDetails.metrics">
          <span class="dtCss">Metric(s):</span>
          <span class="ddCss">{{ summary.metrics }}</span>
        </div>
        <div class="bento-desc-list-item" *ngIf="viewDetails && viewDetails.practices">
          <span class="dtCss">Practice Group(s):</span>
          <span class="ddCss">{{ summary.practices }}</span>
        </div>
        <div class="bento-desc-list-item" *ngIf="viewDetails && viewDetails.offices">
          <span class="dtCss">Office(s):</span>
          <span class="ddCss">{{ summary.offices }}</span>
        </div>
        <div class="bento-desc-list-item" *ngIf="viewDetails && viewDetails.titles">
          <span class="dtCss">Title(s):</span>
          <span class="ddCss">{{ summary.titles }}</span>
        </div>
        <div
          class="bento-desc-list-item"
          *ngIf="
            viewDetails &&
            ((viewDetails.associateExpYears && viewDetails.associateExpYears.length) ||
              (viewDetails.srAssociateExpYears && viewDetails.srAssociateExpYears.length) ||
              (viewDetails.partnerExpYears && viewDetails.partnerExpYears.length))
          "
        >
          <span class="dtCss">Experience(s):</span>
          <span class="ddCss">
            <ng-container *ngIf="viewDetails.associateExpYears && viewDetails.associateExpYears.length"
              >{{ summary.associateExp
              }}{{
                (viewDetails.srAssociateExpYears && viewDetails.srAssociateExpYears.length) ||
                (viewDetails.partnerExpYears && viewDetails.partnerExpYears.length)
                  ? ','
                  : ''
              }}
            </ng-container>
            <ng-container *ngIf="viewDetails.srAssociateExpYears && viewDetails.srAssociateExpYears.length"
              >{{ summary.srAssociateExp
              }}{{ viewDetails.partnerExpYears && viewDetails.partnerExpYears.length ? ',' : '' }}
            </ng-container>
            <ng-container *ngIf="viewDetails.partnerExpYears && viewDetails.partnerExpYears.length">{{
              summary.partnerYearsExp
            }}</ng-container>
          </span>
        </div>
        <div class="bento-desc-list-item" *ngIf="userSettingsModel && userSettingsModel.viewsSettings">
          <span class="dtCss">Settings:</span>
          <span class="ddCss">
            Statistics - {{ userSettingsModel.viewsSettings.statisticsType | titlecase }}, Comparison -
            <ng-container *ngIf="userSettingsModel.viewsSettings.comparisonType != 'year over year'"
              >{{ userSettingsModel.viewsSettings.comparisonType | titlecase }},</ng-container
            >
            <ng-container *ngIf="userSettingsModel.viewsSettings.comparisonType == 'year over year'"
              >Year over year,</ng-container
            >
            Matter type -
            <ng-container *ngIf="userSettingsModel.viewsSettings.matterType != 'all'"
              >{{ userSettingsModel.viewsSettings.matterType | titlecase }},</ng-container
            >
            <ng-container *ngIf="userSettingsModel.viewsSettings.matterType == 'all'"
              >Billable and Contingent,</ng-container
            >
            Contractors - {{ userSettingsModel.viewsSettings.contractorsType | titlecase }}, Currency -
            {{ userSettingsModel.viewsSettings.currencyType | titlecase }}
          </span>
        </div>
      </div>
    </header>
    <div class="ViewPanel-body">
      <section class="Section Section--views Section--overflow Section--overlap">
        <header class="Section-header">
          <div class="sr-only" id="mainGridOfView">
            <ng-container *ngIf="viewDetails"> Current view </ng-container>
            <ng-container *ngIf="!viewDetails"> View preview </ng-container>
          </div>
          <div class="Actions">
            <div class="width50">
              <div class="Actions-view-data" *ngIf="allowViewSettings">
                <div class="Actions-item">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-icon btn-sm"
                    aria-label="Settings"
                    ngbTooltip="Settings"
                    placement="right"
                    (click)="openViewSettings()"
                  >
                    <i class="bento-icon-settings" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="Actions-item Actions-item-view-data">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-icon btn-sm"
                    [attr.aria-label]="
                      isColumnFreezed
                        ? 'Toggle Button to Unfreeze Metrics and My firm columns'
                        : 'Toggle Button to Freeze Metrics and My firm columns'
                    "
                    [ngbTooltip]="
                      isColumnFreezed ? 'Unfreeze Metrics and My firm columns' : 'Freeze Metrics and My firm columns'
                    "
                    placement="right"
                    (click)="freezeColumnToggle()"
                  >
                    <i [ngClass]="isColumnFreezed ? 'bento-icon-lock' : 'bento-icon-unlocked'" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="export width50" *ngIf="showExportButton">
              <div class="justify-content-end">
                <div class="Actions-item">
                  <div class="Actions justify-content-end">
                    <div class="Actions-item">
                      <div ngbDropdown class="dropdown" placement="bottom-right">
                        <button
                          ngbDropdownToggle
                          id="actionsDropDownButton"
                          aria-haspopup="true"
                          aria-controls="actionsDropDown"
                          class="btn btn-primary trbranding-button"
                          type="button"
                          [disabled]="disableExportButton"
                          (click)="openExportModal()"
                        >
                          <span class="btn-text textDecorationNone">Export</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  class="reorder-checkbox-container" *ngIf="showReorderCheckbox && (showExportButton || (viewDetails && viewDetails.viewTemplateId))">
            <label class="checkbox-inline zero-paddings checkbox-container checkbox-label" for="reorder">
              <bento-checkbox
                id="reorder"
                name="reorder"
                ngDefaultControl
                [(ngModel)]="reorder"
                (change)="onReorderChange(flexGrid)"
              ></bento-checkbox>
              <span class="reorder-label">Reorder of rows using mouse by dragging <strong>or</strong> using <strong>Ctrl + Shift + UpArrow / Ctrl + Shift + DownArrow</strong>.&nbsp;
 (Assistive technology users should enable forms/focus mode)</span>
              <!-- Reorder rows (Mark the checkbox and use your mouse to reorder rows in the table <b>OR</b> use
              <b>Ctrl+Shift+UpArrow/Ctrl+Shift+DownArrow</b> to reorder rows).<br /><b
                >Note:
              </b>
              Assistive technology users should enable forms/focus mode. -->
              </label
            >
          </div>
        </header>

        <div [ngClass]="showExportButton ? 'topLeft' : 'topLeft'" class="Section-body viewDataGrid">
          <wj-flex-grid
            #flexGrid
            class="DataGrid DataGrid--views"
            [selectionMode]="'ListBox'"
            [itemsSource]="processedMainGridData.processedData"
            [showMarquee]="true"
            [allowMerging]="'ColumnHeaders'"
            [alternatingRowStep]="0"
            [isReadOnly]="true"
            [autoGenerateColumns]="false"
            [allowDragging]="'Rows'"
            [virtualizationThreshold]="5000"
            (draggingRow)="onDraggingRow(flexGrid, $event)"
            (draggedRow)="onDraggedRow(flexGrid, $event)"
            [copyHeaders]="allowCopyPaste ? 'Column' : 'None'"
            [allowSorting]="false"
            [frozenColumns]="frozenColumnCount"
            [autoRowHeights]="true"
            [headersVisibility]="'All'"
            [headersFocusability]="'All'"
            (initialized)="onInitialized(flexGrid, null, false, 'mainGridOfView')"
            *ngIf="processedMainGridData && processedMainGridData.processedData"
          >
            <wj-flex-grid-column
              [binding]="'metricName'"
              [header]="'Metric'"
              [allowMerging]="true"
              [align]="'left'"
              [width]="200"
              [wordWrap]="true"
              [cellTemplate]="templateMetricNameLink"
            >
            </wj-flex-grid-column>
            <wj-flex-grid-column
              [binding]="'firmValue'"
              [header]="'Value'"
              [align]="'left'"
              [width]="110"
              [wordWrap]="true"
            >
              <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
                <ng-container
                  *ngIf="(item.firmValue != null || item.firmValue != undefined) && item.firmValue_format == 'percent'"
                >
                  <!-- {{ item.firmValue | percent: '1.1' }} -->
                  {{ item.firmValue }}
                </ng-container>
                <ng-container
                  *ngIf="
                    (item.firmValue != null || item.firmValue != undefined) &&
                    (item.firmValue_format == 'number' || item.firmValue_format.startsWith('actual'))
                  "
                >
                  {{ item.firmValue }}
                </ng-container>
                <ng-container
                  *ngIf="
                    (item.firmValue != null || item.firmValue != undefined) &&
                    item.firmValue_format != 'percent' &&
                    item.firmValue_format != 'number' &&
                    !item.firmValue_format.startsWith('actual')
                  "
                >
                  <!-- {{ item.firmValue | currency: item.currency:'symbol':'1.0' }} -->
                  {{ item.firmValue }}
                </ng-container>
                <ng-container *ngIf="item.firmValue == null || item.firmValue == undefined">--</ng-container>
              </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column
              [binding]="'firmYOY'"
              [header]="yoyHeader"
              [align]="'left'"
              [width]="92"
              [wordWrap]="true"
            >
              <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
                <ng-container
                  *ngIf="(item.firmYOY != null || item.firmYOY != undefined) && item.firmYOY_format == 'percent'"
                >
                  <!-- {{ item.firmYOY | percent: '1.1' }} -->
                  {{ item.firmYOY }}
                </ng-container>
                <ng-container *ngIf="item.firmYOY == null || item.firmYOY == undefined">--</ng-container>
              </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column
              [binding]="col.binding"
              [header]="col.header"
              [visible]="col.visible"
              [align]="'left'"
              [width]="col.width"
              [wordWrap]="col.wordWrap"
              [format]="allowCopyPaste ? '' : ''"
              *ngFor="let col of processedMainGridData.processedColumn"
            >
              <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
                <ng-container
                  *ngIf="
                    (item.peerGroups[cell.col._binding._parts[1]] != null ||
                      item.peerGroups[cell.col._binding._parts[1]] != undefined) &&
                    item.peerGroups[cell.col._binding._parts[1] + '_format'] == 'percent'
                  "
                >
                  <!-- {{ item.peerGroups[cell.col._binding._parts[1]] | percent: '1.1' }} -->
                  {{ item.peerGroups[cell.col._binding._parts[1]] }}
                </ng-container>
                <ng-container
                  *ngIf="
                    (item.peerGroups[cell.col._binding._parts[1]] != null ||
                      item.peerGroups[cell.col._binding._parts[1]] != undefined) &&
                    (item.peerGroups[cell.col._binding._parts[1] + '_format'] == 'number' ||
                      item.peerGroups[cell.col._binding._parts[1] + '_format'].startsWith('actual'))
                  "
                >
                  {{ item.peerGroups[cell.col._binding._parts[1]] }}
                </ng-container>
                <ng-container
                  *ngIf="
                    (item.peerGroups[cell.col._binding._parts[1]] != null ||
                      item.peerGroups[cell.col._binding._parts[1]] != undefined) &&
                    item.peerGroups[cell.col._binding._parts[1] + '_format'] != 'percent' &&
                    item.peerGroups[cell.col._binding._parts[1] + '_format'] != 'number' &&
                    !item.peerGroups[cell.col._binding._parts[1] + '_format'].startsWith('actual')
                  "
                >
                  <!-- {{ item.peerGroups[cell.col._binding._parts[1]] | currency: item.currency:'symbol':'1.0' }}
                   -->
                  {{ item.peerGroups[cell.col._binding._parts[1]] }}
                </ng-container>
                <ng-container
                  *ngIf="
                    item.peerGroups[cell.col._binding._parts[1]] == null ||
                    item.peerGroups[cell.col._binding._parts[1]] == undefined
                  "
                  >--</ng-container
                >
              </ng-template>
            </wj-flex-grid-column>
          </wj-flex-grid>
        </div>
      </section>

      <section
        class="Section Section--details"
        [ngClass]="{'Section--overflow': isDataTableOrGraph == 'Grid' && processedBaseTableData}"
        *ngIf="showBasicTable && selectedMetric"
      >
        <header class="Section-header mb-3">
          <div class="row align-items-center">
            <div class="col">
              <h2 class="Section-heading" id="mainGridBaseTable">{{ selectedMetric.name }}</h2>
            </div>
            <div class="col">
              <div class="Actions Actions-flex justify-content-end">
                <div
                  ngbDropdown
                  class="dropdown Actions-item--small Actions-item-flex"
                  *ngIf="isDataTableOrGraph == 'Graph'"
                >
                  <button
                    ngbDropdownToggle
                    type="button"
                    id="metricExportButton-primary"
                    class="btn btn-outline-secondary btn-icon"
                    aria-haspopup="true"
                    aria-controls="metricExportMenu-primary"
                    aria-label="Export as"
                    ngbTooltip="Export as"
                  >
                    <i class="bento-icon-download" aria-hidden="true"></i>
                  </button>
                  <div
                    ngbDropdownMenu
                    ngDefaultControl
                    id="metricExportMenu-primary"
                    role="menu"
                    aria-labelledby="metricExportButton-primary"
                  >
                    <button ngbDropdownItem class="dropdown-item" role="menuitem" (click)="exportChart('JPEG')">
                      JPEG
                    </button>
                    <button ngbDropdownItem class="dropdown-item" role="menuitem" (click)="exportChart('PNG')">
                      PNG
                    </button>
                    <button ngbDropdownItem class="dropdown-item" role="menuitem" (click)="exportChart('SVG')">
                      SVG
                    </button>
                  </div>
                </div>
                <div
                  ngbRadioGroup
                  name="metricButtonGroup-primary"
                  class="btn-group btn-group-toggle Actions-item--small Actions-item-flex"
                  *ngIf="processedBaseTableData && processedBaseTableData.processedData"
                >
                  <button
                    type="button"
                    [ngClass]="isDataTableOrGraph == 'Grid' ? 'active' : ''"
                    class="btn btn-outline-secondary btn-icon btn-toggle"
                    ngbTooltip="Grid"
                    closeDelay="1000"
                    aria-label="Grid"
                    (click)="tableOrGraphChange('Grid')"
                  >
                    <i aria-hidden="true" class="bento-icon-table"></i>
                  </button>
                  <button
                    type="button"
                    [ngClass]="isDataTableOrGraph == 'Graph' ? 'active' : ''"
                    class="btn btn-outline-secondary btn-icon btn-toggle"
                    aria-label="Graph"
                    ngbTooltip="Graph"
                    closeDelay="1000"
                    (click)="tableOrGraphChange('Graph')"
                    *ngIf="isLineChartAvailable"
                  >
                    <i aria-hidden="true" class="bento-icon-stats"></i>
                  </button>
                </div>
                <div ngbDropdown class="dropdown Actions-item--small Actions-item-flex">
                  <button
                    ngbDropdownToggle
                    type="button"
                    id="metricActionsButton-primary"
                    class="btn btn-outline-secondary btn-icon"
                    aria-haspopup="true"
                    aria-controls="metricActionsMenu-primary"
                    aria-label="Actions"
                    ngbTooltip="Actions"
                  >
                    <i class="bento-icon-more-vert" aria-hidden="true"></i>
                  </button>
                  <div
                    ngbDropdownMenu
                    ngDefaultControl
                    type="button"
                    id="metricActionsMenu-primary"
                    role="menu"
                    aria-labelledby="metricActionsButton-primary"
                    [(ngModel)]="isDetails"
                  >
                    <button
                      ngbDropdownItem
                      role="menuitem"
                      class="dropdown-item"
                      (click)="setDetails(0, -1, 'Expand')"
                      *ngIf="has_levels && shouldDisplayPivot"
                    >
                      Expand
                    </button>
                    <button
                      ngbDropdownItem
                      role="menuitem"
                      class="dropdown-item"
                      (click)="setDetails(0, -1, 'Details')"
                      *ngIf="can_pivot && shouldDisplayPivot"
                    >
                      Details
                    </button>
                    <button ngbDropdownItem role="menuitem" class="dropdown-item" (click)="closeBasicOrDetailsTable()">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div class="Section-body">
          <ng-container *ngIf="isDataTableOrGraph == 'Grid' && processedBaseTableData">
            <wj-transposed-grid
              #transposedFlex
              class="DataGrid DataGrid--simple"
              [autoClipboard]="true"
              [alternatingRowStep]="0"
              [selectionMode]="'ListBox'"
              [copyHeaders]="'All'"
              [headersVisibility]="'Row'"
              [isReadOnly]="true"
              [itemsSource]="processedBaseTableData.processedData"
              [showMarquee]="true"
              (initialized)="transposedInitialize(transposedFlex, null, false, 'mainGridBaseTable')"
              (loadedRows)="loadedRows(transposedFlex)"
              (formatItem)="formatItem(transposedFlex, $event)"
              [rowGroups]="processedBaseTableData.processedColumn"
              (updatingView)="updatingView(transposedFlex)"
              (gotFocus)="focusOnTransposeGrid(transposedFlex, $event)"
              (selectionChanged)="handleSelectionChangedTG(transposedFlex, $event)"
              *ngIf="processedBaseTableData.processedData && processedBaseTableData.processedColumn"
            >
            </wj-transposed-grid>
          </ng-container>
          <div
            class="container-fluid Chart-Area"
            *ngIf="isDataTableOrGraph == 'Graph' && processedBaseTableData"
            role="region"
          >
            <wj-flex-chart
              #lineChart
              class="Chart"
              [itemsSource]="processedBaseTableData.processedData"
              [bindingX]="'yyyymm'"
              [chartType]="'LineSymbols'"
              [header]="selectedMetric.name"
              [palette]="colorPalette"
              (rendered)="chartRenderedCalled(lineChart)"
              *ngIf="isLineChartAvailable && isDataTableOrGraph == 'Graph'"
            >
              <wj-flex-chart-legend [position]="'Bottom'"></wj-flex-chart-legend>
              <wj-flex-chart-axis [wjProperty]="'axisX'" [axisLine]="true" [majorGrid]="true"></wj-flex-chart-axis>
              <wj-flex-chart-axis
                wjProperty="axisY"
                [labelAlign]="'left'"
                [majorGrid]="true"
                [minorGrid]="true"
                [axisLine]="true"
                [itemFormatter]="itemFormatter"
                [format]="processedBaseTableData.precessedWjColformat"
              ></wj-flex-chart-axis>
              <ng-container *ngFor="let col of processedBaseTableData.processedChartSeries">
                <wj-flex-chart-series
                  [binding]="col.binding"
                  [name]="col.header"
                  [style]="col.styling"
                  [visible]="col.visible"
                  [symbolStyle]="col.symbolStyling"
                  *ngIf="col.visible"
                ></wj-flex-chart-series>
              </ng-container>
              <wj-flex-chart-animation [animationMode]="'Point'"></wj-flex-chart-animation>
            </wj-flex-chart>
          </div>
        </div>
      </section>

      <section class="Section Section--stats mt-3" *ngIf="selectedMetric && showDetailsTable && shouldDisplayPivot">
        <header class="Section-header mb-3">
          <div class="row align-items-center">
            <div class="col">
              <h2 class="h3 Section-heading">{{ selectedMetric.name }}</h2>
            </div>
            <div class="col">
              <div class="Actions-flex justify-content-end">
                <div ngbDropdown class="dropdown Actions-item-flex">
                  <button
                    ngbDropdownToggle
                    type="button"
                    id="metricActionsButton-details"
                    class="btn btn-outline-secondary btn-icon"
                    aria-haspopup="true"
                    aria-controls="metricActionsMenu-details"
                    aria-label="Actions"
                    ngbTooltip="Actions"
                  >
                    <i class="bento-icon-more-vert" aria-hidden="true"></i>
                  </button>
                  <div
                    ngbDropdownMenu
                    id="metricActionsMenu-details"
                    role="menu"
                    aria-labelledby="metricActionsButton-details"
                  >
                    <button
                      ngbDropdownItem
                      role="menuitem"
                      class="dropdown-item"
                      (click)="handleMetricSelection(selectedOption)"
                    >
                      Base Table
                    </button>
                    <button ngbDropdownItem role="menuitem" class="dropdown-item" (click)="closeBasicOrDetailsTable()">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div class="Section-body">
          <app-pivot-table-view
            class="u-flexGrowCol"
            [metricId]="selectedMetric.id"
            [metricBasicData]="metricBasicData"
            [requestBody]="requestBodyForPivot"
            [peerGroupItems]="userSettingsModel.peerGroupSelectedItems"
            [pivotIndex]="pivotIndex"
            [userSettingsModel]="userSettingsModel.viewsSettings"
            [yoyHeader]="yoyHeader"
            [selectedFirmId]="selectedFirmId"
            [frozenColumnCount]="frozenColumnCount"
            [isTAdmin]="isTAdmin"
            [isCANFirm]="userSettingsModel.isCANFirm"
            *ngIf="isDetails == 'Details'"
          ></app-pivot-table-view>
          <app-expand-table-offices-expenses
            class="u-flexGrowCol"
            [metricId]="selectedMetric.id"
            [metricBasicData]="metricBasicData"
            [requestBody]="requestBodyForPivot"
            [peerGroupItems]="userSettingsModel.peerGroupSelectedItems"
            [selectedMericData]="expandData"
            [pivotIndex]="pivotIndex"
            [selectedFirmId]="selectedFirmId"
            [statistic]="userSettingsModel.viewsSettings.statisticsType"
            [expandType]="'expandExpenses'"
            [userSettingsModel]="userSettingsModel.viewsSettings"
            [frozenColumnCount]="frozenColumnCount"
            [isTAdmin]="isTAdmin"
            *ngIf="isDetails == 'Expand' && report_type == 'EXPANDED'"
          ></app-expand-table-offices-expenses>
          <app-expand-table-view
            class="u-flexGrowCol"
            [metricId]="selectedMetric.id"
            [metricBasicData]="metricBasicData"
            [requestBody]="requestBodyForPivot"
            [peerGroupItems]="userSettingsModel.peerGroupSelectedItems"
            [pivotIndex]="pivotIndex"
            [selectedFirmId]="selectedFirmId"
            [expandType]="'expandOffices'"
            [userSettingsModel]="userSettingsModel.viewsSettings"
            [frozenColumnCount]="frozenColumnCount"
            [isTAdmin]="isTAdmin"
            [isCANFirm]="userSettingsModel.isCANFirm"
            *ngIf="isDetails == 'Expand' && report_type == 'OFFICES'"
          ></app-expand-table-view>
        </div>
      </section>
    </div>
  </section>

  <section
    class="ViewPanel-section ViewPanel-section--details"
    *ngIf="userSettingsModel.cardTitles.length > 0 && shouldCardDisplay"
  >
    <ng-container *ngFor="let card of userSettingsModel.cardTitles">
      <section class="Card Card--views" [ngClass]="{hasError: card.hasError}">
        <div class="Card-header" role="heading" aria-level="3">
          <div class="row align-items-center">
            <div class="col-10">
              <h3 class="Card-heading" [attr.id]="'mainGridOfViewCard-' + card.cardId">&nbsp;{{ card.cardTitle }}</h3>
            </div>
            <div class="col-2">
              <div class="Actions Actions-flex justify-content-end">
                <div class="Actions-item">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-icon btn-sm"
                    [ngbTooltip]="card.isCollapsed ? 'Expand' : 'Collapse'"
                    [attr.aria-label]="card.isCollapsed ? card.cardTitle + ' Expand' : card.cardTitle + ' Collapse'"
                    [attr.aria-expanded]="!card.isCollapsed"
                    [attr.aria-controls]="'cardCollapse-' + card.cardId"
                    (click)="handleCardExpandCollapseAction(card)"
                  >
                    <i
                      [ngClass]="card.isCollapsed ? 'bento-icon-caret-down' : 'bento-icon-caret-up'"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          [attr.id]="'cardCollapse-' + card.cardId"
          class="Card-body"
          [(ngbCollapse)]="card.isCollapsed"
          [bentoBusyLoader]="card.isCardProcessing"
        >
          <section class="Section Section--views">
            <div class="Section-body">
              <wj-flex-grid
                #flexGrid_card
                class="DataGrid DataGrid--views"
                [showMarquee]="true"
                [selectionMode]="'ListBox'"
                [itemsSource]="card.processedMainGrid.processedData"
                [allowMerging]="'ColumnHeaders'"
                [alternatingRowStep]="0"
                [autoGenerateColumns]="false"
                [allowDragging]="false"
                [isReadOnly]="true"
                [frozenColumns]="frozenColumnCount"
                [autoRowHeights]="true"
                [virtualizationThreshold]="5000"
                (initialized)="onInitialized(flexGrid_card, card, false, 'mainGridOfViewCard-' + card.cardId)"
                *ngIf="card && card.processedMainGrid && card.processedMainGrid.processedData"
              >
                <wj-flex-grid-column
                  [binding]="'metricName'"
                  [header]="'Metric'"
                  [allowMerging]="true"
                  [align]="'left'"
                  [width]="200"
                  [wordWrap]="true"
                >
                  <ng-template
                    wjFlexGridCellTemplate
                    [cellType]="'Cell'"
                    let-row="row"
                    let-cell="cell"
                    let-item="item"
                    let-index="index"
                  >
                    <a
                      [routerLink]="['.']"
                      [attr.aria-label]="!item.metricName ? '--' : 'Link: ' + item.metricName"
                      (click)="handleMetricSelectionFromCard(item, card)"
                      tabindex="-1"
                      >{{ item.metricName }}</a
                    >
                    <span class="font-weight-normal" *ngIf="!item.metricName">--</span>
                  </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column
                  [binding]="'firmValue'"
                  [header]="'Value'"
                  [align]="'left'"
                  [width]="110"
                  [wordWrap]="true"
                >
                  <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
                    <ng-container
                      *ngIf="
                        (item.firmValue != null || item.firmValue != undefined) && item.firmValue_format == 'percent'
                      "
                    >
                      <!-- {{ item.firmValue | percent: '1.1' }} -->
                      {{ item.firmValue }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        (item.firmValue != null || item.firmValue != undefined) &&
                        (item.firmValue_format == 'number' || item.firmValue_format.startsWith('actual'))
                      "
                    >
                      {{ item.firmValue }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        (item.firmValue != null || item.firmValue != undefined) &&
                        item.firmValue_format != 'percent' &&
                        item.firmValue_format != 'number' &&
                        !item.firmValue_format.startsWith('actual')
                      "
                    >
                      <!-- {{ item.firmValue | currency: item.currency:'symbol':'1.0' }} -->
                      {{ item.firmValue }}
                    </ng-container>
                    <ng-container *ngIf="item.firmValue == null || item.firmValue == undefined">--</ng-container>
                  </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column
                  [binding]="'firmYOY'"
                  [header]="yoyHeader"
                  [align]="'left'"
                  [width]="92"
                  [wordWrap]="true"
                >
                  <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
                    <ng-container
                      *ngIf="(item.firmYOY != null || item.firmYOY != undefined) && item.firmYOY_format == 'percent'"
                    >
                      <!-- {{ item.firmYOY | percent: '1.1' }} -->
                      {{ item.firmYOY }}
                    </ng-container>
                    <ng-container *ngIf="item.firmYOY == null || item.firmYOY == undefined">--</ng-container>
                  </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column
                  [binding]="col.binding"
                  [header]="col.header"
                  [visible]="col.visible"
                  [align]="'left'"
                  [width]="col.width"
                  [wordWrap]="col.wordWrap"
                  *ngFor="let col of card.processedMainGrid.processedColumn"
                >
                  <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-cell="cell" let-item="item">
                    <ng-container
                      *ngIf="
                        (item.peerGroups[cell.col._binding._parts[1]] != null ||
                          item.peerGroups[cell.col._binding._parts[1]] != undefined) &&
                        item.peerGroups[cell.col._binding._parts[1] + '_format'] == 'percent'
                      "
                    >
                      <!-- {{ item.peerGroups[cell.col._binding._parts[1]] | percent: '1.1' }} -->
                      {{ item.peerGroups[cell.col._binding._parts[1]] }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        (item.peerGroups[cell.col._binding._parts[1]] != null ||
                          item.peerGroups[cell.col._binding._parts[1]] != undefined) &&
                        (item.peerGroups[cell.col._binding._parts[1] + '_format'] == 'number' ||
                          item.peerGroups[cell.col._binding._parts[1] + '_format'].startsWith('actual'))
                      "
                    >
                      {{ item.peerGroups[cell.col._binding._parts[1]] }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        (item.peerGroups[cell.col._binding._parts[1]] != null ||
                          item.peerGroups[cell.col._binding._parts[1]] != undefined) &&
                        item.peerGroups[cell.col._binding._parts[1] + '_format'] != 'percent' &&
                        item.peerGroups[cell.col._binding._parts[1] + '_format'] != 'number' &&
                        !item.peerGroups[cell.col._binding._parts[1] + '_format'].startsWith('actual')
                      "
                    >
                      <!-- {{ item.peerGroups[cell.col._binding._parts[1]] | currency: item.currency:'symbol':'1.0' }} -->
                      {{ item.peerGroups[cell.col._binding._parts[1]] }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        item.peerGroups[cell.col._binding._parts[1]] == null ||
                        item.peerGroups[cell.col._binding._parts[1]] == undefined
                      "
                      >--</ng-container
                    >
                  </ng-template>
                </wj-flex-grid-column>
              </wj-flex-grid>
            </div>
          </section>

          <section class="Section Section--details" *ngIf="card.shouldShowBasicTable && card.selectedMetric">
            <header class="Section-header mb-3">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 Section-heading" [attr.id]="'baseTableforCard-' + card.cardId">
                    {{ card.selectedMetric.name }}
                  </h5>
                </div>
                <div class="col">
                  <div class="Actions-flex justify-content-end">
                    <div
                      ngbDropdown
                      class="dropdown Actions-item-flex Actions-item--small"
                      *ngIf="card.isDataTableOrGraph == 'Graph'"
                    >
                      <button
                        ngbDropdownToggle
                        type="button"
                        [attr.id]="'metricExportButton-' + card.cardId"
                        class="btn btn-outline-secondary btn-icon"
                        aria-haspopup="true"
                        [attr.aria-controls]="'metricExportMenu-' + card.cardId"
                        aria-label="Export as"
                        ngbTooltip="Export as"
                      >
                        <i class="bento-icon-download" aria-hidden="true"></i>
                      </button>
                      <div
                        ngbDropdownMenu
                        ngDefaultControl
                        [attr.id]="'metricExportMenu-' + card.cardId"
                        role="menu"
                        [attr.aria-labelledby]="'metricExportButton-' + card.cardId"
                      >
                        <button
                          ngbDropdownItem
                          role="menuitem"
                          class="dropdown-item"
                          (click)="exportChart('jpeg', card)"
                        >
                          JPEG
                        </button>
                        <button
                          ngbDropdownItem
                          role="menuitem"
                          class="dropdown-item"
                          (click)="exportChart('png', card)"
                        >
                          PNG
                        </button>
                        <button
                          ngbDropdownItem
                          role="menuitem"
                          class="dropdown-item"
                          (click)="exportChart('svg', card)"
                        >
                          SVG
                        </button>
                      </div>
                    </div>
                    <div
                      ngbRadioGroup
                      [attr.name]="'metricButtonGroup-' + card.cardId"
                      class="btn-group btn-group-toggle Actions-item-flex Actions-item--small"
                      *ngIf="card.processedBaseTable && card.processedBaseTable.processedData"
                    >
                      <button
                        type="button"
                        [ngClass]="card.isDataTableOrGraph == 'Grid' ? 'active' : ''"
                        class="btn btn-outline-secondary btn-icon btn-toggle"
                        aria-label="Grid"
                        closeDelay="1000"
                        ngbTooltip="Grid"
                        (click)="gridClick(card.selectedOption, card)"
                      >
                        <i aria-hidden="true" class="bento-icon-table"></i>
                      </button>
                      <button
                        type="button"
                        [ngClass]="card.isDataTableOrGraph == 'Graph' ? 'active' : ''"
                        class="btn btn-outline-secondary btn-icon btn-toggle"
                        aria-label="Graph"
                        ngbTooltip="Graph"
                        closeDelay="1000"
                        (click)="graphClick(card.selectedOption, card)"
                        *ngIf="card.isLineChartAvailable"
                      >
                        <i aria-hidden="true" class="bento-icon-stats"></i>
                      </button>
                    </div>
                    <div ngbDropdown class="dropdown Actions-item-flex Actions-item--small">
                      <button
                        ngbDropdownToggle
                        [attr.id]="'metricActionsButton-' + card.cardId"
                        class="btn btn-outline-secondary btn-icon"
                        aria-haspopup="true"
                        [attr.aria-controls]="'metricActionsMenu-' + card.cardId"
                        aria-label="Actions"
                        ngbTooltip="Actions"
                      >
                        <i class="bento-icon-more-vert" aria-hidden="true"></i>
                      </button>
                      <div
                        ngbDropdownMenu
                        ngDefaultControl
                        [attr.id]="'metricActionsMenu-' + card.cardId"
                        role="menu"
                        [attr.aria-labelledby]="'metricActionsButton-' + card.cardId"
                        [(ngModel)]="card.isDetails"
                      >
                        <button
                          ngbDropdownItem
                          role="menuitem"
                          class="dropdown-item"
                          (click)="setDetails(card, card.cardId, 'Details')"
                          *ngIf="card.can_pivot"
                        >
                          Details
                        </button>
                        <button
                          ngbDropdownItem
                          role="menuitem"
                          class="dropdown-item"
                          (click)="setDetails(card, card.cardId, 'Expand')"
                          *ngIf="card.has_levels"
                        >
                          Expand
                        </button>
                        <button
                          ngbDropdownItem
                          role="menuitem"
                          class="dropdown-item"
                          (click)="closeBasicOrDetailsTable(card)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div class="Section-body">
              <ng-container *ngIf="card.isDataTableOrGraph == 'Grid'">
                <wj-transposed-grid
                  #transposedGrid
                  class="DataGrid DataGrid--simple"
                  [alternatingRowStep]="0"
                  [autoClipboard]="true"
                  [selectionMode]="'ListBox'"
                  [showMarquee]="true"
                  [showSelectedHeaders]="'All'"
                  [headersVisibility]="'Row'"
                  [isReadOnly]="true"
                  [itemsSource]="card.processedBaseTable.processedData"
                  [copyHeaders]="'All'"
                  (initialized)="transposedInitialize(transposedGrid, card, false, 'baseTableforCard-' + card.cardId)"
                  (formatItem)="formatItem(transposedGrid, $event, card)"
                  (loadedRows)="loadedRows(transposedGrid)"
                  [rowGroups]="card.processedBaseTable.processedColumn"
                  (updatingView)="updatingView(transposedGrid)"
                  (gotFocus)="focusOnTransposeGrid(transposedGrid, $event)"
                  (selectionChanged)="handleSelectionChangedTG(transposedGrid, $event)"
                  *ngIf="card && card.processedBaseTable && card.processedBaseTable.processedData"
                >
                </wj-transposed-grid>
              </ng-container>
              <div
                class="container-fluid Chart-Area"
                *ngIf="card.isDataTableOrGraph == 'Graph' && card.isLineChartAvailable"
                role="region"
              >
                <wj-flex-chart
                  #lineChart
                  class="Chart"
                  [itemsSource]="card.processedBaseTable.processedData"
                  [bindingX]="'yyyymm'"
                  [chartType]="'LineSymbols'"
                  [header]="card.selectedMetric.name"
                  [tooltipContent]="tooltipContent"
                  [palette]="colorPalette"
                  (rendered)="chartRenderedCalled(lineChart, card)"
                  *ngIf="card && card.processedBaseTable && card.processedBaseTable.processedData"
                >
                  <wj-flex-chart-legend [position]="'Bottom'"></wj-flex-chart-legend>
                  <wj-flex-chart-axis [wjProperty]="'axisX'" [axisLine]="true" [majorGrid]="true"></wj-flex-chart-axis>
                  <wj-flex-chart-axis
                    wjProperty="axisY"
                    [labelAlign]="'left'"
                    [majorGrid]="true"
                    [majorUnit]="100"
                    [minorGrid]="true"
                    [axisLine]="true"
                    [minorUnit]="50"
                    [itemFormatter]="itemFormatter"
                    [format]="card.processedBaseTable.precessedWjColformat"
                  ></wj-flex-chart-axis>
                  <ng-container *ngFor="let col of card.processedBaseTable.processedChartSeries">
                    <wj-flex-chart-series
                      [binding]="col.binding"
                      [name]="col.header"
                      [style]="col.styling"
                      [visible]="col.visible"
                      [symbolStyle]="col.symbolStyling"
                      *ngIf="col.visible"
                    ></wj-flex-chart-series>
                  </ng-container>
                  <wj-flex-chart-animation [animationMode]="'Point'"></wj-flex-chart-animation>
                </wj-flex-chart>
              </div>
            </div>
          </section>

          <section
            class="Section Section--stats"
            *ngIf="card.showDetailsTable && shouldCardDisplay && shouldDisplayPivot && card.selectedMetric"
          >
            <header class="Section-header mb-3">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 Section-heading">{{ card.selectedMetric.name }}</h5>
                </div>
                <div class="col">
                  <div class="Actions-flex justify-content-end">
                    <div ngbDropdown class="dropdown Actions-item-flex">
                      <button
                        ngbDropdownToggle
                        [attr.id]="'metricActionsButton-' + card.cardId"
                        class="btn btn-outline-secondary btn-icon"
                        aria-haspopup="true"
                        [attr.aria-controls]="'metricActionsMenu-' + card.cardId"
                        aria-label="Actions"
                        ngbTooltip="Actions"
                      >
                        <i class="bento-icon-more-vert" aria-hidden="true"></i>
                      </button>
                      <div
                        ngbDropdownMenu
                        [attr.id]="'metricActionsMenu-' + card.cardId"
                        role="menu"
                        [attr.aria-labelledby]="'metricActionsButton-' + card.cardId"
                      >
                        <button
                          ngbDropdownItem
                          role="menuitem"
                          class="dropdown-item"
                          (click)="handleMetricSelectionFromCard(card.selectedOption, card)"
                        >
                          Base Table
                        </button>
                        <button
                          ngbDropdownItem
                          role="menuitem"
                          class="dropdown-item"
                          (click)="closeBasicOrDetailsTable(card)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div class="Section-body">
              <app-pivot-table-view
                class="u-flexGrowCol"
                [metricId]="card.selectedMetric.id"
                [metricBasicData]="card.metricBasicData"
                [requestBody]="requestBodyForPivot"
                [peerGroupItems]="userSettingsModel.peerGroupSelectedItems"
                [pivotIndex]="pivotIndex"
                [selectedFirmId]="selectedFirmId"
                [frozenColumnCount]="frozenColumnCount"
                [userSettingsModel]="userSettingsModel.viewsSettings"
                [yoyHeader]="yoyHeader"
                [isTAdmin]="isTAdmin"
                [isCANFirm]="userSettingsModel.isCANFirm"
                *ngIf="card.isDetails == 'Details'"
              ></app-pivot-table-view>
              <app-expand-table-offices-expenses
                [metricId]="card.selectedMetric.id"
                [metricBasicData]="card.metricBasicData"
                [requestBody]="requestBodyForPivot"
                class="u-flexGrowCol"
                [peerGroupItems]="userSettingsModel.peerGroupSelectedItems"
                [selectedMericData]="expandData"
                [selectedFirmId]="selectedFirmId"
                [pivotIndex]="pivotIndex"
                [statistic]="userSettingsModel.viewsSettings.statisticsType"
                [expandType]="'expandExpenses'"
                [userSettingsModel]="userSettingsModel.viewsSettings"
                [frozenColumnCount]="frozenColumnCount"
                [isTAdmin]="isTAdmin"
                *ngIf="card.isDetails == 'Expand' && card.report_type == 'EXPANDED'"
              ></app-expand-table-offices-expenses>
              <app-expand-table-view
                class="u-flexGrowCol"
                [metricId]="card.selectedMetric.id"
                [metricBasicData]="card.metricBasicData"
                [requestBody]="requestBodyForPivot"
                [peerGroupItems]="userSettingsModel.peerGroupSelectedItems"
                [pivotIndex]="pivotIndex"
                [selectedFirmId]="selectedFirmId"
                [expandType]="'expandOffices'"
                [frozenColumnCount]="frozenColumnCount"
                [userSettingsModel]="userSettingsModel.viewsSettings"
                *ngIf="card.isDetails == 'Expand' && card.report_type == 'OFFICES'"
              ></app-expand-table-view>
            </div>
          </section>
        </div>
      </section>
    </ng-container>
  </section>
  <div id="liveRegion" aria-live="polite" style="position: absolute; left: -9999px"></div>
</div>

<ng-template #listOfFirmsModal let-c="close" let-d="dismiss">
  <button type="button" class="close" aria-label="Close" (click)="closePGPopup()">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h3 id="modalPeerGroup" class="modal-header h2">
    {{ this.peerGroupDetail.selectedPeerGroup.name }}
  </h3>
  <div class="modal-body">
    <ol class="OrderedList" *ngIf="!peerGroupDetail.is_ppgRequest">
      <li *ngFor="let firm of peerGroupDetail.selectedFirms; index as sequence">{{ firm.name }}</li>
    </ol>
    <ol class="OrderedList" *ngIf="peerGroupDetail.is_ppgRequest">
      <li *ngFor="let criteria of peerGroupDetail.ppgCriteria">{{ criteria }}</li>
    </ol>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="closePGPopup()">Close</button>
  </div>
</ng-template>
