import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss']
})
export class ActionsMenuComponent implements OnInit {
  @Input() actionMenuItems;
  @Input() disable;
  @Output() menuSelectChange = new EventEmitter<string>();

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}
  menuOnSelectChange(name) {
    this.menuSelectChange.emit(name);
  }
}