 <div class="Actions justify-content-end">
  <div class="Actions-item">
    <ng-container *ngFor="let item of actionMenuItems">
        <button
          role="button"
          class="btn btn-primary Actions-button trbranding-button"
          name="{{ item.name }}"
          [disabled]="item.isDisabled"
          (click)="menuOnSelectChange($event.target.name)"
        >
          {{ item.name }}
        </button>
      </ng-container>
    </div>
</div>
  