import {Component, EventEmitter, Input, Output, ViewChild, OnInit, ElementRef, Renderer2} from '@angular/core';
import {BentoTransferboxBasicColumnDefinitions, BentoAlertItemOptions} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import UserModel from 'src/app/core/models/user.model';
import OfficePermissionModel from 'src/app/core/models/office-permission.model';
import PracticePermissionModel from 'src/app/core/models/practice-permission.model';
import MetricPermissionModel from 'src/app/core/models/metric-permission.model';
import {AxeToolCommonMethodsService} from 'src/app/core/services/axe-tool-common-methods/axe-tool-common-methods.service';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
})
export class UserPermissionsComponent implements OnInit {
  @ViewChild('transferbox', {static: true}) transferbox;
  assignedOffices: OfficePermissionModel[] = [];
  assignedOfficesCopy: OfficePermissionModel[] = [];
  allOffices: OfficePermissionModel[] = [];
  restrictedOffices: OfficePermissionModel[] = [];
  restrictedOfficesCopy: OfficePermissionModel[] = [];
  assignedPractices: PracticePermissionModel[] = [];
  assignedPracticesCopy: PracticePermissionModel[] = [];
  allPractices: PracticePermissionModel[] = [];
  restrictedPractices: PracticePermissionModel[] = [];
  restrictedPracticesCopy: PracticePermissionModel[] = [];
  assignedMetrics: MetricPermissionModel[] = [];
  assignedMetricsCopy: MetricPermissionModel[] = [];
  allMetrics: MetricPermissionModel[] = [];
  restrictedMetrics: MetricPermissionModel[] = [];
  restrictedMetricsCopy: MetricPermissionModel[] = [];
  assignedOfficesIds: number[];
  assignedMetricsIds: number[];
  gridOptions: BentoTransferboxBasicColumnDefinitions[] = [];
  gridHeight = 530;
  hidePagination = false;
  isVisiblePermissionsEmpty = false;
  successMsg = '';
  errorMessage = '';
  header = '';
  isGetDataLoading = false;
  isTransferBoxDataLoading = false;
  alerts: BentoAlertItemOptions[] = [];
  userData: UserModel = new UserModel();
  clearUserData: UserModel = new UserModel();
  assignedPracticesIds: any[];

  @Input() firmId: number;
  @Input() selectedUserData: any;
  @Input() permission: any;
  @Output() handleUserAction = new EventEmitter<string>();

  constructor(private service: BaseService,
        private el: ElementRef, private renderer: Renderer2,
        private axeToolService: AxeToolCommonMethodsService
  ) {}

  ngOnInit(): void {
    if (this.permission == 'Office permission') {
      this._initGridOffice();
      this.getAssignedOfficePermissions();
    } else if (this.permission == 'Practice permission') {
      this._initGridPractice();
      this.getAssignedPracticePermissions();
    } else if (this.permission == 'Metric permission') {
      this._initGridMetric();
      this.getAssignedMetricPermissions();
    }
  }
  ngAfterViewChecked(): void { 
    this.axeToolService.removeTransferboxSpanForAccessibility(this.el,this.renderer);
  }
  private _initGridPractice() {
    this.gridOptions = [
      {
        header: 'All practices',
        binding: 'practiceName',
      },
    ];
  }

  getAssignedPracticePermissions() {
    this.isGetDataLoading = true;
    this.isTransferBoxDataLoading = false;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/user/' + this.selectedUserData.userId + '/practice', '')
      .subscribe(
        (result) => {
          this.assignedPractices = result;
          this.assignedPracticesCopy = JSON.parse(JSON.stringify(this.assignedPractices));
          if (this.assignedPractices.length < 1) this.isVisiblePermissionsEmpty = true;
          this.getAllPracticePermissions();
        },

        (error) => {
          this.isGetDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  getAllPracticePermissions() {
    this.isGetDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmId + '/practice', '').subscribe(
      (result) => {
        this.allPractices = result;
        this.getRestrictedPractices();
        this.isGetDataLoading = false;
      },

      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  getRestrictedPractices() {
    if (this.assignedPractices.length == this.allPractices.length) this.restrictedPractices = [];
    else if (this.assignedPractices.length < 1) {
      this.restrictedPractices = this.allPractices;
    } else {
      this.restrictedPractices = this.allPractices.filter((o) => !this.assignedPractices.some((v) => v.id === o.id));
    }
    this.isTransferBoxDataLoading = true;
    this.restrictedPracticesCopy = JSON.parse(JSON.stringify(this.restrictedPractices));
  }

  getRestrictedMetrics() {
    if (this.assignedMetrics.length == this.allMetrics.length) this.restrictedMetrics = [];
    else if (this.assignedMetrics.length < 1) {
      this.restrictedMetrics = this.allMetrics;
    } else {
      this.restrictedMetrics = this.allMetrics.filter(
        (o) => !this.assignedMetrics.some((v) => v.metricId === o.metricId)
      );
    }
    this.isTransferBoxDataLoading = true;
    this.restrictedMetricsCopy = JSON.parse(JSON.stringify(this.restrictedMetrics));
  }

  getAllMetricPermissions() {
    this.isGetDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmId + '/metric', '').subscribe(
      (result) => {
        this.allMetrics = result;
        this.getRestrictedMetrics();
        this.isGetDataLoading = false;
      },

      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getAssignedMetricPermissions() {
    this.isGetDataLoading = true;
    this.isTransferBoxDataLoading = false;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/user/' + this.selectedUserData.userId + '/metric', '')
      .subscribe(
        (result) => {
          this.assignedMetrics = result;
          this.assignedMetricsCopy = JSON.parse(JSON.stringify(this.assignedMetrics));
          if (this.assignedMetrics.length < 1) this.isVisiblePermissionsEmpty = true;
          this.getAllMetricPermissions();
        },

        (error) => {
          this.isGetDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  onTransferMetric($event) {
    this.restrictedMetrics = $event.gridDataOne;
    this.assignedMetrics = $event.gridDataTwo;
    this.assignedMetricsIds = [];
    $event.gridDataTwo.forEach((item) => {
      this.assignedMetricsIds.push(item.metricId);
    });
    if ($event.gridDataTwo.length < 1) this.isVisiblePermissionsEmpty = true;
    else this.isVisiblePermissionsEmpty = false;
  }

  private _initGridMetric() {
    this.gridOptions = [
      {
        header: 'All metrics',
        binding: 'metricDescription',
      },
    ];
  }

  saveMetricInfo() {
    this.isGetDataLoading = true;
    let responseBody = {
      userId: this.selectedUserData.userId,
      metrics: this.assignedMetricsIds,
    };
    this.service.post(environment.FIAdminBaseEndpoint + 'v1/user/metric', responseBody).subscribe(
      (response) => {
        this.isGetDataLoading = false;
        this.successMsg = 'Data modified successfully.';
        this.restrictedMetricsCopy = this.restrictedMetrics;
        this.assignedMetricsCopy = this.assignedMetrics;
        this.alerts.push({
          type: 'success',
          msg: this.successMsg,
          timeout: 2500,
          closeable: true,
        });
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  closeMetricInfo() {
    this.isTransferBoxDataLoading = false;
    this.isGetDataLoading = true;
    this.restrictedMetrics = this.restrictedMetricsCopy;
    this.assignedMetrics = this.assignedMetricsCopy;
    this.alerts.push({
      type: 'success',
      msg: 'Data reset successfully.',
      timeout: 2500,
      closeable: true,
    });
    setTimeout(() => {
      this.isTransferBoxDataLoading = true;
      this.isGetDataLoading = false;
    }, 100);
  }

  onTransferOffice($event: any) {
    this.restrictedOffices = $event.gridDataOne;
    this.assignedOffices = $event.gridDataTwo;
    this.assignedOfficesIds = [];
    $event.gridDataTwo.forEach((item) => {
      this.assignedOfficesIds.push(item.id);
    });
    if ($event.gridDataTwo.length < 1) this.isVisiblePermissionsEmpty = true;
    else this.isVisiblePermissionsEmpty = false;
  }

  onTransferPractice($event) {
    this.restrictedPractices = $event.gridDataOne;
    this.assignedPractices = $event.gridDataTwo;
    this.assignedPracticesIds = [];
    $event.gridDataTwo.forEach((item) => {
      this.assignedPracticesIds.push(item.id);
    });
    if ($event.gridDataTwo.length < 1) this.isVisiblePermissionsEmpty = true;
    else this.isVisiblePermissionsEmpty = false;
  }

  getRestrictedOffices() {
    if (this.assignedOffices.length == this.allOffices.length) this.restrictedOffices = [];
    else if (this.assignedOffices.length < 1) {
      this.restrictedOffices = this.allOffices;
    } else {
      this.restrictedOffices = this.allOffices.filter((o) => !this.assignedOffices.some((v) => v.id === o.id));
    }
    this.isTransferBoxDataLoading = true;
    this.restrictedOfficesCopy = JSON.parse(JSON.stringify(this.restrictedOffices));
  }

  getAllOfficePermissions() {
    this.isGetDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm/' + this.firmId + '/office', '').subscribe(
      (result) => {
        this.allOffices = result;
        this.getRestrictedOffices();
        this.isGetDataLoading = false;
      },

      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  getAssignedOfficePermissions() {
    this.isGetDataLoading = true;
    this.isTransferBoxDataLoading = false;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/user/' + this.selectedUserData.userId + '/office', '')
      .subscribe(
        (result) => {
          this.assignedOffices = result;
          this.assignedOfficesCopy = JSON.parse(JSON.stringify(this.assignedOffices));
          if (this.assignedOffices.length < 1) this.isVisiblePermissionsEmpty = true;
          this.getAllOfficePermissions();
        },
        (error) => {
          this.isGetDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  private _initGridOffice() {
    this.gridOptions = [
      {
        header: 'All offices',
        binding: 'name',
      },
    ];
  }
  saveOfficeInfo() {
    this.isGetDataLoading = true;
    let responseBody = {
      userId: this.selectedUserData.userId,
      offices: this.assignedOfficesIds,
    };
    this.service.post(environment.FIAdminBaseEndpoint + 'v1/user/office', responseBody).subscribe(
      (response) => {
        this.isGetDataLoading = false;
        this.successMsg = 'Data modified successfully.';
        this.restrictedOfficesCopy = this.restrictedOffices;
        this.assignedOfficesCopy = this.assignedOffices;
        this.alerts.push({
          type: 'success',
          msg: this.successMsg,
          timeout: 2500,
          closeable: true,
        });
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  savePracticeInfo() {
    this.isGetDataLoading = true;
    let responseBody = {
      userId: this.selectedUserData.userId,
      practices: this.assignedPracticesIds,
    };
    this.service.post(environment.FIAdminBaseEndpoint + 'v1/user/practice', responseBody).subscribe(
      (response) => {
        this.isGetDataLoading = false;
        this.successMsg = 'Data modified successfully.';
        this.restrictedPracticesCopy = this.restrictedPractices;
        this.assignedPracticesCopy = this.assignedPractices;
        this.alerts.push({
          type: 'success',
          msg: this.successMsg,
          timeout: 2500,
          closeable: true,
        });
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  closeOfficeInfo() {
    this.isTransferBoxDataLoading = false;
    this.isGetDataLoading = true;
    this.restrictedOffices = this.restrictedOfficesCopy;
    this.assignedOffices = this.assignedOfficesCopy;
    this.alerts.push({
      type: 'success',
      msg: 'Data reset successfully.',
      timeout: 2500,
      closeable: true,
    });
    setTimeout(() => {
      this.isTransferBoxDataLoading = true;
      this.isGetDataLoading = false;
    }, 100);
  }
  closePracticeInfo() {
    this.isTransferBoxDataLoading = false;
    this.isGetDataLoading = true;
    this.restrictedPractices = this.restrictedPracticesCopy;
    this.assignedPractices = this.assignedPracticesCopy;
    this.alerts.push({
      type: 'success',
      msg: 'Data reset successfully.',
      timeout: 2500,
      closeable: true,
    });
    setTimeout(() => {
      this.isTransferBoxDataLoading = true;
      this.isGetDataLoading = false;
    }, 100);
  }
}
