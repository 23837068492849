import {Component, EventEmitter, Input, ViewChild, Output, OnInit} from '@angular/core';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import UserModel from 'src/app/core/models/user.model';
import {eventDispatcher} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-firm-users',
  templateUrl: './firm-users.component.html',
})
export class FirmUsersComponent implements OnInit {
  @Input() firmId: number;
  @Output() handleUsersAction = new EventEmitter<string>();
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  userList: UserModel[] = [];
  userData: UserModel = new UserModel();
  page: any = 1;
  pageSize: any = 10;
  totalItem = 0;
  errorMessage = '';
  searchText = '';
  columnName = '';
  sortDirection = '';
  isLastNameAscending: boolean | null = null;
  isFirstNameAscending: boolean | null = null;
  isRoleAscending: boolean | null = null;
  selectItems: number[] = [10, 20, 30, 40, 50];
  isDataLoading = false;
  alerts: BentoAlertItemOptions[] = [];
  currentInfo: any = {
    page: 1,
    totalItems: this.totalItem,
    numItems: 10,
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
  };
  lastPage = this.currentInfo.page;
  currentRecords: number;
  totalRecords: number;
  isLoadedFirstTime: boolean;

  constructor(private service: BaseService, private globalservice: GlobalService) {}

  ngOnInit(): void {
    this.totalRecords = 0;
    this.isLoadedFirstTime = true;
    this.getUserListByFirmId();
  }

  getUserListByFirmId(): void {
    this.isDataLoading = true;
    let params = {
      page: this.page,
      size: this.pageSize,
      search: this.searchText,
      sortColumn: this.columnName,
      sortDir: this.sortDirection,
    };
    this.service.post(environment.FIAdminBaseEndpoint + 'v1/user/firmId/' + this.firmId, params).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.userList = result.details;
        this.currentInfo.totalItems = result.totalCount;
        this.currentRecords = this.userList.length;
        if (this.isLoadedFirstTime) {
          this.totalRecords = result.totalCount;
          this.isLoadedFirstTime = false;
        }
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong. Please try again.',
          closeable: true,
        });
      }
    );
  }

  setOrderByLastName() {
    if (this.isLastNameAscending) {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.isLastNameAscending = !this.isLastNameAscending;
  }

  setOrderByFirstName() {
    if (this.isFirstNameAscending) {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.isFirstNameAscending = !this.isFirstNameAscending;
  }

  setOrderByRole() {
    if (this.isRoleAscending) {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.isRoleAscending = !this.isRoleAscending;
  }

  sortingColumn(headerName: string) {
    this.isDataLoading = true;
    if (headerName === 'lastName') {
      this.isFirstNameAscending = null;
      this.isRoleAscending = null;
      this.setOrderByLastName();
    } else if (headerName === 'firstName') {
      this.isLastNameAscending = null;
      this.isRoleAscending = null;
      this.setOrderByFirstName();
    } else if (headerName === 'role') {
      this.isLastNameAscending = null;
      this.isFirstNameAscending = null;
      this.setOrderByRole();
    }
    this.columnName = headerName;
    this.getUserListByFirmId();
  }

  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    this.currentRecords = flexGrid.rows.length;
    if (flexGrid) flexGrid.select(-1, -1);
    flexGrid.cells.hostElement.setAttribute('aria-labelledby', 'usersTitle');
  }

  searchUsers() {
    this.currentInfo.page = 1;
    this.page = 1;
    this.lastPage = 1;
    this.getUserListByFirmId();
  }

  onPageChanged(page) {
    if (page !== this.lastPage) {
      setTimeout(() => {
        this.page = page;
        this.getUserListByFirmId();
      });
    }
    this.lastPage = page;
    this.currentRecords = this.userList.length;
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.pageSize = itemsPerPage;
    if (this.page === 1) {
      this.pageSize = itemsPerPage;
      this.getUserListByFirmId();
    }
    this.currentRecords = this.userList.length;
  }

  addUser() {
    eventDispatcher.next({type: ActionTypes.SetUserDetail, payload: this.userData});
    let option = {message: 'Add', selectedOption: null};
    this.handleUsersAction.emit(JSON.stringify(option));
  }

  editUser(userId: string) {
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/user/' + userId, '').subscribe(
      (result) => {
        let userInformation: UserModel;
        userInformation = result;
        let option = {message: 'Edit', selectedOption: userInformation};
        this.handleUsersAction.emit(JSON.stringify(option));
        eventDispatcher.next({type: ActionTypes.SetUserDetail, payload: userInformation});
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong. Please try again.',
          closeable: true,
        });
      }
    );
  }
}
