/* eslint-disable max-len */
export const environment = {
  production: '#{production_env}#',
  offline: {
    status: false,
    date: 'Wed, Apr 29, 2021',
    time: '11:00 pm',
    timezone: 'CST',
    timeToComplete: '2 hours',
  },
  BaseEndpoint: '#{BaseEndpoint_url}#',
  GetDetailsBaseEndpoint: '#{GetDetailsBaseEndpoint_url}#',
  FIAdminBaseEndpoint: '#{FIAdminBaseEndpoint_url}#',
  FIClientBaseEndpoint: '#{FIClientBaseEndpoint_url}#',
  wijmoKey: '#{wijmoKey}#',
  auth: {
    domain: '#{auth_domain}#',
    clientId: '#{auth_clientId}#',
    audience: '#{auth_audience}#',
    redirectUri: window.location.origin,
    errorPath: '/unauthorized',
  },
  httpInterceptor: {
    allowedList: ['#{services_ciam_url}#'],
  },
  customOverlay: false,
  loadOnlyFIFirms: '#{loadOnlyFIFirms}#',
  productInsightURL: '#{productInsightURL}#',
  productInsightToken: '#{productInsightToken}#',
  inactivityTimeout: '#{inactivityTimeout}#', //in minutes
  appInsights: {
    instrumentationKey: '#{instrumentationKey}#',
    ciamUrl: '#{ciamURL}#',
  },
 
  pmmUrl: '#{pmmUrl}#',
  pmmFileName: '#{pmmFileName}#',
  pmmVersion: '#{pmmVersion}#',
  UserIdleConfig: {
    // user idle value in seconds.
    idle: '#{userIdleTime}#',
    // inactive session popup timeout value in seconds.
    timeout: '#{userIdleTimeout}#',
    // time interval when refresh the token should called.
    // ping value should be less then of token expired time.
    // i.e if token expire in 5 minutes then ping value should be 5 minus 1. so it will be 4 minutes.
    // ping value in seconds.
    ping: '#{userIdlePing}#',
  },
};
