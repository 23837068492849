import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BentoAlertItemOptions, BentoTagsInputEvent} from '@bento/bento-ng';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import FirmDetailModel from 'src/app/core/models/firmdetail.model';
import {FirmPeerGroupList, PeerGroupFirms, PeerGroupRequestBody} from 'src/app/core/models/firmpeergroupstatus.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {PeergroupService} from 'src/app/core/services/peergroup/peergroup.service';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {CellType} from '@grapecity/wijmo.grid';
declare let TRAAC: any;
import {GlobalService} from 'src/app/core/services/global/global.service';
import {AxeToolCommonMethodsService} from 'src/app/core/services/axe-tool-common-methods/axe-tool-common-methods.service';

@Component({
  selector: 'app-firm-custom-peer-groups',
  templateUrl: './firm-custom-peer-groups.component.html',
  styleUrls: ['./firm-custom-peer-groups.component.scss'],
})
export class FirmCustomPeerGroupsComponent implements OnInit, AfterViewInit {
  selectedOption: FirmPeerGroupList = null;
  isEditOrAddPeerGroup = false;
  tagInputSelection: any[] = [];
  data: wjcCore.CollectionView;
  CHECKBOX_SELECTED = 1;
  CHECKBOX_UNSELECTED = 2;
  headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
  CHECKBOX_INDETERMINATE = 3;
  selectBinding = 'selected';
  searchText: String = '';
  currentInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };

  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  gridSelection: any[] = [];
  peerGroupName: string;
  isReadyToSave = false;
  @Input() firmDetails: FirmDetailModel = new FirmDetailModel();
  isDataLoading: boolean;
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  availablePeerGroupFirms: PeerGroupFirms[] = [];
  selectedPeerGroupFirms: PeerGroupFirms[];
  selectedPeerGroupFirmIds: number[] = [];
  currentRecords: number;
  totalRecords: number;
  isLoadedFirstTime: boolean;
  mandatorySelectionCount = 0;
  isQuickSightEnabled = false;
  isListGrid: boolean = false;
  gridsSelectedRow = 0;
  gridsSelectedCol = 0;
  lastPressedKey: any;

  constructor(
    private peerGroupService: PeergroupService,
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    private featureToggleService: FeatureToggleService,
    private globalservice: GlobalService,
    private axeToolService: AxeToolCommonMethodsService
  ) {}

  ngOnInit(): void {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /**************************************************************/
    let customPeerGroupMode = this.peerGroupService.getCustomPeerGroupMode;
    if (customPeerGroupMode) {
      this.isEditOrAddPeerGroup = customPeerGroupMode == 'edit' ? true : false;
    }

    let selectedOption = this.peerGroupService.getselectedPeerGroup;
    if (selectedOption) {
      this.selectedOption = selectedOption;
    }

    let selectedFirms = this.peerGroupService.getSelectedPeerGroupFirms;
    if (selectedFirms && this.isEditOrAddPeerGroup) {
      this.selectedPeerGroupFirms = selectedFirms;
      this.selectedPeerGroupFirmIds = [
        ...new Set(this.selectedPeerGroupFirms.map((item: PeerGroupFirms) => item.firmId)),
      ];
    }

    this.getAvailableFirms();

    this.data = new wjcCore.CollectionView();
    this.data.pageSize = this.currentInfo.pageSize;

    this.isLoadedFirstTime = true;
    this.totalRecords = 0;

    if (this.isEditOrAddPeerGroup) {
      this.peerGroupName = this.selectedOption.peerlabel;
    }
    this.mandatorySelectionCount = this.firmDetails.defaultAccess == 'CAN' ? 6 : 5;
    this.verifySelection();
  }
  ngAfterViewInit(): void {
    let ariaRoleRemoval = document.querySelector("div[wj-part='root']");
    ariaRoleRemoval.removeAttribute('tabindex');
  }

  gridInitialized(flexGrid: wjcGrid.FlexGrid, gridHeaderId: string = '') {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    // flexGrid.select(-1,-1);
    this.flexGrid = flexGrid;
    let rowIndex: any;

    flexGrid.hostElement.addEventListener(
      'keydown',
      (e) => {
        this.lastPressedKey = e.code;
        // if (wjcCore.closestClass(e.target, 'wj-colheaders')) return; // Skip column header
        const isCell = wjcCore.hasClass(e.target as Element, 'wj-cell');
        const isRowHeader = wjcCore.closestClass(e.target as Element, 'wj-rowheaders');
        const isTopLeft = wjcCore.closestClass(e.target as Element, 'wj-topleft');
        if (isCell && isRowHeader && !isTopLeft) {
          const chk = (e.target as Element).querySelector('input[type="checkbox"]');
          const rowIndexID = chk?.getAttribute('id') || '';
          rowIndex = rowIndexID ? rowIndexID.replace(/\D/g, '') : -1;
          rowIndex = parseInt(rowIndex);
          if (e.code === 'Space' && chk) {
            e.preventDefault();
            //(chk as HTMLInputElement).focus();
            (chk as HTMLInputElement).click();
            let cell = e.target;
            wjcCore.setAttribute(cell as Element, 'aria-selected', (chk as HTMLInputElement).checked);
          }
        } else if (isTopLeft) {
          const chk = (e.target as Element).querySelector('input[type="checkbox"]');

          if (e.code === 'Space' && chk) {
            e.preventDefault();
            (chk as HTMLInputElement).click();
            (chk as HTMLInputElement).checked = !(chk as HTMLInputElement).checked;
            chk.setAttribute('aria-checked', "'" + (chk as HTMLInputElement).checked + "'");
            let cell = e.target;
            wjcCore.setAttribute(cell as Element, 'aria-selected', (chk as HTMLInputElement).checked);
          }
        }

        // Toggle the selected class on the header cell
        if (wjcCore.hasClass(e.target as Element, 'wj-state-selected')) {
          wjcCore.removeClass(e.target as Element, 'wj-state-selected');
        } else {
          wjcCore.addClass(e.target as Element, 'wj-state-selected');
        }
      },
      true
    );
    flexGrid.hostElement.addEventListener(
      'click',
      (e) => {
        //when checkbox is clicked then using arrow doesnt work as focus is still on the checkbox. Placeholder for code.
      },
      true
    );

    flexGrid.formatItem.addHandler((s, e: any) => {
      //Added for correct column numbers with rownumber and work with NVDA Focus mode"off
      const isRowHeader = wjcCore.closestClass(e.target as Element, 'wj-rowheaders');
      e.cell.id = `cell-${e.panel.cellType}-${e.row}-${e.col}`;
      const currentRow = e.cell.parentElement;

      if (
        currentRow &&
        currentRow.classList.contains('wj-row') &&
        (e.panel.cellType === wjcGrid.CellType.Cell || e.panel.cellType === wjcGrid.CellType.ColumnHeader) &&
        e.col === s.columns.firstVisibleIndex &&
        e.cell.parentElement.getAttribute('aria-owns') == null
      ) {
        let ownedElementsList = '';

        const rowHeaderType =
          e.panel.cellType === wjcGrid.CellType.ColumnHeader ? wjcGrid.CellType.TopLeft : wjcGrid.CellType.RowHeader;

        // Place id for rowHeader cells.
        for (let colIndex = 0; colIndex < s.rowHeaders.columns.length; colIndex++) {
          ownedElementsList += `cell-${rowHeaderType}-${e.row}-${colIndex} `;
        }

        // Place id for data cells.
        for (let colIndex = 0; colIndex < s.columns.length; colIndex++) {
          ownedElementsList += `cell-${e.panel.cellType}-${e.row}-${colIndex} `;
        }
        ownedElementsList = ownedElementsList.slice(0, ownedElementsList.length - 1);
        //
        currentRow.setAttribute('aria-owns', ownedElementsList);
      }
      if (e.panel.cellType == wjcGrid.CellType.TopLeft) {
        e.cell.setAttribute('aria-label', 'Checkbox for select all');
      } else if (e.panel.cellType == wjcGrid.CellType.RowHeader) {
        e.cell.removeAttribute('role');
        e.cell.setAttribute('role', 'gridcell');
      }
      // Calculate and set colIndex on data cell columns while considering the column count from rowHeader columns.
      let colIndex = s.rowHeaders.columns.length + e.col + 1;
      if (!isRowHeader && e.panel.cellType === wjcGrid.CellType.Cell) {
        e.cell.setAttribute('aria-colindex', colIndex.toString());
      }
      if (e.panel.cellType === wjcGrid.CellType.ColumnHeader) {
        e.cell.setAttribute('aria-colindex', colIndex.toString());
      }
    });

    if (this.isEditOrAddPeerGroup) {
      this.onCheckBoxChange();
    }
    // if (gridHeaderId && flexGrid) flexGrid.cells.hostElement.setAttribute('aria-labelledby', gridHeaderId);
    this.currentRecords = flexGrid.rows.length;
  }

  refreshCells() {
    if (this.flexGrid) {
      this.flexGrid.refreshCells(true);
    }
  }

  refreshed(s, e) {
    if (this.flexGrid) {
      const wjRole = this.flexGrid.hostElement.querySelector('.wj-topleft .wj-header');
      const roleRow = this.flexGrid.hostElement.querySelector('.wj-topleft .wj-row');
      if (wjRole && roleRow) {
        wjRole.setAttribute('role', 'rowheader');
        roleRow.setAttribute('role', 'row');
      }
    }
  }

  isAllSelected() {
    return this.headerCheckBoxMode === this.CHECKBOX_SELECTED;
  }

  isIndeterminate() {
    return this.headerCheckBoxMode === this.CHECKBOX_INDETERMINATE;
  }

  /**
   * Event Handler for Multi-Select Column Header Checkbox
   */
  onHeaderCheckBoxChange() {
    let selected = false;
    if (this.headerCheckBoxMode !== this.CHECKBOX_SELECTED) {
      this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      selected = true;
    } else {
      this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
      selected = false;
    }
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        this.flexGrid.rows[i].dataItem[this.selectBinding] = selected;
        this.flexGrid.rows[i].cssClass = selected ? 'row-selected' : '';
        selected
          ? this.saveSelection(this.flexGrid.rows[i].dataItem)
          : this.removeSelection(this.flexGrid.rows[i].dataItem);
      }
    }
  }

  // handle checkbox change event
  onCheckBoxChange() {
    this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
    let count = 0;
    this.data.sourceCollection.forEach((element) => {
      element.selected ? this.saveSelection(element) : this.removeSelection(element);
    });
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        if (this.flexGrid.rows[i].dataItem[this.selectBinding] === true) {
          count++;
          this.flexGrid.rows[i].dataItem[this.selectBinding] = true;
          this.flexGrid.rows[i].cssClass = 'row-selected';
        } else {
          this.flexGrid.rows[i].cssClass = '';
        }
      }
      if (count === this.flexGrid.rows.visibleLength && this.flexGrid.rows.visibleLength != 0) {
        this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      } else if (count > 0) {
        this.headerCheckBoxMode = this.CHECKBOX_INDETERMINATE;
      }
    }
  }

  // Save selection
  saveSelection(selectedOption) {
    const index = this.gridSelection.indexOf(selectedOption);
    if (index != -1) {
      this.gridSelection.splice(index, 1, selectedOption);
    } else {
      this.gridSelection.push(selectedOption);
    }
    this.refreshTags();
  }

  // Remove selection
  removeSelection(selectedOption) {
    const index = this.gridSelection.indexOf(selectedOption);
    if (index != -1) {
      this.gridSelection.splice(index, 1);
    }
    this.refreshTags();
  }

  // Refresh selected options tags
  refreshTags() {
    setTimeout(() => {
      var list = [];
      this.gridSelection.forEach((obj) => {
        list.push(obj);
      });
      this.tagInputSelection = list;
      this.verifySelection();
    }, 0);
  }

  // Pagination
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
    this.currentRecords = this.flexGrid.rows.length;
    this.onCheckBoxChange();
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.data.pageSize = itemsPerPage;
    this.currentRecords = this.flexGrid.rows.length;
    this.onCheckBoxChange();
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  handleButtonAction(actionfrom: string) {
    this.peerGroupService.setSelectedTab = 'Peer groups';
    if (actionfrom == 'Cancel') {
      this.updateServiceHandler();
    } else if (actionfrom == 'Delete') {
      this.deletePeerGroup();
    } else if (actionfrom == 'Save') {
      if (this.isEditOrAddPeerGroup) {
        this.updatePeerGroup();
      } else {
        if (this.isQuickSightEnabled) {
          TRAAC.track(TRAAC.keyValue('Add a Peer group', {email: this.authService.getUserEmail()}).build());
        }
        this.addPeerGroup();
      }
    }
  }

  updateServiceHandler() {
    this.peerGroupService.setSelectedTab = 'Peer groups';
    this.peerGroupService.setCustomPeerGroupMode = null;
    this.peerGroupService.setselectedPeerGroup = null;
    this.peerGroupService.setSelectedPeerGroupFirms = [];
    this.peerGroupService.setIsDefaultView = true;
    this.peerGroupService.peerGroupStatusHandler();
  }

  verifySelection() {
    this.isReadyToSave = this.peerGroupName && this.tagInputSelection.length >= this.mandatorySelectionCount;
  }

  searchGlobalPeerGroups() {
    this.data.sourceCollection = this.availablePeerGroupFirms.filter(
      (element) => element.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
    );
    this.currentRecords = this.flexGrid.rows.length;
  }

  onTagRemoved(e: BentoTagsInputEvent) {
    e.tag.selected = false;
    this.onCheckBoxChange();
  }

  // getfirmId() should return the selected firmID OR firmID of logged in user.
  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  getAvailableFirms() {
    this.isDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/peergroup/all/firm/', this.getfirmId).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.availablePeerGroupFirms = result;
        let selectedTags = [];
        if (this.isEditOrAddPeerGroup && this.selectedPeerGroupFirmIds.length > 0) {
          this.availablePeerGroupFirms.forEach((element) => {
            if (this.selectedPeerGroupFirmIds.includes(element.firmId)) {
              element.selected = true;
              selectedTags.push(element);
            }
          });
          this.tagInputSelection = selectedTags;
          this.verifySelection();
        }
        this.data = new wjcCore.CollectionView(this.availablePeerGroupFirms);
        this.data.pageSize = this.currentInfo.pageSize;
        if (this.isLoadedFirstTime) {
          this.totalRecords = this.data.totalItemCount;
          this.isLoadedFirstTime = false;
        }

        this.currentRecords = this.data.itemCount;
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  addPeerGroup() {
    if (!this.isReadyToSave) return;
    this.isDataLoading = true;
    let firmIds: number[] = [...new Set(this.gridSelection.map((item) => item.firmId))];
    let requestBody = new PeerGroupRequestBody();
    requestBody.name = this.peerGroupName;
    requestBody.selectedFirmIds = firmIds;
    this.service.post(environment.FIAdminBaseEndpoint + 'v1/peergroup/' + this.getfirmId, requestBody).subscribe(
      (result) => {
        this.isDataLoading = false;
        this.updateServiceHandler();
      },

      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        let errorMsg = '';
        if (this.errorMessage.status == 400) {
          errorMsg = this.errorMessage.message ? this.errorMessage.message : this.errorMessage.messages[0].message;
        } else {
          errorMsg = 'Something went wrong, please try again.';
        }
        this.alerts.push({
          type: 'warning',
          msg: errorMsg,
          closeable: true,
        });
      }
    );
  }

  updatePeerGroup() {
    if (!this.isReadyToSave) return;
    this.isDataLoading = true;
    let firmIds: number[] = [...new Set(this.gridSelection.map((item) => item.firmId))];
    let requestBody = new PeerGroupRequestBody();
    requestBody.name = this.peerGroupName;
    requestBody.selectedFirmIds = firmIds;
    this.service
      .put(
        environment.FIAdminBaseEndpoint + 'v1/peergroup/' + this.selectedOption.peerid + '/firm/' + this.getfirmId,
        requestBody
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.updateServiceHandler();
        },

        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          let errorMsg = '';
          if (this.errorMessage.status == 400) {
            errorMsg = this.errorMessage.message ? this.errorMessage.message : this.errorMessage.messages[0].message;
          } else {
            errorMsg = 'Something went wrong, please try again.';
          }
          this.alerts.push({
            type: 'warning',
            msg: errorMsg,
            closeable: true,
          });
        }
      );
  }

  deletePeerGroup() {
    if (this.isEditOrAddPeerGroup == false) return;
    this.isDataLoading = true;
    this.service.delete(environment.FIAdminBaseEndpoint + 'v1/peergroup/', this.selectedOption.peerid).subscribe(
      (result) => {
        this.isDataLoading = false;
        let alert = [
          {
            type: 'warning',
            msg: '“' + this.selectedOption.peerlabel + '” peer group has deleted.',
            closeable: true,
          },
        ];
        this.peerGroupService.setAlerts = alert;
        this.updateServiceHandler();
      },

      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  initLoadedRow(s: wjcGrid.FlexGrid) {
    this.onCheckBoxChange();
    this.verifySelection();
  }

  closeAlert(event) {}
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    this.gridsSelectedCol = args.col;
    this.gridsSelectedRow = args.row;
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }

  handleKeyDown(flexGrid, event) {
    if (event.code === 'Space') {
      event.preventDefault();
      let cell = flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
      let input = cell.querySelector('a');
      input.click();
    } else if (event.keyCode === wjcCore.Key.Tab) {
      this.axeToolService.enableFocusFromCloumnAndRowHeaders(
        event,
        'button:not([tabindex="-1"]):not([disabled]),[tabindex]:not([tabindex="-1"]):not([disabled])'
      );
    }
  }
  focusOnGrid(flexGrid: wjcGrid.FlexGrid, event) {
    flexGrid.select(0, 0);
  }
}
