<div class="GlobalLayout" role="main">
  <main id="MainContent" tabindex="-1" class="Main Main--narrow" [bentoBusyLoader]="isGetDataLoading">
    <header class="Main-header">
      <app-global-breadcrumbs
        [isFirmPeerGroup]="true"
        (updateService)="backToGlobalPeerGroups()"
        [peerLabel]="selectedGlobalPeerGroup ? selectedGlobalPeerGroup.peerLabel : ''"
        [firmName]="'Global peer groups'"
      ></app-global-breadcrumbs>
      <h1 class="Main-heading">
        {{ selectedGlobalPeerGroup ? selectedGlobalPeerGroup.peerLabel : '' }}
      </h1>
      <dl class="bento-desc-list bento-desc-list--inline bento-desc-list--bullets FirmDetails">
        <div class="bento-desc-list-item">
          <dt>Visibility:</dt>
          <dd>{{ visibility }}</dd>
        </div>
      </dl>
    </header>
    <div class="Main-body">
      <section class="Section">
        <header class="row align-items-center Section-header">
          <div class="col">
            <app-grid-record-details
              [currentRecords]="currentRecords"
              [totalRecords]="totalRecords"
            ></app-grid-record-details>
          </div>
          <div class="col">
            <div class="Actions justify-content-end">
              <div class="Actions-item">
                <span class="bento-search">
                  <label for="firmPeerGroup" class="sr-only">Search firm</label>
                  <input
                    type="text"
                    class="form-control"
                    name="firmPeerGroup"
                    id="firmPeerGroup"
                    [(ngModel)]="searchText"
                  />
                  <button type="button" class="btn btn-primary" (click)="searchGlobalPeerGroups()">
                    <span class="btn-text">Search</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </header>
        <div class="Section-body">
          <wj-flex-grid
            #flexGrid
            class="DataGrid"
            [class.list-grid]="true"
            [itemsSource]="data"
            [headersVisibility]="'Column'"
            [stickyHeaders]="true"
            [showMarquee]="true"
            [showSort]="true"
            [allowDragging]="false"
            [autoRowHeights]="true"
            (initialized)="gridInitialized(flexGrid)"
          >
            <wj-flex-grid-column
              [header]="'Firm name'"
              [binding]="'name'"
              [visible]="true"
              [width]="'*'"
              [isReadOnly]="true"
              [wordWrap]="true"
            ></wj-flex-grid-column>
          </wj-flex-grid>
        </div>
        <footer class="Section-footer">
          <bento-pagination
            [page]="data.pageIndex + 1"
            [directionLinks]="true"
            [boundaryLinks]="true"
            [totalItems]="data.totalItemCount"
            [(itemsPerPage)]="data.pageSize"
            (itemsPerPageChange)="onItemsPerPageChanged($event)"
            (pageChange)="onPageChanged($event)"
          >
          </bento-pagination>
        </footer>
      </section>
    </div>
  </main>
</div>
