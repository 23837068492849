<bento-alert [items]="alerts"></bento-alert>
<div class="GlobalLayout-group" *ngIf="isContentDisplay && isItemsReady">
  <div class="GlobalLayout-item GlobalLayout-item--small">
    <bento-side-nav
      class="NavSide"
      aria-label="Firm administrator settings"
      [items]="items"
      [collapsible]="!isNewFirm"
      (select)="onSelect($event)"
    >
    </bento-side-nav> 
  </div>
  <div class="GlobalLayout-item GlobalLayout-item--large">
    <ng-container *ngIf="items[0].data.isVisible">
      <app-details [isNewFirm]="isNewFirm" [isFAdmin]="isFAdmin" [firmId]="firmId"></app-details>
    </ng-container>
    <div *ngIf="!isFAdmin">
      <ng-container *ngFor="let item of items[1].items">
        <app-firm-permission
          *ngIf="item.data.isVisible"
          [isFirmPermission]="true"
          [permissionName]="permissionNameLbl"
          [firmId]="firmId"
        ></app-firm-permission>
      </ng-container>
      <ng-container *ngIf="items[2] && items[2].data.isVisible && isStaffingratioEnable">
        <app-firm-staffingratio-subscription [firmId]="firmId"></app-firm-staffingratio-subscription>
      </ng-container>
    </div>
  </div>
</div>
