import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  CreateChart,
  ProcessedVisualization,
  RequestHandler,
  ResponseHandler,
  SelectionFieldRules,
  VisualizationList,
  VisualizationOptions,
  VisualizationTemplate,
  VisualizationTypes,
} from 'src/app/core/models/create-chart.model';
import * as wjGrid from '@grapecity/wijmo.grid';
import {WjTransposedGrid} from '@grapecity/wijmo.angular2.grid.transposed';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import {WjFlexChart} from '@grapecity/wijmo.angular2.chart';
import { isArray, Point, hasClass , closestClass} from '@grapecity/wijmo';
import {FlexChart, SeriesVisibility, Position, SelectionMode, ChartType, ChartTooltip,ChartElement} from '@grapecity/wijmo.chart';
import {ViewsSettingsComponent} from '../../create-view/views-settings/views-settings.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {ActivatedRoute, Router} from '@angular/router';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {ICellTemplateContext} from '@grapecity/wijmo.grid';
import * as wijmo from '@grapecity/wijmo';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {PeerCheckServiceService} from '../../peer-check/peer-check-service.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {FormatdateService} from 'src/app/core/services/formatdate/formatdate.service';
import {DateFormat} from 'src/app/shared/enums';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {colorPalette} from 'src/app/shared/constants';
import {SaveViewComponent} from '../../create-view/save-view/save-view.component';
declare let TRAAC: any;
import $ from 'jquery';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-chart-details',
  templateUrl: './chart-details.component.html',
  styleUrls: ['./chart-details.component.scss'],
})
export class ChartDetailsComponent implements OnInit {
  chart: WjFlexChart | null = null;
  processedInfo: ProcessedVisualization;
  vizType = 'KPM-V1';
  isFirmSelected: boolean;
  firmId: number;
  isDataLoading: boolean;
  firmBasicData: any;
  pcViewCols: any;
  isGetBasicAPICalled: boolean;
  visualizationOption: VisualizationOptions;
  errorMessage: any;
  dataType: string;
  colorPalette: string[];
  isChartTemplate: boolean;
  defaultSelectionChartModel: CreateChart;
  isQuickSightEnabled = false;
  isExportLoading = false;
  isColumnFreezed = true;
  frozenColumnCount = 1;
  isPressedArray:Array<boolean>=[];
  count=0;
  showTransposeGridButton = false;
  showTransposeGrid = false;
  showTransposeGridLabel = 'Show older grid';
  displayOldGrid = 'none';
  rowCount: any = 0;
  colCount: any = 0;
  constructor(
    public modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private _peercheckService: PeerCheckServiceService,
    private _peercheckMsgHandlerService: PeercheckMessageHandlerService,
    private formatdate: FormatdateService,
    private service: BaseService,
    private featureToggleService: FeatureToggleService,
    private globalservice: GlobalService
  ) {
    this.dataType = 'p1';
    // Original Color palette
    // this.colorPalette = [
    //   'rgba(43, 56, 102, 1)',
    //   'rgba(149, 186, 225, 1)',
    //   'rgba(59, 122, 111, 1)',
    //   'rgba(136, 174, 102, 1)',
    //   'rgba(219, 207, 94, 1)',
    //   'rgba(217, 144, 101, 1)',
    //   'rgba(193, 92, 95, 1)',
    //   'rgba(227, 144, 178, 1)',
    //   'rgba(100, 88, 183, 1)',
    //   'rgba(152, 137, 206, 1)',
    //   'rgba(112, 112, 112, 1)',
    //   'rgba(164, 164, 164, 1)',
    // ];
    this.colorPalette = colorPalette;
  }

  @Input() createChartModel: CreateChart = new CreateChart();
  @Input() selectedChartData: VisualizationList;
  @Input() shouldShowActionButton: boolean;
  @Input() shouldShowSettingButton: boolean;
  @Input() calledForScreen = '';
  @Input() vizTemplateDetail: VisualizationTemplate = new VisualizationTemplate();
  @Output() dataLoadingStateChanged = new EventEmitter<boolean>();
  @Output() settingsChanged = new EventEmitter<boolean>();
  @Output() rendered = new EventEmitter();
  @ViewChild('transposedFlex', {static: true}) flexGrid: WjTransposedGrid;
  @ViewChild('lineChart', {static: true}) flexChart: WjFlexChart;
  @ViewChild('flexGrid', {static: true}) flexSimplifiedGrid: wjcGrid.FlexGrid;
  alerts: BentoAlertItemOptions[] = [];
  actionDropdownItems = [];
  exportDropdownItems = [];
  tooltipMap = [];
  createChartSelectionRules: SelectionFieldRules;

  async ngOnInit() {
    let actionName = 'Edit/Copy';
    this.visualizationOption = this._peercheckMsgHandlerService.getSelectedVisualizationType as VisualizationOptions;
    if (this.visualizationOption && this.visualizationOption == VisualizationOptions.VisualizationTemplates) {
      actionName = 'New visualization';
    }
    this.exportDropdownItems = [
      {name: 'SVG', isDisabled: false},
      {name: 'JPEG', isDisabled: false},
      {name: 'PNG', isDisabled: false},
      {name: 'Excel', isDisabled: false},
    ];
    this.actionDropdownItems = [
      {name: actionName, isDisabled: false},
      {
        name: 'Export as',
        isDisabled: false,
        options: [
          {name: 'SVG', isDisabled: false},
          {name: 'JPEG', isDisabled: false},
          {name: 'PNG', isDisabled: false},
          {name: 'Excel', isDisabled: false},
        ],
      },
    ];

    let dataLoading_PC = this._peercheckService.isDataLoadingMessage.subscribe((dataLoadingState) => {
      this.isDataLoading = dataLoadingState;
      //  this.dataLoadingStateChanged.emit(dataLoadingState);
    });

    this.rendered.emit();
    this.isChartTemplate = this.visualizationOption == VisualizationOptions.VisualizationTemplates;
    this.getBasicDetails();
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    await this.featureToggleService
      .getFeatureValue('VISUALIZATION_QUICKSIGHT')
      .then((val) => (this.isQuickSightEnabled = val));
    /*************************************************/
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - DISABLE_OLD_VIZ_GRID ******/
    await this.featureToggleService
      .getFeatureValue('DISABLE_OLD_VIZ_GRID')
      .then((val) => (this.showTransposeGridButton = val));
    /*************************************************/
  }

  async getBasicDetails() {
    //Service will return getBasic details and firm level rules
    // this.dataLoadingStateChanged.emit(true);
    this.firmBasicData = await this._peercheckService.getFirmBasicsData(this.getDefaultOrSelectedFirmId);
    this.pcViewCols = this.firmBasicData.firmLevelRules;
    // this.dataLoadingStateChanged.emit(false);
    this.isGetBasicAPICalled = true;
    this.createChartModel.isCanadianFirm = this.firmBasicData.defaultAccess == 'CAN';

    if (this.selectedChartData) {
      this.getTemplateDetails();
    }
  }

  transposeTemplate = (ctx: ICellTemplateContext) => {
    if (!isNaN(ctx.value)) return wijmo.Globalize.formatNumber(ctx.value, ctx.row.format);
    else return wijmo.Globalize.format(ctx.value, ctx.row.format);
  };

  onCopyVisualization() {
    let requestBody = this._peercheckMsgHandlerService.createRequestBodyForVisualization(
      this.createChartModel,
      this.vizTemplateDetail,
      this.firmBasicData,
      this.createChartModel.templateKey.toUpperCase(),
      'save'
    );
    requestBody['chartTemplateId'] = this.selectedChartData.chartTemplateId;
    const modalRef = this.modalService.open(SaveViewComponent, {ariaLabelledBy: 'modalSaveVisualization'});
    modalRef.componentInstance.settingFrom = 'Create new visualization';
    modalRef.componentInstance.visualizationOption = VisualizationOptions.MyVisualizations;

    modalRef.componentInstance.isCalledForVizOrView = true;
    modalRef.componentInstance.firmID = this.getDefaultOrSelectedFirmId;
    modalRef.componentInstance.visualizationRequestBody = requestBody;
    modalRef.componentInstance.visualizationModel = this.createChartModel;

    modalRef.result.then(
      (result) => {
        if (result == 'Success') {
          this.alerts.push({
            type: 'success',
            msg: 'Visualization saved successfully.',
            timeout: 2500,
            closeable: true,
          });
          this.isDataLoading = true;
          setTimeout(() => {
            if ((this.route.snapshot, this.route.snapshot.params, this.route.snapshot.params.id)) {
              this.router.navigate(['firm/' + this.getDefaultOrSelectedFirmId + '/visualizations']);
            } else {
              this.router.navigate(['visualizations']);
            }
          }, 500);
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  populateChartData() {
    this.firmId = this.getDefaultOrSelectedFirmId;
    if (this.vizTemplateDetail) {
      this._peercheckMsgHandlerService.populatePreSelectedVisualizationDetails(
        this.createChartModel,
        this.vizTemplateDetail,
        this.isChartTemplate
      );
      if (this.isChartTemplate && this.calledForScreen && this.calledForScreen.indexOf('VisualizationDetails') == 0) {
        this.createChartModel.name = this.createChartModel.orignalName;
      }
      this.defaultSelectionChartModel = JSON.parse(JSON.stringify(this.createChartModel));
      this.prepareDescriptionText();
      this.updateSelectedFieldText();
      this.updateChartData();
    }
  }
  gridInitialized(flexGrid) {
    flexGrid.allowResizing = wjGrid.AllowResizing.BothAllCells;
    flexGrid.autoSizeColumns();
    if (flexGrid) {
      flexGrid.cells.hostElement.setAttribute('aria-labelledby', 'visualizationDataChartName');
      flexGrid.cells.hostElement.setAttribute('aria-describedby', 'ChartPanel-header-name-id');
      this.rowCount = wijmo.Globalize.format(flexGrid.rows.length, 'n0');
      this.colCount = wijmo.Globalize.format(flexGrid.columns.length, 'n0');
    }
    // select the 2nd cloumn 1st cell value
    flexGrid.select(0, 1);
    // handle keydown row headers
    flexGrid.hostElement.addEventListener(
      'keydown',
      function (e) {
        var ht = flexGrid.hitTest(document.activeElement);
        if ((e.keyCode == 37 && ht.col == 1) || (e.keyCode == 40 && ht.col == -1)) {
          e.preventDefault();
        }
      },
      true
    );

    flexGrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType != 3) {
        const self = this;
        let row = e.getRow();
        if (
          e.panel.cellType == 1 &&
          row.visibleIndex + 1 > 0 &&
          e.cell.innerText &&
          s.columns[e.col].binding == 'column0'
        ) {
          let color = 'transparent';
          if (self.colorPalette) {
            let index = 0;
            var randomNumber = row.visibleIndex + 1 - 1;
            index = (index + randomNumber) % self.colorPalette.length;
            color = self.colorPalette[index];
          }
          e.cell.style.borderLeft = '5px solid ' + color;
        }

        if (e.panel.cellType == 1 && s.columns[e.col].binding == 'column0') {
          e.cell.style.color = '#404040';
          e.cell.style.fontWeight = '600';
          //e.cell.setAttribute('role', 'columnheader');
          e.cell.setAttribute('role', 'rowheader');
        }
        e.cell.style.textAlign = 'left';
      }
    });
    this.flexSimplifiedGrid = flexGrid;
  }

  transposedInitialize(transposedGrid: WjTransposedGrid, isManualCall: boolean = false) {
    transposedGrid.allowResizing = wjGrid.AllowResizing.BothAllCells;
    if (transposedGrid) transposedGrid.cells.hostElement.setAttribute('aria-labelledby', 'visualizationDataChart');
    transposedGrid.itemFormatter = function (panel, r, c, cell) {
      // if (r == 0 && panel.rows && panel.rows[r]) {
      //   panel.rows[r].height = 40;
      // }
      // adjusting width but flickring in UI
      // panel.columns[c].width = (c == 0 && panel.cellType != 1) ? 230 : 130;
      if (panel.cellType != 3) {
        if (panel.cellType == 1 && panel.rows[r].binding == 'bindingX') {
          cell.style.background = '#f5f7ff';
          cell.style.color = '#404040';
          cell.style.fontWeight = '600';
          // cell.style.paddingLeft = '1rem';
          // cell.style.paddingRight = '1rem';
          // cell.style.paddingTop = '0.75rem';
          // cell.style.paddingBottom = '0.75rem';
          cell.setAttribute('role', 'columnheader');
        } else {
          // cell.style.height = '60px';
          // cell.style.padding = '10px';
        }
        // panel.rows[r].wordWrap = true;
        cell.style.textAlign = 'left';
      } else if (panel.cellType == 3) {
        if (c == 0 && panel.columns && panel.columns[c]) {
          cell.style.color = '#404040';
          cell.style.fontWeight = '600';
          // panel.columns[0].width = 200;
          // panel.columns[c].wordWrap = true;
        }
        if (panel.rows[r].binding == 'bindingX') {
          cell.style.color = '#404040';
          cell.style.background = '#f5f7ff';
          // cell.style.paddingLeft = '1rem';
          // cell.style.paddingRight = '1rem';
          // cell.style.paddingTop = '0.75rem';
          // cell.style.paddingBottom = '0.75rem';
          cell.setAttribute('role', 'columnheader');
        } else {
          cell.style.background = 'white';
          // cell.style.height = '60px';
          // cell.style.padding = '10px';
        }
      }
    };
    this.flexGrid = transposedGrid;
  }

  updatedView(s) {
    s.autoSizeRows(1);
    s.autoSizeColumns();
  }
  loadedRows(s: WjTransposedGrid) {
    // s.columns.defaultSize = 150;
    // s.rows.defaultSize = 50;
    s.autoSizeRows(1);
    s.autoSizeColumns();
  }
  loadedSimplifiedRows(s) {
    // s.columns.defaultSize = 150;
    // s.rows.defaultSize = 50;
    s.autoSizeRows(1);
    s.autoSizeColumns();
  }

  getFileNameForExport() {
    if (
      this.createChartModel.templateKey == VisualizationTypes.KPMV1 &&
      this.selectedChartData.templateName == 'Year over Year (YOY) Growth By Metrics'
    ) {
      return 'YearoverYear(YOY)GrowthByMetrics';
    } else if (this.createChartModel.templateKey == VisualizationTypes.KPMV1) {
      return 'KeyPerformanceMeasures';
    } else if (this.createChartModel.templateKey == VisualizationTypes.DPA) {
      return 'DemandGrowth';
    }
    return 'Visualization';
  }

  saveChart(fileFormat: string) {
    if (this.flexChart) {
      if (fileFormat === 'png' || fileFormat === 'jpeg') {
        this.isExportLoading = true;
        const tempChart: WjFlexChart = this.flexChart;
        // tempChart.axisX.labelAngle = 90;
        tempChart.axisX.itemFormatter = (engine, lable) => {
          lable.text = this.tooltipMap[lable.val];
          return lable;
        };
        const wjFlexElement = document.getElementById('lineChart');

        wjFlexElement.style.width = '2000px';
        wjFlexElement.style.height = '720px';
        tempChart.refresh();
        setTimeout(() => {
          tempChart.saveImageToFile(this.getFileNameForExport() + '.' + fileFormat);
          tempChart.axisX.itemFormatter = this.itemFormatter;
          tempChart.refresh();
        }, 1000);
        setTimeout(() => {
          wjFlexElement.style.width = 'auto';
          wjFlexElement.style.height = '520px';
          this.isExportLoading = false;
        }, 2000);
      } else {
        this.flexChart.saveImageToFile(this.getFileNameForExport() + '.' + fileFormat);
      }
    }
    /**
     * 
     * Export to PDF with Chart and Data
     * 
    let doc = new pdf.PdfDocument({
      pageSettings: {
        layout: pdf.PdfPageOrientation.Landscape,
        size: pdf.PdfPageSize.Tabloid,
        margins: {
          left: 50,
          top: 50,
          right: 50,
          bottom: 50,
        },
      },
      ended: (_sender: pdf.PdfDocument, args: pdf.PdfDocumentEndedEventArgs) =>
        pdf.saveBlob(args.blob, 'Sample Chart' + '.pdf'),
    });
    this.flexChart.saveImageToDataUrl(chart.ImageFormat.Svg, (url: string) => {
      doc.drawSvg(url);
      doc.moveDown();
    });
    setTimeout(() => {
      gridPdf.FlexGridPdfConverter.draw(this.flexGrid, doc, 1000, 500, {
        customCellContent: true,
      });
      doc.end();
    }, 200);
     */
  }

  makeBG(elem) {
    var svgns = 'http://www.w3.org/2000/svg';
    var bounds = elem.getBBox();
    var bg = document.createElementNS(svgns, 'rect');
    var style = getComputedStyle(elem);
    var padding_top = parseInt(style['padding-top']);
    var padding_left = parseInt(style['padding-left']);
    var padding_right = parseInt(style['padding-right']);
    var padding_bottom = parseInt(style['padding-bottom']);
    bg.setAttribute('x', (bounds.x - parseInt(style['padding-left'])).toString());
    bg.setAttribute('y', (bounds.y - parseInt(style['padding-top'])).toString());
    bg.setAttribute('width', bounds.width + padding_left + padding_right);
    bg.setAttribute('height', bounds.height + padding_top + padding_bottom);
    bg.setAttribute('fill', style['background-color']);
    bg.setAttribute('rx', style['border-radius']);
    bg.setAttribute('stroke-width', style['border-top-width']);
    bg.setAttribute('stroke', style['border-top-color']);
    if (elem.hasAttribute('transform')) {
      bg.setAttribute('transform', elem.getAttribute('transform'));
    }
    elem.parentNode.insertBefore(bg, elem);
  }
  initChart(chart: WjFlexChart) {
    this.chart = chart;
    do{
      this.isPressedArray=Array(chart.series.length).fill(false);
      this.count=1;
    }while(this.count==0)
    // removing the event listeners
    chart.hostElement.addEventListener(
      'mousemove',
      (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
      },
      true
    );

    chart.hostElement.addEventListener(
      'mouseleave',
      (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
      },
      true
    );

    // handled click event to toggle series visibility, when NVDA is being used
  chart.hostElement.addEventListener(
    'click',
    (e) => {

      let element: any= e.target;
      if (hasClass(element, 'wj-label') && closestClass(element, 'wj-legend')) {
        e.stopImmediatePropagation();
        this.toggleSeriesVisibility(e);
      }
    },
    true
  );

  // to toggle legends using Enter key
  chart.hostElement.addEventListener('keydown', (e) => {
    if (e.key == 'Enter') {

      this.toggleSeriesVisibility(e);
    }
  });
  }

  rndrCalled(chart: WjFlexChart) {
    this.flexChart = chart;
    let elements = chart.hostElement;
    let barWidth = 0;
    // Below code will add the spaces between two bars.
    let series = elements.querySelectorAll('.wj-series-group g');
    series.forEach((s, idx) => {
      s.querySelectorAll('rect').forEach((rect) => {
        let width = Number(rect.getAttribute('width'));
        if (width > 10) rect.setAttribute('width', (width - 5).toString());
        barWidth = Number(rect.getAttribute('width'));
      });
    });

    // Below code will convert legend icon from square to circle.
    let legends = elements.querySelectorAll('.wj-legend g rect');
    legends.forEach((s, idx) => {
      s.setAttribute('rx', '5');
      s.setAttribute('ry', '5');
    });

    // Below code will add right border to plot area.
    let plotAreaRect = elements.querySelector('.wj-plot-area rect');
    let width = plotAreaRect.getAttribute('width');
    let height = plotAreaRect.getAttribute('height');
    plotAreaRect.setAttribute('stroke-dasharray', '0,' + width + ',' + height + ',' + height);

    const _window = window as any;

    //removes label if zoom > 100%
    if (_window.wijmo && _window.wijmo.isFirefox() && (_window.devicePixelRatio >= 1.001 || _window.outerWidth <= 1200))
      this.flexChart.dataLabel.position = 0;
    else if (!_window.wijmo.isFirefox() && (_window.outerWidth / _window.innerWidth) * 100 > 100)
      this.flexChart.dataLabel.position = 0;
    else this.flexChart.dataLabel.position = barWidth > 23 ? 2 : 0;
    //this.flexChart.dataLabel.position = barWidth > 15 ? 2 : 0;

    // Below code will align negative value label properly
    var dataLabels = document.querySelectorAll('.wj-data-labels text');
    for (var i = 0; i < dataLabels.length; i++) {
      if (parseFloat(dataLabels[i].textContent.replace(/\%/g, '')) < 0) {
        dataLabels[i].attributes[1].nodeValue = (parseFloat(dataLabels[i].attributes[1].nodeValue) + 21).toString();
      }
      let xPos = Number(dataLabels[i].getAttribute('x'));
      dataLabels[i].setAttribute('x', (xPos - 2.5).toString());
    }
    // Below code will keep Yaxis point 0 at middle on the chart.
    let objAx = chart.axisY;
    const dataInfo = chart._dataInfo;
    if (!(objAx && dataInfo)) {
      return;
    }
    const min = dataInfo.getMinY();
    const max = dataInfo.getMaxY();

    // might be used later on
    function round(value, step) {
      step || (step = 1.0);
      var inv = 1.0 / step;
      return Math.round(value * inv) / inv;
    }
    let expectedMin = min ? min : null;
    let expectedMax = max ? max : null;
    let bufferArea = 0.15;
    if (objAx.max >= 1 - bufferArea) bufferArea = 0.15;
    if (min < 0 && max > 0 && objAx.format.startsWith('p')) {
      if (max - -min > 0) {
        expectedMax = max;
        expectedMin = -max;
      } else if (max - -min < 0) {
        expectedMax = -min;
        expectedMin = min;
      }
      // To add buffer area to the chart
      //objAx.max != (expectedMax + expectedMax * bufferArea) || objAx.min != (expectedMin + expectedMin * bufferArea)
      if (objAx.max != expectedMax || objAx.min != expectedMin) {
        objAx.max = expectedMax + expectedMax * bufferArea;
        objAx.min = expectedMin + expectedMin * bufferArea;
      }
    } else {
      if (min < 0 && max < 0) {
        objAx.max = null;
        objAx.min = expectedMin + expectedMin * bufferArea;
      } else if (min > 0 && max > 0) {
        objAx.max = expectedMax + expectedMax * bufferArea;
        objAx.min = null;
      } else {
        objAx.max = objAx.max ? expectedMax + expectedMax * bufferArea : expectedMax;

        objAx.min = objAx.min ? expectedMin + expectedMin * bufferArea : expectedMin;
      }
    }

    // Commented this code because we can't add a check by static percentage because
    // in FI we have more thn 100% values and in that case the below logic will fail.
    // Add the logic of Shifting the Legends and x-axis labels down by 10px.

    // if (objAx.max <= -0.95 && objAx.format.startsWith('p')) {
    //   //adjust lagend and label if value is 100%
    //   var labelX = document.querySelectorAll('.wj-axis-x .wj-label');
    //   for (var i = 0; i < labelX.length; i++) {
    //     labelX[i].attributes[1].nodeValue = (parseFloat(labelX[i].attributes[1].nodeValue) + 10).toString();
    //   }
    //   var legendLabel = document.querySelectorAll('.wj-legend .wj-label');
    //   var legendRect = document.querySelectorAll('.wj-legend rect');
    //   for (var i = 0; i < legendLabel.length; i++) {
    //     legendLabel[i].attributes[1].nodeValue = (parseFloat(legendLabel[i].attributes[1].nodeValue) + 6).toString();
    //     legendRect[i + 1].attributes[4].nodeValue = (
    //       parseFloat(legendRect[i + 1].attributes[4].nodeValue) + 6
    //     ).toString();
    //   }
    // }

    var ns = 'http://www.w3.org/2000/svg';
    var labelX = document.querySelectorAll('.wj-axis-x g');
    // let labels = chart.hostElement.querySelectorAll('.wj-axis-x .wj-label');
    // this.setXLabels(labels);
    // this.flexChart.axisX.labelPadding = 10;
    if (labelX && labelX.length != 0) {
      for (let i = 0; i < labelX.length; i++) {
        const text = labelX[i].children[0];
        const titleElement = document.createElementNS(ns, 'title');
        titleElement.classList.add('contains-title');
        titleElement.innerHTML = this.tooltipMap && this.tooltipMap[i];
        text.append(titleElement);
        // text.setAttribute("onmousemove","showTooltip(evt, 'This is blue')")
        // text.setAttribute("onmouseout","hideTooltip()")
      }
    } else {
      var textX = document.querySelectorAll('.wj-axis-x text');

      for (let i = 0; i < textX.length; i++) {
        const text = textX[i];
        const titleElement = document.createElementNS(ns, 'title');
        titleElement.innerHTML = this.tooltipMap && this.tooltipMap[i];
        text.append(titleElement);
        titleElement.classList.add('contains-title');
        text.innerHTML = this.tooltipMap && this.tooltipMap[i];
        // text.setAttribute("onmousemove","showTooltip(evt, 'This is blue')")
        // text.setAttribute("onmouseout","hideTooltip()")
      }
    }

    //this.flexChart.axisX.labelAngle = 45;
    document.getElementById('lineChart').scrollIntoView();

    
    let legends1 = chart.hostElement.querySelectorAll('.wj-legend g text');
    for (let i = 0; i < legends1.length; i++) {
      legends1[i].setAttribute('aria-pressed',this.isPressedArray[i].toString())
    }
        legends1.forEach((legend) => {
          const legendElement = legend as HTMLElement;
          legendElement.setAttribute('aria-label', legendElement.textContent);
          legendElement.tabIndex = 0;
          legendElement.setAttribute('role','button');
          legendElement.addEventListener('focus', (e) => {
            let target = e.target as HTMLElement;
            target.classList.add('tab-focused-legend');
          });
          // Remove the class when focus is removed from the header.
          legendElement.addEventListener('blur', (e) => {
            let target = e.target as HTMLElement;
            target.classList.remove('tab-focused-legend');
          });
        });
    // Add accessibility features once the chart is rendered
    chart.rendered.addHandler((sender, e) => {
      // For FlexChart.
      const hostElement = sender.hostElement;
      hostElement.tabIndex = -1;
      // For focusing on header.
      let headerElement = hostElement.querySelector('.wj-header') as HTMLElement;
      if (headerElement && sender.header) {
        headerElement.setAttribute('aria-label', sender.header);
        headerElement.tabIndex = 0;

        headerElement.addEventListener('focus', (e) => {
          let target = e.target as HTMLElement;
          target.classList.add('tab-focused-header');
        });
        // Remove the class when focus is removed from the header.
        headerElement.addEventListener('blur', (e) => {
          let target = e.target as HTMLElement;
          target.classList.remove('tab-focused-header');
        });
      }
      // Cast sender to FlexChart to access the series property
      const charts = sender as WjFlexChart;
      // Handle series elements for accessibility
      let xAxisLabels = hostElement.querySelectorAll('.wj-axis-x text');

      //extract symbol like $,%,£
      let yAxisLabels = hostElement.querySelectorAll('.wj-axis-y text');
      let symbolBefore = ``;
      let symbolAfter = ``;

      if (yAxisLabels.length > 0) {
        let labelText = yAxisLabels[0].textContent.trim();
        let match = labelText.match(/^([^\d.,]*)([\d.,]+)([^\d.,]*)$/);
        if (match) {
          symbolBefore = match[1]; // Special char before the number
          symbolAfter = match[3]; // Special char after the number
        }
      }
      for (let seriesIndex = 0; seriesIndex < charts.series.length; seriesIndex++) {
        let currentSeries = charts.series[seriesIndex];
        let element = currentSeries.hostElement.querySelectorAll('rect');
        if (element.length <= 0) {
          element = currentSeries.hostElement.querySelectorAll('ellipse');
        }
        let elementIndex = 0;
        let valueIndex = 0;

        while (elementIndex < element.length) {
          const ele = element[elementIndex];
          // keep finding the next valueIndex until a value is found.
          //When chart has a 0 value, the flow breaks. adding set time out to prevent the break
          if (currentSeries._values[valueIndex] === 0 || currentSeries._values[valueIndex] === 0.0) {
            setTimeout(() => {
              // Proceed with the while loop logic inside setTimeout if value is 0.
              while (!currentSeries._values[valueIndex] && valueIndex < currentSeries._values.length) {
                valueIndex++;
              }
            }, 10);
          } else {
            // Proceed normally if the value is not 0.
            while (!currentSeries._values[valueIndex] && valueIndex < currentSeries._values.length) {
              valueIndex++;
            }
          }  
          // If series is hidden, skip setting the attributes.
          if (charts.series[seriesIndex].visibility === SeriesVisibility.Hidden) {
            continue;
          }

          let contentElement = xAxisLabels[valueIndex];
          let contentText = '';
          let value = Math.round(currentSeries._values[valueIndex] * 10000) / 100;
          let content = '';
          //When chart has a 0 value and the legends are concatenated queryselector throws an error. set time out used to fix that error
          if (currentSeries._values[valueIndex] === 0 || currentSeries._values[valueIndex] === 0.0) {
            setTimeout(() => {
              const titleElement = contentElement.querySelector('.contains-title');
              contentText = titleElement ? titleElement.textContent : contentElement.textContent;
              if (symbolAfter) {
                content = `<b>${currentSeries.name}</b><br/> 
                                ${contentText} 
                                ${value}${symbolAfter}`;
                                ele.ariaLabel = `${currentSeries.name}, ${contentText}, ${value}${symbolAfter}`;

                // Case 2: Symbol is before the number (like '$' or '£')
              } else if (symbolBefore) {
                content = `<b>${currentSeries.name}</b><br/> 
                                ${contentText} 
                                ${symbolBefore}${value}`;
                                ele.ariaLabel = `${currentSeries.name}, ${contentText}, ${symbolBefore}${value}`;
              } else {
                // No special character, just show the value
                content = `<b>${currentSeries.name}</b><br/> 
                                ${contentText} 
                                ${value}`;
                                ele.ariaLabel = `${currentSeries.name}, ${contentText}, ${value}`;
              }
            }, 0);
          } else {
            contentText = contentElement.querySelector('.contains-title')
              ? contentElement.querySelector('.contains-title').textContent
              : contentElement.textContent;

            // Case 1: Symbol is after the number (like '%')
            if (symbolAfter) {
              content = `<b>${currentSeries.name}</b><br/> 
                            ${contentText} 
                            ${value}${symbolAfter}`;
                            ele.ariaLabel = `${currentSeries.name}, ${contentText}, ${value}${symbolAfter}`;

              // Case 2: Symbol is before the number (like '$' or '£')
            } else if (symbolBefore) {
              content = `<b>${currentSeries.name}</b><br/> 
                            ${contentText} 
                            ${symbolBefore}${value}`;              
                            ele.ariaLabel = `${currentSeries.name}, ${contentText}, ${symbolBefore}${value}`;                     
            } else {
              // No special character, just show the value
              content = `<b>${currentSeries.name}</b><br/> 
                            ${contentText} 
                            ${value}`;
                            ele.ariaLabel = `${currentSeries.name}, ${contentText}, ${value}`;
            }
          }

          // Set the attribute.
          // adding atribute is causing image download to stop. needs fix//
          //ele.setAttribute('aria-label', content);
          if(ele.getAttribute("height") == "0.0") {
            ele.setAttribute('height', "0.000001");
            ele.setAttribute("stroke", "rgba(238, 238, 238, 1)" )
          }
       //   ele.ariaLabel = `${currentSeries.name}, ${contentText}, ${value}${symbolAfter || symbolBefore || ''}`;
          // Make the element focusable with "Tab".
          ele.tabIndex = 0;
          // Add a class when the element is focused.
          ele.addEventListener('focus', (e) => {
            e.target.classList.add('tab-focused');            
            chart?.tooltip.show(e.target, content); 
            ele.addEventListener('keydown',(e)=>{
              if(e.key === 'Escape'){
                chart?.tooltip.hide();
              }
            });
        });
          ele.addEventListener('mouseover', (e) => {
            chart?.tooltip.show(e.target, content);
          });
          // Remove the class when the focus is removed from the element.
          ele.addEventListener('blur', (e) => {
            e.target.classList.remove('tab-focused');
          });
          //hide tooltip when focus changes to diff page
          window.addEventListener('beforeunload', () => {
            chart?.tooltip.hide();
          });
          document.addEventListener('focusout', (e) => {
            chart?.tooltip.hide();
          });

          elementIndex++;
          valueIndex++;
        }
      }
    });
  }
  toggleSeriesVisibility(e) {
    let element = e.target as HTMLElement;
    const rect = element.getBoundingClientRect();
    const clickEvent = new PointerEvent('click', {
      clientX: rect.left + rect.width / 2,
      clientY: rect.top + rect.height / 2,
      pointerId: 1,
      pointerType: 'mouse',
    });

    // toggle series visibility
    let ht = this.chart?.hitTest(clickEvent);
    if (!ht) return;
    if (ht.chartElement == ChartElement.Legend && ht.series) { 
      if (ht.series.visibility == SeriesVisibility.Legend) {
        ht.series.visibility = SeriesVisibility.Visible;
      } else if (ht.series.visibility == SeriesVisibility.Visible) {
        ht.series.visibility = SeriesVisibility.Legend;
      }
    }

    // re-focus the same legend
    setTimeout(() => {
        let legends = this.chart.hostElement.querySelectorAll('.wj-legend g text');
        let expX = element.getAttribute('x');
        let expY = element.getAttribute('y');
        for (let i = 0; i < legends.length; i++) {
          let x = legends[i].getAttribute('x');
          let y = legends[i].getAttribute('y');
          if (expX == x && expY == y) {
            const legendsc= legends[i] as HTMLElement
            legendsc.focus();
            this.isPressedArray[i]=!this.isPressedArray[i];
         legendsc.setAttribute('aria-pressed',this.isPressedArray[i].toString());
            break;
          }
        }
      },50);
    
  }

  openViewSettings() {
    const persistValues = this.createChartModel.viewsSettings;
    const modalRef = this.modalService.open(ViewsSettingsComponent, {ariaLabelledBy: 'modalSettings'});
    let chartSettings: RequestHandler = new RequestHandler();
    chartSettings.requestedFrom = 'VisualizationSettings';
    chartSettings.priorRequestData = persistValues;
    modalRef.componentInstance.settingsModel = this.createChartModel.viewsSettings;
    modalRef.componentInstance.settingsFor = 'Visualization';
    modalRef.componentInstance.rules = {showMean: true, showMedian: true, showYOY: true, showVariance: true};
    modalRef.result.then(
      (result) => {
        this.settingsChanged.emit();
        if (result) {
          this.createChartModel.viewsSettings = result;
          if (
            persistValues.matterType != result.matterType ||
            persistValues.contractorsType != result.contractorsType ||
            persistValues.currencyType != result.currencyType
          ) {
            chartSettings.currentRequestData = result;
            this.updateChartData(chartSettings);
          } else {
            this.alerts.push({
              type: 'success',
              msg: 'Settings applied successfully.',
              timeout: 2500,
              closeable: true,
            });
            if (this.createChartModel.viewsSettings.comparisonType == 'variance') {
              this.dataType = 'n1';
            } else {
              this.dataType = 'p1';
            }
            this.refreshColumns();
          }
        } else {
          this.alerts.push({
            type: 'warning',
            msg: 'Unable to apply settings.',
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  onCreateVisualization() {
    this._peercheckMsgHandlerService.setSelectedVisualizationData = this.selectedChartData;
    if (this.isFirmSelected) {
      this.router.navigate(['firm/' + this.firmId + '/new-visualization']);
    } else {
      this.router.navigate(['new-visualization']);
    }
  }

  itemFormatter(engine, label) {
    if (label.text.length > 24) {
      label.text.setAttribute;
      label.text = label.text.substr(0, 24) + '...';
    }
    // let labeltext1 = label.text.substr(0,15);
    // let labeltext2 = label.text.substr(15);
    // let labeltext3 = labeltext1 + "\n" + labeltext2
    // console.log(`labeltext3: ${labeltext3} `)
    // label.text = labeltext3;
    return label;
  }
  // setXLabels(labels){
  //   labels.forEach((value, key, parent) => {
  //     let x = +value.getAttribute('x');
  //     let y = +value.getAttribute('y');
  //     let text = value.innerHTML.split('\n');
  //     value.innerHTML = '';
  //     console.log('text: ',text)
  //     text.forEach((item, index) => {
  //       let e = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
  //       e.setAttribute('x', (x + 50).toString());
  //       e.setAttribute('y', (y + 15 * index).toString());
  //       e.innerHTML = item;
  //       value.appendChild(e);
  //     });
  //   });
  // }

  dropDownOnSelectChange(name: string) {
    if (name === 'SVG' || name === 'PNG' || name === 'JPEG') {
      if (this.isQuickSightEnabled)
        TRAAC.track(
          TRAAC.keyValue('Export visualization click', {
            exportOption: name,
            templateKey: this.selectedChartData.templateKey,
            email: this.authService.getUserEmail(),
          }).build()
        );
      this.saveChart(name.toLowerCase());
    } else if (name === 'Excel') {
      if (this.isQuickSightEnabled)
        TRAAC.track(
          TRAAC.keyValue('Export visualization click', {
            exportOption: name,
            templateKey: this.selectedChartData.templateKey,
            email: this.authService.getUserEmail(),
          }).build()
        );
      //pass data and file name
      this.exportGridToExcel(this.flexGrid, this.getFileNameForExport());
    }
  }

  actionButtonSelectionHandler(name: string) {
    if (name === 'Edit/Copy' || name === 'New visualization') {
      this._peercheckMsgHandlerService.setSelectedVisualizationData = this.selectedChartData;
      if (this.isFirmSelected) {
        this.router.navigate(['firm/' + this.firmId + '/new-visualization']);
      } else {
        this.router.navigate(['new-visualization']);
      }
    } else if (name === 'SVG' || name === 'PNG' || name === 'JPEG') {
      if (this.isQuickSightEnabled)
        TRAAC.track(
          TRAAC.keyValue('Export visualization click', {
            exportOption: name,
            templateKey: this.selectedChartData.templateKey,
            email: this.authService.getUserEmail(),
          }).build()
        );
      this.saveChart(name.toLowerCase());
    } else if (name === 'Excel') {
      if (this.isQuickSightEnabled)
        TRAAC.track(
          TRAAC.keyValue('Export visualization click', {
            exportOption: name,
            templateKey: this.selectedChartData.templateKey,
            email: this.authService.getUserEmail(),
          }).build()
        );
      //pass data and file name
      this.exportGridToExcel(this.flexGrid, this.getFileNameForExport());
    }
  }

  exportGridToExcel(data, fileName) {
    wjGridXlsx.FlexGridXlsxConverter.saveAsync(
      data,
      {
        includeColumnHeaders: false,
        // eslint-disable-next-line max-len
        includeRowHeaders: true,
        includeStyles: true,
        formatItem: this._exportFormatItem,
      },
      fileName
    );
  }
  private _exportFormatItem(args: wjGridXlsx.XlsxFormatItemEventArgs) {
    var row = args.row,
      xlsxCell = args.xlsxCell,
      cell: HTMLElement;
    let format = args.panel.rows[row].format;
    if (format != null && format.includes('c')) {
      cell = args.getFormattedCell();
      if (!isNaN(args.xlsxCell.value)) {
        xlsxCell.value = parseFloat(args.xlsxCell.value.toFixed(2));
        if (cell.textContent.trim().includes('£')) xlsxCell.style.format = '[$£-en-GB]#,##0.00';
        else xlsxCell.style.format = '$#,##0.00';
      }
    } else if (format != null && format.includes('p')) xlsxCell.style.format = '0.0%';
  }

  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    this.isFirmSelected = false;
    if (this.route.snapshot && this.route.snapshot.paramMap && this.route.snapshot.paramMap.get('id')) {
      this.isFirmSelected = true;
      return parseInt(this.route.snapshot.paramMap.get('id'));
    } else {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  refreshColumns() {
    if (!this.processedInfo || !this.processedInfo.processedColumn || !this.processedInfo.processedChartSeries) {
      return;
    }
    this.processedInfo.processedColumn.forEach((column) => {
      let binding: string = column && column.binding ? column.binding : '';

      if (binding.endsWith('_meanDelta'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'year over year' &&
          this.createChartModel.viewsSettings.statisticsType == 'Mean';
      else if (binding.endsWith('_medianDelta'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'year over year' &&
          this.createChartModel.viewsSettings.statisticsType == 'Median';
      else if (binding.endsWith('_meanVariance'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'variance' &&
          this.createChartModel.viewsSettings.statisticsType == 'Mean';
      else if (binding.endsWith('_medianVariance'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'variance' &&
          this.createChartModel.viewsSettings.statisticsType == 'Median';
    });
    this.processedInfo.processedChartSeries.forEach((column) => {
      let binding: string = column && column.binding ? column.binding : '';
      if (binding.endsWith('_meanDelta'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'year over year' &&
          this.createChartModel.viewsSettings.statisticsType == 'Mean';
      else if (binding.endsWith('_medianDelta'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'year over year' &&
          this.createChartModel.viewsSettings.statisticsType == 'Median';
      else if (binding.endsWith('_meanVariance'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'variance' &&
          this.createChartModel.viewsSettings.statisticsType == 'Mean';
      else if (binding.endsWith('_medianVariance'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'variance' &&
          this.createChartModel.viewsSettings.statisticsType == 'Median';
    });
    this.processedInfo.simplifiedColumn.forEach((column) => {
      let binding: string = column && column.binding ? column.binding : '';

      if (binding.endsWith('_meanDelta'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'year over year' &&
          this.createChartModel.viewsSettings.statisticsType == 'Mean';
      else if (binding.endsWith('_medianDelta'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'year over year' &&
          this.createChartModel.viewsSettings.statisticsType == 'Median';
      else if (binding.endsWith('_meanVariance'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'variance' &&
          this.createChartModel.viewsSettings.statisticsType == 'Mean';
      else if (binding.endsWith('_medianVariance'))
        column.visible =
          this.createChartModel.viewsSettings.comparisonType == 'variance' &&
          this.createChartModel.viewsSettings.statisticsType == 'Median';
    });
  }

  formatItem(s: any, e: any) {
    const self = this;
    if (e.panel.cellType == 3) {
      let row = e.getRow();
      if (row.visibleIndex > 0 && e.cell.innerText) {
        let color = 'transparent';
        if (self.colorPalette) {
          let index = 0;
          var randomNumber = row.visibleIndex - 1;
          index = (index + randomNumber) % self.colorPalette.length;
          color = self.colorPalette[index];
        }
        e.cell.style.borderLeft = '5px solid ' + color;
      }
    }
  }
  extractDateFormat(date) {
    return this.formatdate.format(date, DateFormat.MMMMy);
  }

  prepareDescriptionText() {
    let description = this._peercheckMsgHandlerService.getCommaSeparatedString(
      this.createChartModel.selectedMetrics,
      true
    );
    this.createChartModel.description = description;
  }

  updateSelectedFieldText() {
    if (this.createChartModel.selectedPeerGroups && this.createChartModel.selectedPeerGroups.length)
      this.createChartModel.selectedPeerGroupsText = this.createChartModel.selectedPeerGroups.length + ' Selected';
    else this.createChartModel.selectedPeerGroupsText = 'Select';

    if (this.createChartModel.selectedPracticeGroups && this.createChartModel.selectedPracticeGroups.length)
      this.createChartModel.selectedPracticeGroupsText =
        this.createChartModel.selectedPracticeGroups.length + ' Selected';
    else this.createChartModel.selectedPracticeGroupsText = 'Select';

    if (this.createChartModel.selectedOffices && this.createChartModel.selectedOffices.length)
      this.createChartModel.selectedOfficesText = this.createChartModel.selectedOffices.length + ' Selected';
    else this.createChartModel.selectedOfficesText = 'Select';

    if (this.createChartModel.selectedTitles && this.createChartModel.selectedTitles.length)
      this.createChartModel.selectedTitlesText = this.createChartModel.selectedTitles.length + ' Selected';
    else this.createChartModel.selectedTitlesText = 'Select';

    if (this.createChartModel.selectedAssociateYears && this.createChartModel.selectedAssociateYears.length)
      this.createChartModel.selectedAssociateYearsText =
        this.createChartModel.selectedAssociateYears.length + ' Selected';
    else this.createChartModel.selectedAssociateYearsText = 'Select';

    if (this.createChartModel.selectedSrAssociateYears && this.createChartModel.selectedSrAssociateYears.length)
      this.createChartModel.selectedSrAssociateYearsText =
        this.createChartModel.selectedSrAssociateYears.length + ' Selected';
    else this.createChartModel.selectedSrAssociateYearsText = 'Select';

    if (this.createChartModel.selectedPartnerYears && this.createChartModel.selectedPartnerYears.length)
      this.createChartModel.selectedPartnerYearsText = this.createChartModel.selectedPartnerYears.length + ' Selected';
    else this.createChartModel.selectedPartnerYearsText = 'Select';
  }

  // API Calls

  async getTemplateDetails() {
    // this.isDataLoading = true;
    let response = await this._peercheckService.getVisualizationDetailsWithType(
      this.isChartTemplate ? this.selectedChartData.chartTemplateId : this.selectedChartData.chartId,
      this.getDefaultOrSelectedFirmId,
      this.isChartTemplate
    );
    // this.isDataLoading = false;
    if (response && response.response && !response.hasError) {
      this.vizTemplateDetail = response.response;
      this.updateSelectionFieldRules();
      this.createChartModel.favorite = this.selectedChartData.favorite;
      this.populateChartData();
    } else {
      this.alerts = response.alerts;
    }
  }

  updateSelectionFieldRules() {
    this.createChartSelectionRules = new SelectionFieldRules();
    this.createChartSelectionRules.metrics = this.vizTemplateDetail.metrics;
    this.createChartSelectionRules.peerGroup = this.vizTemplateDetail.peerGroups;
    this.createChartSelectionRules.practiceGroup = this.vizTemplateDetail.practices;
    this.createChartSelectionRules.offices = this.vizTemplateDetail.offices;
    this.createChartSelectionRules.titles = this.vizTemplateDetail.titles;
    this.createChartSelectionRules.dateRange.displayOnUi = true;
    this.createChartSelectionRules.dateRange.maxCount = this.createChartSelectionRules.dateRange.minCount = 3;
  }

  updateChartData(requestedFrom: RequestHandler = null) {
    // Validate selections.
    if (!this.verifyReadyToSave(false, true)) {
      this.actionDropdownItems = this.actionDropdownItems.filter((obj) => {
        return obj.name == 'Edit/Copy' || obj.name == 'New visualization';
      });
      return;
    }

    // Generate Request body.
    let requestBody = this._peercheckMsgHandlerService.createRequestBodyForVisualization(
      this.createChartModel,
      this.vizTemplateDetail,
      this.firmBasicData,
      this.createChartModel.templateKey
    );
    this.isDataLoading = true;
    this.dataLoadingStateChanged.emit(true);
    this.service
      .post(environment.FIAdminBaseEndpoint + 'v1/visualization/' + this.getDefaultOrSelectedFirmId, requestBody)
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.dataLoadingStateChanged.emit(false);
          this.processedInfo = this._peercheckMsgHandlerService.processDataForVisualizationType(
            this.createChartModel.templateKey,
            result[0],
            this.createChartModel
          );
          this.tooltipMap = [];
          if (this.processedInfo && this.processedInfo.processedData) {
            for (let i = 0; i < this.processedInfo.processedData.length; i++) {
              this.tooltipMap.push(this.processedInfo.processedData[i].bindingX);
            }
          }
          this.refreshColumns();
          if (this.processedInfo.hasEmptyResult) {
            this.alerts.push({
              type: 'warning',
              msg: 'No data available to display.',
              closeable: true,
            });
            this.actionDropdownItems = this.actionDropdownItems.filter((obj) => {
              return obj.name == 'Edit/Copy' || obj.name == 'New visualization';
            });
          }
          if (requestedFrom) {
            requestedFrom.requestCompletionStatus = true;
            this.handleCustomRequestChanges(requestedFrom);
          }
          this.globalservice.skipFocusTwiceCombobox();
        },
        (error) => {
          this.dataLoadingStateChanged.emit(false);
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.processedInfo = null;
          if (requestedFrom) {
            requestedFrom.requestCompletionStatus = false;
            this.handleCustomRequestChanges(requestedFrom);
          } else {
            this.alerts.push({
              type: 'warning',
              msg: this._peercheckMsgHandlerService.getServiceErrorMessageString(this.errorMessage),
              closeable: true,
            });
          }
        }
      );
  }

  handleCustomRequestChanges(requestedFrom: RequestHandler) {
    if (!requestedFrom) return;

    if (requestedFrom.requestedFrom == 'VisualizationSettings') {
      if (requestedFrom.requestCompletionStatus) {
        this.alerts.push({
          type: 'success',
          msg: 'Settings applied successfully.',
          timeout: 2500,
          closeable: true,
        });
      } else {
        this.createChartModel.viewsSettings = requestedFrom.priorRequestData;
        this.alerts.push({
          type: 'warning',
          msg: 'Unable to apply settings.',
          closeable: true,
        });
      }
    }
  }

  verifyReadyToSave(applyChartNameConstraint: boolean = false, showError: boolean = false) {
    if (
      this.createChartModel.selectedDateRangeType &&
      this.createChartModel.selectedDateRangeType.id == 2 &&
      !(
        this.createChartModel.customRange.startPeriod1 &&
        this.createChartModel.customRange.startPeriod2 &&
        this.createChartModel.customRange.endPeriod1 &&
        this.createChartModel.customRange.endPeriod2
      )
    ) {
      if (showError) {
        this.alerts.push({
          type: 'warning',
          msg: 'Start Period or End Period is not valid.',
          closeable: true,
        });
      }
      return false;
    }

    if (
      this.createChartModel.selectedDateRangeType &&
      this.createChartModel.selectedDateRangeType.id == 1 &&
      !(
        this.createChartModel.dateRange.startDateRange.years &&
        this.createChartModel.dateRange.startDateRange.years.value &&
        this.createChartModel.dateRange.startDateRange.month &&
        this.createChartModel.dateRange.startDateRange.month.value
      )
    ) {
      if (showError) {
        this.alerts.push({
          type: 'warning',
          msg: 'Start Period or End Period is not valid.',
          closeable: true,
        });
      }
      return false;
    }

    if (
      !(
        this.createChartModel.selectedPeerGroups &&
        this.createChartModel.selectedPeerGroups.length >= this.createChartSelectionRules.peerGroup.minCount &&
        this.createChartModel.selectedPeerGroups.length <= this.createChartSelectionRules.peerGroup.maxCount
      )
    ) {
      if (showError) {
        this.alerts.push({
          type: 'warning',
          msg: 'Peer Group is not valid.',
          closeable: true,
        });
      }
      return false;
    }

    if (
      this.createChartModel.templateKey.toLowerCase() == VisualizationTypes.DPA &&
      !(
        this.createChartModel.selectedPracticeGroups &&
        this.createChartModel.selectedPracticeGroups.length >= this.createChartSelectionRules.practiceGroup.minCount &&
        this.createChartModel.selectedPracticeGroups.length <= this.createChartSelectionRules.practiceGroup.maxCount
      )
    ) {
      if (showError) {
        this.alerts.push({
          type: 'warning',
          msg: 'Practice Group is not valid.',
          closeable: true,
        });
      }
      return false;
    }

    if (this.createChartModel.orignalName == this.createChartModel.name && applyChartNameConstraint) {
      return false;
    }

    return true;
  }

  getDescription(descriptionOption) {
    return descriptionOption ? this._peercheckMsgHandlerService.getCommaSeparatedString(descriptionOption, true) : '';
  }
  freezeColumnToggle() {
    if (this.isColumnFreezed) this.frozenColumnCount = 0;
    else this.frozenColumnCount = 1;

    this.isColumnFreezed = !this.isColumnFreezed;
  }
  showOlderGrid() {
    if (this.displayOldGrid == 'none') {
      this.displayOldGrid = 'block';
      this.showTransposeGridLabel = 'Hide older grid';
    } else {
      this.displayOldGrid = 'none';
      this.showTransposeGridLabel = 'Show older grid';
    }
  }
}
