import {Component, OnInit, Output, ViewChild, AfterViewChecked} from '@angular/core';
import {RatesReportRequest} from 'src/app/core/models/rates-report-request.model';
import {RatesReportModal} from 'src/app/core/models/rates-report.model';
import {BehaviorSubject, Observable} from 'rxjs';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {BentoComboboxOptions} from '@bento/bento-ng';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {DownloadService} from 'src/app/core/services/download/download.service';
import {HttpParams} from '@angular/common/http';
import {GlobalService} from 'src/app/core/services/global/global.service';
import {setSelectionRange} from '@grapecity/wijmo';

@Component({
  selector: 'app-rates-reports',
  templateUrl: './rates-reports.component.html',
  styleUrls: ['./rates-reports.component.scss'],
})
export class RatesReportsComponent implements OnInit, AfterViewChecked {
  ratesReportData: RatesReportModal = new RatesReportModal();
  searchString: any = '';
  data: wjcCore.CollectionView;
  selectedFirm: any = {id: 'all', name: 'All'};
  currentSelectedFirm: any = {id: 'all', name: 'All'};
  selectedPeriod: any = 'all';
  isGetDataLoading = false;
  ratesReportRequest: RatesReportRequest = new RatesReportRequest();
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  // @Output() openChangeEvent = new EventEmitter<boolean>();
  firmsArr: BehaviorSubject<string[]>;
  openNewReport = false;
  reportDataList: any = [];
  alerts: BentoAlertItemOptions[] = [];
  errorMessage: any;
  lastPressedKey: any;
  gridsSelectedRow = 0;
  gridsSelectedCol = 0;

  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    autoSelect: true,
    searchCompare: (row, search) => {
      this.searchString = search;
      const searchLowerCase = search.toLocaleLowerCase();
      const name = `${row.name}`.toLowerCase();

      return name.indexOf(searchLowerCase) > -1;
    },
    labelFormatter: (row) => `${row.name}`,
  };

  pageInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };
  constructor(
    private downloadFile: DownloadService,
    private _service: BaseService,
    private globalservice: GlobalService
  ) {
    this.getReportDataList();
    this.data = new wjcCore.CollectionView(this.reportDataList);
    this.data.pageSize = this.pageInfo.pageSize;
  }

  ngAfterViewChecked(): void {
    //To add aria-controls to the year and month dropdowns
    let ariaControlsFirmSelect = document.querySelector('#myFirmSelect');
    ariaControlsFirmSelect.setAttribute('aria-controls', 'myFirmSelect');
  }

  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 50;
      flexGrid.columnHeaders.rows.defaultSize = 40;
      // flexGrid.select(-1,-1);
    }
    flexGrid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });

    this.flexGrid = flexGrid;
  }
  focusOnGrid(flexGrid, event) {
    flexGrid.select(0, 0);
  }

  openChangeEvent(isOpen: any) {
    if (isOpen) {
      this.currentSelectedFirm = this.selectedFirm;
      this.selectedFirm = null;
    } else {
      if (this.searchString == '') {
        if (this.selectedFirm == null) {
          //this.selectedFirm = {id: 'all', name: 'All'};
          this.selectedFirm = this.currentSelectedFirm;
        }
      }
    }
  }

  onFirmChange() {
    this.searchString = '';
    this.getReportDataList();
  }
  onPeriodChange() {
    this.searchString = '';
    this.getReportDataList();
  }

  onPageSizeChanged(size) {
    this.data.pageSize = size;
    this.flexGrid.select(this.flexGrid.rows.length - 1, this.flexGrid.columns.length - 1);
    //this.tkHistoryRecordRequest.size = size;
    this.pageInfo.pageSize = size;
  }

  // Pagination Page Changed Event Handler
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
  }

  ngOnInit(): void {
    this.getRatesPeerGroupNames();
    this.getAllFirms();
  }
  getAllFirms() {
    this.isGetDataLoading = true;
    this._service.get(environment.FIAdminBaseEndpoint + 'v1/firms', '').subscribe((data) => {
      this.isGetDataLoading = false;
      this.firmsArr = new BehaviorSubject(
        (() => {
          const a = [];
          a.push({id: 'all', name: 'All'});
          for (let i = 0; i < data.firms.length; i++) {
            a.push(data.firms[i]);
          }
          return a;
        })()
      );
    });
  }
  onReportsClick() {
    this.openNewReport = !this.openNewReport;
  }

  onPeerGroupSelected(peerGroups: any[]) {
    this.ratesReportRequest.peerGroup = [];
    if (peerGroups.length == 1) {
      this.ratesReportData.selectedPeerGroupText = '1 peer group selected';
    } else if (peerGroups.length == this.ratesReportRequest.peerGroup.length) {
      this.ratesReportData.selectedPeerGroupText = 'All';
    } else this.ratesReportData.selectedPeerGroupText = peerGroups.length.toString() + ' peer groups selected';
    for (var peerGroup of peerGroups) {
      this.ratesReportRequest.peerGroup.push(peerGroup);
    }
  }

  getRatesPeerGroupNames() {
    //this.ratesReportData.peerGroup = ['London', 'Peer Monitor AU Big 8'];
    this.ratesReportData.peerGroupStream = new BehaviorSubject(
      (() => {
        const a = ['London', 'Peer Monitor AU Big 8'];
        //this.ratesReportData.peerGroup = a;
        return a;
      })()
    );
    //this.ratesReportData.peerGroup ;
    //this.ratesReportData.selectedPeerGroupText = 'All';
  }

  getReportDataList() {
    this.isGetDataLoading = true;
    var reportQueryString = '';
    if (this.selectedFirm.id != 'all' && this.selectedPeriod != 'all') {
      reportQueryString = '?firmId=' + this.selectedFirm.id + '&period=' + this.selectedPeriod;
    } else if (this.selectedFirm.id != 'all' && this.selectedPeriod == 'all') {
      reportQueryString = '?firmId=' + this.selectedFirm.id;
    } else if (this.selectedFirm.id == 'all' && this.selectedPeriod != 'all') {
      reportQueryString = '?period=' + this.selectedPeriod;
    }

    this._service.get(environment.FIAdminBaseEndpoint + 'v1/ratesReport/fileList' + reportQueryString, '').subscribe(
      (result) => {
        this.isGetDataLoading = false;
        this.reportDataList = result;
        this.data = new wjcCore.CollectionView(this.reportDataList);
        this.data.pageSize = this.pageInfo.pageSize;

        //added for skip 2 focus betno combobox for accessibility fix
        this.globalservice.skipFocusTwiceCombobox();

        //added for pagination additional information announcing for accessibility fix
        this.globalservice.addPaginationAdditionalInfo();
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: this.errorMessage,
          timeout: 2500,
          closeable: true,
        });
      }
    );
  }

  reportDownload(reportName) {
    var extension = 'xlsx';
    let params = new HttpParams();
    params = params.set('blobName', reportName);
    params = params.set('fileName', reportName);

    this._service.downloadExcel(environment.FIAdminBaseEndpoint + 'v1/storage/downloadFile?' + params).subscribe(
      (response) => {
        this.downloadFile.downloadWithExtension(
          response.body,
          response.headers.get('content-disposition').split('=')[1],
          extension
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          timeout: 2500,
          closeable: true,
        });
      }
    );
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    this.gridsSelectedCol = args.col;
    this.gridsSelectedRow = args.row;
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        const windowHeight = window.innerHeight;
        const documentHeight = document.body.scrollHeight;
        const scrollTop = (documentHeight - windowHeight) / 2;
        window.scrollTo({
          top: scrollTop + 56,
          behavior: 'smooth',
        });
      }
    }
  }
  handleKeyDown(flexGrid: wjcGrid.FlexGrid, e) {
    if (e.code === 'Space') {
      e.preventDefault();
      if (this.gridsSelectedCol == 2) {
        let cell = flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
        let input = cell.querySelector('input');
        input.click();
      }
    }
  }
}
