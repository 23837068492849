<bento-alert [items]="alerts"></bento-alert>
<div class="GlobalLayout staffing-ratio-add-peer-groups" >
  <main id="MainContent" role="main" tabindex="-1" class="Main" [bentoBusyLoader]="isDataLoading">
    <header class="Main-header">
      <app-global-breadcrumbs
        [isFirmPeerGroup]="true"
        [firmName]="'Pre-set peer groups'"
        [peerLabel]="isEditOrAddPeerGroup ? selectedStaffingRatioPeerGroup.peerGroupName : 'Add peer group'"
        (updateService)="updateServiceHandler()"
      >
      </app-global-breadcrumbs>
      <h1 class="Main-heading" id="addPeerGroupHeader">
        {{ isEditOrAddPeerGroup ? selectedStaffingRatioPeerGroup.peerGroupName : 'Add peer group' }}
        <span class="survey-year-label">{{ '(' }}Survey Year: {{ currentSurveyYear }}{{ ')' }}</span>
      </h1>
      <span class="label-required-summary">Required field</span>
      <form bfmForm name="customPeerGroup" class="Form sr-peergroup-firms">
        <div class="form-group Form-input Form-input--medium">
          <label for="peerGroupName" class="bento-label-required">Peer group name</label>
          <input
            type="text"
            name="peerGroupName"
            id="peerGroupName"
            class="form-control"
            bfmField
            bfmLabel="Peer group name"
            required
            [(ngModel)]="peerGroupName"
            (ngModelChange)="verifySelection()"
          />
        </div>
        <p class="note-info">
          <span class="note-1">Note - Select minimum {{ mandatorySelectionCount }} firms</span>
          <span class="grid-note">
            Note - Press <span class="shortcut">Alt + Shift + Down Arrow </span> to see filter options for selected
            column.</span
          >
        </p>
      </form>
      <div class="row">
        <div class="col">
          <span class="sr-total-firms" role="status">Showing {{ currentRecords }} of {{ totalRecords }}</span>
        </div>
        <div class="col-sm-6 sr-selected-btn-sec">
          <span class="">
            <button
              *ngIf="peerGroupStatus.isShowSelectedFirms"
              class="btn btn-primary"
              (click)="selectedFirms(true, searchText)"
            >
              Show Selected Firms
            </button>
            <button
              *ngIf="peerGroupStatus.isShowAllFirms"
              class="btn btn-primary"
              (click)="selectedFirms(false, searchText)"
            >
              Show All Firms
            </button>
          </span>
        </div>
        <div class="col">
          <div class="Actions justify-content-end">
            <div class="Actions-item Actions-item--large">
              <span class="bento-search">
                <label for="firmPeerGroup" class="sr-only">Search firm name</label>
                <input
                  type="text"
                  name="firmPeerGroup"
                  id="firmPeerGroup"
                  class="form-control"
                  (keyup.enter)="searchGlobalPeerGroups()"
                  [(ngModel)]="searchText"
                />
                <button type="button" class="btn btn-primary" (click)="searchGlobalPeerGroups()">
                  <span class="btn-text">Search</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="Main-body sr-add-firms sr-add-firms-grid-main">
      <wj-flex-grid
        #flexGrid
        class="DataGrid DataGridFlexScroll"
        [class.list-grid]="false"
        [itemsSource]="data"
        [stickyHeaders]="true"
        [showMarquee]="true"
        [showSort]="true"
        [allowDragging]="false"
        [autoRowHeights]="true"
        (loadedRows)="initLoadedRow(flexGrid)"
        [headersFocusability]="'All'"
        [virtualizationThreshold]="data.totalItemCount"
        (initialized)="gridInitialized(flexGrid, 'addPeerGroupHeader')"
        [autoGenerateColumns]="false"
      >
        <wj-flex-grid-filter
          #filter
          (initialized)="initFilter()"
          (filterChanged)="onFilterChange()"
          (filterApplied)="onFilterApply()"
        ></wj-flex-grid-filter>
        <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'" let-col="col">
          <ng-container *ngIf="col.index === 0">
            <bento-checkbox
              id="selectAll"
              [ngModel]="isAllSelected()"
              (change)="onHeaderCheckBoxChange()"
              [indeterminate]="isIndeterminate()"
            ></bento-checkbox>
            <label for="selectAll" class="sr-only">Select all</label>
          </ng-container>
        </ng-template>
        <ng-template wjFlexGridCellTemplate [cellType]="'RowHeader'" let-col="col" let-row="row" let-item="item">
          <ng-container *ngIf="col.index === 0">
            <bento-checkbox
              id="selectRow-{{ row.index }}"
              [ngModel]="item.selected"
              (change)="item.disabled || (item.selected = !item.selected); onCheckBoxChange()"
            >
            </bento-checkbox>
            <label for="selectRow-{{ row.index }}" class="sr-only" [attr.aria-label]="item.firmName">{{
              item.firmName
            }}</label>
          </ng-container>
        </ng-template>
        <wj-flex-grid-column
          [header]="'Firm name'"
          [binding]="'firmName'"
          [visible]="true"
          [width]="'*'"
          [align]="'left'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Main office country'"
          [binding]="'mainOfficeCountry'"
          [minWidth]="200"
          [width]="200"
          [visible]="true"
          [align]="'left'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Rank (AmLaw)'"
          [binding]="'amLawRank'"
          [dataType]="2"
          [format]="'f'"
          [minWidth]="150"
          [width]="150"
          [visible]="true"
          [align]="'right'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Lawyers'"
          [binding]="'lawyers'"
          [dataType]="2"
          [format]="'f'"
          [minWidth]="150"
          [width]="150"
          [visible]="true"
          [align]="'right'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
        <wj-flex-grid-column
          [header]="'Offices'"
          [binding]="'offices'"
          [dataType]="2"
          [format]="'f'"
          [minWidth]="150"
          [width]="150"
          [visible]="true"
          [align]="'right'"
          [isReadOnly]="true"
          [wordWrap]="true"
        ></wj-flex-grid-column>
      </wj-flex-grid>
    </div>
    <footer class="Main-footer sr-add-firms-footer" *ngIf="!hideFooter">
      <div class="Actions">
        <div class="Actions-item">
          <button type="button" class="btn btn-outline-secondary" (click)="handleButtonAction('Cancel')">
            <span class="btn-text">Cancel</span>
          </button>
        </div>
        <div class="Actions-item" *ngIf="isEditOrAddPeerGroup">
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="removeChartWithConfirmation(removeModalContent)"
          >
            <span class="btn-text">Delete</span>
          </button>
        </div>
        <div class="Actions-item a11y">
          <button
            aria-live="polite"
            type="button"
            class="btn btn-primary"
            (click)="handleButtonAction('Save')"
            [attr.aria-disabled]="!isReadyToSave"
          >
            <span class="btn-text">Save</span>
          </button>
        </div>
      </div>
    </footer>
  </main>
</div>
<ng-template #removeModalContent let-c="close" let-d="dismiss">
  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <i class="bento-icon-close-x" aria-hidden="true"></i>
  </button>
  <h2 id="modalViewRemove" class="modal-header h2">Delete</h2>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <p>
            Click on Delete to delete
            <b>{{ selectedStaffingRatioPeerGroup.peerGroupName ? selectedStaffingRatioPeerGroup.peerGroupName : '' }}</b
            >. Click Cancel to go to previous screen.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-common-action-btns-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="c('cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="c('confirm')">Delete</button>
  </div>
</ng-template>
