<main id="MainContent" tabindex="-1" class="Main" [bentoBusyLoader]="isGetDataLoading">
  <bento-alert [items]="alerts"></bento-alert>
  <div *ngIf="!openNewReport" class="Actions justify-content-center">
    <div>
      <label for="myFirmSelect">Firm</label>
    </div>
    <div class="ml-10">
      <bento-combobox
        id="myFirmSelect"
        [(ngModel)]="selectedFirm"
        (ngModelChange)="onFirmChange()"
        (openChange)="openChangeEvent($event)"
        [itemsObservable]="firmsArr"
        [options]="comboboxOptions"
        class="width-class"
        [rowTemplate]="myFirmSelect"
      ></bento-combobox>
      <ng-template #myFirmSelect let-$data="data" let-$options="options" let-$index="index">
        <div>
          {{ $data.row.name }}
        </div>
      </ng-template>
    </div>
    <!-- <select id="my-select" bentoSelect name="mySelect" [(ngModel)]="selectedFirm" class="form-control">
      <input type="text" placeholder="search" class="border-class" />
      <option value="all">All</option>
      <option *ngFor="let firm of firmsArr" [value]="firm.id">{{ firm.name }}</option>
    </select> -->

    <div class="ml-10">
      <label for="myPeriodSelect">Period</label>
    </div>
    <div class="ml-10">
      <select
        id="myPeriodSelect"
        [(ngModel)]="selectedPeriod"
        (ngModelChange)="onPeriodChange()"
        bentoSelect
        name="myPeriodSelect"
        class="form-control"
      >
        <option value="all">All</option>
        <option value="DAY">Day</option>
        <option value="WEEK">Week</option>
        <option value="MONTH">Month</option>
        <option value="MONTH6">6 Month</option>
        <option value="YEAR">Year</option>
      </select>
    </div>
    <div class="Actions-item Actions-item--large">
      <button type="button" class="btn btn-outline-secondary" (click)="onReportsClick()">
        <span class="btn-text">New report</span>
      </button>
    </div>
  </div>
  <div *ngIf="!openNewReport" class="mt-20 Actions justify-content-center">
    <wj-flex-grid
      #flexGrid
      class="DataGrid DataGridFlexScroll widthClass btn-addreport"
      [itemsSource]="data"
      [stickyHeaders]="true"
      [showMarquee]="true"
      [showSort]="true"
      [selectionMode]="'Cell'"
      [autoRowHeights]="true"
      [headersVisibility]="'Column'"
      [virtualizationThreshold]="data.totalItemCount"
      (initialized)="gridInitialized(flexGrid)"
      (keydown)="handleKeyDown(flexGrid, $event)"
      (selectionChanged)="handleSelectionChanged(flexGrid, $event)"
    >
      <wj-flex-grid-column
        [header]="'File name'"
        [binding]="'reportName'"
        [visible]="true"
        [width]="450"
        [isReadOnly]="true"
        [wordWrap]="true"
      ></wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'File date'"
        [binding]="'reportCompletedDate'"
        [visible]="true"
        [width]="350"
        [isReadOnly]="true"
        [wordWrap]="true"
      ></wj-flex-grid-column>
      <wj-flex-grid-column
        [header]="'Action'"
        [width]="300"
        [binding]="'id'"
        [align]="'center'"
        [visible]="true"
        [isReadOnly]="true"
      >
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row" let-col="col">
          <ng-container *ngIf="item.reportStatus == 'COMPLETED'; then completeReport else incompleteReport"></ng-container>
          <ng-template #completeReport>
            <input
            type="button"
            class="btn btn-primary"
            (click)="reportDownload(item.reportName)"
            value="Download"
           (focus) = "handleFocus(row.index,col.index)"
            />
              
            
          </ng-template>
          <ng-template #incompleteReport>Error: File unavailable</ng-template>
          
        </ng-template>
      </wj-flex-grid-column>
    </wj-flex-grid>
  </div>
  <div *ngIf="!openNewReport">
    <bento-pagination
      #pagination
      [page]="data.pageIndex + 1"
      [directionLinks]="true"
      [boundaryLinks]="true"
      [totalItems]="data.totalItemCount"
      [(itemsPerPage)]="data.pageSize"
      (itemsPerPageChange)="onPageSizeChanged($event)"
      (pageChange)="onPageChanged($event)"
    ></bento-pagination>
  </div>
</main>

<app-new-rates-report (reportsClick)="onReportsClick()" *ngIf="openNewReport"></app-new-rates-report>
