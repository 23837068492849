import {Component, EventEmitter, Input, Output, OnInit, Inject} from '@angular/core';
import {BentoAlertItemOptions, BentoModalConfirmationService, BentoModalConfirmationCloseReason} from '@bento/bento-ng';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {AuthService} from '../../../services/auth/auth.service';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import UserModel from 'src/app/core/models/user.model';
import {currencyValues, fAdminAddUserRole, pmmUserValues, tAdminAddUserRole} from 'src/app/shared/constants';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {DOCUMENT} from '@angular/common';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
declare let TRAAC: any;

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent implements OnInit {
  isEditUser = false;
  isAddUser = false;
  userData: UserModel = new UserModel();
  resetUserData: UserModel = new UserModel();
  clearUserData: UserModel = new UserModel();
  alerts: BentoAlertItemOptions[] = [];
  currencyValues: any;
  roleValues = this.isTAdmin ? tAdminAddUserRole : fAdminAddUserRole;
  pmmUserValues = pmmUserValues;
  isSaving = false;
  isDeleting = false;
  isQuickSightEnabled = false;
  showApiUser: any;

  @Input() firmId: number;
  @Input() selectedUserData: any;
  @Output() handleUserAction = new EventEmitter<string>();
  @Output() enableAllUserSideNav = new EventEmitter<UserModel>();
  newzealandCurrency: boolean;
  errorMessage: any;

  constructor(
    private modalService: BentoModalConfirmationService,
    private authService: AuthService,
    private service: BaseService,
    private featureToggleService: FeatureToggleService,
    public auth0: Auth0Service,
    @Inject(DOCUMENT) private doc: Document
  ) {
    store.subscribe((state) => {
      const {userDetail} = state;
      if (userDetail) {
        this.userData = Object.assign({}, userDetail);
      }
    });
  }

  async ngOnInit() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /***********************************************************/

    eventDispatcher.next({type: ActionTypes.GetUserDetails});
    this.getNewzealandCurrencyFeatureFlag();
    this.userData.firmId = this.firmId;
    if (this.userData.isStaffingratioUser == null) {
      this.userData.isStaffingratioUser = false;
    }
    if (this.userData.isApiUser == null) {
      this.userData.isApiUser = false;
    }
    this.resetUserData = JSON.parse(JSON.stringify(this.userData));
    if (this.selectedUserData) {
      this.isEditUser = true;
    } else this.isAddUser = true;

    this.showApiUser = await this.getUserApiFeatureFlag();
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  editUserInfo() {
    this.isSaving = true;
    const userData = Object.assign({}, this.userData);
    delete userData.userId;
    this.service.put(environment.FIAdminBaseEndpoint + 'v1/user/' + this.userData.userId, userData).subscribe(
      (result) => {
        eventDispatcher.next({type: ActionTypes.SetUserDetail, payload: this.userData});
        this.resetUserData = JSON.parse(JSON.stringify(this.userData));
        this.isSaving = false;
        this.alerts.push({
          type: 'success',
          msg: 'Data saved successfully.',
          timeout: 2500,
          closeable: true,
        });
        if (this.isSelfUser) {
          setTimeout(() => {
            this.auth0.logout({returnTo: this.doc.location.origin});
          }, 2500);
        }
      },
      (error) => {
        let errorMessage: string;
        if (error.status == 400) {
          if (error.error && error.error.messages) {
            let message = '';
            error.error.messages.forEach((element) => {
              message += '<div>' + element.fieldName + ': ' + element.message + '</div>';
            });
            errorMessage = message;
          } else {
            errorMessage = error.error.message;
          }
        } else {
          errorMessage = 'Something went wrong. Please try again.';
        }

        if (
          errorMessage &&
          errorMessage.includes('Email should be same with CIAM Profile for internal user with User ID')
        ) {
          errorMessage = 'Email should be the same.';
        }

        if (errorMessage && errorMessage.includes('TR Email Domain is not applicable for external user with User ID')) {
          errorMessage = 'TR Email Domain is not applicable.';
        }

        this.alerts.push({
          type: 'warning',
          msg: errorMessage,
          closeable: true,
        });
        this.isSaving = false;
      }
    );
  }

  getUserApiFeatureFlag(): any {
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/APIUSER', '')
      .toPromise()
      .then((result) => {
        return result['APIUSER'];
      });
  }

  saveUserInfo() {
    this.userData.firmId = this.firmId;

    if (this.userData && this.userData.userId) {
      if (this.isSelfUser) {
        this.confirmEdit();
      } else {
        this.editUserInfo();
      }
    } else {
      this.isSaving = true;
      if (this.isQuickSightEnabled) {
        TRAAC.track(TRAAC.keyValue('Add a User', {email: this.authService.getUserEmail()}).build());
      }
      this.service.post(environment.FIAdminBaseEndpoint + 'v1/user', this.userData).subscribe(
        (result) => {
          this.userData.userId = result.userId;
          this.enableAllUserSideNav.emit(this.userData);
          eventDispatcher.next({type: ActionTypes.SetUserDetail, payload: this.userData});
          this.resetUserData = JSON.parse(JSON.stringify(this.userData));
          this.isSaving = false;
          this.isAddUser = false;
          this.isEditUser = true;
          this.alerts.push({
            type: 'success',
            msg: 'Data saved successfully.',
            timeout: 2500,
            closeable: true,
          });
        },
        (error) => {
          let errorMessage: string;
          if (error.status == 400) {
            if (error.error && error.error.messages) {
              let message = '';
              error.error.messages.forEach((element) => {
                message += '<div>' + element.fieldName + ': ' + element.message + '</div>';
              });
              errorMessage = message;
            } else {
              errorMessage = error.error.message;
            }
          } else {
            errorMessage = 'Something went wrong. Please try again.';
          }

          this.alerts.push({
            type: 'warning',
            msg: errorMessage,
            closeable: true,
          });
          this.isSaving = false;
        }
      );
    }
  }

  get isSelfUser(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return (
      backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.userID === this.userData.userId
    );
  }

  closeUserInfo() {
    if (typeof this.clearUserData !== 'undefined') {
      this.userData = JSON.parse(JSON.stringify(this.clearUserData));
    }
    eventDispatcher.next({type: ActionTypes.SetUserDetail, payload: this.userData});
    let option = {message: 'Return', selectedOption: null};
    this.handleUserAction.emit(JSON.stringify(option));
  }

  confirmEdit() {
    const message = 'Application will redirect you to login after changes are successfully saved.';
    this.modalService
      .open(message, {
        titleText: 'Edit user',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeButtonText: 'Close',
      })
      .result.then(
        (result: BentoModalConfirmationCloseReason) => {
          if (result === 'Confirm') {
            this.editUserInfo();
          }
        },
        (reason) => {}
      );
  }

  deleteUser() {
    const deleteUser =
      'You are about to delete ' + this.resetUserData.firstName + ' ' + this.resetUserData.lastName + '.';
    this.displayError(deleteUser, 'Delete', 'Cancel');
  }

  displayError(message, confirmMessage = 'OK', cancelMessage = 'CANCEL') {
    this.modalService
      .open(message, {
        titleText: 'Delete user',
        confirmButtonText: confirmMessage,
        cancelButtonText: cancelMessage,
        closeButtonText: 'Close',
        buttonType: 'danger',
      })
      .result.then(
        (result: BentoModalConfirmationCloseReason) => {
          if (confirmMessage === 'Delete' && result === 'Confirm') {
            this.isDeleting = true;
            this.service.delete(environment.FIAdminBaseEndpoint + 'v1/user/', this.userData.userId).subscribe(
              (result) => {
                this.isDeleting = false;
                let option = {message: 'Deleted', selectedOption: null};
                this.handleUserAction.emit(JSON.stringify(option));
              },
              (error) => {
                this.isDeleting = false;
                this.alerts.push({
                  type: 'warning',
                  msg: 'Something went wrong. Please try again.',
                  closeable: true,
                });
              }
            );
          }
        },
        (reason) => {}
      );
    setTimeout(() => {
      let ariaRoleAdd = document.querySelector('.close');
      ariaRoleAdd.setAttribute('aria-label', 'Close Dialog');
    }, 100);
  }

  getCurrency() {
    if (this.newzealandCurrency) {
      this.service.get(environment.FIAdminBaseEndpoint + 'v1/currency', '').subscribe(
        (result) => {
          this.currencyValues = result;
        },
        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
    } else {
      this.currencyValues = currencyValues;
    }
  }

  getNewzealandCurrencyFeatureFlag() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - NEWZEALAND_CURRENCY ******/
    /**Remove the call to feature API ***** */
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/NEWZEALAND_CURRENCY', '').subscribe((result) => {
      this.newzealandCurrency = result['NEWZEALAND_CURRENCY'];
      this.getCurrency();
    });
  }

  onIsSRUserChange(isSRUser) {
    console.log(isSRUser);
    if (isSRUser) {
      this.userData.currency = 'USD';
    }
  }
}
