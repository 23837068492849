<header class="global-header global-header-text Header" role="banner">
  <div class="topbar-left">
    <a
      [routerLink]="[mainHeaderLink]"
      (click)="refreshPeerCheckData()"
      class="topbar-item topbar-logo Header-logo"
      aria-label="Thomson Reuters Financial Insights"
    >
      <app-svg-logo></app-svg-logo>
    </a>
  </div>
  <ul class="topbar-right">
    <li
      class="topbar-item Header-item Header-item--medium"
      *ngIf="
        (router.url.indexOf('current-views') > 0 ||
          router.url.indexOf('create-view') > 0 ||
          router.url.indexOf('visualizations') > 0 ||
          router.url.indexOf('new-visualization') > 0 ||
          router.url === '/' ||
          router.url.indexOf('firm/') > 0) &&
        isTAdmin
      "
    >
      <!-- <div [bentoBusyLoader]="isDataLoading" [size]="'sm'"></div>    -->
      <label for="headerSelectFirm" class="sr-only">Select firm</label>
      <bento-combobox
        id="headerSelectFirm"
        class="HeaderSelectFirm"
        [(ngModel)]="selectedFirm"
        [itemsObservable]="dataStream"
        [options]="comboboxOptions"
        [placeholder]="false"
        [rowTemplate]="firmTemplate"
        (endOfScroll)="didScroll($event)"
        (search)="didSearch($event)"
        (resetItems)="didReset()"
        (ngModelChange)="firmSelectionChanged()"
        aria-controls="headerSelectFirm"
        [attr.aria-autocomplete]="'both'"
      ></bento-combobox>
      <ng-template #firmTemplate let-$data="data" let-$options="options" let-$index="index">
        <span class="HeaderSelectFirm-ID">{{ $data.row.id }}</span
        ><span class="HeaderSelectFirm-name sr-only">{{ $data.row.name }}</span>
      </ng-template>
    </li>
    <li *ngIf="!isNoAccessUser" class="topbar-item Header-item Header-item--small">
      <a
        class="btn btn-secondary btn-ghost"
        href="https://marketinsights.thomsonreuters.com"
        target="_blank"
        (click)="trackMarketInsightsLinkClickEvent()"
      >
        Market Insights
        <span class="sr-only">&nbsp; opens in a new window</span>
      </a>
    </li>
    <li
      class="topbar-item dropdown Header-item"
      [ngClass]="
        (router.url.indexOf('current-views') > 0 ||
          router.url.indexOf('create-view') > 0 ||
          router.url.indexOf('visualizations') > 0 ||
          router.url.indexOf('new-visualization') > 0 ||
          router.url === '/' ||
          router.url.indexOf('firm/') > 0) &&
        isTAdmin
          ? 'usermenu-dropdown-item'
          : ''
      "
      ngbDropdown
      #drop
      *ngIf="userName"
    >
      <button
        ngbDropdownToggle
        type="button"
        id="selectFirmButton"
        class="btn btn-flat-secondary btn-ghost"
        aria-haspopup="true"
        aria-controls="selectFirmDropdown"
        (click)="checkDropDown(drop)"
      >
        <span class="btn-text username-text">{{ userName }}</span>
        <i
          [ngClass]="dropdown && dropdown.isOpen() ? 'bento-icon-close-circle' : 'bento-icon-caret-down-filled'"
          aria-hidden="true"
        ></i>
      </button>
      <div
        ngbDropdownMenu
        id="selectFirmDropdown"
        class="dropdown-menu account-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="selectFirmButton"
      >
        <ul>
          <li *ngIf="isTAdmin || isFAdmin">
            <a ngbDropdownItem [routerLink]="isTAdminUser" (click)="trackAdministratorClickEvent()">Administrator</a>
          </li>
          <li>
            <a
              ngbDropdownItem
              *ngIf="
                showExportFirmMapping &&
                  !isNoAccessUser &&
                  (router.url === '/' ||
                    router.url.includes('current-views') ||
                    router.url.includes('create-view') ||
                    router.url.includes('new-visualization'));
                else exportFirmsMap
              "
              (click)="exportFirmsMapClickEvent(true)"
              href="javascript:void(0);"
              tabindex="0"
              >Firm's map (XLS)</a
            >
            <ng-template #exportFirmsMap>
              <a
                ngbDropdownItem
                *ngIf="showExportFirmMapping && !isNoAccessUser"
                href="javascript:void(0);"
                tabindex="0"
                (click)="exportFirmsMapClickEvent(false)"
                >Firm's map (XLS)</a
              >
            </ng-template>
          </li>
          <li>
            <a
              ngbDropdownItem
              *ngIf="
                showExportPeerGroups &&
                  !isNoAccessUser &&
                  (router.url === '/' ||
                    router.url.includes('current-views') ||
                    router.url.includes('create-view') ||
                    router.url.includes('new-visualization'));
                else exportPG
              "
              (click)="exportPeerGroupsClickEvent(true)"
              href="javascript:void(0);"
              tabindex="0"
              >Export peer groups (XLS)</a
            >
            <ng-template #exportPG>
              <a
                ngbDropdownItem
                *ngIf="showExportPeerGroups && !isNoAccessUser"
                href="javascript:void(0);"
                tabindex="0"
                (click)="exportPeerGroupsClickEvent(false)"
                >Export peer groups (XLS)</a
              >
            </ng-template>
          </li>
          <li>
            <a ngbDropdownItem [routerLink]="['/contact-us']" (click)="trackContactUsClickEvent()">Contact us</a>
          </li>
          <li>
            <a ngbDropdownItem href="https://www.thomsonreuters.com/en/privacy-statement.html" target="_blank"
              >Privacy policy</a
            >
          </li>
          <li>
            <a ngbDropdownItem  href="javascript:void(0);" (click)="signOut()" tabindex="0"> Sign out</a>
          </li>
        </ul>
        <!-- <div class="copyright">&copy; Thomson Reuters</div> -->
      </div>
    </li>
  </ul>
</header>
<div>
  <bento-toolbar
    class="global-subnav GlobalSubNav"
    aria-label="Main"
    [items]="administratorData"
    *ngIf="
      router.url === 'administrator' ||
      router.url.includes('administrator/addfirm') ||
      router.url.includes('administrator/firms') ||
      router.url.includes('administrator/peer-groups') ||
      router.url.includes('administrator/staffing-peer-groups') ||
      router.url.includes('administrator/staffing-custom-segment') ||
      router.url.includes('administrator/staffingratio-configurations')
    "
  ></bento-toolbar>
  
  <bento-toolbar
    #globalSubnav
    class="global-subnav"
    aria-label="Main"
    [items]="peerCheckData"
    *ngIf="isPeerCheckData"
   
  >
  </bento-toolbar>
  <ng-container *ngIf="isTAdmin">
    <bento-toolbar
      #globalSubnav
      class="global-subnav"
      aria-label="Main"
      [items]="peerCheckData"
      *ngIf="isPeerCheckDataTadmin"
    >
    </bento-toolbar>
  </ng-container>
</div>
