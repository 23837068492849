<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" role="main" class="Main  landing-page" [bentoBusyLoader]="isDataLoading" *ngIf="!isNoAccessUser">
  <header class="Main-header">
    <div class="row mb-1">
      <div class="col">
        <h1 id="pageHeader" tabindex="-1" class="Main-heading">Welcome, <span class="btn-text username-text">{{ userName }}</span></h1>
      </div>
      <div class="col">
        <div class="Actions justify-content-end">
          <div class="Actions-item-container" *ngIf="isDataLoading === false">
            <app-actions-menu
              #actionButton
              [actionMenuItems]="actionMenuItems"
              (menuSelectChange)="menuOnSelectChange($event)"
            ></app-actions-menu>
          </div>
          
        </div>
      </div>
    </div>
    <p>Below is your default personalized dashboard, showcasing key metrics and peer group insights.</p>
  </header>
  <div *ngIf="isFirmBasicLoaded" class="Main-body Main-body--viewgrid">
    <app-view-data-grid
      #ViewGrid
      [userSettingsModel]="userSettingsModel"
      [firmBasicData]="firmBasicData"
      [pcViewCols]="pcViewCols"
      [viewDetails]="viewDetails"
      [shouldCardDisplay]="true"
      [shouldDisplayPivot]="true"
      [allowViewSettings]="true"
      [showDescription]="false"
      [shouldCheckMetricGroup]="false"
      [showCustomDescription]="true"
      [isTAdmin]="isTAdmin"
      (dataLoadingStateChange)="dataLoadingStateChange($event)"
      (dragDone)="onDragDone($event)"
    ></app-view-data-grid>
  </div>
</main>
