<style nonce="cssRand0m">
  .Section-heading::first-letter {
    text-transform: uppercase;
  }
</style>

<bento-alert [items]="alerts" (close)="closeAlert($event)"></bento-alert>
<section class="Section" [bentoBusyLoader]="isInformationLoading">
  <header class="Section-header">
    <h2 class="Section-heading">{{ permissionName }}</h2>
  </header>
  <div class="Section-body Transferbox" *ngIf="isGetDataloading">
    <div class="row Transferbox-header">
      <div class="col">
        <h3>Restricted {{ permissionName }}</h3>
      </div>
      <div class="col">
        <h3>Firm visible {{ permissionName }}</h3>
      </div>
    </div>
    <div class="Transferbox-body transfer">
      <bento-transferbox-basic
        #transferbox
        [boxOneItemSource]="dataOne"
        [boxTwoItemSource]="dataTwo"
        [boxOneTitle]="'Available items'"
        [boxTwoTitle]="'Available items'"
        [columnDefinitions]="gridOptions"
        (transfer)="onTransfer($event)"
        [columnKey]="columnKey"
        [height]="gridHeight"
        [hidePagination]="hidePagination"
        (keydown)="onKeyDown($event)"
      ></bento-transferbox-basic>
    </div>
  </div>
  <footer class="Section-footer">
    <div class="Actions">
      <div class="Actions-item">
        <button type="button" class="btn btn-outline-secondary" (click)="cancelClick()">
          <span class="btn-text">Cancel</span>
        </button>
      </div>
      <div class="Actions-item">
        <button type="submit" class="btn btn-primary" (click)="saveMetricInfo()" [disabled]="isVisiblePermissionsEmpty">
          <span class="btn-text">Save</span>
        </button>
      </div>
    </div>
  </footer>
</section>
