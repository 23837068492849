<bento-alert [items]="alerts"></bento-alert>
<div class="ViewPanel" *ngIf="!isDataLoading">
  <section class="ViewPanel-section chart-visualization">
    <header class="ViewPanel-header">
      <div class="row">
        <div class="col-10">
          <h1 class="h2 ChartPanel-header--name" id="visualizationDataChart">{{ createChartModel.name }}</h1>
          <span class="sr-only" id="visualizationDataChartName"
            >{{ createChartModel.name }} Grid {{ rowCount }} rows {{ colCount }} columns</span
          >
        </div>
        <div *ngIf="shouldShowSettingButton" class="Actions-item">
          <button
            type="button"
            class="btn btn-outline-secondary btn-icon btn-md Actions"
            (click)="openViewSettings()"
            aria-label="Settings"
            placement="right"
          >
            Settings &nbsp; <i class="bento-icon-settings" aria-hidden="true"></i>
          </button>
        </div>
        <div class="col" *ngIf="shouldShowActionButton && actionDropdownItems && actionDropdownItems.length">
          <div
            *ngIf="this.visualizationOption && this.visualizationOption == 'Visualization templates'"
            class="Actions justify-content-end"
          >
            <div class="Actions-item">
              <button
                type="button"
                class="btn btn-outline-secondary btn-icon btn-md"
                (click)="openViewSettings()"
                aria-label="Settings"
                placement="right"
              >
                Settings &nbsp; <i class="bento-icon-settings" aria-hidden="true"></i>
              </button>
            </div>
            <div class="Actions-item">
              <div ngbDropdown class="dropdown" placement="bottom-right">
                <button
                  ngbDropdownToggle
                  id="actionsDropDownButton"
                  aria-haspopup="true"
                  aria-controls="actionsDropDown"
                  class="btn btn-outline-secondary btn-icon btn-md"
                  type="button"
                >
                  <span class="btn-text">Export &nbsp;</span>
                  <i class="bento-icon-download" aria-hidden="true"></i>
                </button>
                <div
                  ngbDropdownMenu
                  id="actionsDropDown"
                  role="presentation"
                  aria-labelledby="actionsDropDownButton"
                  placement="bottom-right"
                >
                  <ng-container *ngFor="let item of exportDropdownItems">
                    <button
                      ngbDropdownItem
                      role="button"
                      [ngClass]="item && item.length ? 'dropdown-item-root' : 'dropdown-item'"
                      name="{{ item.name }}"
                      (click)="dropDownOnSelectChange($event.target.name)"
                    >
                      {{ item.name }}
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="Actions-item">
              <button type="button" (click)="onCreateVisualization()" class="btn btn-primary Actions-button">
                Create Visualization
              </button>
            </div>
            <!-- <div class="Actions-item">
              <app-action-dropdown
                #actionButton
                [actionDropdownItems]="actionDropdownItems"
                (dropDownSelectChange)="actionButtonSelectionHandler($event)"
              ></app-action-dropdown>
            </div> -->
          </div>
          <div
            *ngIf="this.visualizationOption && this.visualizationOption == 'My visualizations'"
            class="Actions justify-content-end"
          >
            <div class="Actions-item">
              <button
                type="button"
                class="btn btn-outline-secondary btn-icon btn-md"
                (click)="openViewSettings()"
                aria-label="Settings"
                placement="right"
              >
                Settings &nbsp; <i class="bento-icon-settings" aria-hidden="true"></i>
              </button>
            </div>
            <div class="Actions-item">
              <div ngbDropdown class="dropdown" placement="bottom-right">
                <button
                  ngbDropdownToggle
                  id="actionsDropDownButton"
                  aria-haspopup="true"
                  aria-controls="actionsDropDown"
                  class="btn btn-outline-secondary btn-icon btn-md"
                  type="button"
                >
                  <span class="btn-text">Export &nbsp;</span>
                  <i class="bento-icon-download" aria-hidden="true"></i>
                </button>
                <div
                  ngbDropdownMenu
                  id="actionsDropDown"
                  role="presentation"
                  aria-labelledby="actionsDropDownButton"
                  placement="bottom-right"
                >
                  <ng-container *ngFor="let item of exportDropdownItems">
                    <button
                      ngbDropdownItem
                      role="button"
                      [ngClass]="item && item.length ? 'dropdown-item-root' : 'dropdown-item'"
                      name="{{ item.name }}"
                      (click)="dropDownOnSelectChange($event.target.name)"
                    >
                      {{ item.name }}
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="Actions-item">
              <button type="button" (click)="onCreateVisualization()" class="btn btn-primary Actions-button">
                Edit/Copy
              </button>
            </div>
            <!-- <div class="Actions-item">
              <app-action-dropdown
                #actionButton
                [actionDropdownItems]="actionDropdownItems"
                (dropDownSelectChange)="actionButtonSelectionHandler($event)"
              ></app-action-dropdown>
            </div> -->
          </div>

          <!-- <div
            *ngIf="this.visualizationOption && this.visualizationOption == 'My visualizations'"
            class="Actions justify-content-end"
          >
            <div class="Actions-item">
              <app-action-dropdown
                #actionButton
                [actionDropdownItems]="actionDropdownItems"
                (dropDownSelectChange)="actionButtonSelectionHandler($event)"
              ></app-action-dropdown>
            </div>
          </div> -->
        </div>
      </div>
      <div class="bento-desc-list bento-desc-list--inline PeerMeta">
        <div class="displayCss">
          <div class="bento-desc-list-item w-30" *ngIf="vizTemplateDetail && createChartModel.selectedDateRangeType">
            <span class="dtCss">Range:</span>
            <div class="displayInline">
              <div class="headerClass ddCss" *ngIf="createChartModel.selectedDateRangeType.id == 1">
                Year to year monthly percent change: {{ extractDateFormat(createChartModel.currentPeriodStartDate) }} v.
                {{ extractDateFormat(createChartModel.priorPeriodStartDate) }}
              </div>
              <div class="headerClass ddCss" *ngIf="createChartModel.selectedDateRangeType.id == 2">
                Custom range comparison: compare {{ extractDateFormat(createChartModel.currentPeriodStartDate) }} -
                {{ extractDateFormat(createChartModel.currentPeriodEndDate) }} to
                {{ extractDateFormat(createChartModel.priorPeriodStartDate) }} -
                {{ extractDateFormat(createChartModel.priorPeriodEndDate) }}
              </div>
            </div>
          </div>
          <div class="bento-desc-list-item w-70" *ngIf="createChartModel && createChartModel.viewsSettings">
            <span class="dtCss">Settings:</span>
            <div class="displayInline">
              <div class="headerClass ddCss">
                Statistics - {{ createChartModel.viewsSettings.statisticsType | titlecase }}, Comparison -
                <ng-container *ngIf="createChartModel.viewsSettings.comparisonType != 'year over year'"
                  >{{ createChartModel.viewsSettings.comparisonType | titlecase }},</ng-container
                >
                <ng-container *ngIf="createChartModel.viewsSettings.comparisonType == 'year over year'"
                  >Year over year,
                </ng-container>
                Matter type -
                <ng-container *ngIf="createChartModel.viewsSettings.matterType != 'all'"
                  >{{ createChartModel.viewsSettings.matterType | titlecase }},</ng-container
                >
                <ng-container *ngIf="createChartModel.viewsSettings.matterType == 'all'"
                  >Billable and Contingent,
                </ng-container>
                Contractors - {{ createChartModel.viewsSettings.contractorsType | titlecase }}, Currency -
                {{ createChartModel.viewsSettings.currencyType | titlecase }}
              </div>
            </div>
          </div>
        </div>
        <div class="filterSecCss">
          <div class="dtCss">Filters -</div>
          <div
            class="bento-desc-list-item"
            *ngIf="createChartModel.selectedMetrics && createChartModel.selectedMetrics.length"
          >
            <span class="dtCss">Metric(s):</span>
            <span class="ddCss">
              {{ getDescription(createChartModel.selectedMetrics) }}
            </span>
          </div>
          <!-- <div
            class="bento-desc-list-item"
            *ngIf="createChartModel.selectedPeerGroups && createChartModel.selectedPeerGroups.length"
          >
            <dt>Peer Group(s):</dt>
            <dd>
              {{ getDescription(createChartModel.selectedPeerGroups) }}
            </dd>
          </div> -->
          <div
            class="bento-desc-list-item"
            *ngIf="createChartModel.selectedPracticeGroups && createChartModel.selectedPracticeGroups.length"
          >
            <span class="dtCss">Practice Group(s):</span>
            <span class="ddCss">
              {{ getDescription(createChartModel.selectedPracticeGroups) }}
            </span>
          </div>
          <div
            class="bento-desc-list-item"
            *ngIf="createChartModel.selectedOffices && createChartModel.selectedOffices.length"
          >
            <span class="dtCss">Office(s):</span>
            <span class="ddCss">
              {{ getDescription(createChartModel.selectedOffices) }}
            </span>
          </div>
          <div
            class="bento-desc-list-item"
            *ngIf="createChartModel.selectedTitles && createChartModel.selectedTitles.length"
          >
            <span class="dtCss">Title(s):</span>
            <span class="ddCss">
              {{ getDescription(createChartModel.selectedTitles) }}
            </span>
          </div>
          <div
            class="bento-desc-list-item"
            *ngIf="
              createChartModel &&
              ((createChartModel.selectedAssociateYears && createChartModel.selectedAssociateYears.length) ||
                (createChartModel.selectedSrAssociateYears && createChartModel.selectedSrAssociateYears.length) ||
                (createChartModel.selectedPartnerYears && createChartModel.selectedPartnerYears.length))
            "
          >
            <span class="dtCss">Experience(s):</span>
            <span class="ddCss">
              <ng-container
                *ngIf="createChartModel.selectedAssociateYears && createChartModel.selectedAssociateYears.length"
              >
                {{ getDescription(createChartModel.selectedAssociateYears)
                }}{{
                  (createChartModel.selectedSrAssociateYears && createChartModel.selectedSrAssociateYears.length) ||
                  (createChartModel.selectedPartnerYears && createChartModel.selectedPartnerYears.length)
                    ? ','
                    : ''
                }}
              </ng-container>
              <ng-container
                *ngIf="createChartModel.selectedSrAssociateYears && createChartModel.selectedSrAssociateYears.length"
              >
                {{ getDescription(createChartModel.selectedSrAssociateYears)
                }}{{ createChartModel.selectedPartnerYears && createChartModel.selectedPartnerYears.length ? ',' : '' }}
              </ng-container>
              <ng-container
                *ngIf="createChartModel.selectedPartnerYears && createChartModel.selectedPartnerYears.length"
              >
                {{ getDescription(createChartModel.selectedPartnerYears) }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </header>
    <div class="ViewPanel-body">
      <div class="row">
        <div class="col-11" *ngIf="vizTemplateDetail && createChartModel.selectedDateRangeType">
          <h2
            id="ChartPanel-header-name-id"
            class="ChartPanel-header--name h3"
            *ngIf="createChartModel.selectedDateRangeType.id == 1"
          >
            Year to year monthly percent change: {{ extractDateFormat(createChartModel.currentPeriodStartDate) }} v.
            {{ extractDateFormat(createChartModel.priorPeriodStartDate) }}
          </h2>
          <h2
            id="ChartPanel-header-name-id"
            class="ChartPanel-header--name h3"
            *ngIf="createChartModel.selectedDateRangeType.id == 2"
          >
            Custom range comparison: compare {{ extractDateFormat(createChartModel.currentPeriodStartDate) }} -
            {{ extractDateFormat(createChartModel.currentPeriodEndDate) }} to
            {{ extractDateFormat(createChartModel.priorPeriodStartDate) }} -
            {{ extractDateFormat(createChartModel.priorPeriodEndDate) }}
          </h2>
        </div>
        <div class="col" *ngIf="processedInfo && processedInfo.processedData">
          <div class="Actions justify-content-end">
            <!-- <div class="Actions-item">
              <button
                type="button"
                class="btn btn-outline-secondary btn-icon btn-sm"
                aria-label="Settings"
                ngbTooltip="Settings"
                placement="top"
                (click)="openViewSettings()"
              >
                Settings
                <i class="bento-icon-settings" aria-hidden="true"></i>
              </button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row" *ngIf="processedInfo && processedInfo.processedData">
        <div class="container-fluid" aria-labelledby="ChartPanel-header-name-id" role = "region">
          <wj-flex-chart
            #lineChart
            id="lineChart"
            class="Visualization"
            [itemsSource]="processedInfo.processedData"
            [bindingX]="'bindingX'"
            [chartType]="'Column'"
            (initialized)="initChart(lineChart)"
            (rendered)="rndrCalled(lineChart)"
            [palette]="colorPalette"
            [legendToggle]="true"
            [bentoBusyLoader]="isExportLoading"
          >
            <wj-flex-chart-legend [position]="'Top'"></wj-flex-chart-legend>
            <wj-flex-chart-axis
              [wjProperty]="'axisX'"
              [axisLine]="true"
              [majorGrid]="false"
              [majorTickMarks]="'None'"
              [minorTickMarks]="'None'"
              [minorGrid]=isMinorGrid
              [itemFormatter]="itemFormatter"
            ></wj-flex-chart-axis>

            <wj-flex-chart-data-label [content]="'{y}'" [position]="'Top'"> </wj-flex-chart-data-label>

            <wj-flex-chart-axis
              [wjProperty]="'axisY'"
              [title]="'Growth rate'"
              [format]="'p1'"
              [axisLine]="true"
              [position]="'Left'"
              [minorGrid]="false"
              [majorGrid]="true"
            ></wj-flex-chart-axis>

            <ng-container *ngFor="let series of processedInfo.processedChartSeries; let index = index">
              <wj-flex-chart-series
                [binding]="series.binding"
                [name]="series.header"
                [format]="series.format"
                *ngIf="series.visible"
              >
              </wj-flex-chart-series>
            </ng-container>
          </wj-flex-chart>
        </div>
      </div>
      <div class="freez-unfreez-actions-main">
        <div class="Actions-item Actions-item-view-data unfreezeColumn" style="display: inline-block">
          <button
            type="button"
            class="btn btn-outline-secondary btn-icon btn-sm"
            [attr.aria-label]="isColumnFreezed ? 'Unfreeze Firm/Peer group column' : 'Freeze Firm/Peer group column'"
            [ngbTooltip]="isColumnFreezed ? 'Unfreeze Firm/Peer group column' : 'Freeze Firm/Peer group column'"
            placement="right"
            (click)="freezeColumnToggle()"
          >
            <i [ngClass]="isColumnFreezed ? 'bento-icon-lock' : 'bento-icon-unlocked'" aria-hidden="true"></i>
          </button>
        </div>
        <div *ngIf="showTransposeGridButton" style="display: inline-block; padding-left: 20px">
          <button
            type="button"
            class="btn btn-primary"
            [attr.aria-label]="showTransposeGridLabel"
            [ngbTooltip]="showTransposeGridLabel"
            placement="right"
            (click)="showOlderGrid()"
          >
            {{ showTransposeGridLabel }}
          </button>
        </div>
      </div>
      <div class="Chart-Datagrid" *ngIf="processedInfo && processedInfo.simplifiedData">
        <wj-flex-grid
          #flexSimplifiedGrid
          class="visualization-Grid DataGrid DataGrid--visualization"
          [itemsSource]="processedInfo.simplifiedData"
          [showMarquee]="true"
          [autoRowHeights]="true"
          [copyHeaders]="'Column'"
          [autoSizeColumn]="true"
          [headersVisibility]="'Column'"
          [isReadOnly]="true"
          (initialized)="gridInitialized(flexSimplifiedGrid)"
          [frozenColumns]="frozenColumnCount"
          [virtualizationThreshold]="5000"
          (loadedRows)="loadedSimplifiedRows(flexSimplifiedGrid)"
          (updatedView)="updatedView(flexSimplifiedGrid)"
        >
          <ng-container *ngFor="let c of processedInfo.simplifiedColumn">
            <wj-flex-grid-column
              [binding]="c.binding"
              [header]="c.header"
              [align]="'left'"
              [format]="c.format"
              [width]="c.width"
              [wordWrap]="false"
              [allowSorting]="false"
              *ngIf="c.visible"
            >
            </wj-flex-grid-column>
          </ng-container>
        </wj-flex-grid>
      </div>
      <div
        class="Chart-Datagrid"
        *ngIf="processedInfo && processedInfo.processedData"
        [ngStyle]="{display: displayOldGrid}"
      >
        <wj-transposed-grid
          #transposedFlex
          class="visualization-Grid DataGrid DataGrid--simple"
          [alternatingRowStep]="1"
          [showSelectedHeaders]="'Row'"
          [headersVisibility]="'Row'"
          [copyHeaders]="'Row'"
          [itemsSource]="processedInfo.processedData"
          [isReadOnly]="true"
          (formatItem)="formatItem(transposedFlex, $event)"
          (initialized)="transposedInitialize(transposedFlex)"
          (loadedRows)="loadedRows(transposedFlex)"
        >
          <ng-container *ngFor="let c of processedInfo.processedColumn">
            <wj-transposed-grid-row
              [binding]="c.binding"
              [header]="c.header"
              [align]="c.align"
              [format]="c.format"
              [width]="c.width"
              [wordWrap]="c.wordWrap"
              *ngIf="c.visible"
            >
            </wj-transposed-grid-row>
          </ng-container>
        </wj-transposed-grid>
      </div>
    </div>
  </section>
</div>
