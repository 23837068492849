import {
  Component,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  Renderer2,
  ElementRef,
  AfterViewChecked,
  HostListener,
} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Role} from 'src/app/shared/enums';
import {AuthService} from '../../services/auth/auth.service';
import {BackendTokenClaims} from '../../models/tokenResponse';
import {
  BentoComboboxOptions,
  BentoComboboxSearchEvent,
  BentoSmartBadgeStyle,
  BentoSmartBadgeType,
  BentoToolbarComponent,
} from '@bento/bento-ng';
import {BentoSubnavItem} from './custom-bento-subnav/subnav.interface';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {GlobalHeaderServiceService} from './global-header-service.service';
import {BehaviorSubject} from 'rxjs';
import FirmSettingModel from '../../models/firmsetting.model';
import {HttpParams} from '@angular/common/http';
import FirmDetailModel from '../../models/firmdetail.model';
import {AuthService as Auth0Service} from '@auth0/auth0-angular';
import {DOCUMENT} from '@angular/common';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {PeerCheckServiceService} from '../peer-check/peer-check/peer-check-service.service';
import {ActionTypes} from '../../store/actions';
import {eventDispatcher} from '../../store';
import FirmBasicsModel from '../../models/firm-basics.model';
import {DownloadService} from 'src/app/core/services/download/download.service';
declare let TRAAC: any;
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-global-header',
  templateUrl: 'global-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PmGlobalHeaderComponent implements OnInit, AfterViewChecked {
  @ViewChild('toolbarItemTemplate') toolbarItemTemplateRef: TemplateRef<any>;
  @ViewChild('globalSubnav', {static: false}) globalSubnav: BentoToolbarComponent;
  //Axe
  ngAfterViewChecked(): void {
    const selectors =
      'ul.nav.navbar-nav li a[aria-selected], ul.nav.navbar-nav li a[aria-current="page"], .dropdown-toggle.btn-toolbar';
    const elements = document.querySelectorAll(selectors);
    if(elements.length > 0) {
    elements.forEach((element) => {
      element.removeAttribute('aria-selected');
    });
    }
    
    const iconElement = document.querySelectorAll('i.bento-combobox-dropdown-button-icon');
    if(iconElement.length > 0) {
      iconElement.forEach((element) => {
        element.removeAttribute('role');
        element.setAttribute('tabindex', '-1');
      });
    }
  }

  firm_Id: number;
  successMsg = '';
  errorMessage = '';
  alerts: BentoAlertItemOptions[] = [];
  firmSubNav: any[] = [];
  isGetDataLoading = false;
  isDataLoading = false;
  currentPage: string;
  isFirmSelected = false;
  isDropdownOpen = false;
  isDropdownClose = true;
  isDropdownClicked = false;
  isCanadianUser = true;
  firmBasicData = new FirmBasicsModel();
  firmDefaultAccess: string;
  showExportPeerGroups = false;
  showExportFirmMapping = false;
  isQuickSightEnabled = false;
  showVisualizationHeader = false;
  showVisualizations = false;
  showNewVisualization = false;
  showDataManager = false;
  showRatesReport = false;
  showStaffingRatio = false;
  showPmmLink = false;
  isMappingData = false;
  isPeerCheckData = false;
  isPeerCheckDataTadmin = false;
  showInquiryMenu = false;
  activeTabValue: string;
  mainHeaderLink: string;

  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  dropdown: NgbDropdown;

  tabData: any[] = [
    'Visualizations',
    'Views',
    'Create a view',
    'Current views',
    'Data manager',
    'Rates reports',
    'Staffing ratio',
    'Resources',
    'PMM',
  ];

  peerCheckData: BentoSubnavItem[] = [
    {
      label: 'Current views',
      id: 'Current views',
      useAriaCurrent: true,
      routerLink: {
        href: '/current-views',
      },
      hidden: true,
    },
    {
      label: 'Create a view',
      id: 'Create a view',
      useAriaCurrent: true,
      routerLink: {
        href: '/create-view',
      },
      hidden: true,
    },
    {
      label: 'Views',
      id: 'Views',
      dropdown: [],
      hidden: true,
      ariaLabel: 'Views Menu',
    },
    {
      label: 'Visualizations',
      id: 'Visualizations',
      useAriaCurrent: true,
      routerLink: {
        href: '/visualizations',
      },
      hidden: !this.showVisualizationHeader || !this.showNewVisualization,
    },
    {
      label: 'Data manager',
      id: 'Data manager',
      useAriaCurrent: true,
      routerLink: {
        href: '/data-manager',
      },
      hidden: !this.isTAdmin || !this.showDataManager,
    },
    {
      label: 'Rates reports',
      id: 'Rates reports',
      useAriaCurrent: true,
      routerLink: {
        href: '/rates-reports',
      },
      hidden: !this.isTAdmin || !this.showRatesReport,
    },
    {
      label: 'Staffing ratio',
      id: 'StaffingRatio',
      useAriaCurrent: true,

      routerLink: {
        href: '/staffing-ratio',
      },
      hidden: !this.showStaffingRatio,
    },
    {
      label: 'PMM',
      id: 'PMM',
      useAriaCurrent: true,

      routerLink: {
        href: '/pmm',
      },
      hidden: !this.showPmmLink,
    },
    {
      label: 'Resources',
      id: 'Resources',
      useAriaCurrent: true,

      routerLink: {
        href: '/resources',
      },
      hidden: this.isCanadianUser,
    },
  ];

  

  // Global Subnav
  administratorData: any[] = [];
  navData: any[] = [];
  dataStream: BehaviorSubject<any>;
  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    useServerSearch: true,
    minSearchCharacterCount: 0,
    debounceTime: 500,
    searchCompare: (row, search) => {
      const searchLowerCase = search.toLocaleLowerCase();
      const name = `${row.id} ${row.name}`.toLowerCase();

      return name.indexOf(searchLowerCase) > -1 || String(row.id).indexOf(searchLowerCase) > -1;
    },
    labelFormatter: (row) => `${row.id}`,
  };
  selectedFirm: any;
  firmDetailModel: FirmDetailModel = new FirmDetailModel();
  listOfAllFirms: FirmSettingModel = new FirmSettingModel();
  itemsPerPage = 100;
  page = 1;
  lastPage: number = this.page;
  isCurrentViewORCreateView = false;
  firmIdString: string;
  selectedSubjectFirmId: number;

  constructor(
    public router: Router,
    private authService: AuthService,
    private service: BaseService,
    private changeDetection: ChangeDetectorRef,
    private route: ActivatedRoute,
    public _service: GlobalHeaderServiceService,
    public auth0: Auth0Service,
    private changeDetectionRef: ChangeDetectorRef,
    private _peerCheckService: PeerCheckServiceService,
    private downloadFile: DownloadService,
    @Inject(DOCUMENT) private doc: Document,
    private globalservice: GlobalService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    if (
      router.url === '/' ||
      router.url.indexOf('current-views') > 0 ||
      router.url.indexOf('create-view') > 0 ||
      router.url.indexOf('resources') > 0 ||
      router.url.indexOf('contact-us') > 0 ||
      router.url.indexOf('new-visualization') > 0 ||
      router.url.indexOf('visualizations') > 0 ||
      router.url.indexOf('data-manager') > 0 ||
      router.url.indexOf('rates-reports') > 0 ||
      router.url.indexOf('staffing-ratio') > 0 ||
      router.url.indexOf('pmm') > 0 
    ) {
      this.dataStream = new BehaviorSubject([]);
      this.isPeerCheckData =
        router.url === '/' ||
        router.url.indexOf('current-views') > 0 ||
        router.url.indexOf('create-view') > 0 ||
        router.url.indexOf('resources') > 0 ||
        router.url.indexOf('contact-us') > 0 ||
        router.url.indexOf('new-visualization') > 0 ||
        router.url.indexOf('visualizations') > 0 ||
        router.url.indexOf('data-manager') > 0 ||
        router.url.indexOf('rates-reports') > 0 ||
        router.url.indexOf('staffing-ratio') > 0 ||
        router.url.indexOf('pmm') > 0 ;
      this.isPeerCheckDataTadmin =
        router.url.indexOf('firm/') > 0 &&
        router.url.indexOf('/current-views') == -1 &&
        router.url.indexOf('/create-view') == -1 &&
        router.url.indexOf('/new-visualization') == -1 &&
        router.url.indexOf('/visualizations') == -1 &&
        router.url.indexOf('/data-manager') == -1 &&
        router.url.indexOf('/rates-reports') == -1 &&
        router.url.indexOf('/staffing-ratio') == -1 &&
        router.url.indexOf('/pmm') == -1 &&
        router.url.indexOf('/resources') == -1;
      this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          if (this.isPeerCheckData || this.isPeerCheckDataTadmin) {
            this.peerCheckData.map((data) => {
              let endpoint = data.label.toLowerCase().replace(/ /g, '-');
              if (router.url.indexOf('/' + endpoint) > -1) {
                data.active = true;
              } else {
                data.active = false;
              }
            });
          }
          this.changeDetection.markForCheck();
          if (this.globalSubnav) {
            this.globalSubnav.refresh();
          }
        }
      });
    }
    this.updateGlobalMenu();
  }

  async ngOnInit() {
    this.showPmmLink = await this.getAnyFeatureFlag('PMM-LINKS');
    this.showStaffingRatio = await this.getStaffingRatioFeatureFlag();
    this.populateMainHeaderLink();
    this.adminNavMenuItemsData();
    if (
      (this.router.url.indexOf('current-views') ||
        this.router.url.indexOf('create-view') ||
        this.router.url === '/' ||
        this.router.url.indexOf('firm/') > 0) &&
      this.isTAdmin
    ) {
      this.isCurrentViewORCreateView = this.router.url.indexOf('current-views') > 0;
      // this._service.getFirmSubject().subscribe((data) => {
      //   if (data['id']) {
      //     this.showMappingMenuForFirm = data['id'] > 900 && data['id'] < 1999;
      //     this.selectedSubjectFirmId = data['id'];
      //     this._peerCheckService.setSelectedFirmId = data['id'];
      //     this.setMappingsData(data['id']);
      //   }
      // });

      this.getfirmDetails(this.selectedSubjectFirmId ? this.selectedSubjectFirmId : this.getDefaultOrSelectedfirmId);
      this.listOfAllFirms.firms = [];
      this.getfirmsData();
      eventDispatcher.next({type: ActionTypes.SetSelectedFirmId, payload: this.getDefaultOrSelectedfirmId});
    } else if (!this.isNoAccessUser) {
      this.getFirmBasicData(this.getDefaultOrSelectedfirmId);
    }

    this.registerQuickSiteEvents();
    this.getExportMappingFeatureFlag();
    this.getExportPeergroupFeatureFlag();
    let srMenuObj = {
      label: 'Staffing ratio',
      useAriaCurrent: true,
      routerLink: {
        href: '/staffing-ratio',
      },
      hidden: !this.showStaffingRatio,
    };

    let pmmMenuObj = {
      label: 'PMM',
      useAriaCurrent: true,
      routerLink: {
        href: '/pmm',
      },
      hidden: !this.showPmmLink,
    };
    if (this.router.url === '/' && this.isSRUser && this.isNoAccessUser && this.isPMMUser) {
      this.peerCheckData = [srMenuObj, pmmMenuObj];
      this.router.navigate(['staffing-ratio']);
    } else if (this.router.url === '/' && !this.isSRUser && this.isPMMUser && this.isNoAccessUser) {
      this.peerCheckData = [pmmMenuObj];
      this.router.navigate(['pmm']);
    } else if (this.router.url === '/' && this.isSRUser && !this.isPMMUser && this.isNoAccessUser) {
      this.peerCheckData = [srMenuObj];
      this.router.navigate(['staffing-ratio']);
    }
  }
  //Admin & nav menu items Set
  adminNavMenuItemsData() {
    this.administratorData = [
      {
        label: 'Firms',
        useAriaCurrent: true,
        routerLink: {
          href: this.isTAdmin ? '/administrator/firms' : '/administrator',
        },
      },
      {
        label: 'Global peer groups',
        useAriaCurrent: true,
        routerLink: {
          href: '/administrator/peer-groups',
        },
        hidden: !this.isTAdmin,
      },
      {
        label: 'Staffing ratio setup',
        dropdown: [
          {
            label: 'Admin configurations',
            routerLink: {
              href: '/administrator/staffingratio-configurations',
            },
          },
          {
            label: 'Pre-set peer groups',
            useAriaCurrent: true,
            routerLink: {
              href: '/administrator/staffing-peer-groups',
            },
          },
          {
            label: 'Custom peer group segment mapping',
            useAriaCurrent: true,
            routerLink: {
              href: '/administrator/staffing-custom-segment',
            },
          },
        ],
        hidden: !this.showStaffingRatio,
        ariaLabel: 'Staffing ratio setup',
      },
    ];
    this.navData = [
      {
        label: 'Peer Check',
        routerLink: '/',
        useAriaCurrent: true,
        tData: this.peerCheckData,
        visible: this.isTAdmin || this.isFAdmin || this.isGUser || this.isSRUser || this.isPMMUser,
      },
      {
        label: 'Administrator',
        useAriaCurrent: true,
        routerLink: this.isTAdmin ? '/administrator/firms' : '/administrator',
        tData: this.administratorData,
        visible: this.isTAdmin || this.isFAdmin,
      },
    ];
  }
  getFirmBasicData(firmId) {

    return this.service.get(environment.FIAdminBaseEndpoint + 'v1/view/basic/details/' + firmId, '').subscribe(
      (result) => {
        this.firmBasicData = result;
        if (this.firmBasicData.defaultAccess == 'CAN') this.isCanadianUser = true;
        else this.isCanadianUser = false;         
        if (this.globalSubnav) {
          this.globalSubnav.refresh();
          this.changeDetection.markForCheck();
        }
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  public get isTAdminUser() {
    return this.isTAdmin ? '/administrator/firms' : '/administrator';
  }
  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  public get isFAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.FAdmin;
  }

  public get isEAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.EAdmin;
  }

  public get isGUser(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.GUser;
  }

  public get isNoAccessUser(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.NOAccess;
  }

  public get userName(): string {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.fullName;
  }

  public get isSRUser(): boolean {
    let isSRUser = false;
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO.isStaffingratioUser && backendTokenClaims.userDDO.isStaffingratioUser != null) {
      isSRUser = backendTokenClaims.userDDO.isStaffingratioUser;
    }
    return isSRUser;
  }

  public get isPMMUser(): boolean {
    let isPMMUser = false;
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO.isPMMUser && backendTokenClaims.userDDO.isPMMUser != null) {
      isPMMUser = backendTokenClaims.userDDO.isPMMUser;
    }
    return isPMMUser;
  }

  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims && backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  signOut(): void {
    this.auth0.logout({returnTo: this.doc.location.origin});
  }

  // getfirmsData(search:string = null) will return list of All firms to TRAdmin
  getfirmsData(search: string = null) {
    this.isDataLoading = true;
    let params = new HttpParams();
    params = params.set('page', this.page.toString());
    params = params.set('size', this.itemsPerPage.toString());
    if (search) {
      params = params.set('search', search);
    }
    if (JSON.parse(environment.loadOnlyFIFirms)) {
      params = params.set('firmType', 'FI');
    }
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firms?', params).subscribe(
      (result) => {
        this.isDataLoading = false;
        if (this.page == 1) {
          this.dataStream = new BehaviorSubject([]);
          this.listOfAllFirms.firms = [];
        }
        this.listOfAllFirms.firms.push(...result.firms);
        this.listOfAllFirms.totalCount = result.totalCount;
        this.lastPage = result.firms.length < this.itemsPerPage ? this.page + 1 : this.page;
        const nextBlock = result.firms;
        if (nextBlock.length > 0) {
          setTimeout(() => {
            this.dataStream.next(nextBlock);
            this.changeDetectionRef.detectChanges();
          }, 500);
        } else {
          this.changeDetectionRef.detectChanges();
        }
        this.globalservice.skipFocusTwiceCombobox();
        this.globalservice.skipFocusDropDown();
      },
      (error) => {
        this.isDataLoading = false;
        this.errorMessage = error.error;
        this.changeDetectionRef.detectChanges();
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  searchFirms(searchText: string) {
    this.page = 1;
    this.lastPage = 1;
    this.getfirmsData(searchText);
  }
  onPageChanged(page, searchText) {
    if (page !== this.lastPage && this.listOfAllFirms.firms.length != this.listOfAllFirms.totalCount) {
      this.page = page;
      this.getfirmsData(searchText);
    }
    this.lastPage = page;
  }

  didSearch(e: BentoComboboxSearchEvent) {
    this.searchFirms(e.search);
  }

  didScroll(e: BentoComboboxSearchEvent) {
    this.page++;
    this.onPageChanged(this.page, e.search);
  }

  didReset() {
    this.searchFirms(null);
  }

  async firmSelectionChanged() {
    this.isFirmSelected = true;
    if (!this.selectedFirm) {
      this.selectedFirm = {id: this.firm_Id, name: this.firmDetailModel.name, pendingPeerGroupCount: 0};
    }
    this._service.setFirmSubject(this.selectedFirm);
    if (this.selectedFirm.id) this.firm_Id = this.selectedFirm.id;
    eventDispatcher.next({type: ActionTypes.SetSelectedFirmId, payload: this.selectedFirm.id});
    await this.updateGlobalMenu();
    this.firmBasicData = await this._peerCheckService.getFirmBasicsData(this.selectedFirm.id);
    if (this.firmBasicData.defaultAccess == 'CAN') this.isCanadianUser = true;
    else this.isCanadianUser = false;
    this.refreshPeerCheckData(this.selectedFirm.id);
    eventDispatcher.next({type: ActionTypes.SetBasicDetails, payload: this.firmBasicData});
    this.router.navigate(['']);
    if (this.globalSubnav) {
      this.globalSubnav.refresh();
    }
    this.changeDetection.markForCheck();
  }

  trackMarketInsightsLinkClickEvent() {
    /**Remove the call to feature API ***** */
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Market Insights link click', {email: this.authService.getUserEmail()}).build());
    }
  }

  trackAdministratorClickEvent() {
    /**Remove the call to feature API ***** */
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Administrator click', {email: this.authService.getUserEmail()}).build());
    }
  }

  trackContactUsClickEvent() {
    /**Remove the call to feature API ***** */
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Contact Us click', {email: this.authService.getUserEmail()}).build());
    }
  }

  // getfirmId() should return the selected firmID OR firmID of logged in user.
  public get getDefaultOrSelectedfirmId(): number {
    let id;

    if (this.route && this.route.children.length > 0 && this.route.children[0].paramMap) {
      this.route.children[0].paramMap.subscribe((params) => {
        const urlarr = this.router.url.split('/');
        if (urlarr && urlarr.length > 0) {
          let urlFirmId;
          for (let i = 0; i < urlarr.length; i++) {
            if (!isNaN(parseInt(urlarr[i]))) {
              urlFirmId = parseInt(urlarr[i]);
            }
          }

          const isMapping = this.router.url.indexOf('mappings') != -1;

          //const showMappingMenuForFirm = urlFirmId > 900 && urlFirmId < 1999;
          if (isMapping && this._peerCheckService.isValidMappingFirm(urlFirmId)) {
            if (!isNaN(urlFirmId)) {
              id = urlFirmId;
              this.isFirmSelected = id ? true : false;
            }
          } else {
            if (!isMapping) {
              if (!isNaN(urlFirmId)) {
                id = urlFirmId;
                this.isFirmSelected = id ? true : false;
              }
            }
          }
        }
      });
    }
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return !id && backendTokenClaims.userDDO ? backendTokenClaims.userDDO.firmID : id;
  }

  private get getDefaultFirm(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  // getfirmDetails(firmId) function is called to get the firm information like. name, local, etc.
  getfirmDetails(firmId) {
    let params = new HttpParams();
    params = params.set('firmId', firmId);
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/firm?', params).subscribe(
      (result) => {
        this.firmDetailModel = result;
        this.selectedFirm = {id: this.firmDetailModel.id, name: this.firmDetailModel.name, pendingPeerGroupCount: 0};
        this._service.setFirmSubject(this.selectedFirm);
        this._peerCheckService.setSelectedFirmId = this.firmDetailModel.id;
        if (this.firmDetailModel.defaultAccess == 'CAN') this.isCanadianUser = true;
        else 
          this.isCanadianUser = false;
      },
      (error) => {
        this.errorMessage = error.error;

        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  refreshPeerCheckData(firmId = null) {
    this.isDataLoading = true;
    if (!firmId) {
      firmId = this.selectedFirm && this.selectedFirm.id ? this.selectedFirm.id : this.getDefaultOrSelectedfirmId;
    }
    if (this.isFirmSelected && firmId) {
      this._peerCheckService.isFirmChangedSource.next(firmId);
      this.peerCheckData.forEach((obj) => {
        if (obj.label === 'Create a view') {
          obj.routerLink = {
            href: '/firm/' + firmId.toString() + '/create-view',
          };
          obj.useAriaCurrent = true;
        } else if (obj.label === 'Current views') {
          obj.routerLink = {
            href: '/firm/' + firmId.toString() + '/current-views',
          };
          obj.useAriaCurrent = true;
        } else if (obj.label === 'Visualizations') {
          obj.routerLink = {
            href: '/firm/' + firmId.toString() + '/visualizations',
          };
        } else if (obj.label === 'Views') {
          if (obj.dropdown)
            obj.dropdown.forEach((element) => {
              if (element.label == 'Create a view') {
                element.useAriaCurrent = true;
                element.routerLink = {href: '/firm/' + firmId.toString() + '/create-view'};
              } else if (element.label == 'Current views') {
                element.useAriaCurrent = true;
                element.routerLink = {href: '/firm/' + firmId.toString() + '/current-views'};
              }
            });
        }
        if (!this.isNoAccessUser) obj.hidden = false;
        else obj.hidden = true;
        obj.active = false;
      });
    }
    this.peerCheckData.forEach((obj) => {
      obj.active = false;
    });
    if (this.globalSubnav) {
      this.globalSubnav.refresh();
      this.changeDetection.markForCheck();
    }
    this.isDataLoading = false;
  }

  refreshCharts() {
    this.peerCheckData.forEach((obj) => {
      if (obj.label === 'Visualizations' && this.showVisualizations) {
        obj.hidden = this.isNoAccessUser ? true : false;
      }

      if (obj.label === 'Data manager') {
        obj.hidden = !this.isTAdmin || !this.showDataManager;
      }
      if (obj.label === 'Rates reports') {
        obj.hidden = !this.isTAdmin || !this.showRatesReport;
      }
      if (obj.label === 'Staffing ratio') {
        obj.hidden = !this.showStaffingRatio ? true : false;
      }
      if (obj.label === 'PMM') {
        obj.hidden = !this.showPmmLink ? true : false;
      }

      if (obj.label === 'Resources') {
        obj.hidden = this.isCanadianUser ? true : false;
      }
      if (obj.label === 'Views') {
        this.globalservice.skipFocusDropDown();
        obj.dropdown = [];

        obj.dropdown.push({
          label: 'Current views',
          useAriaCurrent: true,
          routerLink: {
            href: '/current-views',
          },
        });

        obj.dropdown.push({
          label: 'Create a view',
          useAriaCurrent: true,

          routerLink: {
            href: '/create-view',
          },
        });
        if (!this.isNoAccessUser) obj.hidden = false;
        else obj.hidden = true;
      }
    });
    let srMenuObj = {
      label: 'Staffing ratio',
      useAriaCurrent: true,
      routerLink: {
        href: '/staffing-ratio',
      },
      hidden: !this.showStaffingRatio,
    };
    let pmmMenuObj = {
      label: 'PMM',
      useAriaCurrent: true,
      routerLink: {
        href: '/pmm',
      },
      hidden: !this.showPmmLink,
    };

    if (this.showStaffingRatio && this.isSRUser && this.isNoAccessUser && this.showPmmLink && this.isPMMUser) {
      this.peerCheckData = [srMenuObj, pmmMenuObj];
    } else if (this.showStaffingRatio && this.isSRUser && this.isNoAccessUser) {
      this.peerCheckData = [srMenuObj];
    } else if (this.showPmmLink && this.isPMMUser && this.isNoAccessUser) {
      this.peerCheckData = [pmmMenuObj];
    }
  }

  checkDropDown(dropdown: any) {
    // Check which dropdown was clicked
    this.dropdown = this.dropdowns.find((x) => (x as any)._elementRef.nativeElement == dropdown);
  }
  async exportPeerGroupsClickEvent(isPeerCheckPage) {
    let firm_id = '';
    if (this.isTAdmin) {
      if (isPeerCheckPage) firm_id = this.selectedFirm.id;
      else firm_id = this.getDefaultFirm.toString();
    } else firm_id = this.getfirmId.toString();
    this.firmBasicData = this._peerCheckService.firmBasicsData;
    if (this.firmBasicData.firmId.toString() != firm_id)
      this.firmBasicData = await this._peerCheckService.getBasicDetails(firm_id);
    this.service
      .downloadExcel(
        environment.FIAdminBaseEndpoint +
          'v1/report/export/peerGroups/' +
          firm_id +
          '?defaultAccess=' +
          this.firmBasicData.defaultAccess
      )
      .subscribe(
        (response) => {
          this.downloadFile.download(response.body, response.headers.get('content-disposition').split('=')[1]);
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  exportFirmsMapClickEvent(isPeerCheckPage) {
    let firm_id = '';
    if (this.isTAdmin) {
      if (isPeerCheckPage) firm_id = this.selectedFirm.id;
      else firm_id = this.getDefaultFirm.toString();
    } else firm_id = this.getfirmId.toString();
    this.service.downloadExcel(environment.FIClientBaseEndpoint + 'v1/firm/mapping/' + firm_id).subscribe(
      (response) => {
        this.downloadFile.download(response.body, response.headers.get('content-disposition').split('=')[1]);
      },
      (error) => {
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }
  registerQuickSiteEvents() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    /**Remove the call to feature API ***** */
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/QUICKSIGHT', '').subscribe(
      (result) => {
        this.isQuickSightEnabled = result['QUICKSIGHT'];
        const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
        if (result['QUICKSIGHT'] && backendTokenClaims && backendTokenClaims.userDDO) {
          TRAAC.register({
            'Firm ID': backendTokenClaims.userDDO.firmID,
            'User ID': backendTokenClaims.userDDO.userID,
            'Firm Name': backendTokenClaims.userDDO.firmName,
          });
        }
      },
      (error) => {}
    );
  }
  getExportMappingFeatureFlag() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - EXPORT_PEERGROUP ******/
    /**Remove the call to feature API ***** */
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/EXPORT_PEERGROUP', '').subscribe((result) => {
      this.showExportPeerGroups = result['EXPORT_PEERGROUP'];
    });
  }
  getExportPeergroupFeatureFlag() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - EXPORT_MAPPINGS ******/
    /**Remove the call to feature API ***** */
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/feature/EXPORT_MAPPINGS', '').subscribe((result) => {
      this.showExportFirmMapping = result['EXPORT_MAPPINGS'];
    });
  }

  getHeaderVisualizationsFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - VISUALIZATION_HEADER_MENU ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/VISUALIZATION_HEADER_MENU', '')
      .toPromise()
      .then((result) => {
        return result['VISUALIZATION_HEADER_MENU'];
      });
  }

  getVisualizationsFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - VISUALIZATIONS ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/VISUALIZATIONS', '')
      .toPromise()
      .then((result) => {
        return result['VISUALIZATIONS'];
      });
  }
  getNewVisualizationFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - NEW_VISUALIZATION ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/NEW_VISUALIZATION', '')
      .toPromise()
      .then((result) => {
        return (this.showNewVisualization = result['NEW_VISUALIZATION']);
      });
  }

  async updateGlobalMenu() {
    this.isDataLoading = true;
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - VISUALIZATION_HEADER_MENU, VISUALIZATIONS, NEW_VISUALIZATION ******/
    this.showVisualizationHeader = await this.getHeaderVisualizationsFeatureFlag();
    this.showNewVisualization = await this.getNewVisualizationFeatureFlag();
    this.showVisualizations = await this.getVisualizationsFeatureFlag();
    this.showDataManager = await this.getDataManagerFeatureFlag();
    this.showRatesReport = await this.getRatesReportsFeatureFlag();
    this.showStaffingRatio = await this.getStaffingRatioFeatureFlag();
    this.showPmmLink = await this.getAnyFeatureFlag('PMM-LINK');

    if ((this.showNewVisualization || this.showVisualizations) && this.showVisualizationHeader) {
      this.peerCheckData = this.peerCheckData.filter(
        (item) =>
          item.label == 'Visualizations' ||
          item.label == 'Views' ||
          (this.isTAdmin && this.showRatesReport && item.label == 'Rates reports') ||
          (this.isTAdmin && this.showDataManager && item.label === 'Data manager') ||
          (this.isSRUser && this.showStaffingRatio && item.label == 'Staffing ratio') ||
          (this.isPMMUser && this.showPmmLink && item.label == 'PMM')||
          (!this.isCanadianUser && item.label == 'Resources')
      );
      this.refreshCharts();
    } else {
      this.peerCheckData.forEach((obj) => {
        if (obj.label === 'Current views') obj.hidden = false;
        if (obj.label === 'Create a view') obj.hidden = false;
        if (obj.label === 'Data manager') obj.hidden = !this.isTAdmin || !this.showDataManager;
        if (obj.label === 'Rates reports') obj.hidden = !this.isTAdmin || !this.showRatesReport;
        if (obj.label === 'Staffing ratio') obj.hidden = !this.showStaffingRatio;
        if (obj.label === 'PMM') obj.hidden = !this.showPmmLink;
        if (obj.label === 'Resources') obj.hidden = this.isCanadianUser;
      });
    }
    if (this.globalSubnav) {
      this.globalSubnav.refresh();

      const selectors =
        'ul.nav.navbar-nav li a[aria-selected], ul.nav.navbar-nav li a[aria-current="page"], .dropdown-toggle.btn-toolbar';
      const elements = document.querySelectorAll(selectors);
      elements.forEach((element) => {
        element.removeAttribute('aria-selected');
      });

      this.changeDetection.markForCheck();
    }
    this.isDataLoading = true;
    let srMenuObj = {
      label: 'Staffing ratio',
      useAriaCurrent: true,
      routerLink: {
        href: '/staffing-ratio',
      },
      hidden: !this.showStaffingRatio,
    };
    let pmmMenuObj = {
      label: 'PMM',
      useAriaCurrent: true,
      routerLink: {
        href: '/pmm',
      },
      hidden: !this.showPmmLink,
    };
    if (this.showStaffingRatio && this.isSRUser && this.isNoAccessUser && this.showPmmLink && this.isPMMUser) {
      this.peerCheckData = [srMenuObj, pmmMenuObj];
    } else if (this.showStaffingRatio && this.isSRUser && this.isNoAccessUser) {
      this.peerCheckData = [srMenuObj];
    } else if (this.showPmmLink && this.isPMMUser && this.isNoAccessUser) {
      this.peerCheckData = [pmmMenuObj];
    }
  }
  // commented out as this is being handled by isPeercheckData and isPeercheckDataTadmin
  // setActiveTab(e) {
  //   if (!this.tabData.includes(e)) return;
  //   let active = false;
  //   let keepGoing = true;
  //   this.peerCheckData.forEach((obj) => {
  //     active = false;
  //     obj.active = false;
  //   });
  //   this.peerCheckData.forEach((obj) => {
  //     // if (obj.label == e  && e !== 'Visualizations' && e!=='Resources') {
  //     if (obj.label == e && e !== 'Resources') {
  //       active = true;
  //       obj.active = true;
  //       obj.useAriaCurrent = true;
  //       keepGoing = false;
  //     }
  //   });
  //   if (keepGoing) {
  //     this.peerCheckData.forEach((obj) => {
  //       active = obj.active;
  //       obj.useAriaCurrent = true;
  //       if (obj.dropdown) {
  //         active = false;
  //         obj.useAriaCurrent = true;
  //         obj.dropdown.forEach((element) => {
  //           if (element.label == e) {
  //             element.active = true;
  //             element.useAriaCurrent = true;
  //             obj.active = true;
  //             obj.useAriaCurrent = true;
  //           }
  //         });
  //       }
  //       obj.active = false;
  //       obj.useAriaCurrent = true;
  //       // if (this.showStaffingRatio && e == 'Staffing ratio') {
  //       //   obj.active = true;
  //       //   obj.useAriaCurrent = true;
  //       // }
  //       // if (this.showPmmLink && e == 'PMM') {
  //       //   obj.active = true;
  //       //   obj.useAriaCurrent = true;
  //       // }
  //     });

  //     if (this.globalSubnav) {
  //       this.globalSubnav.refresh();
  //       this.changeDetection.markForCheck();
  //       active = false;
  //       keepGoing = true;
  //     }
  //   }
  // }

  //   isTabActive(tablabel: string): boolean {
  //     return this.peerCheckData.some(obj => obj.label === tablabel && obj.active);
  // }
  getDataManagerFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - DATA_MANAGER ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/DATA_MANAGER', '')
      .toPromise()
      .then((result) => {
        return result['DATA_MANAGER'];
      });
  }

  getRatesReportsFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - RATES-REPORTS ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/RATES-REPORTS', '')
      .toPromise()
      .then((result) => {
        return result['RATES-REPORTS'];
      });
  }

  getStaffingRatioFeatureFlag(): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - STAFFING-RATIO ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/STAFFING-RATIO', '')
      .toPromise()
      .then((result) => {
        return result['STAFFING-RATIO'];
      });
  }

  getAnyFeatureFlag(flagName: string): any {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - STAFFING-RATIO ******/
    /**Remove the call to feature API ***** */
    return this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/' + flagName, '')
      .toPromise()
      .then((result) => {
        return result[flagName];
      });
  }

  populateMainHeaderLink() {
   
    if (this.isNoAccessUser) {
      if (this.isSRUser) this.mainHeaderLink = '/staffing-ratio';
      else if (this.isPMMUser) this.mainHeaderLink = '/pmm';
      else this.mainHeaderLink = '/';
    } else {
      this.mainHeaderLink = '/';
    
    }
  }
}
