<bento-alert [items]="alerts"></bento-alert>
<div class="Actions justify-content-center">
  <wj-flex-grid
    #flexGrid
    class="DataGrid DataGridFlexScroll widthClass btn-addreport"
    [itemsSource]="data"
    [stickyHeaders]="true"
    [showMarquee]="true"
    [showSort]="true"
    [autoRowHeights]="true"
    [headersVisibility]="'Column'"
    [bentoBusyLoader]="isGetDataLoading"
    [totalItems]="data.totalItemCount"
    (initialized)="gridInitialized(flexGrid)"
  >
    <wj-flex-grid-column
      [header]="'Report name'"
      [binding]="'reportName'"
      [visible]="true"
      [width]="450"
      [isReadOnly]="true"
      [wordWrap]="true"
    ></wj-flex-grid-column>
    <wj-flex-grid-column
      [header]="'Start time'"
      [binding]="'createdDate'"
      [visible]="true"
      [width]="200"
      [isReadOnly]="true"
      [wordWrap]="true"
    ></wj-flex-grid-column>
    <wj-flex-grid-column
      [header]="'User email'"
      [binding]="'toEmail'"
      [visible]="true"
      [width]="350"
      [isReadOnly]="true"
      [wordWrap]="true"
    ></wj-flex-grid-column>
    <wj-flex-grid-column
      [header]="'Report type'"
      [binding]="'reportType'"
      [visible]="true"
      [width]="150"
      [isReadOnly]="true"
      [wordWrap]="true"
    ></wj-flex-grid-column>
  </wj-flex-grid>
</div>
<bento-pagination
  #pagination
  [page]="data.pageIndex + 1"
  [directionLinks]="true"
  [boundaryLinks]="true"
  [totalItems]="data.totalItemCount"
  [(itemsPerPage)]="data.pageSize"
  (itemsPerPageChange)="onPageSizeChanged($event)"
  (pageChange)="onPageChanged($event)"
></bento-pagination>
