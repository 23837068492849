import {OnDestroy, ViewChild} from '@angular/core';
import {Component, OnInit, HostBinding} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BentoAlertItemOptions, BentoSkipLinksService, SkipLink} from '@bento/bento-ng';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import CreateViewModel, {
  DescriptionSummary,
  FixedTimeRange,
  Metric,
  ProcessedBaseTableData,
  ProcessedMainData,
  RollingTimeRange,
  ViewDetails,
  ViewsOverlayObject,
  ViewsSettings,
} from 'src/app/core/models/create-view.model';
import FirmBasicsModel from 'src/app/core/models/firm-basics.model';
import PeerCheckViewColumns from 'src/app/core/models/peerCheckViewColumns.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import {ExportModalComponent} from '../export-modal/export-modal.component';
import {ActionTypes} from 'src/app/core/store/actions';
import {eventDispatcher, store} from 'src/app/core/store';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {Subscription} from 'rxjs';
import {ViewDataGridComponent} from '../view-grid/view-data-grid/view-data-grid.component';
import {GlobalHeaderServiceService} from '../../global-header/global-header-service.service';
declare let TRAAC: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
})
export class LandingComponent implements OnInit, OnDestroy {
  errorMessage: any;
  subscription: Subscription[] = [];
  summary: DescriptionSummary;
  yoyHeader: string;
  actionMenuItems: {name: string; isDisabled: boolean}[];
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }

  alerts: BentoAlertItemOptions[] = [];
  isDataLoading: boolean;
  userSettingsModel: CreateViewModel = new CreateViewModel();
  pcViewCols: PeerCheckViewColumns = new PeerCheckViewColumns();
  firmBasicData = new FirmBasicsModel();
  processedData: ProcessedMainData;
  processedDataForBasicTable: ProcessedBaseTableData;

  @ViewChild('ViewGrid') viewGridComponent: ViewDataGridComponent;
  selectedMetric: Metric;
  selectedFirmId: number;
  viewDetails: ViewDetails;

  isGetBasicAPICalled = false;
  isValueInitialized = false;
  isFirmBasicLoaded = false;
  isQuickSightEnabled = false;

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private route: ActivatedRoute,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService,
    private router: Router,
    private _service: PeerCheckServiceService,
    private service: BaseService,
    private featureToggleService: FeatureToggleService,
    private bentoSkipLinksService: BentoSkipLinksService,
    private _globalHeaderService: GlobalHeaderServiceService
  ) {
    this._globalHeaderService.getFirmSubject().subscribe((firm) => {
      this.selectedFirmId = firm.id;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && this.subscription.length) {
      this.subscription.forEach((subsc) => {
        subsc.unsubscribe();
      });
    }
  }

  async ngOnInit() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    await this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /********************************************************************/
    this.actionMenuItems = [
      {name: 'Edit Table', isDisabled: false},
      {name: 'Export', isDisabled: false},
    ];
    //Service will return getBasic details and firm level rules
    eventDispatcher.next({type: ActionTypes.GetSelectedFirmId});
    this.yoyHeader = 'YOY growth';
    if (!this.selectedFirmId) {
      this.selectedFirmId = this.getDefaultOrSelectedFirmId;
    }
    if (!this.isNoAccessUser) this.getBasicDetails();
    this.bentoSkipLinksService.updateLinks([new SkipLink('pageHeader', 'Skip to main content', false)]);
  }

  async getBasicDetails() {
    let subscription = this._service.isDataLoadingMessage.subscribe((isDataProcessing: boolean) => {
      //this.isDataLoading = isDataProcessing;
    });
    this.subscription.push(subscription);

    //Service will return getBasic details and firm level rules
    this.firmBasicData = await this._service.getFirmBasicsData(this.selectedFirmId);
    this.initializeValues();
    this.isGetBasicAPICalled = true;
  }

  initializeValues() {
    if (this.firmBasicData) this.pcViewCols = this.firmBasicData.firmLevelRules;
    this.peercheckMessageHandlerService.setIsUnreadMessage = true;
    this.peercheckMessageHandlerService.setIsCustomRequest = false;
    this.getDefaultViewDetails();

    this.userSettingsModel = new CreateViewModel();
    this.isDataLoading = true;

    this.userSettingsModel.viewsSettings = new ViewsSettings();

    let subscriptionFirm = this._service.isFirmChangedMessage.subscribe(async (isFirmChangedMessage: any) => {
      this.selectedFirmId = isFirmChangedMessage;

      if (this.viewGridComponent) {
        this.viewGridComponent.selectedFirmId = this.selectedFirmId;
      }
      this.firmBasicData = await this._service.getFirmBasicsData(this.selectedFirmId);
      this.pcViewCols = this.firmBasicData.firmLevelRules;
      this.getDefaultViewDetails();
    });
    this.subscription.push(subscriptionFirm);

    this.isValueInitialized = true;
  }

  // getDefaultOrSelectedFirmId() should return the selected firmID OR firmID of logged in user.
  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  private get getDefaultFirm(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }
  public get isNoAccessUser(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.NOAccess;
  }
  editView(requestPayload) {
    this.isDataLoading = true;
    this.service
      .put(
        environment.FIAdminBaseEndpoint +
          'v1/view/viewTemplate/' +
          this.viewDetails.viewTemplateId +
          '/firm/' +
          this.selectedFirmId,
        requestPayload
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.service
            .get(environment.FIAdminBaseEndpoint + 'v1/view/firm/' + this.selectedFirmId + '/default', '')
            .subscribe(
              (result) => {
                this.isFirmBasicLoaded = true;
                this.viewDetails = result;
                this.userSettingsModel = this.peercheckMessageHandlerService.processSelectedViewDetails(
                  this.viewDetails,
                  this.userSettingsModel,
                  this.firmBasicData.yyyymm
                );
              },
              (error) => {
                this.isDataLoading = false;
                this.errorMessage = error.error;
                this.alerts.push({
                  type: 'warning',
                  msg: 'Something went wrong, please try again.',
                  closeable: true,
                });
              }
            );
        },
        (error) => {
          this.errorMessage = error.error;
          let errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
          this.alerts.push({
            type: 'warning',
            msg: errMsg,
            closeable: true,
          });
          this.isDataLoading = false;
        }
      );
  }

  onDragDone(indexObj) {
    const {dragIndex, dropIndex, metricIdArr} = indexObj;

    let requestBody = this.createRequestBodyForSaveView();
    requestBody['name'] = this.viewDetails.viewName;
    requestBody['isDefault'] = this.viewDetails.default;
    // let item = requestBody['metrics'][dragIndex];
    // requestBody['metrics'].splice(dragIndex, 1);
    // requestBody['metrics'].splice(dropIndex, 0, item);
    requestBody['metrics'] = metricIdArr;
    //need to ask sumita about users and defaultForAll key
    requestBody['users'] = [];
    requestBody['isDefaultforAll'] = false;

    if (this.viewDetails.viewTemplateId) {
      this.editView(requestBody);
    }
  }

  createRequestBodyForSaveView() {
    let requestbody = {};

    if (this.userSettingsModel.timeRangeSelected == 'Rolling' && this.userSettingsModel.rollingTimeRangeSelectedItems) {
      let stDateAry = this.userSettingsModel.rollingTimeRangeSelectedItems.startPeriod1
        .replace(/(.{4})/g, '$1-')
        .split('-');
      let edDateAry = this.userSettingsModel.rollingTimeRangeSelectedItems.endPeriod1
        .replace(/(.{4})/g, '$1-')
        .split('-');

      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();

      firstDay_startDate.setFullYear(Number(stDateAry[0]));
      firstDay_startDate.setDate(1);
      firstDay_startDate.setMonth(Number(stDateAry[1]) - 1);

      lastDay_endDate.setDate(1);
      lastDay_endDate.setFullYear(Number(edDateAry[0]));
      lastDay_endDate.setMonth(Number(edDateAry[1]));
      lastDay_endDate.setDate(0);

      requestbody['rollingPeriodId'] = this.userSettingsModel.rollingTimeRangeSelectedItems.id;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + (firstDay_startDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else if (
      this.userSettingsModel.timeRangeSelected == 'Fixed' &&
      this.userSettingsModel.fixedTimeRange &&
      this.userSettingsModel.fixedTimeRange.startPeriod1 &&
      this.userSettingsModel.fixedTimeRange.endPeriod1
    ) {
      let stDateAry = this.userSettingsModel.fixedTimeRange.startPeriod1.replace(/(.{4})/g, '$1-').split('-');
      let edDateAry = this.userSettingsModel.fixedTimeRange.endPeriod1.replace(/(.{4})/g, '$1-').split('-');

      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();

      firstDay_startDate.setFullYear(Number(stDateAry[0]));
      firstDay_startDate.setDate(1);
      firstDay_startDate.setMonth(Number(stDateAry[1]) - 1);

      lastDay_endDate.setDate(1);
      lastDay_endDate.setFullYear(Number(edDateAry[0]));
      lastDay_endDate.setMonth(Number(edDateAry[1]));
      lastDay_endDate.setDate(0);

      requestbody['rollingPeriodId'] = 9;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + (firstDay_startDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else if (this.userSettingsModel.timeRangeSelected == 'Year To Date(YTD)') {
      const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
      const month = this.firmBasicData.yyyymm.toString().slice(-2);
      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();
      firstDay_startDate.setDate(1);
      firstDay_startDate.setMonth(1);
      firstDay_startDate.setFullYear(parseInt(year));
      lastDay_endDate.setDate(1);
      lastDay_endDate.setMonth(parseInt(month) - 1);
      lastDay_endDate.setFullYear(parseInt(year));
      requestbody['rollingPeriodId'] = 0;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + firstDay_startDate.getMonth()).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else {
      requestbody['rollingPeriodId'] = null;
      requestbody['startPeriod'] = null;
      requestbody['endPeriod'] = null;
    }

    if (this.userSettingsModel.metricSelectedItems) {
      let result = this.userSettingsModel.metricSelectedItems.filter((item) => true);
      let metrics: number[] = [...new Set(result.map((item) => item.id))];
      requestbody['metrics'] = metrics;
    } else {
      requestbody['metrics'] = [];
    }

    if (this.userSettingsModel.peerGroupSelectedItems) {
      let peerGroups: number[] = [...new Set(this.userSettingsModel.peerGroupSelectedItems.map((item) => item.id))];
      requestbody['peerGroups'] = peerGroups;
    } else {
      requestbody['peerGroups'] = [];
    }

    if (this.userSettingsModel.practiceGroupSelectedItems) {
      let practices: number[] = [...new Set(this.userSettingsModel.practiceGroupSelectedItems.map((item) => item.id))];
      requestbody['practices'] = practices;
    } else {
      requestbody['practices'] = [];
    }

    if (this.userSettingsModel.officeSelectedItems) {
      let offices: number[] = [...new Set(this.userSettingsModel.officeSelectedItems.map((item) => item.id))];
      requestbody['offices'] = offices;
    } else {
      requestbody['offices'] = [];
    }

    if (this.userSettingsModel.titleSelectedItems) {
      let titles: number[] = [...new Set(this.userSettingsModel.titleSelectedItems.map((item) => item.id))];
      requestbody['titles'] = titles;
    } else {
      requestbody['titles'] = [];
    }

    let experienceYears = {};

    if (this.userSettingsModel.partnerYearSelectedItems) {
      let partnerYears: number[] = [...new Set(this.userSettingsModel.partnerYearSelectedItems.map((item) => item.id))];
      experienceYears['partnerYears'] = partnerYears;
    } else {
      experienceYears['partnerYears'] = [];
    }

    if (this.userSettingsModel.srAssociateYearSelectedItems) {
      let srAssociateYears: number[] = [
        ...new Set(this.userSettingsModel.srAssociateYearSelectedItems.map((item) => item.id)),
      ];
      experienceYears['srAssociateYears'] = srAssociateYears;
    } else {
      experienceYears['srAssociateYears'] = [];
    }

    if (this.userSettingsModel.srAssociateYearSelectedItems) {
      let associateYears: number[] = [
        ...new Set(this.userSettingsModel.associateYearSelectedItems.map((item) => item.id)),
      ];
      experienceYears['associateYears'] = associateYears;
    } else {
      experienceYears['associateYears'] = [];
    }

    requestbody['experienceYears'] = experienceYears;

    requestbody['matterType'] = this.userSettingsModel.viewsSettings.matterType.toLowerCase();
    requestbody['contractors'] = this.userSettingsModel.viewsSettings.contractorsType.toLowerCase();
    requestbody['currency'] = this.userSettingsModel.viewsSettings.currencyType.toLowerCase();
    requestbody['comparison'] = this.userSettingsModel.viewsSettings.comparisonType.toLowerCase();
    requestbody['statistics'] = this.userSettingsModel.viewsSettings.statisticsType.toLowerCase();

    return requestbody;
  }

  goForCustomize() {
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Customize click', {email: this.authService.getUserEmail()}).build());
    }
    this.peercheckMessageHandlerService.setIsCustomRequest = true;
    this.peercheckMessageHandlerService.setCreateViewUserSettings = this.userSettingsModel;
    if (this.viewDetails) this.peercheckMessageHandlerService.setSelectedViewId = this.viewDetails.viewId;
    this.peercheckMessageHandlerService.setViewDetails = this.viewDetails;
    if (this.isTAdmin) {
      if (!this.selectedFirmId) this.router.navigate(['/firm/' + this.getDefaultOrSelectedFirmId + '/create-view']);
      else this.router.navigate(['/firm/' + this.selectedFirmId + '/create-view']);
    } else {
      this.router.navigate(['/create-view']);
    }
  }

  exportView() {
    const modalRef = this.modalService.open(ExportModalComponent, {size: 'lg'});
    modalRef.componentInstance.triggeredFrom = 'Landing';
    modalRef.componentInstance.firmBasicData = this.firmBasicData;
    modalRef.componentInstance.viewDetails = this.viewDetails;
    modalRef.componentInstance.userSettingsModel = this.userSettingsModel;
    modalRef.componentInstance.firmId = this.selectedFirmId;
    modalRef.result.then(
      (result) => {
        if (result == 'Success') {
          this.alerts.push({
            type: 'success',
            msg: 'Data saved Successfully.',
            timeout: 2500,
            closeable: true,
          });
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      },
      (reason) => {}
    );
  }

  getDefaultViewDetails() {
    //this.isDataLoading = true;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/view/firm/' + this.selectedFirmId + '/default', '')
      .subscribe(
        (result) => {
          this.isFirmBasicLoaded = true;
          this.viewDetails = result;
          this.userSettingsModel = this.peercheckMessageHandlerService.processSelectedViewDetails(
            this.viewDetails,
            this.userSettingsModel,
            this.firmBasicData.yyyymm
          );
          this.updateSelection();
        },
        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  updateSelection(requestOrigin: any = null) {
    if (this.viewGridComponent) this.viewGridComponent.updateSummary();

    this.userSettingsModel.isUKFirm = this.firmBasicData.defaultAccess == 'UK';
    this.userSettingsModel.isCANFirm = this.firmBasicData.defaultAccess == 'CAN';

    let requestbody: any = this.peercheckMessageHandlerService.createRequestBody(
      this.userSettingsModel,
      this.firmBasicData,
      'gas',
      false,
      this.selectedMetric,
      null,
      false
    );
    if (!requestbody || !this.firmBasicData || !this.pcViewCols) {
      let error = this.peercheckMessageHandlerService.getCustomErrors(this.userSettingsModel);
      error.forEach((err) => {
        this.alerts.push({
          type: 'warning',
          msg: err,
          closeable: true,
        });
      });
      this.isDataLoading = false;
      return;
    }
    this.isDataLoading = true;
    this.userSettingsModel.cardTitles = [];
    this.service
      .post(environment.GetDetailsBaseEndpoint + 'v1/view/details/' + this.selectedFirmId, requestbody)
      .subscribe(
        (result) => {
          let diffOfYear = this.peercheckMessageHandlerService.diffOfYears(
            requestbody.endPeriod,
            requestbody.startPeriod
          );
          this.yoyHeader = diffOfYear && diffOfYear > 1 ? 'Rolling growth' : 'YOY growth';
          if (this.peercheckMessageHandlerService && result) {
            let response = Array.isArray(result) && result.length == 1 ? result[0] : result;
            this.processedData = this.peercheckMessageHandlerService.processDataForMainGrid(
              response,
              this.userSettingsModel,
              null,
              this.pcViewCols,
              this.firmBasicData,
              this.yoyHeader
            );
            this.viewGridComponent.selectedFirmId = this.selectedFirmId;
            this.viewGridComponent.updateGrid(this.processedData, null);
          }
          if (this.processedData.hasEmptyResult) {
            this.alerts.push({
              type: 'warning',
              msg: 'No data available to display.',
              closeable: true,
            });
          }
        },
        (error) => {
          this.errorMessage = error.error;
          this.processedData = this.peercheckMessageHandlerService.processDataForMainGrid(
            null,
            this.userSettingsModel,
            null,
            this.pcViewCols,
            this.firmBasicData
          );
          this.viewGridComponent.selectedFirmId = this.selectedFirmId;
          this.viewGridComponent.updateGrid(this.processedData, null, true);
          if (!requestOrigin || !requestOrigin.requestFrom || requestOrigin.requestFrom != 'openViewSettings') {
            let errMsg = 'Something went wrong, please try again.';
            if (this.peercheckMessageHandlerService) {
              errMsg = this.peercheckMessageHandlerService.getServiceErrorMessageString(this.errorMessage);
            }

            this.alerts.push({
              type: 'warning',
              msg: errMsg,
              closeable: true,
            });
          }
        }
      );
  }

  dataLoadingStateChange(currentDataLoadingState) {
    this.isDataLoading = currentDataLoadingState;
  }

  menuOnSelectChange(name) {
    if (name === 'Export') {
      this.exportView();
    } else if (name === 'Settings') {
      this.viewGridComponent.openViewSettings();
    } else if (name === 'Edit Table') {
      this.goForCustomize();
    }
  }
  public get userName(): string {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.fullName;
  }
}
