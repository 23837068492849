<bento-alert [items]="alerts"></bento-alert>
<div class="GlobalLayout GlobalLayout--twoColumn" role="main">
  <main id="MainContent" tabindex="-1" class="Main">
    <header class="Main-header">
      <app-global-breadcrumbs
        [isFirmPeerGroup]="true"
        (updateService)="handleUserActionCancelPPG(false)"
        [peerLabel]="'Performance peer groups'"
        [firmName]="'Create a view'"
      ></app-global-breadcrumbs>
      <h1 class="Main-heading">Performance peer groups</h1>
    </header>
    <div class="Main-body">
      <div class="GlobalLayout-group">
        <div class="GlobalLayout-item GlobalLayout-item--small">
          <bento-side-nav class="NavSide" aria-label="Performance peer groups" [items]="items"></bento-side-nav>
        </div>
        <div class="GlobalLayout-item GlobalLayout-item--large">
          <app-create-performance-peer-group
            class="u-flexGrowCol"
            [isCanFirm]="isCanFirm"
            (handleUserAction)="usersActionHandler($event)"
            (handleCancelPPG)="handleUserActionCancelPPG($event)"
            *ngIf="items[0].data.isVisible"
          ></app-create-performance-peer-group>
          <app-current-performance-peer-groups
            class="u-flexGrowCol"
            [isCanFirm]="isCanFirm"
            [isPPGSaved]="isPPGSaved"
            (handleDeletePPG)="handleUserActionDeletePPG($event)"
            *ngIf="items[1].data.isVisible"
          ></app-current-performance-peer-groups>
        </div>
      </div>
    </div>
  </main>
</div>
