import {Component, ViewChild} from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import {environment} from 'src/environments/environment';
import {BaseService} from 'src/app/core/services/base/base.service';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import StaffingratioFirmSettings, {
  CustomSegmentRequestBody,
  SRDataProcessing,
} from 'src/app/core/models/staffingration-subscription-settings.model';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';

@Component({
  selector: 'app-staffing-ratio-cutomise-segment',
  templateUrl: './staffing-ratio-cutomise-segment.component.html',
  styleUrls: ['./staffing-ratio-cutomise-segment.component.scss'],
})
export class StaffingRatioCutomiseSegmentComponent {
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  currentRecords: number = 0;
  totalRecords: number = 0;
  enableClearFilter = false;
  data: wjcCore.CollectionView;
  allFirmSegmentData: StaffingratioFirmSettings[] = [];
  originalAllFirmSegmentData: StaffingratioFirmSettings[] = [];
  isDataLoading: boolean = false;
  alerts: BentoAlertItemOptions[] = [];
  currentSurveyYearOfFirm: number;
  gridsSelectedRow = 0;
  gridsSelectedCol = 0;
  calledFirstTime = true;
  lastPressedKey: any;
  constructor(private authService: AuthService, private service: BaseService) {}

  async ngOnInit(): Promise<void> {
    this.isDataLoading = true;
    await this.getCurrentSurveyYear();
    await this.getAllSegmentData();
    this.allFirmSegmentData.forEach((val) => this.originalAllFirmSegmentData.push(Object.assign({}, val)));
    this.data = new wjcCore.CollectionView(this.allFirmSegmentData);
    this.totalRecords = this.allFirmSegmentData.length;
    this.currentRecords = this.totalRecords;
  }
  gridInitialized(flexGrid: wjcGrid.FlexGrid, gridHeaderId: string = '') {
    flexGrid.rows.defaultSize = 42;
    flexGrid.columnHeaders.rows.defaultSize = 42;
    flexGrid.autoGenerateColumns = false;
    // flexGrid.select(-1,-1);
    this.flexGrid = flexGrid;
    flexGrid.hostElement.addEventListener('keydown', (e) => {
      this.lastPressedKey = e.code;
    });
  }
  focusOnGrid(flexGrid, event) {
    if (!this.calledFirstTime) flexGrid.select(this.gridsSelectedRow, this.gridsSelectedCol);
    else {
      this.calledFirstTime = false;
      flexGrid.select(0, 0);
    }
  }

  initFilter() {
    this.gridFilter.showSortButtons = false;
  }

  onFilterChange() {
    this.enableClearFilter = true;
  }
  onFilterApply() {
    this.currentRecords = this.flexGrid.rows.length;
  }
  saveCustomeSegment(row: StaffingratioFirmSettings, cell, columnName: string) {
    let indx = this.allFirmSegmentData.indexOf(row);
    let originalOption = this.originalAllFirmSegmentData[indx];
    if (
      (columnName == 'amLawSegment' && originalOption.amLawSegment != row.amLawSegment) ||
      (columnName == 'lawyerSegment' && originalOption.lawyerSegment != row.lawyerSegment) ||
      (columnName == 'officeSegment' && originalOption.officeSegment != row.officeSegment)
    ) {
      row.dataProcessing.amLawSegment = columnName == 'amLawSegment' ? true : false;
      row.dataProcessing.lawyerSegment = columnName == 'lawyerSegment' ? true : false;
      row.dataProcessing.officeSegment = columnName == 'officeSegment' ? true : false;

      // if(cell && cell.trim().length >0){
      let requestBodyArray: CustomSegmentRequestBody[] = [];
      let requestBody = new CustomSegmentRequestBody();
      requestBody.firmId = row.firmId;
      requestBody.surveyYear = this.currentSurveyYearOfFirm;
      requestBody.amLawSegment = row.amLawSegment;
      requestBody.lawyerSegment = row.lawyerSegment;
      requestBody.officeSegment = row.officeSegment;
      requestBodyArray.push(requestBody);

      this.service
        .post(environment.FIClientBaseEndpoint + 'v1/staffingratio/subscription/segment', requestBodyArray)
        .subscribe(
          (result) => {
            this.originalAllFirmSegmentData[indx] = JSON.parse(JSON.stringify(row));
            row.dataProcessing.amLawSegment = false;
            row.dataProcessing.lawyerSegment = false;
            row.dataProcessing.officeSegment = false;
            this.alerts.push({
              type: 'success',
              msg: 'Data saved successfully.',
              timeout: 2500,
              closeable: true,
            });
            let cell = this.flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
            cell.focus();
          },

          (error) => {
            row.amLawSegment = originalOption.amLawSegment;
            row.lawyerSegment = originalOption.lawyerSegment;
            row.officeSegment = originalOption.officeSegment;
            row.dataProcessing.amLawSegment = false;
            row.dataProcessing.lawyerSegment = false;
            row.dataProcessing.officeSegment = false;
            this.alerts.push({
              type: 'warning',
              msg: error.error,
              closeable: true,
            });
            let cell = this.flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
            cell.focus();
          }
        );
    }
  }
  getAllSegmentData() {
    return this.service
      .get(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/subscriptions/' +
          this.firmId +
          '?surveyYear=' +
          this.currentSurveyYearOfFirm,
        ''
      )
      .toPromise()
      .then(
        (result) => {
          this.allFirmSegmentData = result;
          this.allFirmSegmentData.forEach((o) => {
            o.dataProcessing = new SRDataProcessing();
          });
          this.isDataLoading = false;
        },
        (error) => {
          this.isDataLoading = false;
          this.alerts.push({
            type: 'warning',
            msg: error.error.message,
            closeable: true,
          });
        }
      );
  }
  private get firmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims && backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  getCurrentSurveyYear() {
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/surveyyear/' + this.firmId, '')
      .toPromise()
      .then(
        (result) => {
          this.currentSurveyYearOfFirm = result;
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: error.error.message,
            closeable: true,
          });
        }
      );
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    this.gridsSelectedCol = args.col;
    this.gridsSelectedRow = args.row;
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'nearest',
        });
      }
    }
  }

  handleKeyDown(flexGrid, event) {
    if (event.code === 'Space') {
      let cell = flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
      let input = cell.querySelector('input');
      input.focus();
      wjcCore.setAttribute(cell as Element, 'aria-selected', (input as HTMLInputElement).checked);
    }
  }
}
