import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import {BaseService} from 'src/app/core/services/base/base.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import {environment} from 'src/environments/environment';
import StaffingratioAdminConfiguration from 'src/app/core/models/staffingration-admin-configuration.model';
import {BackendTokenClaims} from '../../../../models/tokenResponse';
import {FlagRequestBody} from 'src/app/core/models/staffingration-admin-configuration.model';
import {setSelectionRange} from '@grapecity/wijmo';
import {FilterType} from '@grapecity/wijmo.grid.filter';
@Component({
  selector: 'app-admin-configurations',
  templateUrl: './admin-configurations.component.html',
  styleUrls: ['./admin-configurations.component.scss'],
})
export class AdminConfigurationsComponent {
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  @ViewChild('staffingRatioPeerGroupsSearchBtn', { static: false }) searchButton: ElementRef;
  
  alerts: BentoAlertItemOptions[] = [];
  enableClearFilter = false;
  currentSurveyYearOfFirm: string;
  data: wjcCore.CollectionView;
  isDataLoading: boolean = true;
  firmsFlagData: StaffingratioAdminConfiguration[] = [];
  originalFirmsFlagData: StaffingratioAdminConfiguration[] = [];
  firmsFlagGridData: StaffingratioAdminConfiguration[] = [];
  searchText = '';
  zeroOneMap: wjcGrid.DataMap;
  currentRecords: number = 0;
  totalRecords: number = 0;
  lastPressedKey: any = null;
  constructor(private authService: AuthService, private service: BaseService) {}
  async ngOnInit(): Promise<void> {
    this.isDataLoading = true;
    let zeroOneMapStructure = [
      {value: true, display: 'true'},
      {value: false, display: 'false'},
      {value: null, display: 'false'},
    ];

    this.zeroOneMap = new wjcGrid.DataMap(zeroOneMapStructure, 'value', 'display');

    await this.getCurrentSurveyYear();
    await this.getAllFlags();
    this.firmsFlagData.forEach((val) => this.originalFirmsFlagData.push(Object.assign({}, val)));
    this.totalRecords = this.firmsFlagData.length;
    this.data = new wjcCore.CollectionView(this.firmsFlagData);
    this.data.collectionChanged.addHandler(() => {
      this.gridFilter.getColumnFilter('firmName').filterType = wjcGridFilter.FilterType.Both;
      this.gridFilter.getColumnFilter('dateTimeReportImported').filterType = wjcGridFilter.FilterType.Both;
      this.gridFilter.getColumnFilter('dateTimeReportExported').filterType = wjcGridFilter.FilterType.Both;
    });
  }
  gridInitialized(flexGrid: wjcGrid.FlexGrid, filter: wjcGridFilter.FlexGridFilter) {
    flexGrid.hostElement.addEventListener(
      'keydown',
      (e) => {
        this.lastPressedKey = e.code;
        if (e.code === 'ArrowDown' && flexGrid.rows.length > 8 && (flexGrid.selection.row % 4 == 0 || flexGrid.selection.row  >= flexGrid.rows.length-5) && flexGrid.selection.col == 7) {
          const documentHeight = document.body.scrollHeight;
          const scrollTop =  document.documentElement.scrollTop + 42*4;
          window.scrollTo({
            top: scrollTop,
            behavior: 'smooth'
          });
        }
        if (e.code === 'ArrowUp' && flexGrid.rows.length > 8 && (flexGrid.selection.row % 5 == 0 || flexGrid.selection.row <=5) && flexGrid.selection.col == 7) {
          const scrollTop =  document.documentElement.scrollTop - 42*4;
          window.scrollTo({
            top: scrollTop,
            behavior: 'smooth'
          });
        }
        if (e.shiftKey && e.code === 'Tab') {
          //Check if last cell of teh grid if itis then focus out
          if (
            !(
              this.flexGrid.selection.row == 0 &&
            this.flexGrid.selection.col == 0
            )
          ) {
            if (this.flexGrid.selection.row == -1 && this.flexGrid.selection.col == -1) {
              flexGrid.select(0, 0);
            } else {
              let cell = this.getPreviousColumnToSelect();
              flexGrid.select(cell.row, cell.col);
            }
            e.preventDefault();
          } else {
            this.searchButton.nativeElement.focus();
          }
        }
        //Manually select the next cell.
        else if (e.code === 'Tab') {
          //Check if last cell of teh grid if itis then focus out
          if (
            !(
              this.flexGrid.rows.length == this.flexGrid.selection.row + 1 &&
              this.flexGrid.columns.length == this.flexGrid.selection.col + 1
            )
          ) {
            if (this.flexGrid.selection.row == -1 && this.flexGrid.selection.col == -1) {
              flexGrid.select(0, 0);
            } else {
              let cell = this.getNextColumnToSelect();
              flexGrid.select(cell.row, cell.col);
            }
            e.preventDefault();
          } else {
            flexGrid.select(-1, -1);
          }
        }
        
      },
      true
    );
    flexGrid.rows.defaultSize = 42;
    flexGrid.columnHeaders.rows.defaultSize = 42;
    this.flexGrid = flexGrid;
    filter.defaultFilterType = wjcGridFilter.FilterType.Value;
    this.currentRecords = flexGrid.rows.length;
  }

  getNextColumnToSelect() {
    let grid = this.flexGrid;
    let col = grid.selection.rightCol + 1,
      row = grid.selection.bottomRow;

    // If last cell in a row is already selected.
    if (col >= grid.columns.length) {
      // If there are any more rows in the grid.
      if (row + 1 < grid.rows.length) row++;
      return {col: 0, row};
    }
    //
    return {col, row};
  }
  getPreviousColumnToSelect() {
    let grid = this.flexGrid;
    let col = grid.selection.col - 1,
      row = grid.selection.row;

    // If last cell in a row is already selected.
    if (col < 0) {
      // If there are any more rows in the grid.
      if (row > 0) row--;
      return {col: grid.columns.length - 1, row};
    }
    //
    return {col, row};
  }
  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    if (this.lastPressedKey === 'Tab') {
      let column = args.getColumn();
      if (
        column.binding === 'firmDataValid' ||
        column.binding === 'isPeerDisplayed' ||
        column.binding === 'peerSubmitted' ||
        column.binding === 'reportReady' ||
        column.binding === 'isFirmDisabled'
      ) {
        let input = args.panel.getCellElement(args.row, args.col).querySelector('input');
        // Select the content of the input element.
        input.focus();
        setSelectionRange(input, 0, -1);
      }
      this.lastPressedKey = null;
    }
  }
  onFilterChange() {
    this.enableClearFilter = true;
  }
  onFilterApply() {
    this.currentRecords = this.flexGrid.rows.length;
  }
  onCheckBoxChange(row) {
    row.isUpdating = true;
    let requestBodyArray = [];
    let requestBody = new FlagRequestBody();
    requestBody.firmId = row.firmId;
    requestBody.surveyYear = row.surveyYear;
    requestBody.firmDataValid = row.firmDataValid == null ? false : row.firmDataValid;
    requestBody.firmDisabled = row.isFirmDisabled == null ? false : row.isFirmDisabled;
    requestBody.peersDisplayed = row.isPeerDisplayed == null ? false : row.isPeerDisplayed;
    requestBody.reportsReady = row.reportReady == null ? false : row.reportReady;
    requestBody.peersSubmitted = row.peerSubmitted == null ? false : row.peerSubmitted;
    requestBody.firmId = row.firmId;
    requestBodyArray.push(requestBody);
    this.service
      .put(environment.FIClientBaseEndpoint + 'v1/staffingratio/subscription/flags', requestBodyArray)
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          row.isUpdating = false;
          this.alerts.push({
            type: 'success',
            msg: 'Data saved successfully.',
            timeout: 2500,
            closeable: true,
          });
        },

        (error) => {
          row.isUpdating = false;
          this.isDataLoading = false;
          this.alerts.push({
            type: 'warning',
            msg: error.error,
            closeable: true,
          });
        }
      );
  }
  getCurrentSurveyYear() {
    this.isDataLoading = true;
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/surveyyear/' + this.firmId, '')
      .toPromise()
      .then(
        (result) => {
          this.currentSurveyYearOfFirm = result;
        },
        (error) => {
          this.alerts.push({
            type: 'warning',
            msg: error.error.message,
            closeable: true,
          });
        }
      );
  }
  getAllFlags() {
    return this.service
      .get(environment.FIClientBaseEndpoint + 'v1/staffingratio/subscription/flags/' + this.currentSurveyYearOfFirm, '')
      .toPromise()
      .then(
        (result) => {
          this.firmsFlagData = result;
          this.firmsFlagGridData = this.firmsFlagData;
        },
        (error) => {
          this.isDataLoading = false;
          this.alerts.push({
            type: 'warning',
            msg: error.error.message,
            closeable: true,
          });
        }
      );
  }
  private get firmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO.firmID;
  }
  searchStaffingRatioFirm() {
    this.firmsFlagGridData = this.firmsFlagData.filter(
      (element) => element.firmName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
    );
    this.data = new wjcCore.CollectionView(this.firmsFlagGridData);
  }
  initFilter() {
    this.gridFilter.showSortButtons = false;
  }

  onTabFromSearchButton(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      // Focus is leaving the button via Tab key
      this.flexGrid.select(0, 0);
    }
  }
}
