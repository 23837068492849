import {AfterViewChecked,Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BentoAlertItemOptions, BentoTagsInputEvent} from '@bento/bento-ng';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import FirmDetailModel from 'src/app/core/models/firmdetail.model';
import {
  FirmPeerGroupList,
  PeerGroupFirms,
  PeerGroupRequestBody,
  SRPresetPeerGroupRequestBody,
} from 'src/app/core/models/firmStaffingpeergroupstatus.model';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {StaffingPeergroupService} from 'src/app/core/services/staffing-peergroup/staffing-peergroup.service';
import {Role} from 'src/app/shared/enums';
import {environment} from 'src/environments/environment';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {StaffingRatioPeerGroupList} from 'src/app/core/models/staffingRatioPeerGroupList.model';
import * as mockfirmdata from './mock-firm-data.json';
import {Firmpeergroupstatus} from 'src/app/core/models/firmStaffingpeergroupstatus.model';
import {Router} from '@angular/router';
import {FilterType} from '@grapecity/wijmo.grid.filter';
import {CellType} from '@grapecity/wijmo.grid';
import { AxeToolCommonMethodsService } from 'src/app/core/services/axe-tool-common-methods/axe-tool-common-methods.service';
declare let TRAAC: any;

@Component({
  selector: 'app-staffing-ratio-add-peer-groups',
  templateUrl: './staffing-ratio-add-peer-groups.component.html',
})
export class StaffingRatioAddPeerGroupsComponent implements OnInit, AfterViewChecked {
  @Input() selectedStaffingRatioPeerGroup: StaffingRatioPeerGroupList = new StaffingRatioPeerGroupList();
  @Output() backToStaffingRatioPeerGroupsAction = new EventEmitter();
  //  selectedFirmId:any;
  @Input() currentSurveyYear: any = '';
  selectedOption: FirmPeerGroupList = null;
  isEditOrAddPeerGroup = false;
  tagInputSelection: any[] = [];
  data: wjcCore.CollectionView;
  CHECKBOX_SELECTED = 1;
  CHECKBOX_UNSELECTED = 2;
  headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
  CHECKBOX_INDETERMINATE = 3;
  selectBinding = 'selected';
  searchText: String = '';
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  gridSelection: any[] = [];
  @Input() isShowSelectedFirms: boolean = false;
  @Input() isShowAllFirms: boolean = false;
  peerGroupName: string;
  isReadyToSave = false;
  @Input() firmDetails: FirmDetailModel = new FirmDetailModel();
  isDataLoading: boolean;
  isFilterReset = false;
  enableClearFilter = false;
  errorMessage: any;
  alerts: BentoAlertItemOptions[] = [];
  availablePeerGroupFirms: PeerGroupFirms[] = [];
  selectedPeerGroupFirms: PeerGroupFirms[] = [];
  selectedPeerGroupFirmIds: number[] = [];
  sample_data: any = (mockfirmdata as any).default;
  currentRecords: number;
  totalRecords: number;
  isLoadedFirstTime: boolean;
  mandatorySelectionCount = 0;
  isQuickSightEnabled = false;
  peerGroupStatus = new Firmpeergroupstatus();
  IsShowSelectedFirmsActive: boolean = false;
  selectedfirmsCount = 0;
  hideFooter = false;
  lastPressedKey: any = null;
  gridsSelectedRow = 0;
  gridsSelectedCol = 0;
  currentInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };

  constructor(
    private staffingPeergroupService: StaffingPeergroupService,
    private authService: AuthService,
    private service: BaseService,
    private featureToggleService: FeatureToggleService,
    private router: Router,
    private modalService: NgbModal,
    private axeToolService: AxeToolCommonMethodsService
  ) {}

  async ngOnInit(): Promise<void> {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /**************************************************************/
    this.getPeerGroupStatusData();
    let customPeerGroupMode = this.staffingPeergroupService.getCustomPeerGroupMode;
    if (customPeerGroupMode) {
      this.isEditOrAddPeerGroup = customPeerGroupMode == 'edit' ? true : false;
    }
    //console.log(mockfirmdata);
    let selectedOption = this.staffingPeergroupService.getselectedPeerGroup;
    if (selectedOption) {
      this.selectedOption = selectedOption;
    }

    let selectedFirms = this.staffingPeergroupService.getSelectedPeerGroupFirms;
    if (selectedFirms && this.isEditOrAddPeerGroup) {
      this.isShowSelectedFirms = true;
      this.selectedPeerGroupFirms = selectedFirms;
      this.selectedPeerGroupFirmIds = [
        ...new Set(this.selectedPeerGroupFirms.map((item: PeerGroupFirms) => item.firmId)),
      ];
    }
    if (this.currentSurveyYear != '') this.getAvailableFirms();

    this.data = new wjcCore.CollectionView();
    this.data.pageSize = this.currentInfo.pageSize;
    this.totalRecords = 0;
    this.isLoadedFirstTime = true;

    if (this.isEditOrAddPeerGroup) {
      this.peerGroupName = this.selectedStaffingRatioPeerGroup.peerGroupName;
    }
    this.mandatorySelectionCount = this.firmDetails.defaultAccess == 'CAN' ? 6 : 5;
    this.verifySelection();
  }
  ngAfterViewChecked(): void { 
    let ariaRoleRemoval=document.querySelector("div[wj-part='root']");
    ariaRoleRemoval.removeAttribute('tabindex'); 
  }

  gridInitialized(flexGrid: wjcGrid.FlexGrid, gridHeaderId: string = '') {
    let rowIndex: any;


    flexGrid.hostElement.addEventListener(
      'keydown',
      (e : any) => {
        this.lastPressedKey = e.code;
        const isCell = wjcCore.hasClass(e.target as Element, 'wj-cell');
        const isRowHeader = wjcCore.closestClass(e.target as Element, 'wj-rowheaders');
        const isTopLeft = wjcCore.closestClass(e.target as Element, 'wj-topleft');
        //Select ColumN Header Filters on Space
        let sel = flexGrid.selection;
        const isColHeaderCell =
          wjcCore.closestClass(e.target, 'wj-colheaders') && wjcCore.hasClass(e.target as Element, 'wj-header');
        const cf = this.gridFilter.getColumnFilter(sel.col, false); // check if filter exists
        if (isColHeaderCell && e.code === 'Space' && cf && cf.filterType != FilterType.None) {
          // with type != None
          this.gridFilter.editColumnFilter(sel.col); // then show it
          e.preventDefault(); // and kill the event
          e.stopPropagation();
        }
        if (wjcCore.closestClass(e.target, 'wj-colheaders')) return; // Skip column header

        if (isCell && isRowHeader || isTopLeft) {
          const chk = (e.target as Element).querySelector('input[type="checkbox"]');
          const rowIndexID = chk?.getAttribute('id') || '';
          rowIndex = rowIndexID ? rowIndexID.replace(/\D/g, '') : -1;
          rowIndex = parseInt(rowIndex);
          if (e.code === 'Space' && chk) {
            e.preventDefault();          
            (chk as HTMLInputElement).click();
            (chk as HTMLInputElement).checked = !(chk as HTMLInputElement).checked
            chk.setAttribute('aria-checked', "'"+(chk as HTMLInputElement).checked+"'");
          }
        }
      

        // Toggle the selected class on the header cell
        // if (wjcCore.hasClass(e.target as Element, 'wj-state-selected')) {
        //   wjcCore.removeClass(e.target as Element, 'wj-state-selected');
        // } else {
        //   wjcCore.addClass(e.target as Element, 'wj-state-selected');
        // }
      },
      true
    );
    flexGrid.hostElement.addEventListener(
      'click',
      (e) => {
        //when checkbox is clicked then using arrow doesnt work as focus is still on the checkbox. Placeholder for code. 
      },
      true
    );

    flexGrid.formatItem.addHandler((s, e:any) => {

     //******Added for correct column numbers with rownumber and work with NVDA Focus mode"off*****
      const isRowHeader = wjcCore.closestClass(e.target as Element, 'wj-rowheaders');
      e.cell.id = `cell-${e.panel.cellType}-${e.row}-${e.col}`;
      const currentRow = e.cell.parentElement;

      if (
        currentRow &&
        currentRow.classList.contains('wj-row') &&
        (e.panel.cellType === wjcGrid.CellType.Cell || e.panel.cellType === wjcGrid.CellType.ColumnHeader) &&
        e.col === s.columns.firstVisibleIndex &&
        e.cell.parentElement.getAttribute('aria-owns') == null
      ) {
        let ownedElementsList = '';

        const rowHeaderType = e.panel.cellType === wjcGrid.CellType.ColumnHeader ? wjcGrid.CellType.TopLeft : wjcGrid.CellType.RowHeader;

        // Place id for rowHeader cells.
        for (let colIndex = 0; colIndex < s.rowHeaders.columns.length; colIndex++) {
          ownedElementsList += `cell-${rowHeaderType}-${e.row}-${colIndex} `;
        }

        // Place id for data cells.
        for (let colIndex = 0; colIndex < s.columns.length; colIndex++) {
          ownedElementsList += `cell-${e.panel.cellType}-${e.row}-${colIndex} `;
        }
        ownedElementsList = ownedElementsList.slice(0, ownedElementsList.length - 1);
        //
        currentRow.setAttribute('aria-owns', ownedElementsList);
      }
      // Calculate and set colIndex on data cell columns while considering the column count from rowHeader columns.
      let colIndex = s.rowHeaders.columns.length + e.col + 1;
      if (!isRowHeader && e.panel.cellType === wjcGrid.CellType.Cell) {
        e.cell.setAttribute('aria-colindex', colIndex.toString());     
      }
      if (e.panel.cellType === wjcGrid.CellType.ColumnHeader) {
        e.cell.setAttribute('aria-colindex', colIndex.toString());   
      }
      //************************************/
      if (e.panel.cellType == wjcGrid.CellType.TopLeft || e.panel.cellType == wjcGrid.CellType.RowHeader) {
        e.cell.removeAttribute('role')
        e.cell.setAttribute('role', 'gridcell');
      }

      if (e.panel == s.columnHeaders) {
        let cell = e.cell;
        if (cell) {
          cell.focus();
        }
      }
    });

    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    flexGrid.autoGenerateColumns = false;
    // flexGrid.select(-1,-1)
    this.flexGrid = flexGrid;
    if (this.isEditOrAddPeerGroup) {
      this.onCheckBoxChange();
    }
   // if (gridHeaderId && flexGrid) flexGrid.cells.hostElement.setAttribute('aria-labelledby', gridHeaderId);
    this.currentRecords = flexGrid.rows.length;
  }

  refreshCells() {
    if (this.flexGrid) {
      this.flexGrid.refreshCells(true);
    }
  }
  
  getNextColumnToSelect() {
    let grid = this.flexGrid;
    let col = grid.selection.rightCol + 1,
      row = grid.selection.bottomRow;

    // If last cell in a row is already selected.
    if (col >= grid.columns.length) {
      // If there are any more rows in the grid.
      if (row + 1 < grid.rows.length) row++;
      return {col: 0, row};
    }
    //
    return {col, row};
  }

  isAllSelected() {
    this.selectedfirmsCount = this.gridSelection.length;
    return this.headerCheckBoxMode === this.CHECKBOX_SELECTED;
  }

  isIndeterminate() {
    this.selectedfirmsCount = this.gridSelection.length;
    return this.headerCheckBoxMode === this.CHECKBOX_INDETERMINATE;
  }

  /**
   * Event Handler for Multi-Select Column Header Checkbox
   */
  onHeaderCheckBoxChange() {
    let selected = false;
    if (this.headerCheckBoxMode !== this.CHECKBOX_SELECTED) {
      this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      selected = true;
      this.staffingPeergroupService.setIsShowSelectedFirms = true;
    } else {
      this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
      selected = false;
      //this.staffingPeergroupService.setIsShowSelectedFirms = false;
      //this.staffingPeergroupService.setIsShowAllFirms = true;
    }
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        this.flexGrid.rows[i].dataItem[this.selectBinding] = selected;
        this.flexGrid.rows[i].cssClass = selected ? 'row-selected' : '';
        selected
          ? this.saveSelection(this.flexGrid.rows[i].dataItem)
          : this.removeSelection(this.flexGrid.rows[i].dataItem);
      }
    }
    this.staffingPeergroupService.peerGroupStatusHandler();
    this.getPeerGroupStatusData();
    this.checkBoxAriaLabelChange(selected);
  }

  // handle checkbox change event
  onCheckBoxChange() {
    this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
    let count = 0;
    this.data.sourceCollection.forEach((element, index, data_array) => {
      element.selected ? this.saveSelection(element) : this.removeSelection(element);
      //only selected last grid scenario code
      if (index === data_array.length - 1 && element.selected && !this.IsShowSelectedFirmsActive) {
        this.staffingPeergroupService.setIsShowSelectedFirms = true;
        this.staffingPeergroupService.setIsShowAllFirms = false;

        this.staffingPeergroupService.peerGroupStatusHandler();
      }
    });
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        if (this.flexGrid.rows[i].dataItem[this.selectBinding] === true) {
          count++;
          this.flexGrid.rows[i].dataItem[this.selectBinding] = true;
          this.flexGrid.rows[i].cssClass = 'row-selected';
        } else {
          this.flexGrid.rows[i].cssClass = '';
        }
      }
      if (count === this.flexGrid.rows.visibleLength && this.flexGrid.rows.visibleLength != 0) {
        this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      } else if (count > 0) {
        this.headerCheckBoxMode = this.CHECKBOX_INDETERMINATE;
      }
    }
  }
  selectedFirms(isShowSelectedFirms: any, searchText: String) {
    if (isShowSelectedFirms) {
      this.flexGrid.select(0, 0);
      this.staffingPeergroupService.setIsShowSelectedFirms = false;
      this.staffingPeergroupService.setIsShowAllFirms = true;
      this.IsShowSelectedFirmsActive = true;
      this.staffingPeergroupService.peerGroupStatusHandler();
      this.data = new wjcCore.CollectionView(this.gridSelection);
      this.data.pageSize = this.currentInfo.pageSize;
      this.currentRecords = this.data.itemCount;
      this.totalRecords = this.data.itemCount;
    } else {
      if (this.selectedfirmsCount > 0) this.staffingPeergroupService.setIsShowSelectedFirms = true;

      this.staffingPeergroupService.setIsShowAllFirms = false;
      this.IsShowSelectedFirmsActive = false;
      this.staffingPeergroupService.peerGroupStatusHandler();
      let filterGridData = this.availablePeerGroupFirms;
      if (searchText && searchText != '') {
        filterGridData = this.availablePeerGroupFirms.filter(
          (element) => element.firmName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
        );
        this.flexGrid.select(0, 0);
      }
      this.data = new wjcCore.CollectionView(filterGridData);
      this.data.pageSize = this.currentInfo.pageSize;
      this.currentRecords = this.data.itemCount;
      this.totalRecords = this.data.totalItemCount;
    }
  }
  // Save selection
  saveSelection(selectedOption) {
    const index = this.gridSelection.indexOf(selectedOption);
    if (index != -1) {
      this.gridSelection.splice(index, 1, selectedOption);
    } else {
      this.gridSelection.push(selectedOption);
    }
    this.refreshTags();
  }

  // Remove selection
  removeSelection(selectedOption) {
    const index = this.gridSelection.indexOf(selectedOption);
    if (index != -1) {
      this.gridSelection.splice(index, 1);
    }
    this.selectedfirmsCount = this.gridSelection.length;
    if (this.selectedfirmsCount == 0) {
      this.staffingPeergroupService.setIsShowSelectedFirms = false;
      if (this.IsShowSelectedFirmsActive) this.staffingPeergroupService.setIsShowAllFirms = true;
      else this.staffingPeergroupService.setIsShowAllFirms = false;
    } else {
      this.staffingPeergroupService.setIsShowSelectedFirms = true;
      this.staffingPeergroupService.setIsShowAllFirms = false;
    }
    this.staffingPeergroupService.peerGroupStatusHandler();
    this.getPeerGroupStatusData();
    this.refreshTags();
  }

  // Refresh selected options tags
  refreshTags() {
    var list = [];
    this.gridSelection.forEach((obj) => {
      list.push(obj);
    });
    this.tagInputSelection = list;
    this.verifySelection();
  }

  // Pagination
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
    this.currentRecords = this.flexGrid.rows.length;
    this.onCheckBoxChange();
  }

  onItemsPerPageChanged(itemsPerPage) {
    this.data.pageSize = itemsPerPage;
    this.currentRecords = this.flexGrid.rows.length;
    this.onCheckBoxChange();
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  handleButtonAction(actionfrom: string) {
    // this.staffingPeergroupService.setSelectedTab = 'Peer groups';
    if (actionfrom == 'Cancel') {
      this.updateServiceHandler();
    } else if (actionfrom == 'Delete') {
      this.deletePeerGroup();
    } else if (actionfrom == 'Save') {
      if (this.isEditOrAddPeerGroup) {
        this.updatePeerGroup();
      } else {
        if (this.isQuickSightEnabled) {
          TRAAC.track(TRAAC.keyValue('Add SR Pre-Set Peer group', {email: this.authService.getUserEmail()}).build());
        }
        this.addPeerGroup();
      }
    }
  }

  updateServiceHandler() {
    this.staffingPeergroupService.setCustomPeerGroupMode = null;
    this.staffingPeergroupService.setselectedPeerGroup = null;
    this.staffingPeergroupService.setSelectedPeerGroupFirms = [];
    this.staffingPeergroupService.setIsDefaultView = true;
    this.staffingPeergroupService.peerGroupStatusHandler();
    this.backToStaffingRatioPeerGroups();
  }

  verifySelection() {
    this.isReadyToSave = this.peerGroupName && this.tagInputSelection.length >= this.mandatorySelectionCount;
  }

  searchGlobalPeerGroups() {
    let filterGriddata;
    if (this.IsShowSelectedFirmsActive) {
      filterGriddata = this.data.sourceCollection = this.gridSelection.filter(
        (element) => element.firmName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
      );
      this.currentRecords = this.flexGrid.rows.length;
    } else {
      filterGriddata = this.availablePeerGroupFirms.filter(
        (element) => element.firmName.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
      );
      this.currentRecords = this.flexGrid.rows.length;
    }
    this.data = new wjcCore.CollectionView(filterGriddata);
    this.data.pageSize = this.currentInfo.pageSize;
    this.flexGrid.select(0, 0);
  }

  onTagRemoved(e: BentoTagsInputEvent) {
       e.tag.selected = false;
    this.onCheckBoxChange();
  }

  // getfirmId() should return the selected firmID OR firmID of logged in user.
  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO.firmID;
  }

  getAvailableFirms() {
    this.isDataLoading = true;
    this.service
      .get(
        environment.FIClientBaseEndpoint + 'v1/staffingratio/subscriptions/' + this.getfirmId + '?surveyYear=',
        this.currentSurveyYear
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          //console.log(result);
          this.availablePeerGroupFirms = result;
          let selectedTags = [];
          if (this.isEditOrAddPeerGroup && this.selectedPeerGroupFirmIds.length > 0) {
            this.availablePeerGroupFirms.forEach((element) => {
              if (this.selectedPeerGroupFirmIds.includes(element.firmId)) {
                element.selected = true;
                selectedTags.push(element);
              }
            });
            this.tagInputSelection = selectedTags;
            this.verifySelection();
          }
          this.data = new wjcCore.CollectionView(this.availablePeerGroupFirms);
          this.data.pageSize = this.currentInfo.pageSize;
          if (this.isLoadedFirstTime) {
            this.totalRecords = this.data.totalItemCount;
            this.isLoadedFirstTime = false;
          }

          this.currentRecords = this.data.itemCount;
        },
        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  addPeerGroup() {
    if (!this.isReadyToSave) return;
    this.isDataLoading = true;
    let firmIds: number[] = [...new Set(this.gridSelection.map((item) => item.firmId))];
    let requestBody = new SRPresetPeerGroupRequestBody();
    requestBody.peerGroupName = this.peerGroupName;
    requestBody.surveyYear = this.currentSurveyYear;
    requestBody.isGlobal = true;
    requestBody.firmId = 0;
    requestBody.firmIds = firmIds;
    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/staffingratio/peergroup/' + this.getfirmId, requestBody)
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.alerts.push({
            type: 'success',
            msg: 'Data saved successfully.',
            timeout: 2500,
            closeable: true,
          });
          this.updateServiceHandler();
        },

        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          let errorMsg = '';
          if (this.errorMessage.status == 400) {
            errorMsg = this.errorMessage.message ? this.errorMessage.message : this.errorMessage.messages[0].message;
          } else {
            errorMsg = 'Something went wrong, please try again.';
          }
          this.alerts.push({
            type: 'warning',
            msg: errorMsg,
            closeable: true,
          });
        }
      );
  }

  updatePeerGroup() {
    if (!this.isReadyToSave) return;
    this.isDataLoading = true;
    let firmIds: number[] = [...new Set(this.gridSelection.map((item) => item.firmId))];
    let requestBody = new SRPresetPeerGroupRequestBody();
    requestBody.peerGroupId = this.selectedStaffingRatioPeerGroup.peerGroupId;
    requestBody.peerGroupName = this.peerGroupName;
    requestBody.surveyYear = this.currentSurveyYear;
    requestBody.isGlobal = true;
    requestBody.firmIds = firmIds;
    requestBody.firmId = 0;
    this.service
      .put(environment.FIClientBaseEndpoint + 'v1/staffingratio/peergroup/' + this.getfirmId, requestBody)
      .subscribe(
        (result) => {
          this.alerts.push({
            type: 'success',
            msg: 'Data saved successfully.',
            timeout: 2500,
            closeable: true,
          });
          this.isDataLoading = false;
          this.updateServiceHandler();
        },

        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          let errorMsg = '';
          if (this.errorMessage.status == 400) {
            errorMsg = this.errorMessage.message ? this.errorMessage.message : this.errorMessage.messages[0].message;
          } else {
            errorMsg = 'Something went wrong, please try again.';
          }
          this.alerts.push({
            type: 'warning',
            msg: errorMsg,
            closeable: true,
          });
        }
      );
  }

  removeChartWithConfirmation(removeModalContent) {
    this.hideFooter = true;
    this.modalService
      .open(removeModalContent, {ariaLabelledBy: 'modalPeerGroupRemove', backdrop: 'static', keyboard: false})
      .result.then(
        (result: string) => {
          this.hideFooter = false;
          if (result === 'confirm') {
            this.deletePeerGroup();
          }
        },
        (reason) => {
          this.hideFooter = false;
        }
      );
  }

  deletePeerGroup() {
    if (this.isEditOrAddPeerGroup == false) return;
    this.isDataLoading = true;
    this.service
      .delete(
        environment.FIClientBaseEndpoint +
          'v1/staffingratio/peergroup/' +
          this.selectedStaffingRatioPeerGroup.peerGroupId +
          '/' +
          this.currentSurveyYear,
        ''
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          let alert = [
            {
              type: 'warning',
              msg: '“' + this.selectedStaffingRatioPeerGroup.peerGroupName + '” peer group has deleted.',
              closeable: true,
            },
          ];
          this.staffingPeergroupService.setAlerts = alert;
          this.updateServiceHandler();
        },

        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
  initFilter() {
    this.gridFilter.showSortButtons = false;
  }
  onFilterChange() {
    this.enableClearFilter = true;
  }
  onFilterApply() {
    this.currentRecords = this.flexGrid.rows.length;
  }
  initLoadedRow(s: wjcGrid.FlexGrid) {
    this.onCheckBoxChange();
    this.verifySelection();
  }

  closeAlert(event) {}

  backToStaffingRatioPeerGroups(): void {
    this.backToStaffingRatioPeerGroupsAction.emit();
  }

  getPeerGroupStatusData() {
    this.staffingPeergroupService.currentMessage.subscribe((message) => {
      this.peerGroupStatus = message;
      this.alerts = this.peerGroupStatus.alerts;
    });
  }

  handleSelectionChanged(sender: wjcGrid.FlexGrid, args: wjcGrid.CellRangeEventArgs) {
    this.gridsSelectedCol = args.col;
    this.gridsSelectedRow = args.row;
    let column = args.getColumn();
    // console.log('column', args.col);
    // console.log('row', args.row);
    // sender.scrollIntoView(args.row, args.col);
    if (this.lastPressedKey == 'ArrowDown' || this.lastPressedKey == 'ArrowUp') {
      // gets the input element present in the currently selected cell
      let selectedCell = args.panel.getCellElement(args.row, args.col);
      if (selectedCell) {
        //let gridContainer = sender.hostElement.querySelector('.wj-cells');
        // const containerRect = gridContainer.getBoundingClientRect();
        // console.log('containerRect',containerRect);
        // const selectedCellRect = selectedCell.getBoundingClientRect();
        // console.log('selectedCellRect',selectedCellRect);
        // const isCellVisible = selectedCellRect.top >= containerRect.top && selectedCellRect.bottom <= containerRect.bottom;
        // const isCellPartiallyVisible = selectedCellRect.top < containerRect.top || selectedCellRect.bottom > containerRect.bottom;
        // console.log('isCellVisible',isCellVisible);
        // if(isCellVisible && isCellPartiallyVisible){
        selectedCell.scrollIntoView({
          block: 'center',
          behavior: 'auto',
          inline: 'start',
        });
        //}

        // Select the content of the input element.
        //slectedCell.focus();
        // wjcCore.setSelectionRange(input, 0, -1); // set selection range in input element - https://developer.mescius.com/wijmo/api/index.html#setselectionrange
      }
      this.lastPressedKey = null;
    }
  }

  handleKeyDown(flexGrid, event) {
    if (event.code === 'Space') {
      event.preventDefault();
      let cell = flexGrid.cells.getCellElement(this.gridsSelectedRow, this.gridsSelectedCol);
      let input = cell.querySelector('a');
      input.click();
    }else if (event.keyCode === wjcCore.Key.Tab) {
       this.axeToolService.enableFocusFromCloumnAndRowHeaders(event, 
        'button:not([tabindex="-1"]):not([disabled]):not(.wj-btn .wj-btn-glyph .wj-right .wj-elem-filter),[tabindex]:not([tabindex="-1"]):not([disabled])');
    }
  }
  focusOnGrid(flexGrid: wjcGrid.FlexGrid, event) {
    flexGrid.select(0, 0);
  }

  checkBoxAriaLabelChange(checkbox)
 {
  const statusSpan = document.getElementById('checkboxStatus')
  if (checkbox) {
    statusSpan.textContent = 'Select all Checkbox is checked';
  } else {
    statusSpan.textContent = 'Select all Checkbox is unchecked';
  }
 }
}
